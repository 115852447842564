<template>
  <v-btn
    :class="`${classes}`"
    :disabled="disabled"
    :title="buttontype"
    small
  >
    <v-icon>
      mdi-help
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ChecklistCellButtonTypeDefault',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'buttontype',
    'data'
  ],
  computed: {
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    }
  }
}
</script>
