<template>
  <div
    data-toggable-view="add_comment"
    class="element-content-extra"
  >
    <v-textarea
      v-model="value"
      :placeholder="lang('ChecklistBuilder.placeholders.element_comment')"
      :disabled="disabled"
      rows="1"
      auto-grow
      @change="onChange()"
    />
  </div>
</template>

<script>
export default {
  name: 'ChecklistCellCommentSettings',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      value: this.dataViewer.comment || ''
    }
  },
  computed: {
    disabled() {
      return this.state.disabled
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    onChange() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      this.dataViewer.comment = this.value
      builder.saveChecklistValues(index, this.x, this.y, this.dataViewer)
    }
  }
}
</script>
