<template>
  <div class="wrapper-collection-view mt-3">
    <v-expansion-panels v-model="panel" hover>
      <v-expansion-panel
        v-for="(checklist, index) in checklists"
        :key="index"
      >
        <template v-if="isVisible(checklist)">
          <checklist-accordion-header
            :checklist="checklist"
            :data="data"
          />
          <v-expansion-panel-content
            :key="`${collection.id}_${index}`"
            class="checklist-content"
          >
            <checklist-table
              :checklist="checklist"
              :data="data"
            />
            <template v-if="hasInlineClones">
              <template
                v-for="(_checklist, _index) in checklists"
              >
                <checklist-inline-clone
                  v-if="isVisibleChildClone(checklist, _checklist)"
                  :key="`${collection.id}_${_index}`"
                  :collection="collection"
                  :checklist="_checklist"
                  :data="data"
                />
              </template>
            </template>
          </v-expansion-panel-content>
        </template>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ChecklistTable
  from '@/components/ChecklistBuilder/Viewer/Checklist/Table.vue'
import ChecklistAccordionHeader
  from '@/components/ChecklistBuilder/Viewer/Checklist/AccordionHeader.vue'
import ChecklistInlineClone
  from '@/components/ChecklistBuilder/Viewer/Checklist/InlineClone.vue'

export default {
  name: 'ChecklistAccordion',
  components: {
    ChecklistTable,
    ChecklistAccordionHeader,
    ChecklistInlineClone
  },
  props: [
    'data',
    'filter'
  ],
  data() {
    return {
      status: [],
      panel: 0
    }
  },
  computed: {
    checklists() {
      return this.data.collection.Checklists || [{
        ChecklistCells: [[]]
      }]
    },
    collection() {
      return this.data.collection || {}
    }
  },
  created() {
    const builder = this.data.ChecklistBuilder
    for (let i = 0, checklist; (checklist = this.checklists[i++]);) {
      builder.buildCorrelationList(checklist)
    }
    this.status = this.data.status
  },
  methods: {
    checkRights(checklist) {
      const allowedChecklists = (this.filter || {}).checklists || []
      if (Array.isArray(allowedChecklists) && allowedChecklists.length) {
        if (allowedChecklists.indexOf(checklist.id) === -1) {
          return false
        }
      }
      const index = checklist.index
      const status = this.status[index] || {}
      return !!status.visible
    },
    getLocalization(object, property) {
      return this.data.ChecklistBuilder.getLocalization(object, property)
    },
    isVisible(checklist) {
      if (!this.checkRights(checklist)) {
        return false
      }
      if (this.isInlineClone(checklist)) {
        return false
      }
      return true
    },
    isCloneWithChild(checklist) {
      return this.data.ChecklistBuilder.isCloneWithChild(checklist)
    },
    isInlineClone(checklist) {
      if (!checklist.clone_type) {
        return false
      }
      const builder = this.data.ChecklistBuilder
      switch (checklist.clone_type) {
        case builder.CLONE_TYPE_SAME_TAB:
        case builder.CLONE_TYPE_SAME_TAB_WITH_HEADER:
          if (checklist.clone_of_checklist_id) {
            return true
          }
          break
      }
      return false
    },
    isVisibleChildClone(checklistParent, checklistChild) {
      if (checklistParent.id !== checklistChild.clone_of_checklist_id) {
        return false
      }
      if (!this.checkRights(checklistChild)) {
        return false
      }
      return true
    },
    hasInlineClones(checklist) {
      if (!checklist.clone_type) {
        return false
      }
      const index = checklist.index
      const clonedChecklist = this.checklists[index + 1] || {}
      if (checklist.id === clonedChecklist.clone_of_checklist_id) {
        return true
      }
      return false
    }
  }
}
</script>

<style>
  .checklist-viewer .wrapper-collection-view {
    width: 100%;
  }

  .checklist-viewer .checklist-accordion-header-neo > i {
    font-size: 20px;
  }

  .checklist-viewer .checklist-content table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  .theme--dark .checklist-viewer .checklist-content
  table.checklist-table-content > tbody >
  tr:not([style*="display: none"]) {
    background-color: #505050;
  }

  .theme--dark .checklist-viewer .checklist-content
  table.checklist-table-content > tbody >
  tr.checklist-row-colored:not([style*="display: none"]):nth-child(even) {
    background-color: #303030;
  }

  .theme--light .checklist-viewer .checklist-content
  table.checklist-table-content > tbody >
  tr:not([style*="display: none"]) {
    background-color: #fff;
  }

  .theme--light .checklist-viewer .checklist-content
  table.checklist-table-content > tbody >
  tr.checklist-row-colored:not([style*="display: none"]):nth-child(even) {
    background-color: #eee;
  }

  .checklist-viewer .checklist-content > table > tbody > tr {
    border-collapse: collapse;
  }

  .checklist-viewer .checklist-content > table > tbody > tr > td {
    border-collapse: collapse;
    padding: 0;
    height: 100%;
  }

  .checklist-viewer .checklist-content .cell-borders-view {
    border: thin solid #999;
  }
</style>
