/**
 * Alle unterstüzten Status einer Checkliste.
 */
export enum ChecklistStatus {
  /**
   * Checkliste besitzt keinen Status
   */
  None = 0,

  /**
   * Checkliste ist noch nicht erledigt
   */
  Undone = 1 << 0,

  /**
   * Checkliste ist sichtbar
   */
  Visible = 1 << 1,

  /**
   * Checkliste ist noch nicht erledigt und sichtbar
   */
  UndoneVisible = Undone | Visible,

  /**
   * Checkliste ist erledigt
   */
  Done = 1 << 2,

  /**
   * Checkliste ist erledigt und sichtbar
   */
  DoneVisible = Done | Visible
}
