/**
 * Alle Einstellungen eines Items
 */
export interface ButtonCollectionViewer {
  /**
   * Subtye
   */
  subtype?: string

  /**
   * Zellentypen
   */
  celltypes?: string[]

  /**
   * Eigenschaft
   */
  property: string

  /**
   * Hauptype
   */
  type: string
}

/**
 * Einstellung für die Anzeige der Buttons in der Kollektion (?)
 *
 * @returns Liste mit den Einstellungen
 */
export function getButtonCollectionViewer(): ButtonCollectionViewer[] {
  return [
    {
      property: 'comment',
      type: 'Info'
    },
    {
      subtype: 'settings',
      property: 'comment',
      type: 'Comment'
    },
    // Werden für Offline-Modus nicht gebraucht
    /* {
      property: 'actions',
      type: 'Actions'
    },
    {
      subtype: 'settings',
      property: 'actions',
      type: 'Actions'
    },
    {
      property: 'files',
      type: 'Files'
    },
    {
      subtype: 'settings',
      property: 'files',
      type: 'Files'
    }, */
    {
      subtype: 'settings',
      property: 'adopt_value',
      type: 'AdoptValue'
    },
    {
      celltypes: ['text_formatted'],
      property: 'toggle_content',
      type: 'ToggleContent'
    },
    // Werden für Offline-Modus nicht gebraucht
    /* {
      subtype: 'settings',
      property: 'display_checklists',
      type: 'DisplayChecklists'
    },
    {
      subtype: 'settings',
      property: 'logging_display',
      type: 'DisplayLogging'
    },
    {
      subtype: 'settings',
      property: 'drawing_board_ribbon_open_document_in_tab',
      type: 'DisplayOriginalImage'
    }, */
    {
      property: 'tooltip',
      type: 'DisplayTooltip'
    },
    {
      subtype: 'settings',
      property: 'tooltip_image_data',
      type: 'DisplayTooltip'
    }
  ]
}
