import { ChecklistStatus } from '@/components/ChecklistBuilder/Misc/checklist-status.enum'
import { Connection } from '../offline/connection.enum'
import { getAllowCellSettings } from './checklists/allow-cell.setting'
import { getDefaultCellTypeSetttings } from './checklists/default-cell-type.setting'
import { getButtonCollectionViewer } from './checklists/button-collection-viewer'
import { getDefaultSettings } from './checklists/default.setting'
import { Types as DBEntryValuesTypes } from './checklists/dbentry-value/types.enum'
import { ALLOW_TABLE } from './checklists/dbentry-value/allow-table.setting'
import { getApp, getService } from './feathers'

const CELLS_WITHOUT_VALUE = [
  'create',
  'empty',
  'label',
  'image',
  'custom_button'
]

/**
 * Klasse mit Funktionen für Checklisten.
 *
 * @memberof helpers
 * @param DATA - Objekt, dass die teils aufbereiteten Daten einer
 * Collection beinhaltet.
 * @param SETTINGS - Beinhaltet Einstellungen, überschreibt die
 * Standardwerte der Collection.
 */
function ChecklistBuilder(DATA, SETTINGS) {
  DATA = DATA || {}
  SETTINGS = SETTINGS || {}
  const SUPPORTED_MODULES = {
    0: {
      label: {
        de: 'Kein Modul',
        en: 'No Module'
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      base_selector: 'none',
      ChecklistCategories: []
    }
  }

  let REQUEST = getApp()
  let OFFLINE_DATA = {}
  let COLLECTION_HASHCODE = 0

  // Konstanten
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const VERSION = (this.VERSION = '0.37h')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const VERSION_DATE = (this.VERSION_DATE = '13.05.2019')

  const MSG_SUCCESS = (this.MSG_SUCCESS = 0)
  const MSG_ERROR = (this.MSG_ERROR = 1)
  const MSG_WARN = (this.MSG_WARN = 2)
  const MSG_INFO = (this.MSG_INFO = 3)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const UI_JQUERY_UI = (this.UI_JQUERY_UI = 0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const UI_BOOTSTRAP = (this.UI_BOOTSTRAP = 1)
  const UI_EPLAS_NEO = (this.UI_EPLAS_NEO = 2)

  const MODE_EDITOR = (this.MODE_EDITOR = 0)
  const MODE_VIEWER = (this.MODE_VIEWER = 1)
  const MODE_PREVIEW = (this.MODE_PREVIEW = 2)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const MODE_LOG = (this.MODE_LOG = 3)
  const MODE_PRINTER = (this.MODE_PRINTER = 4)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const TYPE_COLLECTION = (this.TYPE_COLLECTION = 0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const TYPE_CHECKLIST = (this.TYPE_CHECKLIST = 1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const TYPE_CELL = (this.TYPE_CELL = 2)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const TYPE_VALUES = (this.TYPE_VALUES = 3)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const DISPLAY_TYPE_LIST = (this.DISPLAY_TYPE_LIST = 0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const DISPLAY_TYPE_ACCORDION = (this.DISPLAY_TYPE_ACCORDION = 1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const DISPLAY_TYPE_TABS = (this.DISPLAY_TYPE_TABS = 2)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const CLONE_TYPE_NONE = (this.CLONE_TYPE_NONE = 0)
  const CLONE_TYPE_SAME_TAB = (this.CLONE_TYPE_SAME_TAB = 1)
  const CLONE_TYPE_NEW_TAB = (this.CLONE_TYPE_NEW_TAB = 2)
  const CLONE_TYPE_SAME_TAB_WITH_HEADER =
    (this.CLONE_TYPE_SAME_TAB_WITH_HEADER = 3)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const TYPE_COLUMN = (this.TYPE_COLUMN = 1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const TYPE_ROW = (this.TYPE_ROW = 2)

  let BUILDER_SETTINGS = {
    UI: UI_EPLAS_NEO,
    REQUEST: REQUEST,
    EXCLUDE_TYPES: ['tree'],
    /**
     * Exclude quick button types,
     * either per type and/or all
     * ```js
     *  {
     *    label: ['toggle_settings', ...],
     *    all: ['toggle_settings', ...]
     *  }
     * ````
     */
    EXCLUDE_QUICK_BUTTONS: {
      all: ['add_actions', 'reset_cell']
    },
    /**
     * Exclude cell settings,
     * either per type and/or all
     * ```js
     *  {
     *    label: ['cloneable', ...],
     *    all: ['cloneable', ...]
     *  }
     * ````
     */
    EXCLUDE_SETTINGS: {
      all: [
        'action_generation',
        'action_generation_tree_node_label',
        'action_generation_tree_node',
        'action_generation_title',
        'action_generation_description',
        'email_acknowledgement_editable',
        'email_acknowledgement_label_link',
        'custom_button_url',
        'custom_button_params',
        'custom_button_email_attachments',
        'custom_button_email_addresses',
        'custom_button_email_message',
        'tree_source',
        'tree_source_label',
        'tree_source_unlock',
        'tree_source_unlock_label'
      ]
    },
    EXCLUDE_VALUE_EVENTS: [],
    // Structure 'language-COUNTRY', see BCP 47.
    LOCALE: 'de-DE',
    LANGUAGE: 'de',
    VALUE_SELECTOR: null,
    // 0 show success, 1 show error, 2 show fatal error, 3 show information
    MESSAGE_LEVEL: [MSG_ERROR],
    // Delete cell value if it is hidden
    DELETE_CELL_VALUE_ON_HIDE: true,
    VARIABLE_CELL_WIDTH: true,
    PREVIEW_EDITABLE: true,
    // Either requires cleanupViewer() after preview is closed
    // or cloneEditorObject() and open preview via returned object.
    PREVIEW_CHECKLIST_CLONING: false,
    // May be required for older jQuery versions, they tend to delete parts of the strings.
    SAVE_AS_JSON: true,
    // Use $.ajax() instead of $.post for saving collection and checklist values.
    USE_JQUERY_AJAX: true,
    CONFIRM_COLLECTION_DELETE: false,
    ON_PROGRESS_CHANGE: undefined,
    // sets editor and viewer to read-only mode.
    READ_ONLY: false,
    // adds specified classes to control element. $selector: 'classes'
    ADDITIONAL_CONTROL_CLASSES: {},
    SHOW_CONTENT_PREVIEW: true,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    SUPPORTED_MODULES: extend({}, SUPPORTED_MODULES),
    // Load plugins if necessary, or change their settings.
    LOAD_PLUGINS: undefined,
    DEBUG_LOG: false,
    // {table_name: record_id, tbl_Mitarbeiter: 8145, ...}
    GET_ENTRIES: {},
    // Object containing column and value for actions.
    ACTION_BASE_VALUES: {},
    // Function to execute after loading of editor is done.
    ON_LOADING_EDITOR_DONE: undefined,
    // Function to execute after loading of viewer is done.
    ON_LOADING_VIEWER_DONE: undefined,
    // Function to execute after loading or printer is done.
    ON_LOADING_PRINTER_DONE: undefined,
    // Function called on complete load of checklist, argument 1 true/false if loading successful.
    ON_CHECKLIST_FULLY_LOADED: undefined,
    /**
     * ```js
     *  {
     *    selector_global: 'test_selector',
     *    date: "/Date(1465336800000)/",
     *    selector_local: 1,
     *    employee_id: 8145
     *  }
     * ````
     */
    VARIABLE_SETTINGS: {},
    // function(extra_info, callback_save) {}
    SET_COLLECTION_EXTRA_INFO: undefined,
    // function(extra_info, callback_save) {}
    SET_CHECKLIST_EXTRA_INFO: undefined,
    /**
     * Structure:
     * ```js
     *  {
     *    _change_x: {
     *      label: text,
     *      enabledInModules: [ID],
     *      disabledInCellTypes: [type],
     *      onChanged: function(value, data_editor, data_viewer, $container, event)
     *    }
     *  }
     * ````
     */
    ON_VALUE_CHANGED: {},
    /**
     * Structure:
     * ```js
     *  {
     *    _right_x: {
     *      label: text,
     *      required: bool,
     *      right: function(checklist)
     *    }
     *  }
     * ````
     */
    CHECKLIST_VISIBILITY_RIGHTS: {
      term: {
        label: 'Term',
        right: list => {
          const term =
            (((list || {}).extra_info || {}).visible || {}).term || ''
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          return evalTermsTest(term, DATA.variables).passed
        }
      }
    },
    /**
     * Structure:
     * ```js
     *  {
     *    _right_x: {
     *      label: text,
     *      required: bool,
     *      right: function(checklist)
     *    }
     *  }
     * ````
     */
    CHECKLIST_EDITABILITY_RIGHTS: {
      term: {
        label: 'Term',
        right: list => {
          const term =
            (((list || {}).extra_info || {}).editable || {}).term || ''
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          return evalTermsTest(term, DATA.variables).passed
        }
      }
    },
    AUTO_SAVE_CHECKLIST_VALUES: true,
    // Load a list of all modules.
    LOAD_MODULES: undefined,
    // Load a list of all collections.
    LOAD_COLLECTIONS: undefined,
    // Load all dropdown templates.
    LOAD_DROPDOWNS: undefined,
    // Load all sql templates.
    LOAD_QUERIES: undefined,
    // Load a list of all variables.
    LOAD_VARIABLES: undefined,
    /**
     * Function to get files, call should return:
     * ```js
     * {
     *   id: {
     *     file_name: string,
     *     action_type: int
     *   }
     * }
     * ````
     */
    LOAD_DOCUMENTS: undefined,
    // Default password for several actions.
    SECURITY_PASSWORD: 'ChecklistBuilder',
    // e.g. Required to delete cell values from DB.
    SECURITY_PASSWORD_UNLOCKED: false,
    // Apply widgets to html.
    APPLY_WIDGETS: undefined,
    // Acknowledge E-Mail and set cell status to done.
    SET_EMAIL_ACKNOWLEDGED: undefined,
    /**
     * Structure:
     * ```js
     * {
     *   'EXAMPLE': {
     *     token_value: xxx,
     *     language: string
     *   }
     * }
     * ````
     */
    CUSTOM_EMAIL_TOKENS: {},
    // Save variable values automatically.
    AUTO_SAVE_VARIABLE_VALUES: undefined,
    // Saves collection -> checklists -> cells seperately
    INCREMENTAL_COLLECTION_SAVE: true,
    // Maximum number of cells saved at once, 0 = all
    INCREMENTAL_SAVE_MAX_CELLS: 25,
    // Saves import incremental, see INCREMENTAL_COLLECTION_SAVE
    INCREMENTAL_IMPORT: true,
    // Action on loading any data (e.g. loading overlay)
    LOADING: undefined,
    // Action after loading of any data is done (e.g. loading overlay removal)
    LOADING_DONE: undefined,
    // Refresh all selects with plugin binding.  args: $(selects)
    REFRESH_SELECTS: undefined,
    /**
     * Set status of collection link
     * ```js
     *  {
     *    collectionLinkID: int,
     *    status: int
     *  }
     * ````
     */
    SET_COLLECTION_LINK_STATUS: undefined,
    /**
     * Actions for custom button
     * ```js
     *  {
     *    'action_name': {
     *      label: 'xxx',
     *      enabledInModules: [ID],
     *      action: function(checklist_index, data_editor, data_viewer),
     *      clicked: false
     *    }
     *  }
     * ````
     */
    CUSTOM_BUTTON_ACTIONS: {},
    // Automatically generates actions on opening checklist.
    AUTO_GENERATE_ACTIONS: false,
    /**
     * Define value event actions
     * ```js
     *  {
     *    'event_name: {
     *      'action': function(data, callback),
     *      'callback': function(data),
     *      'label': 'xxx',
     *      'parameters': [{
     *          'parameter': 'xxx',
     *          'label': 'xxx',
     *          'type': 'js type',
     *          'encode': true/false}],
     *          'data': {...},
     *          enabledInModules: [ID],
     *          disabledInCellTypes: [type]
     *        }, ....]
     *      }
     *    }
     *  }
     * ````
     */
    VALUE_EVENT_TEMPLATES: {},
    /**
     * Define data used in document generation
     * ```js
     *  {
     *    key: value,
     *    key: {
     *      key: ...
     *    }
     *  },
     * ````
     * or function which returns the object.
     */
    DOCUMENT_GENERATION_DATA: {},
    /**
     * Define tokens to replace in SQL queries, either
     * ```js
     *  {
     *    'TOKEN': VALUE,
     *    ...
     *  },
     * ````
     * or function which returns the object.
     */
    SQL_TOKENS: {
      '[#CURRENT_USER_ID#]': 0
    },
    // Toggles buttons if size is less, else show all buttons (button size 33px)
    TOGGLE_QUICKBUTTONS_COLUMN_SIZE: 330,
    // Function which returns selector for buttons, arguments: width
    TOGGLE_QUICKBUTTONS_SELECTORS: undefined,
    // Data passed to files(documents)
    ADDITIONAL_FILE_DATA: {},
    /**
     * Function which is called after saving viewer values if defined,
     * argument is either an entry or a correlation list.
     */
    ON_VALUE_SAVED: undefined,
    /**
     * Function which is called before saving viewer values if defined,
     * arguments is either an entry or a correlation list and a save callback.
     */
    ON_VALUE_SAVING: undefined,
    // Function which is called upon cell click if defined, arguments: data_editor.
    ON_CELL_CLICKED: undefined,
    // Don't show collection in viewer if inactive.
    HIDE_INACTIVE_COLLECTION_IN_VIEWER: false,
    // Don't show collection in printer if inactive.
    HIDE_INACTIVE_COLLECTION_IN_PRINTER: false,
    // If one or more employees were added to list, keep at least one
    EMAIL_KEEP_LAST_EMPLOYEE_RECEIVER: false,
    // If one or more employees were added to list, keep at least one
    EMAIL_KEEP_LAST_EMPLOYEE_BC: false,
    // If one or more employees were added to list, keep at least one
    EMAIL_KEEP_LAST_EMPLOYEE_CC: false,
    // Object with property as value and value as label (e.g. {'value': 'label'})
    EMAIL_ATTACHMENT_OPTIONS: {},
    // Keeps certain quickbuttons in viewer active if checklist is readonly or deactivated
    KEEP_QUICKBUTTONS_IN_VIEWER_ACTIVE: false,
    // Can only be used if AUTO_SAVE_VARIABLE_VALUES is active.
    CALCULATE_VARIABLES_CLIENT_SIDE: true,
    // Can only be used if CALCULATE_VARIABLES_CLIENT_SIDE is active.
    CALCULATE_GLOBAL_VARIABLES_SERVER_SIDE: false, // true, // nach Andreas
    // Object with properties with autocomplete settings.
    AUTOCOMPLETE_SETTINGS: {},
    // If true workflow is shown in editor and viewer.
    WORKFLOW_ENABLED: true,
    // Array contains list of quick options in checklist to exclude.
    EXCLUDE_CHECKLIST_QUICK_OPTIONS: [],
    // Array contains list of options in checklist to exclude (doesn't remove quick options).
    EXCLUDE_CHECKLIST_OPTIONS: [
      'active',
      'hide_header',
      'confirm_delete',
      'print_format',
      'print_new_page',
      'requirement_matrix',
      'delete_values_on_hide',
      'show_in_printer_only',
      'show_in_workflow',
      'unlock_group_partial',
      'hide_cell_borders',
      'checklist_rows_colored',
      'copy_cells',
      'paste_cells'
    ],
    // Array contains list of options in collection to exclude.
    EXCLUDE_COLLECTION_OPTIONS: [],
    // Array contains list of workflow options to exclude.
    EXCLUDE_WORKFLOW_OPTIONS: [
      'containerHeight',
      'containerWidth',
      'autoAdjustContainerHeight',
      'autoAdjustContainerWidth',
      'scrollbarX',
      'scrollbarY'
    ],
    /**
     * Actions for loading external content
     * ```js
     *  {
     *    'action_name': {
     *      label: 'xxx',
     *      init: function(),
     *      action: function(data_checklist, data_editor, data_viewer, $container)
     *    }
     *  }
     * ````
     */
    LOAD_EXTERNAL_CONTENT_ACTIONS: undefined,
    // Defines number of colums for collection, checklist and workflow options.
    OPTION_COLUMN_COUNT: 2,
    // Defines number of max available unlock groups.
    UNLOCK_GROUPS_COUNT: 20,
    // Function($content, list_data) which is called on changing tab in viewer.
    ON_VIEWER_TAB_CHANGE: undefined,
    // Function($content) which is called on unlocking checklist in viewer.
    ON_VIEWER_CHECKLIST_UNLOCKED: undefined,
    /**
     * Function(index, x, y, data_editor, selector)
     * which is called to show logging of cell in viewer.
     */
    DISPLAY_LOG: undefined,
    /**
     * Object with settings for document management,
     * supported settings: keep_generated_documents -> bool.
     */
    DOCUMENT_MANAGEMENT_OPTIONS: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      keep_generated_documents: true
    },
    /**
     * Object contains several mapping entries,
     * can be used for value_events for example.
     */
    MAPPING_ENTRIES: {},
    // Shows current version in editor collection settings.
    SHOW_BUILDER_VERSION: true,
    /**
     * Define the editor header tabs,
     * for the structure see internal object 'editor_header_tabs'.
     * Applied to current UI Mode.
     */
    COLLECTION_HEADER_TAB_SETTINGS: {},
    // Logging checklist values can be enabled/disabled.
    CHECKLIST_VALUE_LOGGING: true,
    // Colspan for cells can be enabled/disabled.
    CELLS_COLSPAN_ENABLED: true,
    // Rowspan for cells can be enabled/disabled.
    CELLS_ROWSPAN_ENABLED: true,
    /**
     * Function which is called before custom button click is evaluated.
     * arguments: execClick, index, data_editor
     */
    ON_CUSTOM_BUTTON_CLICK: undefined,
    // If true, additional columns are added if cached cells exceed column count on paste.
    EXTEND_COLUMNS_ON_PASTE: false,
    // If true, additional rows are added if cached cells exceed row count on paste.
    EXTEND_ROWS_ON_PASTE: false,
    // List of default variables for intellisense.
    DEFAULT_VARIABLES: [
      '@cell_checklist_id@',
      '@cell_checklist_index@',
      '@cell_date@',
      '@cell_date_time@',
      '@cell_id@',
      '@cell_language@',
      '@cell_locale@',
      '@cell_done@',
      '@cell_time@',
      '@previous_value@',
      '@value@'
    ],
    // Function which applies events to DOM elements. arguments: $selector, $builder
    EDITOR_EVENTS: undefined,
    // default: 0, tabs: 1, pills: 2
    BOOSTRAP_TAB_STYLE: 0,
    // Defines if collection is used in online mode.
    ONLINE: true,
    // List of Elements available which are only available when ONLINE = true
    ONLINE_ELEMENTS: [
      'create',
      'text_replace',
      'task_tracking',
      'text_replace_formatted',
      'document_management',
      'dropdown_sql',
      'multiselect_sql',
      'external_content',
      'tree',
      'employee_search'
    ],
    // Object contains button_collection indexes which should be excluded {'edit_row': [5]}
    EXCLUDE_TABLE_BUTTONS: {},
    // List of events which are ignored on value evaluation on trigger.
    IGNORE_VALUE_EVENTS_ON_TRIGGER_VALUE_EVALUATION: [
      'variable_modify',
      'get_dbentry_value',
      'set_dbentry_value'
    ],
    // Function which is triggered if actions applied to cells are added/removed.
    ON_ACTIONS_CHANGED: undefined,
    // Function which translates key into language
    TRANSLATION: undefined,
    // Boolean which indicates if collections is shown in display mode.
    DISPLAY_MODE: false,
    // Function which is triggered if global varibale is changed. arguments: list of global variables
    ON_GLOBAL_VARIABLE_CHANGED: undefined,
    // Collection is opened in Dialog
    WRAPPER_IS_DIALOG: false
  }

  let VALUE_EVENTS = {
    completed: {
      action: (data, callback) => {
        const term = data.event_data.term
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const result = evalTermsTest(term, data.variables).passed
        data.status.done = result && !data.status.requires_action
        if (data.status.done && !data.muted) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          showMessage('success', data.event_data.msg, true)
        }
        if (typeof callback === 'function') {
          callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'msg',
          label: undefined,
          type: 'string',
          localization: true
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    close_dialog: {
      action: async (data, callback) => {
        // const term = data.event_data.term

        // if (typeof callback === 'function') {
        //   if (evalTermsTest(term, data.variables).passed) {
        await callback(data)
        //   }
        // }

        return data.status
      },
      label: 'Fragebogen: Feedbackdialog schließen',
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        }
      ]
    },
    warning: {
      action: (data, callback) => {
        const term = data.event_data.term
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const warn = evalTermsTest(term, data.variables).passed
        if (warn && !data.muted) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          showMessage('warning', data.event_data.msg, true)
        }
        if (typeof callback === 'function') {
          callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'msg',
          label: undefined,
          type: 'string',
          localization: true
        }
      ]
    },
    abort: {
      action: (data, callback) => {
        const term = data.event_data.term
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const abort = evalTermsTest(term, data.variables).passed
        data.status.abort = abort
        if (abort && !data.muted) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          showMessage('error', data.event_data.msg, true)
        }
        if (typeof callback === 'function') {
          callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'msg',
          label: undefined,
          type: 'string',
          localization: true
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    requires_action: {
      action: (data, callback) => {
        const term = data.event_data.term
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const test = evalTermsTest(term, data.variables).passed
        // eslint-disable-next-line @typescript-eslint/naming-convention
        data.status.requires_action = test && !/[0-9]+/.test(data.entry.actions)

        if (data.status.requires_action) {
          data.status.done = false
          if (!data.muted) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            showMessage('warning', data.event_data.msg, true)
          }
        }
        if (typeof callback === 'function') {
          callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'msg',
          label: undefined,
          type: 'string',
          localization: true
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    variable_modify: {
      action: async (data, callback) => {
        const term = data.event_data.term
        if (BUILDER_SETTINGS.READ_ONLY) {
          return data.status
        }
        if (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          MODE !== MODE_VIEWER &&
          BUILDER_SETTINGS.AUTO_SAVE_VARIABLE_VALUES
        ) {
          return data.status
        }

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (evalTermsTest(term, data.variables).passed) {
          let assignStatement = (
            data.event_data.vars ||
            data.event_data.msg ||
            ''
          )
            .replace(/@cell_done@/gi, !!data.entry.done)
            .replace(/(\r\n|\n|\r)/gm, '')
          const regex = new RegExp(
            '(@value|@previous_value|@cell_)[a-z_0-9]*@',
            'gi'
          )
          const matches = assignStatement.match(regex) || []
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          const valueVariablesMatches = mergeArrays(matches, [])
          const variables = data.variables
          for (let variable, i = 0; (variable = valueVariablesMatches[i++]); ) {
            variable = (variable || '').trim()
            assignStatement = assignStatement.replace(
              new RegExp(variable, 'gi'),
              data.variables[variable]
            )
          }

          /**
           * Ersetzt Tokens in einem Text.
           *
           * @param text - Text in dem Tokens ersetzt werden
           * @returns Text nach ersetzen
           */
          const replaceTokens = text => {
            text = text || ''
            const tokens = assignStatement.match(/\[#.*#\]/g) || []
            const entries = DATA.replaceEntries || {}
            for (let token, i = 0; (token = tokens[i++]); ) {
              const tokenEntry = token.replace(/\[#|#\]/g, '').toLowerCase()
              text = text.replace(
                new RegExp('\\[#' + tokenEntry + '#\\]', 'gi'),
                entries[tokenEntry] || ''
              )
            }
            return text
          }

          assignStatement = replaceTokens(assignStatement)
          let remove = replaceTokens(data.event_data.remove)
          const updatedVariables = []

          // Variablen nur im Client berechnen
          const tempText = assignStatement.split(';')
          const tempGlobalStatements = []
          const tempModifyGlobals =
            BUILDER_SETTINGS.AUTO_SAVE_VARIABLE_VALUES &&
            BUILDER_SETTINGS.CALCULATE_VARIABLES_CLIENT_SIDE &&
            BUILDER_SETTINGS.CALCULATE_GLOBAL_VARIABLES_SERVER_SIDE

          const removeVariables = remove.match(/@[a-z_0-9]+@/gi) || []
          for (let variable, i = 0; (variable = removeVariables[i++]); ) {
            variable = variable.toLowerCase()
            let value = data.variables[variable]
            if (!value && typeof value !== 'number') {
              value = ''
            }
            remove = remove.replace(new RegExp(variable, 'gi'), value)
          }
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          remove = escapeRegex(remove)

          // eslint-disable-next-line no-labels
          ENTRIES: for (
            let tempText2, j = 0;
            (tempText2 = tempText[j++]) !== undefined;

          ) {
            const tempText3 = tempText2.replace(/(\r\n|\n|\r)/gm, '').split('=')
            if (tempText3.length >= 3) {
              tempText3[1] = tempText3.slice(1).join('=')
              tempText3.length = 2
            }

            if (tempText3.length === 2) {
              const assignVariable = tempText3[0].trim().toLowerCase()
              let expr = tempText3[1].trim()
              if (!/^@[a-z_0-9]+@$/gi.test(assignVariable)) {
                // eslint-disable-next-line no-labels
                continue ENTRIES
              }
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              if (VARIABLE_TYPES[assignVariable] === 2) {
                if (!data.status.global_variable_modified) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  data.status.global_variable_modified = [assignVariable]
                } else if (
                  data.status.global_variable_modified.indexOf(
                    assignVariable
                  ) === -1
                ) {
                  data.status.global_variable_modified.push(assignVariable)
                }
              }

              let isGlobalVar = false
              for (const variable in variables) {
                if (variables.hasOwnProperty(variable)) {
                  const regex = new RegExp(variable, 'gi')
                  if (regex.test(expr)) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    if (tempModifyGlobals && VARIABLE_TYPES[variable] === 2) {
                      isGlobalVar = true
                      continue
                    }
                    expr = expr.replace(regex, variables[variable] || '')
                  }
                }
              }

              // --
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              if (isGlobalVar || VARIABLE_TYPES[assignVariable] === 2) {
                tempGlobalStatements[
                  tempGlobalStatements.length
                ] = `${assignVariable} = ${expr}`
                // eslint-disable-next-line no-labels
                continue ENTRIES
              }
              // --
              const phoneRegex = new RegExp(
                '^(\\+|0)[0-9]+(\\/|-)?[0-9]+?$',
                'gi'
              )
              const dateRegex = new RegExp(
                '^([0-9]+\\.[0-9]+\\.[0-9]+|[0-9]+\\/[0-9]+\\/[0-9]+)$',
                'gi'
              )
              const listRegex = new RegExp('^([0-9]+, )+[0-9]+$', 'gi')
              const equationRegex = new RegExp(
                '^(((\\+|-)?\\(*[0-9]+\\.?,?[0-9]*)+\\+?-?\\*?\\/?(\\)|\\*|\\/)*)+$',
                'gi'
              )
              if (
                !phoneRegex.test(expr.replace(/ /gi, '')) &&
                !dateRegex.test(expr) &&
                !listRegex.test(expr) &&
                equationRegex.test(expr + '')
              ) {
                expr.replace(/,/g, '.') // Replace , with . for german users
                // eslint-disable-next-line no-eval
                expr = eval(expr) || 0
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                const precision = ~~VARIABLE_DIGITS[assignVariable]
                if (precision > -1) {
                  expr = expr.toFixed(precision)
                }
              }

              variables[assignVariable] = DATA.variables[assignVariable] =
                expr.replace(new RegExp(remove, 'g'), '')
              updatedVariables.push(assignVariable)
              // eslint-disable-next-line @typescript-eslint/naming-convention
              data.status.variables_modified = true
            }
          }

          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          applyVariables(mergeArrays([], updatedVariables))
        }

        if (typeof callback === 'function') {
          await callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'vars',
          label: undefined,
          intellisense: true,
          type: 'string'
        },
        {
          parameter: 'remove',
          label: undefined,
          intellisense: true,
          type: 'string_single'
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    change_background_color: {
      action: (data, callback) => {
        const term = data.event_data.term
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (evalTermsTest(term, data.variables).passed) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          data.status.background_color = data.event_data.color
        }
        if (typeof callback === 'function') {
          callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'color',
          label: undefined,
          type: 'color'
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    change_outline_color: {
      action: (data, callback) => {
        const term = data.event_data.term
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (evalTermsTest(term, data.variables).passed) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          data.status.outline_color = data.event_data.color
        }
        if (typeof callback === 'function') {
          callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'color',
          label: undefined,
          type: 'color'
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    lock_cell: {
      action: (data, callback) => {
        const term = data.event_data.term
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (evalTermsTest(term, data.variables).passed) {
          data.status.locked = true
        }
        if (typeof callback === 'function') {
          callback(data)
        }
        return data.status
      },
      lock: true,
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hide_cell: {
      action: (data, callback) => {
        const term = data.event_data.term
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        data.status.visible = !evalTermsTest(term, data.variables).passed
        if (typeof callback === 'function') {
          callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    remove_cell_values: {
      action: (data, callback) => {
        const eventData = data.event_data
        const matrix = eventData.matrix
        const checklists = DATA.collection.Checklists || []
        if (matrix && !(checklists[data.index] || {}).clone_of_checklist_id) {
          const term = eventData.term
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          if (evalTermsTest(term, data.variables).passed) {
            const valueIds = []
            const valuePos = []
            for (const ikey in matrix) {
              if (!matrix.hasOwnProperty(ikey)) {
                continue
              }
              const _list = matrix[ikey]
              for (const xkey in _list) {
                if (!_list.hasOwnProperty(xkey)) {
                  continue
                }
                const _column = _list[xkey]
                for (const ykey in _column) {
                  if (!_column.hasOwnProperty(ykey)) {
                    continue
                  }
                  const _cell = _column[ykey]
                  const removalType = _cell.delete_value
                  if (!removalType) {
                    continue
                  }
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  const dataViewer = getCellDataViewer(ikey, xkey, ykey) || {}
                  switch (removalType) {
                    case 1:
                      // eslint-disable-next-line @typescript-eslint/no-use-before-define
                      deleteCellValue(ikey, xkey, ykey, true)
                      valuePos[valuePos.length] = {
                        i: ikey,
                        x: xkey,
                        y: ykey,
                        requiredBy: dataViewer.required_by
                      }
                      break
                    case 2:
                      // eslint-disable-next-line @typescript-eslint/no-use-before-define
                      const removeId = deleteCellValue(ikey, xkey, ykey)
                      if (removeId) {
                        valueIds[valueIds.length] = removeId
                        valuePos[valuePos.length] = {
                          i: ikey,
                          x: xkey,
                          y: ykey,
                          requiredBy: dataViewer.required_by
                        }
                      } else {
                        valuePos[valuePos.length] = {
                          i: ikey,
                          x: xkey,
                          y: ykey,
                          requiredBy: dataViewer.required_by
                        }
                      }
                      break
                  }
                }
              }
            }

            new Promise((resolve, reject) => {
              if (valueIds.length) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                deleteChecklistValues(valueIds).then(
                  result => {
                    if (Array.isArray(result)) {
                      resolve(result.map(x => x.id))
                    } else {
                      reject(
                        new Error(
                          `function 'deleteChecklistValues' failed, param: ` +
                            JSON.stringify(valueIds)
                        )
                      )
                    }
                  },
                  () => {
                    reject(
                      new Error(
                        `function 'deleteChecklistValues' failed, param: ` +
                          JSON.stringify(valueIds)
                      )
                    )
                  }
                )
              } else {
                resolve([])
              }
            })
              .then(async removeIDs => {
                for (let _entry, z = 0; (_entry = valuePos[z++]); ) {
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  const dataViewer = getCellDataViewer(
                    _entry.i,
                    _entry.x,
                    _entry.y
                  )

                  if (dataViewer) {
                    if (removeIDs.indexOf(dataViewer.id) !== -1) {
                      dataViewer.id = 0
                    }

                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    await checkCellVisibility(_entry.i, _entry.x, _entry.y)
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    await getDisabledState(
                      // eslint-disable-next-line @typescript-eslint/no-use-before-define
                      getCellDataEditor(_entry.i, _entry.x, _entry.y),
                      _entry.i
                    )
                  }

                  if (_entry.requiredBy) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    await toggleCellVisibility(_entry.i, _entry.requiredBy)
                  }

                  if (dataViewer.id > 0 && !dataViewer._remove) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    saveChecklistValues(
                      _entry.i,
                      _entry.x,
                      _entry.y,
                      dataViewer,
                      false,
                      null,
                      true
                    )
                  }
                }

                for (let _ldata, za = 0; (_ldata = checklists[za++]); ) {
                  const _index = _ldata.index

                  if (
                    !_ldata.clone_of_checklist_id &&
                    (DATA.status[_index] || {}).visible
                  ) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    checkChecklistVisibility(_ldata).then(visible => {
                      if (!visible) {
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        modifyCollectionView('hide', _ldata.index)
                      }
                    })
                  }
                }

                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                showMessage('information', eventData.msg, true)

                if (typeof callback === 'function') {
                  callback(data)
                }
              })
              // Consolen-Ausgabe bewusst drinnen, damit bei einem Fehler,
              // dieser auch Ausgegeben werden kann und dadurch nachvollzogen
              // werden kann.
              // eslint-disable-next-line no-console
              .catch(console.error)
          }
        } else if (typeof callback === 'function') {
          callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'msg',
          label: undefined,
          type: 'string',
          localization: true
        },
        {
          parameter: 'matrix',
          label: undefined,
          type: 'button',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          button_icon: 'gps_fixed',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          button_label: '',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          button_action: 'showRemoveCellValuesOverlay',
          encode: false
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    trigger_cell_events: {
      action: (data, callback) => {
        const term = data.event_data.term
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        data.status.trigger = evalTermsTest(term, data.variables).passed
        if (typeof callback === 'function') {
          callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    date_comparison: {
      action: (data, callback) => {
        const comparators = {
          0: (date1, date2) => {
            return +date1 < +date2
          },
          1: (date1, date2) => {
            return +date1 <= +date2
          },
          2: (date1, date2) => {
            return +date1 > +date2
          },
          3: (date1, date2) => {
            return +date1 >= +date2
          },
          4: (date1, date2) => {
            return +date1 === +date2
          },
          5: (date1, date2) => {
            return +date1 !== +date2
          }
        }
        const actions = {
          completed: (date1, date2, status) => {
            if (comparators[data.event_data.comparator](date1, date2)) {
              if (!data.muted) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                showMessage('success', data.event_data.msg, true)
              }
              status.done = true
            } else {
              status.done = false
            }
          },
          warning: (date1, date2) => {
            if (comparators[data.event_data.comparator](date1, date2)) {
              if (!data.muted) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                showMessage('warning', data.event_data.msg, true)
              }
            }
          },
          abort: (date1, date2, status) => {
            if (comparators[data.event_data.comparator](date1, date2)) {
              if (!data.muted) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                showMessage('error', data.event_data.msg, true)
              }
              status.abort = true
            } else {
              status.abort = false
            }
          }
        }
        /**
         * Validiert und konvertiert ein Datum zu einem Datumsobjekt.
         *
         * @param date - Datum
         * @returns Datumsobjekt
         */
        const validateAndConvertDate = date => {
          if (/^@[a-z_0-9]+@$/g.test(date)) {
            date = data.variables[date]
          }
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          date = getDateObject(date)
          if (!date.getDate()) {
            return
          }
          return date
        }
        const _date1 = validateAndConvertDate(data.event_data.date1)
        const _date2 = validateAndConvertDate(data.event_data.date2)
        const type = data.event_data.type
        if (!type || data.event_data.comparator === undefined) {
          return data.status
        }
        if (_date1 && _date2) {
          try {
            _date1.setDate(
              _date1.getDate() +
                parseInt(data.event_data.offset_days_date1 || 0)
            )
            actions[type](_date1, _date2, data.status)
          } catch (ex) {
            // Keine Aktion benötigt
          }
        } else if (type === 'completed') {
          data.status.done = false
        }
        if (typeof callback === 'function') {
          callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'type',
          label: undefined,
          type: 'select',
          options: () => {
            return [
              {
                value: 'completed',
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                label: lang('templates.value_events.completed')
              },
              {
                value: 'warning',
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                label: lang('templates.value_events.warning')
              },
              {
                value: 'abort',
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                label: lang('templates.value_events.abort')
              }
            ]
          }
        },
        {
          parameter: 'msg',
          label: undefined,
          type: 'string',
          localization: true
        },
        {
          parameter: 'date1',
          label: undefined,
          intellisense: true,
          type: 'string_single',
          localization: true
        },
        {
          parameter: 'offset_days_date1',
          label: undefined,
          type: 'integer'
        },
        {
          parameter: 'comparator',
          label: undefined,
          type: 'select',
          options: () => {
            return [
              {
                value: 0,
                label: '<'
              },
              {
                value: 1,
                label: '<='
              },
              {
                value: 2,
                label: '>'
              },
              {
                value: 3,
                label: '>='
              },
              {
                value: 4,
                label: '=='
              },
              {
                value: 5,
                label: '!='
              }
            ]
          }
        },
        {
          parameter: 'date2',
          label: undefined,
          intellisense: true,
          type: 'string_single',
          localization: true
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    set_dbentry_value: {
      action: async (data, callback) => {
        const _term = data.event_data.term || ''
        if (
          typeof callback === 'function' &&
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          evalTermsTest(_term, data.variables).passed &&
          !data.status.requires_action
        ) {
          await callback(data)
        }

        return data.status
      },
      callback: async data => {
        const table = data.event_data.table || ''
        const allowTable = ALLOW_TABLE[table.toLowerCase().trim()]

        if (allowTable && typeof allowTable.offlinePath === 'string') {
          const mapping = BUILDER_SETTINGS.MAPPING_ENTRIES || {}
          let column = data.event_data.column || ''
          let value = data.event_data.value || ''
          let idOptional = data.event_data.id_optional || ''
          let referenceID = 0
          let skip = true

          try {
            if (idOptional) {
              const _var = (value.match(/^@[a-z_0-9]+@$/gi) || [])[0]
              if (_var) {
                idOptional = data.variables[_var]
              }
              referenceID = parseInt(idOptional) || 0
            }
            if (
              table &&
              (referenceID || (referenceID = mapping[table])) &&
              referenceID >= 0
            ) {
              skip = false

              if (value) {
                const _vars = value.match(/@[a-z_0-9]+@/gi) || []
                for (let _var, i = 0; (_var = _vars[i++]); ) {
                  _var = _var.toLowerCase()
                  let _val = data.variables[_var]
                  if (!_val && typeof _val !== 'number') {
                    _val = ''
                  }
                  value = value.replace(new RegExp(_var, 'gi'), _val)
                }
              }
            }
          } catch (e) {}

          if (skip !== true) {
            const service = await getService(
              allowTable.offlinePath,
              Connection.Offline
            )
            const audit = await service.get(referenceID).catch(() => null)
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            value = convertVariableValue(value, allowTable.type[column])
            column = allowTable.mapping[column]

            if (audit && audit.hasOwnProperty(column) && value !== null) {
              if (audit[column] !== value) {
                await service.patch(audit.id, { [column]: value })
              }
            }
          }
        }
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'table',
          label: undefined,
          type: 'string_single',
          encode: true
        },
        {
          parameter: 'column',
          label: undefined,
          type: 'string_single',
          encode: true
        },
        {
          parameter: 'id_optional',
          label: undefined,
          intellisense: true,
          type: 'string_single',
          encode: true
        },
        {
          parameter: 'value',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'encrypt',
          label: undefined,
          type: 'boolean',
          encode: false
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    get_dbentry_value: {
      action: async (data, callback) => {
        const _term = data.event_data.term || ''
        if (
          typeof callback === 'function' &&
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          evalTermsTest(_term, data.variables).passed &&
          !data.status.requires_action
        ) {
          await callback(data)
        }
        return data.status
      },
      callback: async data => {
        const table = data.event_data.table || ''
        const variable = data.event_data.variable || ''
        const allowTable = ALLOW_TABLE[table.toLowerCase().trim()]

        if (
          /^@[a-z_0-9]+@$/gi.test(variable) && // Variablename ist gültig.
          allowTable &&
          typeof allowTable.offlinePath === 'string'
        ) {
          const mapping = BUILDER_SETTINGS.MAPPING_ENTRIES || {}
          let column = data.event_data.column || ''
          let idOptional = data.event_data.id_optional || ''
          let referenceID = 0

          if (idOptional) {
            try {
              if (/^@[a-z_0-9]+@$/gi.test(idOptional)) {
                idOptional = data.variables[idOptional]
              }

              referenceID = parseInt(idOptional, 10) || 0
            } catch (e) {
              referenceID = 0
            }
          }

          // Spalten werden in der Checkliste nach der alten Stuktur angeben.
          // Entsprechend müssen die Spaltennamen einmal umgewandelt werden,
          // dieses Umwandlung/Mapping wird auch als Whitelist verwendet.
          column = allowTable.mapping[column]

          if (
            column &&
            (referenceID || (referenceID = mapping[table])) &&
            referenceID >= 0
          ) {
            const service = await getService(
              allowTable.offlinePath,
              Connection.Offline
            )
            const audit = await service.get(referenceID).catch(() => ({}))
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            VARIABLE_DATA[variable] = convertToVariableValue(audit[column])
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            applyVariables([variable])
          }
        }
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'table',
          label: undefined,
          type: 'string_single',
          encode: true
        },
        {
          parameter: 'column',
          label: undefined,
          type: 'string_single',
          encode: true
        },
        {
          parameter: 'id_optional',
          label: undefined,
          intellisense: true,
          type: 'string_single',
          encode: true
        },
        {
          parameter: 'variable',
          label: undefined,
          intellisense: true,
          type: 'string_single',
          encode: false
        },
        {
          parameter: 'remove_protocol_type',
          label: 'checklist_editor.value_events.remove_protocol_type',
          type: 'boolean',
          encode: false
        },
        {
          parameter: 'decrypt',
          label: undefined,
          type: 'boolean',
          encode: false
        }
      ]
    },
    /**
     * Value-Event: Zuordnung vom Eigenschaft zum Audit
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    update_audit_activity_mappings: {
      action: async (data, callback) => {
        const _term = data.event_data.term || ''
        if (typeof callback === 'function') {
          if (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            evalTermsTest(_term, data.variables).passed &&
            !data.status.requires_action
          ) {
            await callback(data)
          }
        }
        return data.status
      },
      callback: async data => {
        const _mapping = BUILDER_SETTINGS.MAPPING_ENTRIES || {}
        const auditID = _mapping['tbl_AU_Audits']

        if (auditID > 0) {
          let values

          if (Array.isArray(data.value)) {
            values = data.value
              .map(e =>
                e && e.id
                  ? typeof e.id === 'number'
                    ? e.id
                    : Number.parseInt(e.id.toString(), 10)
                  : 0
              )
              .filter(id => id > 0)
              .sort()
          } else {
            values = []
          }

          const service = await getService(
            'checklists/properties',
            Connection.Offline
          )
          const found = await service.get(auditID).catch(() => null)

          if (found) {
            await service.patch(auditID, { properties: values })
          } else {
            await service.create({ id: auditID, properties: values })
          }
        }
      },
      parameters: []
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    update_audit_location_mappings: {
      action: async (data, callback) => {
        const _term = data.event_data.term || ''
        if (typeof callback === 'function') {
          if (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            evalTermsTest(_term, data.variables).passed &&
            !data.status.requires_action
          ) {
            await callback(data)
          }
        }
        return data.status
      },
      callback: async data => {
        const _mapping = BUILDER_SETTINGS.MAPPING_ENTRIES || {}
        const auditID = _mapping['tbl_AU_Audits']

        if (auditID > 0) {
          let values

          if (Array.isArray(data.value)) {
            values = data.value
              .map(e =>
                e && e.id
                  ? typeof e.id === 'number'
                    ? e.id
                    : Number.parseInt(e.id.toString(), 10)
                  : 0
              )
              .filter(id => id > 0)
              .sort()
          } else {
            values = []
          }

          const service = await getService(
            'checklists/location',
            Connection.Offline
          )
          const found = await service.get(auditID).catch(() => null)

          if (found) {
            await service.patch(auditID, { locations: values })
          } else {
            await service.create({ id: auditID, locations: values })
          }
        }
      },
      parameters: []
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    create_audit_checklist_status: {
      action: async (data, callback) => {
        const term = data.event_data.term
        if (
          typeof callback === 'function' &&
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          evalTermsTest(term, data.variables).passed &&
          !data.status.requires_action
        ) {
          await callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'visible_and_editable_only',
          label: undefined,
          type: 'boolean',
          encode: false
        },
        {
          parameter: 'affected_employee',
          label: undefined,
          type: 'boolean',
          encode: false
        },
        {
          parameter: 'responsible',
          label: undefined,
          type: 'boolean',
          encode: false
        },
        {
          parameter: 'executor',
          label: undefined,
          type: 'boolean',
          encode: false
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    convert_offline_audit: {
      action: async (data, callback) => {
        const term = data.event_data.term
        if (
          typeof callback === 'function' &&
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          evalTermsTest(term, data.variables).passed &&
          !data.status.requires_action
        ) {
          await callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    set_audit_checklist_status: {
      action: async (data, callback) => {
        const term = data.event_data.term
        if (
          typeof callback === 'function' &&
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          evalTermsTest(term, data.variables).passed &&
          !data.status.requires_action
        ) {
          await callback(data)
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'checklist_status',
          label: undefined,
          type: 'select',
          initialValue: 0,
          options: (/* collection, checklist, cell */) => {
            return [
              {
                value: ChecklistStatus.None,
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                label: lang('templates.dropdown.option_0')
              },
              {
                value: ChecklistStatus.Undone,
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                label: lang('templates.dropdown.value_event_checklist_status_1')
              },
              {
                value: ChecklistStatus.Visible,
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                label: lang('templates.dropdown.value_event_checklist_status_2')
              },
              {
                value: ChecklistStatus.UndoneVisible,
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                label: lang('templates.dropdown.value_event_checklist_status_3')
              },
              {
                value: ChecklistStatus.Done,
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                label: lang('templates.dropdown.value_event_checklist_status_4')
              },
              {
                value: ChecklistStatus.DoneVisible,
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                label: lang('templates.dropdown.value_event_checklist_status_6')
              }
            ]
          },
          encode: false
        },
        {
          parameter: 'checklist_index',
          label: undefined,
          type: 'select',
          initialValue: '',
          options: (collection /*, checklist, cell */) => {
            const options = [
              {
                value: '',
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                label: lang('templates.dropdown.option_0')
              }
            ]
            const lists = collection.Checklists || []
            for (let list, i = 0; (list = lists[i]); i++) {
              options.push({
                value: i,
                label: list.label
              })
            }
            return options
          },
          encode: false
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    delete_audit_checklist_status: {
      action: async (data, callback) => {
        const term = data.event_data.term
        if (typeof callback === 'function') {
          if (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            evalTermsTest(term, data.variables).passed &&
            !data.status.requires_action
          ) {
            await callback(data)
          }
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        },
        {
          parameter: 'checklist_index',
          label: undefined,
          type: 'select',
          initialValue: '',
          options: collection => {
            const options = [
              {
                value: '',
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                label: lang('templates.dropdown.option_0')
              }
            ]
            const lists = collection.Checklists || []
            for (let list, i = 0; (list = lists[i]); i++) {
              options.push({
                value: i,
                label: list.label
              })
            }
            return options
          },
          encode: false
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    close_dialog: {
      action: async (data, callback) => {
        const term = data.event_data.term
        if (typeof callback === 'function') {
          if (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            evalTermsTest(term, data.variables).passed &&
            !data.status.requires_action
          ) {
            await callback(data)
          }
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        }
      ]
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    redirect_to_audit_list: {
      action: async (data, callback) => {
        const term = data.event_data.term
        if (typeof callback === 'function') {
          if (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            evalTermsTest(term, data.variables).passed &&
            !data.status.requires_action
          ) {
            await callback(data)
          }
        }
        return data.status
      },
      parameters: [
        {
          parameter: 'term',
          label: undefined,
          intellisense: true,
          type: 'string',
          encode: true
        }
      ]
    }
  }

  /**
   * Listen mit den unterschützen Zelleinstellungen der einzelnen Zellen.
   */
  const ALLOWED_CELL_SETTINGS = getAllowCellSettings()

  /**
   * Liste mit den Standardeinstellungen der Zellen
   */
  const DEFAULT_CELL_TYPE_SETTINGS = getDefaultCellTypeSetttings()

  const BUTTON_COLLECTION_VIEWER = getButtonCollectionViewer()

  const EDITOR_HEADER_TABS = {}
  const EDITOR_ELEMENTS = {}
  const EMAIL_TOKENS = []

  let MODE = 0
  const TIME_MS_NOW = Date.now
  let JUMPTO_CELL_CACHE = []
  let HIDDEN_SPAN_CELL_CACHE = {}
  const PREVIEW_SETTINGS = {}
  let VARIABLE_DATA = {}
  let VARIABLE_DIGITS = {}
  let VARIABLE_DEFAULT_VALUES = {}
  let VARIABLE_TYPES = {}
  let VARIABLE_CELL_MAPPING = {}
  let VARIABLE_CHECKLIST_MAPPING = {}
  let DEFAULT_LOCALE
  const COUNTDOWNS = {
    active: [],
    expired: [],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    locked_collection: false,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    locked_checklists: {}
  }

  DATA.ChecklistBuilder = this
  DATA.collection = {}
  DATA.correlations = []
  DATA.status = []
  DATA.values = {}
  DATA.mandatory = []
  DATA.variables = {}
  DATA.replaceEntries = {}
  DATA.lockedElems = []
  DATA.disabled = false
  DATA.valueEventsQuickAccess = {}

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  applySettings(SETTINGS)

  // eslint-disable-next-line jsdoc/require-jsdoc
  function getBasicChecklist() {
    return {
      active: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      confirm_delete: false,
      ChecklistCells: [],
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      label: lang('misc.label'),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      label_localization: null,
      id: 0,
      index: 0,
      requirement: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      required_progress: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      requirement_matrix: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      column_widths: [undefined],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      row_splits: [undefined],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      visibility_rights: [],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      editability_rights: [],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      print_format: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      print_new_page: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      hide_header: false,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      show_in_workflow: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      delete_values_on_hide: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      unlock_group: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      unlock_group_partial: false,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      workflow_node_type: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      extra_info: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      clone_maximum: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      activate_checklist_tab_on_unlock: -1,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      hide_cell_borders: false,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      checklist_rows_colored: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      show_in_printer_only: false,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      requiresReleaseValue: false
    }
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  function getBasicCell() {
    return {
      id: 0,
      x: 0,
      y: 0,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      name: lang('templates.elements.create'),
      type: 'create',
      settings: {
        count: false
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      preset_value: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      preset_value_localization: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      correlation_matrix: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      requirement_count: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      total_amount_count: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      field_exclusivity_count: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      field_lock_count: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      change_color_count: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      value_event_trigger_count: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      comment: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      comment_localization: null,
      tooltip: null,
      searchable: null
    }
  }

  /**
   * Speichert die neu berechneten Variablen der aktuellen Checkliste im
   * passenden Offline-Service. Bestehende Einträge werden automatisch über den
   * Selector der Werte wieder gefunden und mit den neusten Wert(en)
   * überschrieben.
   */
  async function offlineSaveVariableData() {
    const selector = BUILDER_SETTINGS.VALUE_SELECTOR
    const service = await getService('checklists/variables', Connection.Offline)
    const entries = await service.find({ query: { selector: selector } })
    const variables = {
      id: 0,
      selector: selector,
      Values: DATA.variables,
      Digits: VARIABLE_DIGITS,
      DefaultValues: VARIABLE_DEFAULT_VALUES,
      Types: VARIABLE_TYPES
    }

    if (entries.total > 0) {
      variables.id = entries.data[0].id
      await service.update(variables.id, variables)
    } else {
      await service.create(variables)
    }
  }

  /**
   * Überschreibt Einstellungen der Checkliste
   *
   * @param settings - Neue Werte für Einstellungen
   */
  function applySettings(settings) {
    /**
     * Wenn einstellungen nicht gesetzt sind,
     * versuche die Standardwerte für den Modus herauszufinden.
     */
    if (typeof settings === 'object' && Object.keys(settings).length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      BUILDER_SETTINGS = extend(true, BUILDER_SETTINGS, settings)
    }

    if (!Array.isArray(BUILDER_SETTINGS.MESSAGE_LEVEL)) {
      BUILDER_SETTINGS.MESSAGE_LEVEL = []
    }

    if (
      typeof BUILDER_SETTINGS.AUTO_SAVE_VARIABLE_VALUES === 'undefined' ||
      !BUILDER_SETTINGS.AUTO_SAVE_CHECKLIST_VALUES
    ) {
      BUILDER_SETTINGS.AUTO_SAVE_VARIABLE_VALUES =
        BUILDER_SETTINGS.AUTO_SAVE_CHECKLIST_VALUES
    }

    if (BUILDER_SETTINGS.INCREMENTAL_IMPORT) {
      BUILDER_SETTINGS.INCREMENTAL_COLLECTION_SAVE =
        BUILDER_SETTINGS.INCREMENTAL_IMPORT
    }

    if (BUILDER_SETTINGS.COLLECTION_HEADER_TAB_SETTINGS) {
      const ui = BUILDER_SETTINGS.UI
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      EDITOR_HEADER_TABS[ui] = extend(
        true,
        EDITOR_HEADER_TABS[ui] || {},
        BUILDER_SETTINGS.COLLECTION_HEADER_TAB_SETTINGS
      )
    }

    // Ereignisse bei Wert erweitern
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    VALUE_EVENTS = extend(
      true,
      VALUE_EVENTS,
      BUILDER_SETTINGS.VALUE_EVENT_TEMPLATES || {}
    )

    // E-Mail Tokens erweitern
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const emailTokens = getEmailTokens()
    let emailToken
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const customEmailTokens = extend(
      true,
      {},
      BUILDER_SETTINGS.CUSTOM_EMAIL_TOKENS || {}
    )
    if (
      customEmailTokens &&
      typeof customEmailTokens === 'object' &&
      Object.keys(customEmailTokens).length
    ) {
      if (Array.isArray(emailTokens)) {
        for (let i = 0; (emailToken = emailTokens[i++]) !== undefined; ) {
          if (Array.isArray(emailToken)) {
            delete customEmailTokens[emailToken[1]]
          }
        }
      }
      for (const tokenKey in customEmailTokens) {
        if (
          customEmailTokens.hasOwnProperty(tokenKey) &&
          tokenKey !== 'undefined'
        ) {
          emailToken = customEmailTokens[tokenKey]
          EMAIL_TOKENS[EMAIL_TOKENS.length] = [
            emailToken.language || tokenKey,
            tokenKey
          ]
        }
      }
    }

    /**
     * Überprüft ob die Lokalisierung der Sprache korrekt gesetzt wird.
     * Ansonsten wird die Lokalisierung korrigiert.
     */
    let language =
      ChecklistBuilder.defaults.locale_to_language[BUILDER_SETTINGS.LOCALE]
    let locale =
      ChecklistBuilder.defaults.language_to_locale[BUILDER_SETTINGS.LOCALE]
    if (language) {
      BUILDER_SETTINGS.LANGUAGE = language
    } else if (locale) {
      BUILDER_SETTINGS.LANGUAGE = BUILDER_SETTINGS.LOCALE
      BUILDER_SETTINGS.LOCALE = locale
    } else {
      language =
        ChecklistBuilder.defaults.locale_to_language[BUILDER_SETTINGS.LANGUAGE]
      locale =
        ChecklistBuilder.defaults.language_to_locale[BUILDER_SETTINGS.LANGUAGE]
      if (locale) {
        BUILDER_SETTINGS.LOCALE = locale
      } else if (language) {
        BUILDER_SETTINGS.LOCALE = BUILDER_SETTINGS.LANGUAGE
        BUILDER_SETTINGS.LANGUAGE = language
      } else {
        BUILDER_SETTINGS.LOCALE = 'en-US'
        BUILDER_SETTINGS.LANGUAGE = 'en'
      }
    }

    if (!DEFAULT_LOCALE) {
      DEFAULT_LOCALE = BUILDER_SETTINGS.LOCALE
    }
  }

  /**
   * Holt eine Übersetzung anhand eines Sprachkeys für ChecklistBuilder.
   *
   * @param key - Sprachkey
   * @param replacements - Eigenschaft wird in Text mit Wert ersetzt
   * @returns Übersetzung
   */
  function lang(key, replacements) {
    // const langkey = `components.checklist.${key}`
    const langkey = `ChecklistBuilder.${key}`
    const translator = BUILDER_SETTINGS.TRANSLATION
    if (typeof translator !== 'function') {
      return langkey
    }
    return translator(langkey, replacements)
  }

  /**
   * Erzeugt Hash von Collection.
   *
   * @returns Hashcode
   */
  function getCollectionHashcode() {
    const c = JSON.stringify(DATA.collection || {})
    let h = 0
    let i = c.length
    while (i) {
      h = ((h << 5) - h + c.charCodeAt(--i)) | 0
    }
    return h
  }

  /**
   * Gibt eine Kopie der E-Mail-Tokens zurück.
   *
   * @returns E-Mail-Tokens
   */
  function getEmailTokens() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return extend([], EMAIL_TOKENS)
  }

  /**
   * Durchläuft alle Eigenschaften von Objekten
   * und führt diese zusammen.
   * Erstes Argument kann Boolean sein. Alle anderen Objekte.
   *
   * @returns Ergebnis der Zusammenführung
   */
  function extend() {
    let extended = arguments[0] || {}
    let deep = false
    let i = 1
    const length = arguments.length

    if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
      deep = arguments[0]
      extended = arguments[i] || {}
      i++
    }

    // Falls ein Ziel ein String oder ähnliches ist (möglich in tiefer Kopie)
    if (typeof extended !== 'object' && typeof extended !== 'function') {
      extended = {}
    }

    // Leeres Objekt erweitern, falls nur ein Argument übergeben wurde
    if (i === length) {
      extended = {}
      i--
    }

    /**
     * Durchläuft alle Eigenschaften eines Objekts
     * und führt diese zusammen.
     *
     * @param obj - das zusammengeführt wird.
     */
    const merge = function (obj) {
      for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          const copy = obj[prop]
          if (
            deep &&
            Object.prototype.toString.call(copy) === '[object Object]'
          ) {
            const src = extended[prop]
            if (Object.prototype.toString.call(src) !== '[object Object]') {
              extended[prop] = extend(true, {}, copy)
            } else {
              extended[prop] = extend(true, src, copy)
            }
          } else if (deep && Array.isArray(copy)) {
            const src = extended[prop]
            if (!Array.isArray(src)) {
              extended[prop] = extend(true, [], copy)
            } else {
              extended[prop] = extend(true, src, copy)
            }
          } else {
            extended[prop] = copy
          }
        }
      }
    }

    for (; i < length; i++) {
      const obj = arguments[i]
      merge(obj)
    }

    return extended
  }

  /**
   * Wandelt Text in regulären Ausdruck für RegExp um.
   *
   * @param text - Ausdruck der umgewandelt wird.
   * @returns valider Ausdruck
   */
  function escapeRegex(text) {
    return text.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  /**
   * Holt eine Übersetzung anhand der Sprache,
   * aus einer Eigenschaft.
   *
   * @param obj - Objekt das die Übersetzung beinhaltet
   * @param prop - Eigenschalft die eine Übersetzung hat.
   * Diese befindet sich in prop_localization.
   * @returns Sprachstring
   */
  function getLocalization(obj, prop) {
    let text = obj[prop]
    if (DATA.collection.localization) {
      if (
        MODE === MODE_VIEWER ||
        MODE === MODE_PRINTER ||
        DATA.collection.localization_option_selected
      ) {
        try {
          const localization = JSON.parse(obj[prop + '_localization'])
          if (localization && typeof localization === 'object') {
            text = localization[BUILDER_SETTINGS.LOCALE] || text
          }
        } catch (ex) {
          // Wenn keine Übersetzung vorhanden ist,
          // benutze den Standardwert
        }
      }
    }
    return text || ''
  }

  /**
   * Baut eine Matrix für Abhängigkeiten von Zellen auf.
   *
   * @param checklist - Checkliste mit Zellen
   */
  function buildCorrelationList(checklist) {
    const index = checklist.index
    const vMatrix = DATA.correlations[index]
    const xlength = vMatrix.length
    const ylength = vMatrix[0].length
    const init = []

    for (let x = 0; x < xlength; x++) {
      for (let y = 0; y < ylength; y++) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const dataEditor = getCellDataEditor(index, x, y)
        if (!dataEditor) {
          continue
        }
        if (!dataEditor.settings) {
          dataEditor.settings = {}
        }
        if (dataEditor.settings.inactive) {
          continue
        }
        if (dataEditor.settings.initialize) {
          init.push({
            index: index,
            dataEditor: dataEditor,
            dataViewer: vMatrix[x][y] || {}
          })
        }
        const _events = dataEditor.settings.value_events
        if (Array.isArray(_events)) {
          for (let i = 0, _event; (_event = _events[i++]); ) {
            const _matches = (_event.term || '').match(/@[a-z_0-9]+@/gi) || []
            for (let j = 0, _match; (_match = _matches[j++]); ) {
              if (/(@cell_|@value(_|@)|@previous_value(_|@))/i.test(_match)) {
                continue
              }
              _match = _match.toLowerCase()
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              addVariableCellMapping(_match, index, dataEditor)
            }
          }
        }
        if (
          dataEditor.requirement_count ||
          dataEditor.total_amount_count ||
          dataEditor.field_exclusivity_count ||
          dataEditor.field_lock_count ||
          dataEditor.change_color_count ||
          dataEditor.value_event_trigger_count
        ) {
          const inverseRequired =
            dataEditor.settings.display_requirements_inverse
          const cMatrix = dataEditor.correlation_matrix
          if (!cMatrix) {
            continue
          }
          for (let xx in cMatrix) {
            if (!cMatrix.hasOwnProperty(xx)) {
              continue
            }
            xx = ~~xx
            const cMatrixRow = cMatrix[xx]
            if (!cMatrixRow) {
              continue
            }
            for (let yy in cMatrixRow) {
              if (!cMatrixRow.hasOwnProperty(yy)) {
                continue
              }
              yy = ~~yy
              const cObj = cMatrixRow[yy]
              if (!cObj) {
                continue
              }
              const lObj = vMatrix[xx][yy] || {}
              const llObj = vMatrix[x][y] || {}

              if (cObj.field_exclusivity) {
                if (!lObj.exclusives) {
                  lObj.exclusives = [
                    {
                      x: x,
                      y: y
                    }
                  ]
                } else {
                  lObj.exclusives.push({
                    x: x,
                    y: y
                  })
                }
              }
              if (cObj.change_color) {
                if (!llObj.color_change) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  llObj.color_change = [
                    {
                      x: xx,
                      y: yy,
                      type: ~~cObj.change_color
                    }
                  ]
                } else {
                  llObj.color_change.push({
                    x: xx,
                    y: yy,
                    type: ~~cObj.change_color
                  })
                }
              }
              if (cObj.field_lock) {
                if (!lObj.locked_by) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  lObj.locked_by = [
                    {
                      x: x,
                      y: y,
                      type: ~~cObj.field_lock
                    }
                  ]
                } else {
                  lObj.locked_by.push({
                    x: x,
                    y: y,
                    type: ~~cObj.field_lock
                  })
                }
                if (!llObj.locks) {
                  llObj.locks = [
                    {
                      x: xx,
                      y: yy,
                      type: ~~cObj.field_lock
                    }
                  ]
                } else {
                  llObj.locks.push({
                    x: xx,
                    y: yy,
                    type: ~~cObj.field_lock
                  })
                }
              }
              if (cObj.total_amount) {
                if (!lObj.amounts_to) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  lObj.amounts_to = [
                    {
                      x: x,
                      y: y
                    }
                  ]
                } else {
                  lObj.amounts_to.push({
                    x: x,
                    y: y
                  })
                }
                if (!llObj.totalize) {
                  llObj.totalize = [
                    {
                      x: xx,
                      y: yy,
                      type: cObj.total_amount
                    }
                  ]
                } else {
                  llObj.totalize.push({
                    x: xx,
                    y: yy,
                    type: cObj.total_amount
                  })
                }
              }
              if (cObj.event_trigger) {
                if (!lObj.triggered_by) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  lObj.triggered_by = [
                    {
                      x: x,
                      y: y
                    }
                  ]
                } else {
                  lObj.triggered_by.push({
                    x: x,
                    y: y
                  })
                }
                if (!llObj.triggers) {
                  llObj.triggers = [
                    {
                      x: xx,
                      y: yy
                    }
                  ]
                } else {
                  llObj.triggers.push({
                    x: xx,
                    y: yy
                  })
                }
              }
              if (cObj.requirement) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                const _dataEditor = getCellDataEditor(index, xx, yy)
                if (!_dataEditor || _dataEditor.settings.inactive) {
                  if (dataEditor.requirement_count) {
                    dataEditor.requirement_count--
                  }
                  if (!_dataEditor) {
                    delete cMatrixRow[yy]
                  }
                  continue
                }
                if (inverseRequired) {
                  if (!lObj.requires) {
                    lObj.requires = [
                      {
                        x: x,
                        y: y
                      }
                    ]
                  } else {
                    lObj.requires.push({
                      x: x,
                      y: y
                    })
                  }
                  if (!llObj.required_by) {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    llObj.required_by = [
                      {
                        x: xx,
                        y: yy
                      }
                    ]
                  } else {
                    llObj.required_by.push({
                      x: xx,
                      y: yy
                    })
                  }
                } else {
                  if (!lObj.required_by) {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    lObj.required_by = [
                      {
                        x: x,
                        y: y
                      }
                    ]
                  } else {
                    lObj.required_by.push({
                      x: x,
                      y: y
                    })
                  }
                  if (!llObj.requires) {
                    llObj.requires = [
                      {
                        x: xx,
                        y: yy
                      }
                    ]
                  } else {
                    llObj.requires.push({
                      x: xx,
                      y: yy
                    })
                  }
                }
              }
            }
          }
        }
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    initializeCells(init)
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    checkChecklistVisibility(checklist)
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    checkEditabilityRights(checklist)
  }

  /**
   * Durchläuft alle Checklisten einer Kollektion und
   * baut eine Eine Vorabversion der Matrix für Abhängigkeiten von Zellen auf.
   */
  function preBuildCorrelations() {
    const checklists = DATA.collection.Checklists || []
    for (let i = 0, llength = checklists.length; i < llength; i++) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      preBuildCorrelationList(checklists[i])
    }
  }

  /**
   * Baut eine Matrix für Abhängigkeiten von Zellen auf
   * für eine Checkliste zusammen.
   *
   * @param checklist - Checkliste mit Zellen
   */
  function preBuildCorrelationList(checklist) {
    const cols = checklist.ChecklistCells
    const index = checklist.index
    const collection = DATA.collection
    const status = DATA.status
    const correlations = DATA.correlations || (DATA.correlations = [])
    const list = correlations[index] || (correlations[index] = [])
    const values = DATA.values
    const mandatory = DATA.mandatory
    const cache = {}
    const offline = !BUILDER_SETTINGS.ONLINE
    if (!HIDDEN_SPAN_CELL_CACHE[index]) {
      HIDDEN_SPAN_CELL_CACHE[index] = cache
    }
    if (status.length !== collection.Checklists.length) {
      const defaultStatus = {
        editable: true,
        progress: 0,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        required_by: [],
        visible: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        mandatory_done: true
      }
      if (!status[index]) {
        status.push(defaultStatus)
      } else {
        status.splice(index, 0, defaultStatus)
      }
    }
    for (let x = 0, xlength = cols.length; x < xlength; x++) {
      const rows = cols[x]
      if (!list[x]) {
        list[x] = []
      }

      for (let y = 0, ylength = rows.length; y < ylength; y++) {
        const cell = rows[y]
        const entry = values[cell.id] || {
          index: 1,
          done: false,
          value: null,
          comment: null,
          files: null,
          actions: null,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          background_color: cell.cell_background_color || null
        }
        if (entry.visible === undefined) {
          entry.visible = true
        }
        if (entry.locked === undefined) {
          entry.locked = false
        }
        // eslint-disable-next-line @typescript-eslint/naming-convention
        entry.version_state = 1
        JUMPTO_CELL_CACHE[cell.id] = {
          x: x,
          y: y,
          index: index
        }
        if (cell.type === 'external_content') {
          // TODO
        }
        if (offline) {
          delete entry.requires
          delete entry.required_by
          delete entry.triggers
          delete entry.triggered_by
          delete entry.totalize
          delete entry.amounts_to
          delete entry.locks
          delete entry.locked_by
          delete entry.color_change
          delete entry.exclusives
        }
        // eslint-disable-next-line @typescript-eslint/naming-convention
        entry.outline_color = null
        // eslint-disable-next-line @typescript-eslint/naming-convention
        entry.temporary_background_color = null
        // eslint-disable-next-line @typescript-eslint/naming-convention
        entry.info_visible = null
        // eslint-disable-next-line @typescript-eslint/naming-convention
        entry.comment_visible = null
        // eslint-disable-next-line @typescript-eslint/naming-convention
        entry.requires_action = null
        // eslint-disable-next-line @typescript-eslint/naming-convention
        if (entry.value === undefined) {
          entry.value = null
        }
        if (entry.done && cell.settings.count && !cell.settings.inactive) {
          status[index].progress++
        }
        if (cell.settings.mandatory_field) {
          if (!mandatory[index]) {
            mandatory[index] = [
              {
                x: x,
                y: y,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                ignore_hidden: cell.settings.mandatory_field_hidden
              }
            ]
          } else {
            mandatory[index].push({
              x: x,
              y: y,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ignore_hidden: cell.settings.mandatory_field_hidden
            })
          }
        }
        const colspan = cell.settings.colspan || 1
        const rowspan = cell.settings.rowspan || 1
        if (colspan > 1 || rowspan > 1) {
          let _x = x
          let _y = y
          let _temp = {}
          for (let k = 1; k <= colspan; k++) {
            if (!(_temp = cache[_x])) {
              _temp = cache[_x] = {}
            }
            for (let l = 1; l <= rowspan; l++) {
              _temp[_y] = true
              _y++
            }
            _x++
            _y = y
          }
          delete cache[x][y]
        }
        if (typeof entry.value === 'string') {
          try {
            entry.value = JSON.parse(entry.value)
            if (
              !Array.isArray(entry.value) ||
              (entry.index === 0 && entry.value.length > 0)
            ) {
              entry.value = [entry.value]
            }
          } catch (ex) {
            entry.value = entry.value ? [entry.value] : []
          }
          entry.index = entry.value.length
        }
        list[x][y] = entry
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getMandatoryState(index).then(done => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      status[index].mandatory_done = done
    })
  }

  /**
   * Baut die Abhängigkeiten einer Checkliste zusammen,
   * für eine Checkliste mit dem entsprechenden Index
   * oder für alle Checklisten
   *
   * @param index - Index der Checkliste
   */
  function preBuildListRequirements(index) {
    const checklists = DATA.collection.Checklists || []
    if (typeof index === 'number') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      buildRequirements(checklists[index], index)
    } else {
      for (let checklist, i = 0; (checklist = checklists[i]); i++) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        buildRequirements(checklist, i)
      }
    }
  }

  /**
   * Baut die Abhängigkeiten einer Checkliste zusammen.
   *
   * @param checklist - Daten der Checkliste
   * @param index - Index der Checkliste
   */
  function buildRequirements(checklist, index) {
    const status = DATA.status
    // eslint-disable-next-line @typescript-eslint/naming-convention
    status.required_by = []
    if (typeof checklist.requirement === 'number') {
      const _status = status[checklist.requirement]
      if (_status) {
        _status.required_by.push(index)
      }
    }
    const _matrix = checklist.requirement_matrix
    if (_matrix) {
      for (const _listKey in _matrix) {
        if (_matrix.hasOwnProperty(_listKey)) {
          const _status = status[_listKey]
          if (_status) {
            _status.required_by.push(index)
          }
        }
      }
    }
    const _info = checklist.extra_info || {}
    const _editable = (_info.editable || {}).term || ''
    const _visible = (_info.visible || {}).term || ''
    const _evars = _editable.match(/@[a-z_0-9]+@/gi) || []
    const _vvars = _visible.match(/@[a-z_0-9]+@/gi) || []
    const _vars = Array.from(new Set(_evars.concat(_vvars)))
    for (let i = 0, variable; (variable = _vars[i]); i++) {
      let list = VARIABLE_CHECKLIST_MAPPING[variable]
      if (!Array.isArray(list)) {
        VARIABLE_CHECKLIST_MAPPING[variable] = list = []
      }
      list.push(index)
    }
  }

  /**
   * Setzt die Werte der Variablen in den Zellen
   * vom Typ: Variablen Anzeige (formatiert)
   *
   * @param variables - Liste von Variablen, die geändert wurden.
   */
  async function applyVariables(variables) {
    if (!Array.isArray(variables) || !variables.length) {
      return
    }
    const variableCells = {}
    let checklistIndexes = []
    for (let i = 0, variable; (variable = variables[i++]); ) {
      const entries = VARIABLE_CELL_MAPPING[variable] || {}
      const checklists = VARIABLE_CHECKLIST_MAPPING[variable]
      if (checklists) {
        checklistIndexes = checklistIndexes.concat(
          VARIABLE_CHECKLIST_MAPPING[variable] || []
        )
      }
      for (let j = 0, entry; (entry = entries[j++]); ) {
        const index = entry.index
        const x = entry.data.x
        const y = entry.data.y
        if (entry.data.type !== 'text_variables_formatted') {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await checkCellVisibility(index, x, y)
          continue
        }
        let checklist = variableCells[index]
        if (!variableCells[entry.index]) {
          checklist = variableCells[index] = {}
        }
        let column = checklist[x]
        if (!column) {
          column = checklist[x] = {}
        }
        column[y] = entry.data
      }
    }

    checklistIndexes = Array.from(new Set(checklistIndexes))
    const lists = (DATA.collection || {}).Checklists || []
    if (checklistIndexes.length) {
      for (
        let i = 0, _index;
        (_index = checklistIndexes[i]) !== undefined;
        i++
      ) {
        const _visible = (DATA.status[_index] || {}).visible
        let removeIds = []
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (!(await checkChecklistVisibility(lists[_index]))) {
          if (_visible) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            removeIds = removeIds.concat(modifyCollectionView('hide', _index))
          }
        } else if (!_visible) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          modifyCollectionView('show', _index)
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          checkEditabilityRights(lists[_index])
        } else if (_visible) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          checkEditabilityRights(lists[_index])
        }

        if (removeIds.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          deleteChecklistValues(removeIds)
        }
      }
    }

    for (const index in variableCells) {
      if (variableCells.hasOwnProperty(index)) {
        const checklist = variableCells[index]
        for (const x in checklist) {
          if (checklist.hasOwnProperty(x)) {
            const column = checklist[x]
            for (const y in column) {
              if (column.hasOwnProperty(y)) {
                const dataEditor = column[y]
                let content = getLocalization(dataEditor, 'preset_value') || ''

                const matches = content.match(/@[a-z_0-9]+@/gi) || []
                for (let variable, j = 0; (variable = matches[j++]); ) {
                  variable = variable.toLowerCase()
                  if (DATA.variables.hasOwnProperty(variable)) {
                    const regex = new RegExp(variable, 'gi')
                    content = content.replace(
                      regex,
                      DATA.variables[variable] || ''
                    )
                  }
                }
                content = content.replace(/@[a-z_0-9]+@/gi, '')
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                const dataViewer = getCellDataViewer(index, x, y)
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                const previousValue = getCellValueViewer(index, x, y, 0)
                const valueEvents = dataEditor.settings.value_events
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                evalValues({
                  index: index,
                  dataEditor: dataEditor,
                  dataViewer: dataViewer,
                  cloneIndex: 0,
                  encode: true,
                  saveCallback: () => {
                    if (valueEvents) {
                      if (status.trigger && dataViewer.triggers) {
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        triggerEvents(index, dataViewer.triggers, true)
                      }
                    }
                  },
                  ignoreAutoSaveSetting: false,
                  value: content,
                  previousValue: previousValue
                })
              }
            }
          }
        }
      }
    }
  }

  /**
   * Konvertiert die Variable-Value in den angegeben Type. Dieser entspricht
   * nicht den primitive Typen von JavaScript. Die Rückgabe erfolgt in den
   * primitive Typen von JavaScript.
   *
   * @param value - {string} Variablen-Value (ist immer ein String)
   * @param type - {DBEntryValuesTypes} Ziel-Type
   * @returns - {null|number|string|boolean|Date} Konvertierte Variable-Value in
   * passenden Primitive Typen
   */
  function convertVariableValue(value, type) {
    const TYPE = type || DBEntryValuesTypes.String
    let result = null

    if (TYPE === DBEntryValuesTypes.Int) {
      result = parseInt(value) || 0
    } else if (TYPE === DBEntryValuesTypes.Float) {
      result = parseFloat(value) || 0
    } else if (TYPE === DBEntryValuesTypes.Bool) {
      result = typeof value === 'string' && /^true|1$/i.test(value.trim())
    } else if (TYPE === DBEntryValuesTypes.Date) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      result = getDateObject(value)
    } else if (TYPE === DBEntryValuesTypes.String) {
      result = value ? value.toString() : ''
    }

    return result
  }

  /**
   * Konvertiert die primitiven Typen von JavaScript und das Date-Objekt zu dem
   * passenden Variable-Value.
   *
   * @param value - {string|null|number|boolean|Date} Wert welcher zu einem
   * Variable-Value konvertiert werden soll.
   * @returns - {string} Konvertierte Variable-Value in passenden primitive
   * Typen
   */
  function convertToVariableValue(value) {
    const valueType = typeof value
    let result = ''

    if (value !== null && valueType !== 'undefined') {
      if (valueType === 'number') {
        result = !isNaN(value) && value !== 0 ? value.toString() : '0'
      } else if (valueType === 'boolean') {
        result = value !== true ? 'false' : 'true'
      } else if (value instanceof Date) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        result = getDateTime(value)
      } else if (valueType === 'string') {
        result = value
      }
    }

    return result
  }

  /**
   * Holt die Daten einer Zelle, anhand des Index des Checkliste,
   * x- und y-Position der Zelle
   *
   * @param index - Index der Checkliste
   * @param x - x-Position der Zelle
   * @param y - y-Position der Zelle
   * @returns Gibt Werte der Zelle im Editor zurück
   */
  function getCellDataEditor(index, x, y) {
    if (!DATA.collection) {
      return
    }
    const lists = DATA.collection.Checklists
    if (!lists) {
      return
    }
    const list = lists[index]
    if (!list) {
      return
    }
    const cells = list.ChecklistCells
    if (!cells) {
      return
    }
    const rows = cells[x]
    if (!rows) {
      return
    }
    return rows[y]
  }

  /**
   * Holt die Werte einer Zelle, anhand des Index des Checkliste,
   * x- und y-Position der Zelle
   *
   * @param index - Index der Checkliste
   * @param x - x-Position der Zelle
   * @param y - y-Position der Zelle
   * @returns Gibt Werte der Zelle im Viewer zurück
   */
  function getCellDataViewer(index, x, y) {
    const correlations = DATA.correlations
    if (!correlations) {
      return
    }
    const cells = correlations[index]
    if (!cells) {
      return
    }
    const rows = cells[x]
    if (!rows) {
      return
    }
    return rows[y]
  }

  /**
   * Hold den Wert einer Zelle im Viewer.
   *
   * @param index - Index der Checkliste
   * @param data - Daten der Zelle im Editor
   * @param cloneIndex - Index des Klons
   * @param defaultValue - Wert auf den zurückgefallen wird
   * @returns Gibt Wert der Zelle im Viewer zurück
   */
  function getCellValue(index, data, cloneIndex, defaultValue) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    let value = getCellValueViewer(index, data.x, data.y, ~~cloneIndex)
    if (value === undefined || value === null) {
      if (!cloneIndex) {
        value = getLocalization(data, 'preset_value') || defaultValue || ''
      } else {
        value = defaultValue || ''
      }
    }
    if (value === undefined || value === null) {
      if (defaultValue !== undefined && defaultValue !== null && !cloneIndex) {
        value = defaultValue
      } else {
        value = ''
      }
    }
    return value
  }

  /**
   * Hold den Wert einer Zelle im Viewer.
   *
   * @param index - Index der Checkliste
   * @param x - x-Position der Zelle
   * @param y - y-Position der Zelle
   * @param cloneIndex - Index des Klons
   * @returns Gibt Wert der Zelle im Viewer zurück
   */
  function getCellValueViewer(index, x, y, cloneIndex) {
    const dataViewer = getCellDataViewer(index, x, y) || {}
    const valuesViewer = dataViewer.value || []
    return valuesViewer[cloneIndex]
  }

  /**
   * Überprüft ob die Checkliste sichtbar ist,
   * und setzt entsprechende Flags.
   *
   * @param checklist - Daten der Checkliste.
   * @param ignoreRights - Flag das angibt,
   * ob die Überprüfung der Anzeigerechte ignoriert wird.
   * @returns Gibt den Status der Sichtbarkeit zurück.
   */
  async function checkChecklistVisibility(checklist, ignoreRights) {
    const status = DATA.status[checklist.index]
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (!checklist.active || !checkVisibilityClone(checklist)) {
      return (status.visible = false)
    }
    if (MODE === MODE_PRINTER) {
      if (DATA.collection.show_printer_hidden_checklists) {
        return (status.visible = true)
      }
    } else if (checklist.show_in_printer_only) {
      return (status.visible = false)
    }
    if (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      (!ignoreRights && !checkVisibilityRights(checklist)) ||
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      !(await checkVisibilityRequirements(checklist))
    ) {
      return (status.visible = false)
    }
    return (status.visible = true)
  }

  /**
   * Überprüft ob die Checkliste editierbar ist,
   * und setzt entsprechende Flags,
   * anhand der Berechtigungen.
   *
   * @param checklist - Daten der Checkliste.
   * @returns Gibt den Status der Editierbarkeit zurück.
   */
  function checkEditabilityRights(checklist) {
    checklist = extend({}, checklist)
    const rights = checklist.editability_rights
    const requiredRights = checklist.required_editability_rights || []
    const index = checklist.index
    const status = DATA.status[index]
    let passed = false
    status.editable = true
    if (
      !rights ||
      PREVIEW_SETTINGS.IGNORE_EDITABILITY_RIGHTS ||
      !Array.isArray(rights) ||
      !rights.length ||
      !BUILDER_SETTINGS.CHECKLIST_EDITABILITY_RIGHTS
    ) {
      return true
    }

    for (let _right, i = 0; (_right = rights[i++]) !== undefined; ) {
      const entry = BUILDER_SETTINGS.CHECKLIST_EDITABILITY_RIGHTS[_right] || {}
      const required = entry.required || requiredRights.indexOf(_right) !== -1
      const right = entry.right
      if (typeof right === 'undefined') {
        continue
      }
      if (required) {
        if (typeof right === 'boolean') {
          if (!right) {
            status.editable = false
            return false
          }
          passed = true
        } else if (typeof right === 'function') {
          if (!right(checklist)) {
            status.editable = false
            return false
          }
          passed = true
        }
      } else if (!passed) {
        if (typeof right === 'boolean') {
          if (right) {
            passed = true
          }
        } else if (typeof right === 'function') {
          if (right(checklist)) {
            passed = true
          }
        }
      }
    }

    if (passed) {
      return true
    }

    status.editable = false
    return false
  }

  /**
   * Überprüft ob die Checkliste sichtbar ist,
   * und setzt entsprechende Flags,
   * anhand der Berechtigungen.
   *
   * @param checklist - Daten der Checkliste.
   * @returns Gibt den Status der Sichtbarkeit zurück.
   */
  function checkVisibilityRights(checklist) {
    checklist = extend({}, checklist)
    const rights = checklist.visibility_rights
    const requiredRights = checklist.required_visibility_rights || []
    const index = checklist.index
    const status = DATA.status[index]
    let passed = false
    status.visible = true
    if (
      !rights ||
      PREVIEW_SETTINGS.IGNORE_VISIBILITY_RIGHTS ||
      !Array.isArray(rights) ||
      !rights.length ||
      !BUILDER_SETTINGS.CHECKLIST_VISIBILITY_RIGHTS
    ) {
      return true
    }

    for (let _right, i = 0; (_right = rights[i++]) !== undefined; ) {
      const entry = BUILDER_SETTINGS.CHECKLIST_VISIBILITY_RIGHTS[_right] || {}
      const required = entry.required || requiredRights.indexOf(_right) !== -1
      const right = entry.right
      if (typeof right === 'undefined') {
        continue
      }
      if (required) {
        if (typeof right === 'boolean') {
          if (!right) {
            status.visible = false
            return false
          }
          passed = true
        } else if (typeof right === 'function') {
          if (!right(checklist)) {
            status.visible = false
            return false
          }
          passed = true
        }
      } else if (!passed) {
        if (typeof right === 'boolean') {
          if (right) {
            passed = true
          }
        } else if (typeof right === 'function') {
          if (right(checklist)) {
            passed = true
          }
        }
      }
    }

    if (passed) {
      return true
    }

    status.visible = false
    return false
  }

  /**
   * Überprüft ob die Checkliste sichtbar ist,
   * und setzt entsprechende Flags,
   * anhand der Abhängigkeiten.
   *
   * @param checklist - Daten der Checkliste.
   * @param cellReplacements - Matrix aus Dummy-Werten für Zellen
   * @returns Gibt den Status der Sichtbarkeit zurück.
   */
  async function checkVisibilityRequirements(checklist, cellReplacements) {
    const requirement = checklist.requirement
    const requirementMatrix = checklist.requirement_matrix
    const status = DATA.status
    if (typeof requirement === 'number') {
      const progress = (status[requirement] || {}).progress || 0
      if (progress < checklist.required_progress) {
        return false
      }
    } else if (requirementMatrix) {
      const lists = DATA.collection.Checklists || []
      const groups = {}
      const _groupPartial = checklist.unlock_group_partial
      const stati = DATA.status
      for (const index in requirementMatrix) {
        if (requirementMatrix.hasOwnProperty(index)) {
          const _list = requirementMatrix[index]
          const _listData = lists[index] || {}
          const _group = _listData.unlock_group
          const _groupData = groups[_group] || {
            unlocked: true,
            count: 0,
            skipped: 0
          }
          const _status = stati[index] || {}
          _groupData.count++
          if (_groupPartial && _status.visible) {
            _groupData.unlocked = true
          }
          if (!_listData.active || !_groupData.unlocked) {
            _groupData.skipped++
            groups[_group] = _groupData
            continue
          } else if (_groupPartial && !_status.visible) {
            for (const x in _list) {
              if (_list.hasOwnProperty(x)) {
                const _column = _list[x]
                if (!_column) {
                  continue
                }
                for (const y in _column) {
                  if (_column.hasOwnProperty(y)) {
                    const _reqType = (_column[y] || {}).checklist_requirement
                    const dataViewer = getCellDataViewer(index, x, y) || {}
                    if (_reqType && !dataViewer.done) {
                      _groupData.skipped++
                    }
                  }
                }
              }
            }
            _groupData.unlocked = true
            groups[_group] = _groupData
            continue
          }
          for (const x in _list) {
            if (_list.hasOwnProperty(x)) {
              const _column = _list[x]
              if (!_column) {
                continue
              }
              for (const y in _column) {
                if (_column.hasOwnProperty(y)) {
                  const _reqType = (_column[y] || {}).checklist_requirement
                  const dataViewer = getCellDataViewer(index, x, y) || {}
                  if (_reqType && !dataViewer.done) {
                    if (cellReplacements && cellReplacements[index]) {
                      const _crList = cellReplacements[index]
                      if (_crList[x]) {
                        const _crColumn = _crList[x]
                        if ((_crColumn[y] || {}).done) {
                          continue
                        }
                      }
                    }
                    if (
                      _reqType === 2 ||
                      (_reqType === 1 &&
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        (await checkCellVisibility(index, x, y)))
                    ) {
                      _groupData.unlocked = false
                    }
                  }
                }
              }
            }
          }
          groups[_group] = _groupData
        }
      }
      for (let _group in groups) {
        if (groups.hasOwnProperty(_group)) {
          _group = groups[_group]
          if (_group.unlocked && _group.count !== _group.skipped) {
            return true
          }
        }
      }
      return false
    }
    return true
  }

  /**
   * Überprüft die Sichtbarkeit der Zellen,
   * anhand des Index des Checkliste,
   * x- und y-Position der Zelle
   *
   * @param index - Index der Checkliste
   * @param x - x-Position der Zelle
   * @param y - y-Position der Zelle
   * @returns Gibt den Status der Sichtbarkeit zurück.
   */
  async function checkCellVisibility(index, x, y) {
    const dataEditor = getCellDataEditor(index, x, y) || { settings: {} }
    const collection = DATA.collection
    const list = collection.Checklists[index] || {}
    const dataViewer = getCellDataViewer(index, x, y) || {}

    if (dataEditor.settings.inactive) {
      return (dataViewer.visible = false)
    }

    if (
      list.clone_of_checklist_id &&
      dataEditor.settings.hide_cell_in_cloned_checklist
    ) {
      return (dataViewer.visible = false)
    }

    if (
      // !BUILDER_SETTINGS.ONLINE &&
      Array.isArray(BUILDER_SETTINGS.ONLINE_ELEMENTS) &&
      BUILDER_SETTINGS.ONLINE_ELEMENTS.indexOf(dataEditor.type) > -1
    ) {
      return (dataViewer.visible = false)
    }

    if (MODE === MODE_PRINTER) {
      if (dataEditor.settings.hide_in_report) {
        return (dataViewer.visible = false)
      }
      if (collection.show_printer_hidden_cells) {
        return (dataViewer.visible = true)
      }
    }

    if (dataEditor.type === 'custom_button') {
      if (!dataEditor.settings.offline_visible) {
        return (dataViewer.visible = false)
      }
    }

    const valueEvents = dataEditor.settings.value_events
    if (Array.isArray(valueEvents)) {
      for (let _event, j = 0; (_event = valueEvents[j++]) !== undefined; ) {
        if (_event.action === 'hide_cell') {
          let value = dataViewer.value
          if (value) {
            value = value[0]
          } else {
            value = getLocalization(dataEditor, 'preset_value')
          }
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          const status = await evalTerms({
            Events: [_event],
            DataViewer: dataViewer,
            DataEditor: dataEditor,
            Value: value,
            Muted: true,
            CloneIndex: 0,
            ChecklistIndex: index
          })
          if (!status.visible) {
            return (dataViewer.visible = false)
          }
        }
      }
    }

    const requires = dataViewer.requires
    if (!requires) {
      return (dataViewer.visible = true)
    }

    for (let i = 0, clength = requires.length; i < clength; i++) {
      const req = requires[i]
      const rx = req.x
      const ry = req.y
      if (rx === x && ry === y) {
        return (dataViewer.visible = !!dataViewer.done)
      }
      const entry = getCellDataViewer(index, rx, ry)

      if (typeof entry.done === 'undefined') {
        const data = getCellDataEditor(index, rx, ry)
        const valueEvents = data.settings.value_events
        if (valueEvents) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          const status = await evalTerms({
            Events: valueEvents,
            DataViewer: entry,
            DataEditor: data,
            Value: getLocalization(data, 'preset_value'),
            Muted: true,
            CloneIndex: 0,
            ChecklistIndex: index
          })
          entry.done = status.done
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          saveChecklistValues(index, rx, ry, entry)
          if (!entry.done) {
            return (dataViewer.visible = false)
          }
        }
      } else if (!entry.done) {
        return (dataViewer.visible = false)
      } else if (!(await checkCellVisibility(index, rx, ry))) {
        return (dataViewer.visible = false)
      }
    }
    return (dataViewer.visible = true)
  }

  /**
   * Speichert die Werte einer Zellen im Viewer
   *
   * @param index - Index der Checkliste
   * @param x - x-Position der Zelle
   * @param y - y-Position der Zelle
   * @param entry - Werte der Zelle im Viewer
   * @param ignoreSettingAutoSave - Ignoriert die
   * Einstellungen zum automatischen Speichern der Werte
   * @param callback - Funktion die nach dem Speichern
   * aufgerufen wird
   * @param isRemove - Ob der Aufruf von der Löschfunktion kommt
   */
  function saveChecklistValues(
    index,
    x,
    y,
    entry,
    ignoreSettingAutoSave,
    callback,
    isRemove = false
  ) {
    const dataEditor = getCellDataEditor(index, x, y)
    /**
     * Ruft die Funktion ON_VALUE_CHANGED in den
     * BUILDER_SETTINGS auf, wenn diese definiert wurde.
     */
    const callExec = () => {
      const setting = dataEditor.settings.on_value_changed
      if (setting) {
        const exec = BUILDER_SETTINGS.ON_VALUE_CHANGED[setting] || {}
        if (typeof exec.onChanged === 'function') {
          exec.onChanged(entry.value, dataEditor, entry)
        }
      }
    }

    // eslint-disable-next-line jsdoc/require-jsdoc
    const converter = values => {
      const result = {}
      if (!Array.isArray(values)) {
        return result
      }
      const data = DATA.collection.Checklists || []
      for (let i = 0, length = values.length; i < length; i++) {
        const checklist = values[i]
        if (!Array.isArray(checklist)) {
          continue
        }
        const cells = (data[i] || {}).ChecklistCells || []
        for (let x = 0, xlength = checklist.length; x < xlength; x++) {
          const column = checklist[x]
          if (!Array.isArray(column)) {
            continue
          }
          const column2 = cells[x] || []
          for (let y = 0, ylength = column.length; y < ylength; y++) {
            const cell = column[y]
            if (!cell) {
              continue
            }
            const cell2 = column2[y] || {}
            const cellID = cell2.id || 0
            result[cellID] = cell
          }
        }
      }
      return result
    }

    if (entry && typeof entry === 'object') {
      entry.cellGUID = dataEditor.GUID
    }

    if (
      MODE === MODE_PREVIEW ||
      BUILDER_SETTINGS.READ_ONLY ||
      BUILDER_SETTINGS.DISPLAY_MODE
    ) {
      if (typeof callback === 'function') {
        const id = 0
        callback(id)
      }
      return
    }

    // Setzte Standardwerte für benötigte Felder.
    const valueObject = extend(
      {
        id: 0,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        collection_id: DATA.collection.id,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        checklist_cell_id: dataEditor.id,
        index: 0,
        selector: BUILDER_SETTINGS.VALUE_SELECTOR
      },
      entry
    )

    if (isRemove !== true) {
      if (
        (!ignoreSettingAutoSave &&
          !BUILDER_SETTINGS.AUTO_SAVE_CHECKLIST_VALUES) ||
        !BUILDER_SETTINGS.ONLINE
      ) {
        // eslint-disable-next-line jsdoc/require-jsdoc
        const saveing = () => {
          callExec()
          offlineSaveVariableData()

          if (typeof callback === 'function') {
            callback(entry.id)
          }

          if (!BUILDER_SETTINGS.ONLINE) {
            if (typeof BUILDER_SETTINGS.ON_VALUE_SAVED === 'function') {
              BUILDER_SETTINGS.ON_VALUE_SAVED(
                valueObject,
                DATA.correlations,
                converter
              )
            }
          }
        }

        if (typeof BUILDER_SETTINGS.ON_VALUE_SAVING === 'function') {
          BUILDER_SETTINGS.ON_VALUE_SAVING(
            entry,
            saveing,
            DATA.correlations,
            converter
          )
        } else {
          saveing()
        }
        return
      }
    }

    /**
     * Ruft Funktionen nach dem erfolgreichen Speichern auf.
     *
     * @param id - ID des Wertes der Zelle
     */
    const success = id => {
      try {
        if (id) {
          entry.id = id

          if (!isRemove) {
            callExec()
            offlineSaveVariableData()
            if (typeof callback === 'function') {
              callback(id)
            }

            if (typeof BUILDER_SETTINGS.ON_VALUE_SAVED === 'function') {
              BUILDER_SETTINGS.ON_VALUE_SAVED(
                entry,
                DATA.correlations,
                converter
              )
            }

            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            showMessage(
              'success',
              lang('message.content.save_checklistValuesSuccess')
            )
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          showMessage(
            'error',
            lang('message.content.save_checklistValuesError')
          )
        }
      } catch (ex) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        showMessage('error', lang('message.content.save_checklistValuesFatal'))
      }
    }
    const settings = {
      cellType: dataEditor.type
    }
    if (
      BUILDER_SETTINGS.CHECKLIST_VALUE_LOGGING &&
      dataEditor.settings.logging
    ) {
      settings.logging = true
    }
    if (dataEditor.settings.drawing_protected) {
      settings.drawingProtected = true
    }
    /**
     * Speichert den Wert der Zelle.
     *
     * @param data - Wert der Zelle
     */
    const save = data => {
      const requestData = {
        settings: settings,
        value: extend(
          valueObject,
          data !== null && typeof data === 'object' ? data : {},
          { _remove: false, _reset: false }
        )
      }

      getService('checklists/cells/values', Connection.Offline).then(
        service => {
          const result =
            Number.isInteger(valueObject.id) && valueObject.id > 0
              ? service.update(valueObject.id, requestData)
              : service.create(requestData)

          result.then(
            result => {
              if (result && Number.isInteger(result.id) && result.id > 0) {
                success(result.id)
              }
            },
            () => {
              success(0) // Ungültige-ID, dadurch wird es als Fehler bewertet
            }
          )
        }
      )
    }

    if (typeof BUILDER_SETTINGS.ON_VALUE_SAVING === 'function') {
      BUILDER_SETTINGS.ON_VALUE_SAVING(
        entry,
        save,
        DATA.correlations,
        converter
      )
    } else {
      save()
    }
  }

  /**
   * Zeigt eine Nachricht an entsprechend des Typs an.
   *
   * @param type - Typen:
   * - error
   * - success
   * - warn
   * - default (info)
   * @param msg - Nachricht die angezeigt wird.
   * @param iml - Ignoriert Filter für Nachrichten-Level.
   */
  function showMessage(type, msg, iml) {
    if (msg) {
      switch (type) {
        case 'error':
          if (iml || BUILDER_SETTINGS.MESSAGE_LEVEL.indexOf(MSG_ERROR) > -1) {
            DATA.message.color = '#ff4444'
            DATA.message.text = msg
            DATA.message.active = true
          }
          break
        case 'success':
          if (iml || BUILDER_SETTINGS.MESSAGE_LEVEL.indexOf(MSG_SUCCESS) > -1) {
            DATA.message.color = '#00C851'
            DATA.message.text = msg
            DATA.message.active = true
          }
          break
        case 'warn':
          if (iml || BUILDER_SETTINGS.MESSAGE_LEVEL.indexOf(MSG_WARN) > -1) {
            DATA.message.color = '#ffbb33'
            DATA.message.text = msg
            DATA.message.active = true
          }
          break
        default:
          if (iml || BUILDER_SETTINGS.MESSAGE_LEVEL.indexOf(MSG_INFO) > -1) {
            DATA.message.color = '#33b5e5'
            DATA.message.text = msg
            DATA.message.active = true
          }
          break
      }
      setTimeout(() => {
        DATA.message.active = false
      }, 6000)
    }
  }

  /**
   * Überprüft ob ein Checklisten-Klon sichtbar ist,
   * und setzt entsprechende Flags.
   *
   * @param checklist - Daten der Checkliste.
   * @returns Gibt den Status der Sichtbarkeit zurück.
   */
  function checkVisibilityClone(checklist) {
    if (!checklist.clone_of_checklist_id) {
      return true
    }

    const cells = checklist.ChecklistCells || [[]]
    const index = checklist.index
    for (let column, x = 0; (column = cells[x]); x++) {
      for (let y = 0; column[y]; y++) {
        const dataViewer = getCellDataViewer(index, x, y)
        if (!dataViewer || typeof dataViewer.done !== 'undefined') {
          return true
        }
      }
    }

    DATA.status[index].visible = false
    return false
  }

  /**
   * Wertet Ereignisee bei Wert aus
   *
   * @param args - Objekt das alle Werte und
   * Einstellungen zum Auswerten beinhaltet:
   * - DataViewer
   * - Value
   * - Events
   * - Muted
   * - CloneIndex
   * - ChecklistIndex
   * - DataEditor
   * @returns Gibt das Ergebnis der Auswertung zurück.
   */
  async function evalTerms(args) {
    const entry = args.DataViewer
    const value = args.Value
    const valueEvents = args.Events
    const muted = args.Muted
    const cloneIndex = args.CloneIndex
    const index = args.ChecklistIndex
    const dataEditor = args.DataEditor

    let status = {
      abort: false,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      global_variable_modified: false,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      variables_modified: false,
      done: entry.done
    }

    let variables = {}
    if (valueEvents.length > 0) {
      let previousValue = entry.value || ''
      try {
        if (typeof previousValue === 'string') {
          previousValue = JSON.parse(previousValue)
        }
      } catch (ex) {
        // Keine Aktion nötig
      }
      previousValue = previousValue[~~cloneIndex]
      try {
        if ((EDITOR_ELEMENTS[dataEditor.type] || {}).valueBase64Encoded) {
          const temp = atob(previousValue)
          const encoded = btoa(temp) === previousValue
          if (encoded) {
            previousValue = temp
          }
        }
      } catch (ex) {
        // Keine Aktion nötig
      }
      const values = {
        value: value,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        previous_value: previousValue
      }
      /**
       * Baut Variablen aus Objekten etc.
       *
       * @param basekey - Basis des Schlüssels für die Variable
       * @param tempValue - Wert der aufgelöst und in Variable geschrieben wird.
       */
      const buildVariables = (basekey, tempValue) => {
        if (
          !tempValue &&
          typeof tempValue !== 'number' &&
          typeof tempValue !== 'boolean'
        ) {
          tempValue = ''
        }
        const regex =
          /^(dropdown|dropdown_sql|multiselect_sql|multiselect|multiselect_open|tree|activity|department|location)$/i
        if (Array.isArray(tempValue)) {
          if (regex.test(dataEditor.type)) {
            const labels = []
            const ids = []
            for (let _obj, i = 0; (_obj = tempValue[i++]); ) {
              const label = getLocalization(_obj, 'label')
              variables['@' + basekey + '_' + _obj.id + '@'] = label
              labels[labels.length] = label
              ids[ids.length] = _obj.id
            }
            variables['@' + basekey + '_labels@'] = labels.join(', ')
            variables['@' + basekey + '_ids@'] = ids.join(', ')
          }
        } else if (typeof tempValue === 'object') {
          for (const key in tempValue) {
            if (tempValue.hasOwnProperty(key)) {
              buildVariables(basekey + '_' + key, tempValue[key])
            }
          }
        }
        if (typeof tempValue !== 'string') {
          tempValue = JSON.stringify(tempValue)
        }
        variables['@' + basekey + '@'] = tempValue
      }
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          buildVariables(key, values[key])
        }
      }

      // Standardvariablen für die Mitarbeiter Auswahl setzen
      if (dataEditor.type === 'employee') {
        variables['@value_employee_firstname@'] = ''
        variables['@value_employee_lastname@'] = ''
        variables['@value_employee_number@'] = ''
        variables['@previous_value_employee_firstname@'] = ''
        variables['@previous_value_employee_lastname@'] = ''
        variables['@previous_value_employee_number@'] = ''

        let currentID = 0
        let previousID = 0

        if (value && typeof value.toString === 'function') {
          currentID = parseInt(value.toString(), 10)

          if (isNaN(currentID) || currentID < 0) {
            currentID = 0
          }
        }

        if (previousValue && typeof previousValue.toString === 'function') {
          previousID = parseInt(previousValue.toString(), 10)

          if (isNaN(previousID) || previousID < 0) {
            previousID = 0
          }
        }

        if (currentID !== 0 || previousID !== 0) {
          const service = await getService(
            'offline-data/employees',
            Connection.Offline
          )
          const employees = await service.find({
            query: {
              id: { $in: [currentID, previousID] }
            }
          })

          if (Array.isArray(employees.data) && employees.data.length) {
            employees.data.forEach(data => {
              if (typeof data === 'object') {
                const prefix =
                  data.id === currentID
                    ? 'value_employee'
                    : data.id === previousID
                    ? 'previous_value_employee'
                    : ''

                if (prefix) {
                  variables[`@${prefix}_firstname@`] = data.firstName
                    ? data.firstName.toString()
                    : ''
                  variables[`@${prefix}_lastname@`] = data.lastName
                    ? data.lastName.toString()
                    : ''
                  variables[`@${prefix}_number@`] = data.personnelNumber
                    ? data.personnelNumber.toString()
                    : ''
                }
              }
            })
          }
        }
      }

      variables = extend(variables, DATA.variables)
    }

    const date = TIME_MS_NOW()
    const list = DATA.collection.Checklists[index]

    variables['@cell_is_legacy_eplas@'] = false
    variables['@cell_is_dialog@'] = BUILDER_SETTINGS.WRAPPER_IS_DIALOG
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    variables['@cell_date@'] = getDate(date, BUILDER_SETTINGS.LOCALE)
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    variables['@cell_date_time@'] = getDateTime(date, { seconds: false })
    variables['@cell_year@'] = new Date().getFullYear()
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    variables['@cell_time@'] = getTime(date, { seconds: false })
    variables['@cell_language@'] = BUILDER_SETTINGS.LANGUAGE
    variables['@cell_locale@'] = BUILDER_SETTINGS.LOCALE
    variables['@cell_id@'] =
      dataEditor.id ||
      dataEditor.temp_id ||
      // eslint-disable-next-line @typescript-eslint/naming-convention
      (dataEditor.temp_id = 'c' + Date.now())
    variables['@cell_checklist_index@'] = index
    variables['@cell_checklist_id@'] =
      // eslint-disable-next-line @typescript-eslint/naming-convention
      list.id || list.temp_id || (list.temp_id = 'l' + Date.now())

    for (let event, i = 0; (event = valueEvents[i++]); ) {
      const template = VALUE_EVENTS[event.action] || {}
      if (typeof template.action === 'function') {
        variables['@cell_done@'] = status.done

        const eventParameters = {}
        for (let _param, j = 0; (_param = template.parameters[j++]); ) {
          eventParameters[_param.parameter] = _param
        }

        const data = {
          status: status,
          value: value,
          variables: variables,
          entry: entry,
          muted: muted,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          event_parameters: eventParameters,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          event_data: event,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          action_data: template.data,
          index: index,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          data_editor: dataEditor,
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          evalTermsTest: evalTermsTest
        }

        status =
          (await template.action(data, template.callback)) || data.status || {}
        if (status.abort) {
          break
        }
      }
    }

    if (status.variables_modified) {
      if (BUILDER_SETTINGS.AUTO_SAVE_VARIABLE_VALUES) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        saveVariableData(
          undefined,
          undefined,
          BUILDER_SETTINGS.CALCULATE_GLOBAL_VARIABLES_SERVER_SIDE
        )
      }
    }

    return status
  }

  /**
   * Gibt anhand eines Wertes ein Javascript Datumsobjekt zurück.
   *
   * @param value - Datum als String, Zahl oder Objekt
   * @returns Gibt ein Datumsobjekt zurück
   */
  function getDateObject(value) {
    let date = new Date(0, 0, 0, 0, 0, 0)
    if (/^\/Date\([0-9]+[+-]?[0-9]*\)\/$/i.test(value)) {
      const matches = /[0-9]+/.exec(value)
      date = new Date(parseInt(matches[0]) || 0)
    } else if (BUILDER_SETTINGS.LANGUAGE) {
      switch (BUILDER_SETTINGS.LANGUAGE) {
        case 'de':
          {
            const regex =
              /^(\d{1,2})\.(\d{1,2})\.(\d{4}|)(?: (\d{1,2}):?(\d{1,2}):?(\d{1,2})?)?$/g
            const matches = regex.exec(value)
            if (matches) {
              date = new Date(
                ~~matches[3],
                (~~matches[2] || 1) - 1,
                ~~matches[1],
                ~~matches[4],
                ~~matches[5],
                ~~matches[6]
              )
            } else {
              date = new Date(value)
            }
          }
          break
        default:
          date = new Date(value)
          break
      }
    }
    return date
  }

  /**
   * Gibt ein Date-Object als ISO-Datum zurück (Lokale Zeit)
   * !! Achtung: vanilla 'toISOString()' gibt in 0 UTC zurück !!
   *
   * @param date - Datum welches umgewandekt werden soll
   * @param withTime - Ob Zeit mitgenommen werden soll
   * @returns Gibt Datum als ISO-String zurück
   */
  function getDateISO(date, withTime = true) {
    if (date === null) {
      return ''
    }
    if (!(date instanceof Date)) {
      throw new TypeError("parameter 'date' support only type Date")
    }

    let month, day
    month = date.getMonth() + 1
    month = month <= 9 ? '0' + month : month

    day = date.getDate()
    day = day <= 9 ? '0' + day : day

    if (withTime === true) {
      let hour, min, sec

      hour = date.getHours()
      hour = hour <= 9 ? '0' + hour : hour

      min = date.getMinutes()
      min = min <= 9 ? '0' + min : min

      sec = date.getSeconds()
      sec = sec <= 9 ? '0' + sec : sec

      return `${date.getFullYear()}-${month}-${day} ${hour}:${min}:${sec}`
    }

    return `${date.getFullYear()}-${month}-${day}`
  }

  /**
   * Gibt anhand eines Wertes einen
   * formatierten Datums- und Uhrzeitstring zurück.
   *
   * @param value - Datum als String, Zahl oder Objekt
   * @param options - Einstellungen für das Format
   * @returns Gibt einen
   * formatierten Datums- und Uhrzeitstring zurück
   */
  function getDateTime(value, options) {
    if (!value) {
      return ''
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return getDate(value) + ' ' + getTime(value, options)
  }

  /**
   * Gibt anhand eines Wertes einen formatierten Uhrzeitstring zurück.
   *
   * @param value - Datum als String, Zahl oder Objekt
   * @param options - Einstellungen für das Format
   * @returns Gibt einen formatierten Uhrzeitstring zurück
   */
  function getTime(value, options) {
    if (!value) {
      return ''
    }

    options = options || {}

    let date = new Date()
    if (typeof value === 'string') {
      date = getDateObject(value)
    } else {
      date = new Date(value)
    }
    const hours = options.UTC ? date.getUTCHours() : date.getHours()
    const mins = options.UTC ? date.getUTCMinutes() : date.getMinutes()
    const secs = options.UTC ? date.getUTCSeconds() : date.getSeconds()
    const time = []

    if (!options.hasOwnProperty('hours') || options.hours === true) {
      time[time.length] = hours < 10 ? '0' + hours : hours
    }

    if (!options.hasOwnProperty('minutes') || options.minutes === true) {
      time[time.length] = mins < 10 ? '0' + mins : mins
    }

    if (!options.hasOwnProperty('seconds') || options.seconds === true) {
      time[time.length] = secs < 10 ? '0' + secs : secs
    }

    return time.join(':')
  }

  /**
   * Gibt anhand eines Wertes ein formatierten Datumsstring zurück.
   *
   * @param value - Datum als String, Zahl oder Objekt
   * @param language - Lokalisierung für Format
   * @returns Gibt einen formatierten Datumsstring zurück
   */
  function getDate(value, language) {
    if (!value) {
      return ''
    }

    if (!language) {
      language = BUILDER_SETTINGS.LOCALE
    }

    if (typeof value !== 'number') {
      value = getDateObject(value)
    } else {
      value = new Date(value)
    }
    // WICHTIG! Ersetze Unicode-Zeichen in IE und Edge
    return value
      .toLocaleDateString(language || 'en-US')
      .replace(/[\u200e|\u1456]/g, '')
  }

  /**
   * Wertet Terme aus und gibt Ergebnis zurück
   *
   * @param term - Term der ausgewertet wird
   * @param varValues - Werte der Variablen
   * @param validation - Gibt an ob der Term vailidiert wird
   * @returns Gibt das Ergebnis der Auswertung zurück
   */
  function evalTermsTest(term, varValues, validation) {
    const or = (term || '').split('||')
    let result = {
      passed: false,
      message: lang('message.content.valueEventTermEmpty'),
      valid: false
    }

    if (!term) {
      return result
    }

    for (let i = 0, olength = or.length; i < olength; i++) {
      const partOr = or[i].trim()
      const and = partOr.split('&&')
      result.passed = true
      result.valid = true
      for (let j = 0, alength = and.length; j < alength; j++) {
        const partAnd = and[j].trim()
        const matches = partAnd.match(/(<>|<=|<|>=|>|==|!=)/g)
        if (!matches || matches.length === 0) {
          result.message = "'" + partAnd + "'."
          result.passed = false
          result.valid = false
          continue
        } else {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          result = evalPartAnd(
            partAnd,
            varValues,
            validation,
            matches[0].trim()
          )
          if (validation) {
            if (!result.valid) {
              break
            }
          } else if (!result.passed) {
            break
          }
        }
      }
      if (result.passed) {
        return result
      }
    }
    return result
  }

  /**
   * Wertet Terme aus und gibt Ergebnis zurück
   *
   * @param partAnd - Term der ausgewertet wird
   * @param varValues - Werte der Variablen
   * @param validation - Gibt an ob der Term vailidiert wird
   * @param comparator - Vergleichsoperator
   * @returns Gibt das Ergebnis der Auswertung zurück
   */
  function evalPartAnd(partAnd, varValues, validation, comparator) {
    const result = {
      passed: false,
      message: "'" + partAnd + "'.",
      valid: false
    }
    if (!partAnd.match(new RegExp('.*' + comparator + '.*', 'g'))) {
      return result
    }
    const values = partAnd.split(new RegExp(comparator + '(.*)', 'g'))
    let valueLeft = values[0].trim()
    let valueRight = values[1].trim()
    let isNumberCompare = false

    if (/^\(.*\)$/i.test(valueLeft)) {
      const tempLeft = valueLeft.slice(1, -1)
      if (/^[^)]*(<>|<=|<|>=|>|==|!=).*$/g.test(tempLeft)) {
        const tempResult = evalTermsTest(tempLeft, varValues, validation)
        valueLeft = tempResult.passed.toString()
        if (!tempResult.valid) {
          return tempResult
        }
      } else {
        result.message = "'" + valueLeft + "'."
        return result
      }
    } else if (/(<>|<=|<|>=|>|==|!=)/g.test(valueLeft)) {
      const tempResult = evalTermsTest(valueLeft, varValues, validation)
      valueLeft = tempResult.passed.toString()
      if (!tempResult.valid) {
        return tempResult
      }
    }
    if (/^\(.*\)$/i.test(valueRight)) {
      const tempRight = valueRight.slice(1, -1)
      if (/^[^)]*(<>|<=|<|>=|>|==|!=).*$/g.test(tempRight)) {
        const tempResult = evalTermsTest(tempRight, varValues, validation)
        valueRight = tempResult.passed.toString()
        if (!tempResult.valid) {
          return tempResult
        }
      } else {
        result.message = "'" + valueRight + "'."
        return result
      }
    } else if (/(<>|<=|<|>=|>|==|!=)/g.test(valueRight)) {
      const tempResult = evalTermsTest(valueRight, varValues, validation)
      valueRight = tempResult.passed.toString()
      if (!tempResult.valid) {
        return tempResult
      }
    }
    result.valid = true

    for (let _part, p = -1; (_part = [valueRight, valueLeft][++p]); ) {
      const _vars = _part.match(/@[a-z_0-9]+@/gi)
      if (_vars && _vars.length > 0) {
        for (let _item, i = 0; (_item = _vars[i++]); ) {
          let value = varValues[_item]
          if (typeof value === 'undefined' || value === null || value === '') {
            // TODO determine default value by comparator?
            value = "''"
          }
          _part = _part.replace(new RegExp(_item, 'gi'), value)
        }
        if (p === 0) {
          valueRight = _part
        } else {
          valueLeft = _part
        }
      }
    }

    if (
      /^-?[0-9]+((\.|,)[0-9+]+)?$/.test(valueLeft) &&
      /^-?[0-9]+((\.|,)[0-9+]+)?$/.test(valueRight)
    ) {
      valueLeft = parseFloat(valueLeft.replace(/,/gi, '.'))
      valueRight = parseFloat(valueRight.replace(/,/gi, '.'))
      isNumberCompare = true
    }

    switch (comparator) {
      case '<=':
        if (
          evalPartAnd(valueLeft + '<' + valueRight, varValues, validation, '<')
            .passed
        ) {
          result.passed = true
          return result
        }
        if (
          evalPartAnd(
            valueLeft + '==' + valueRight,
            varValues,
            validation,
            '=='
          ).passed
        ) {
          result.passed = true
          return result
        }
        break
      case '<':
        if (isNumberCompare) {
          if (valueLeft < valueRight) {
            result.passed = true
            return result
          }
        } else {
          const _dateL = getDateObject(valueLeft)
          const _dateR = getDateObject(valueRight)
          if ((_dateL || _dateR) && _dateL < _dateR) {
            result.passed = true
            return result
          }
        }
        break
      case '>=':
        if (
          evalPartAnd(valueLeft + '>' + valueRight, arValues, validation, '>')
            .passed
        ) {
          result.passed = true
          return result
        }
        if (
          evalPartAnd(
            valueLeft + '==' + valueRight,
            varValues,
            validation,
            '=='
          ).passed
        ) {
          result.passed = true
          return result
        }
        break
      case '>':
        if (isNumberCompare) {
          if (valueLeft > valueRight) {
            result.passed = true
            return result
          }
        } else {
          const _dateL = getDateObject(valueLeft)
          const _dateR = getDateObject(valueRight)
          if ((_dateL || _dateR) && _dateL > _dateR) {
            result.passed = true
            return result
          }
        }
        break
      case '==':
        if (
          /^\/Date\([0-9]+[+-]?[0-9]*\)\/$/i.test(valueLeft) ||
          /^\/Date\([0-9]+[+-]?[0-9]*\)\/$/i.test(valueRight)
        ) {
          if (valueLeft === valueRight) {
            result.passed = true
            return result
          }
        } else if (/^\/[\s\S]+\/[gmixXsuUAJ]*$/.test(valueLeft)) {
          const rgx = valueLeft.match(/^(\/[\s\S]+\/)([gmixXsuUAJ]*)$/)
          if (
            valueRight.match(new RegExp(rgx[1].slice(1, -1), rgx[2] || 'g'))
          ) {
            result.passed = true
            return result
          }
        } else if (/^\/[\s\S]+\/[gmixXsuUAJ]*$/.test(valueRight)) {
          const rgx = valueRight.match(/^(\/[\s\S]+\/)([gmixXsuUAJ]*)$/)
          if (valueLeft.match(new RegExp(rgx[1].slice(1, -1), rgx[2] || 'g'))) {
            result.passed = true
            return result
          }
        } else if (valueLeft === valueRight) {
          result.passed = true
          return result
        }
        break
      case '!=':
      case '<>':
        if (
          /^\/Date\([0-9]+[+-]?[0-9]*\)\/$/i.test(valueLeft) ||
          /^\/Date\([0-9]+[+-]?[0-9]*\)\/$/i.test(valueRight)
        ) {
          if (valueLeft !== valueRight) {
            result.passed = true
            return result
          }
        } else if (/^\/[\s\S]+\/[gmixXsuUAJ]*$/.test(valueLeft)) {
          const rgx = valueLeft.match(/^(\/[\s\S]+\/)([gmixXsuUAJ]*)$/)
          if (
            !valueRight.match(new RegExp(rgx[1].slice(1, -1), rgx[2] || 'g'))
          ) {
            result.passed = true
            return result
          }
        } else if (/^\/[\s\S]+\/[gmixXsuUAJ]*$/.test(valueRight)) {
          const rgx = valueRight.match(/^(\/[\s\S]+\/)([gmixXsuUAJ]*)$/)
          if (
            !valueLeft.match(new RegExp(rgx[1].slice(1, -1), rgx[2] || 'g'))
          ) {
            result.passed = true
            return result
          }
        } else if (valueLeft !== valueRight) {
          result.passed = true
          return result
        }
        break
    }
    return result
  }

  /**
   * Speichert Werte von Variablen
   *
   * @param callback - Funktion die bei Erfolg aufgerufen wird.
   * ignoriert werden
   */
  function saveVariableData(callback) {
    if (MODE === MODE_VIEWER && !BUILDER_SETTINGS.READ_ONLY) {
      offlineSaveVariableData().then(() => {
        if (typeof callback === 'function') {
          callback()
        }
      })
    }
  }

  /**
   * Führt mehrere Arrays zusammen. (Distinct)
   *
   * @returns Ergebnis der Zusammenführung.
   */
  function mergeArrays() {
    const result = []
    for (const key in arguments) {
      if (arguments.hasOwnProperty(key)) {
        const arg = arguments[key]
        if (Array.isArray(arg)) {
          for (let i = 0, llength = arg.length; i < llength; i++) {
            const value = arg[i]
            if (result.indexOf(value) === -1) {
              result[result.length] = value
            }
          }
        }
      }
    }
    return result
  }

  /**
   * Gibt den Status der Pflichtfelder der Checkliste zurück
   *
   * @param index - Index der Checkliste
   * @returns true = done, false = undone, undefined = none
   */
  async function getMandatoryState(index) {
    if (DATA.mandatory[index]) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (await mandatoryCheck(index)) {
        return true
      }
      return false
    }
  }

  /**
   * Gibt den Status der Pflichtfelder der Checkliste zurück
   *
   * @param index - Index der Checkliste
   * @param x - x-Position der Zelle
   * @param y - y-Position der Zelle
   * @returns true = done, false = undone
   */
  async function mandatoryCheck(index, x, y) {
    const mandatoryFields = DATA.mandatory[index] || []
    for (let i = 0, ilength = mandatoryFields.length; i < ilength; i++) {
      const field = mandatoryFields[i]
      if (typeof x === 'number') {
        if (field.x !== x) {
          continue
        }
      }
      if (typeof y === 'number') {
        if (field.y !== y) {
          continue
        }
      }
      if (
        field.ignore_hidden &&
        !(await checkCellVisibility(index, field.x, field.y))
      ) {
        continue
      }
      if (!(getCellDataViewer(index, field.x, field.y) || {}).done) {
        return false
      }
    }
    return true
  }

  /**
   * Gibt den Status für die Kollektion, Checkliste und/oder Zelle zurück.
   *
   * @param dataEditor - Daten der Zelle (optional)
   * @param index - Index der Checkliste (optional)
   * @returns Status:
   * {
   * disabled: Attribute,
   * classes: Klassen
   * }
   */
  async function getDisabledState(dataEditor, index) {
    let disabled = false
    const disabledClasses =
      ' disable-cell disable-checklist disable-collection disable-content '
    let lockedCell = ''
    let lockedChecklist = ''
    let lockedCollection = ''
    let lockedContent = ''
    const collection = DATA.collection
    if (BUILDER_SETTINGS.READ_ONLY || !collection.active || DATA.disabled) {
      disabled = true
      lockedCollection = 'disabled-collection '
    }
    const checklist = collection.Checklists[index]
    if (index >= 0) {
      if (!checklist.active) {
        disabled = true
        lockedChecklist = 'disabled-checklist '
      }
    }
    if (dataEditor) {
      if (dataEditor.settings.locked_content) {
        disabled = true
        lockedContent = 'disabled-content '
      }
      if (dataEditor.settings.inactive) {
        disabled = true
        lockedCell = 'disabled-cell '
      }
      if (MODE !== MODE_EDITOR) {
        const dataViewer =
          getCellDataViewer(index, dataEditor.x, dataEditor.y) || {}
        if (!lockedCell) {
          const lockedBy = (dataViewer || {}).locked_by
          if (lockedBy) {
            for (let _item, i = 0; (_item = lockedBy[i++]) !== undefined; ) {
              const dataViewerLock =
                getCellDataViewer(index, _item.x, _item.y) || {}
              const done = dataViewerLock.done
              if ((_item.type === 1 && done) || (_item.type === 2 && !done)) {
                disabled = true
                dataViewer.locked = true
                lockedCell = 'disabled-cell '
                break
              }
            }
          }
        }
        if (!lockedCell) {
          const valueEvents = dataEditor.settings.value_events
          if (Array.isArray(valueEvents)) {
            let value = dataViewer.value
            if (value) {
              value = value[0]
            } else {
              value = getLocalization(dataEditor, 'preset_value')
            }
            for (
              let _event, j = 0;
              (_event = valueEvents[j++]) !== undefined;

            ) {
              if ((VALUE_EVENTS[_event.action] || {}).lock) {
                const locked = (
                  await evalTerms({
                    Events: [_event],
                    DataViewer: dataViewer,
                    DataEditor: dataEditor,
                    Value: value,
                    Muted: true,
                    CloneIndex: 0,
                    ChecklistIndex: index
                  })
                ).locked
                if (locked) {
                  dataViewer.locked = true
                  disabled = true
                  lockedCell = 'disabled-cell '
                  break
                }
              }
            }
          }
        }
        if (!lockedCollection) {
          if (COUNTDOWNS.locked_collection) {
            disabled = true
            dataViewer.locked = true
            lockedCollection = 'disabled-collection '
          }
        }
        if (!lockedChecklist) {
          if (
            COUNTDOWNS.locked_checklists[index] ||
            !checkEditabilityRights(checklist)
          ) {
            disabled = true
            dataViewer.locked = true
            lockedChecklist = 'disabled-checklist '
          }
        }
        if (dataViewer) {
          dataViewer.locked = disabled
        }
      }
    }
    return {
      disabled: !!disabled,
      classes:
        disabledClasses +
        lockedCell +
        lockedChecklist +
        lockedCollection +
        lockedContent
    }
  }

  /**
   * Ersetzt Tokens in einem Text und gibt diesen zurück.
   *
   * @param tokens - Enthält Liste von Tokens die ersetzt werden.
   * @param text - Text in dem die Tokens ersetzt werden
   * @returns Text mit ersetzten Tokens
   */
  function replaceTokens(tokens, text) {
    const entries = DATA.replaceEntries
    if (text && Array.isArray(tokens) && Object.keys(entries).length > 0) {
      for (let i = 0, ilength = tokens.length; i < ilength; i++) {
        const token = tokens[i]
        if (!token.token || !token.target) {
          continue
        }
        const textNew = entries[token.target.toLowerCase()]
        if (typeof textNew === 'string') {
          text = text.replace(token.token, textNew)
        }
      }
    }
    return text
  }

  /**
   * Gibt eine Liste von Werten von Eigenschaften
   * der ausgewählten Optionen zurück.
   *
   * @param data - JSON-String oder Array der ausgewählten Optionen
   * {
   * id: int
   * label: string
   * }
   * @param property - Eigenschaft die verwendet werden soll
   * @returns Liste der Werte
   */
  function getSelectPropertyToArray(data, property) {
    const value = []

    if (typeof data === 'string') {
      try {
        data = JSON.parse(data)
      } catch (ex) {
        return value
      }
    }
    if (Array.isArray(data)) {
      for (let option, i = 0; (option = data[i]); i++) {
        if (option.hasOwnProperty(property)) {
          value[i] = option[property]
        }
      }
    }
    return value
  }

  /**
   * Gibt eine Liste von Werten von Eigenschaften
   * der ausgewählten Optionen zurück.
   *
   * @returns Ergebnis der Abfrage
   */
  function getSqlData() {
    return new Promise(resolve => {
      resolve('')
    })
    /* const settings = dataEditor.settings || {}
    const sql = settings.sql
    if (!sql || !BUILDER_SETTINGS.ONLINE) {
      return new Promise(resolve => {
        resolve('')
      })
    }
    return REQUEST.post('checklist/getSQLData', {
      sql: parseInt(sql) || 0,
      type: dataEditor.type
    }).then(result => {
      return result.data.data
    }) */
  }

  /**
   * Gibt eine Liste von Werten von Eigenschaften
   * der ausgewählten Optionen zurück.
   *
   * @param index - Index der Checkliste.
   * @param dataEditor - Daten der Zelle für die ein SQL-Ergebnis geholt wird.
   * @param dataViewer - Werte der Zelle für die ein SQL-Ergebnis geholt wird.
   * @param force - Wert wird immer übernommen.
   * @returns Ergebnis der Abfrage
   */
  function applySQLData(index, dataEditor, dataViewer, force) {
    const sql = dataEditor.settings.sql
    if (
      !sql ||
      dataViewer.locked ||
      (!force, dataEditor.settings.sql_apply_once && dataViewer.id)
    ) {
      return new Promise(resolve => {
        resolve('')
      })
    }
    if (typeof BUILDER_SETTINGS.ON_SQL_LOAD === 'function') {
      BUILDER_SETTINGS.ON_SQL_LOAD()
    }
    let sqlTokens = BUILDER_SETTINGS.SQL_TOKENS
    if (typeof sqlTokens === 'function') {
      sqlTokens = sqlTokens()
    }
    return REQUEST.post('checklist/getSQLData', {
      sql: parseInt(sql) || 0,
      type: dataEditor.type,
      data: {
        variables: VARIABLE_DATA || {},
        tokens: sqlTokens || {}
      }
    })
      .then(result => {
        try {
          result = result.data.data
          result = JSON.parse(result)
        } catch (e) {
          // result wasn't json.
        }
        if (typeof BUILDER_SETTINGS.ON_SQL_LOADED === 'function') {
          BUILDER_SETTINGS.ON_SQL_LOADED()
        }
        return result
      })
      .catch(() => {
        if (typeof BUILDER_SETTINGS.ON_SQL_LOADED === 'function') {
          BUILDER_SETTINGS.ON_SQL_LOADED()
        }
        return ''
      })
  }

  /**
   * Berechnet einen Gesamtwert aus Zellen mit Zahlenwerten
   *
   * @param index - Index der Checkliste
   * @param x - x-Position der Zelle
   * @param y - y-Position der Zelle
   * @param operator - Operator
   * 1: +
   * 2: -
   * @param value - vorheriger Gesamtwert
   * @returns Gesamtwert
   */
  function calculateTotalAmount(index, x, y, operator, value) {
    let tempValue = 0
    const dataEditor = getCellDataEditor(index, x, y)
    let dataViewer = getCellDataViewer(index, x, y)
    if (!dataEditor && !dataViewer) {
      return value
    }

    switch (dataEditor.type) {
      case 'dropdown':
      case 'dropdown_sql':
      case 'multiselect_sql':
      case 'multiselect':
      case 'multiselect_open':
        {
          let selectValue = []
          if (dataViewer && Array.isArray(dataViewer.value)) {
            for (
              let i = 0, ilength = dataViewer.value.length;
              i < ilength;
              i++
            ) {
              dataViewer = dataViewer.value[i]
              if (typeof dataViewer !== 'undefined') {
                selectValue = getSelectPropertyToArray(dataViewer, 'label')
              }
            }
          } else {
            const presetValue = getLocalization(dataEditor, 'preset_value')
            selectValue = getSelectPropertyToArray(presetValue, 'label')
          }
          for (let z = 0, zlength = selectValue.length; z < zlength; z++) {
            if (tempValue + !isNaN(parseFloat(selectValue[z]))) {
              tempValue = parseFloat(selectValue[z])
            } else {
              tempValue = 0
            }
          }
        }
        break
      default:
        if (dataViewer && Array.isArray(dataViewer.value)) {
          for (let i = 0, ilength = dataViewer.value.length; i < ilength; i++) {
            const valueEntry = dataViewer.value[i]
            if (typeof valueEntry !== 'undefined') {
              tempValue += parseFloat(valueEntry)
            }
          }
        } else {
          tempValue = parseFloat(getLocalization(dataEditor, 'preset_value'))
        }
        break
    }
    if (isNaN(tempValue)) {
      return value
    }
    switch (operator) {
      case 1: // +
        value = value + tempValue
        break
      case 2: // -
        value = value - tempValue
        break
    }
    return value
  }

  /**
   * Berechnet einen Gesamtwert aus Zellen mit Datum oder Zeit
   *
   * @param index - Index der Checkliste
   * @param x - x-Position der Zelle
   * @param y - y-Position der Zelle
   * @param operator - Operator
   * 1: +
   * 2: -
   * @param value - vorheriger Gesamtwert
   * @returns Gesamtwert
   */
  function calculateTotalAmountDates(index, x, y, operator, value) {
    let tempValue = 0
    const dataEditor = getCellDataEditor(index, x, y)
    const dataViewer = getCellDataViewer(index, x, y)
    if (!dataEditor && !dataViewer) {
      return value
    }

    switch (dataEditor.type) {
      case 'date':
      case 'date_time':
        if (dataViewer && (tempValue = (dataViewer.value || [])[0] || 0)) {
          const offset = new Date().getTimezoneOffset() * -1
          const date = getDateObject(dataViewer.value[0])
          tempValue = parseInt(+date / 60000) + offset
        }
        break
      case 'time':
        if (dataViewer && (tempValue = (dataViewer.value || [])[0] || 0)) {
          tempValue = tempValue.split(':')
          const hours = parseInt(tempValue[0]) || 0
          const minutes = parseInt(tempValue[1]) || 0
          tempValue = hours * 60 + minutes
        }
        break
    }

    switch (operator) {
      case 1: // +
        tempValue = value.minutes_total + tempValue
        break
      case 2: // -
        tempValue = value.minutes_total - tempValue
        break
      default:
        return value
    }

    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      weeks_total: parseInt(tempValue / 10080) || 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      days_total: parseInt(tempValue / 1440) || 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      hours_total: parseInt(tempValue / 60) || 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      minutes_total: tempValue || 0,
      weeks: parseInt(tempValue / 10080) || 0,
      days: parseInt((tempValue % 10080) / 1440) || 0,
      hours: parseInt((tempValue % 1440) / 60) || 0,
      minutes: parseInt(tempValue % 60) || 0,
      html: '0'
    }
  }

  this.evalValues = data => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return evalValues(data)
  }

  /**
   * Wertet den neuen Wert einer Zelle aus.
   *
   * @param data - x-Position der Zelle
   * @returns Gesamtwert
   */
  async function evalValues(data) {
    const index = data.index || 0
    const dataEditor = data.dataEditor || { settings: {} }
    const dataViewer = data.dataViewer || {}
    const cloneIndex = data.cloneIndex || 0
    // let encode = data.encode || false
    const saveCallback = data.saveCallback
    const ignoreAutoSaveSetting = data.ignoreAutoSaveSetting || false
    const value = data.value
    const previousValue = data.previousValue
    let removeIds = []

    if (!Array.isArray(dataViewer.value)) {
      dataViewer.value = []
    }
    if (dataEditor.settings.locked_content || dataViewer.locked) {
      dataViewer.value.splice(cloneIndex, 1, previousValue)
      return false
    }
    const valueEvents = dataEditor.settings.value_events
    const status = {
      value: value,
      done: !!dataViewer.done,
      visible: !!dataViewer.visible
    }

    if (valueEvents) {
      const evaluation = await evalTerms({
        Events: valueEvents,
        DataViewer: extend({}, dataViewer),
        DataEditor: dataEditor,
        Value: status.value,
        Muted: false,
        CloneIndex: cloneIndex,
        ChecklistIndex: index
      }).catch(() => {
        return { abort: true }
      })

      if (evaluation.global_variable_modified) {
        if (typeof BUILDER_SETTINGS.ON_GLOBAL_VARIABLE_CHANGED === 'function') {
          BUILDER_SETTINGS.ON_GLOBAL_VARIABLE_CHANGED(
            evaluation.global_variable_modified
          )
        }
      }
      if (evaluation.abort) {
        // TODO value reset not represented in components e.g. checkbox
        dataViewer.value.splice(cloneIndex, 1, previousValue)
        return false
      }
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dataViewer.requires_action = evaluation.requires_action || false
      // Set background-color if not undefined.
      if (evaluation.background_color) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dataViewer.background_color = evaluation.background_color || null
      } else {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dataViewer.background_color = dataEditor.cell_background_color || null
      }
      // Set outline-color if not undefined.
      if (evaluation.outline_color) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dataViewer.outline_color = evaluation.outline_color
      } else {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dataViewer.outline_color = null
      }
      if (dataViewer.locked !== evaluation.locked) {
        dataViewer.locked = evaluation.locked
      }
      status.trigger = evaluation.trigger
      // Überprüfung ob erledigt ein Boolean ist, ansonsten immer auf wahr setzen.
      if (!!evaluation.done === evaluation.done) {
        status.done = evaluation.done
      } else {
        status.done = true
      }
      if (evaluation.visible !== undefined) {
        status.visible = evaluation.visible
      }
    } else {
      status.done = true
    }

    // Fortschritt aktualisieren und abhängig davon Checklisten ein- /ausblenden.
    const update = status.done !== dataViewer.done
    if (dataEditor.settings.count) {
      if (status.done && !dataViewer.done) {
        DATA.status[index].progress++
      } else if (!status.done && dataViewer.done) {
        DATA.status[index].progress--
      }
    }

    if (dataEditor.settings.cloneable) {
      if (dataViewer.index > 1) {
        // TODO ? Überprüfung des Werts entfernen
        if (!status.value || !status.done) {
          if (dataViewer.value.length) {
            dataViewer.value.splice(cloneIndex, 1)
            return false
          }
        }
      }
    }
    dataViewer.value.splice(cloneIndex, 1, status.value)
    dataViewer.done = status.done
    dataViewer.visible = status.visible
    dataViewer.index = dataViewer.value.length

    // Lock checklist/collection etc.
    if (update && dataEditor.type === 'countdown') {
      const lockAction = parseInt(dataEditor.settings.countdown_expired)
      // lock/unlock collection
      if (lockAction === 1) {
        if (status.done) {
          DATA.disabled = true
        } else {
          DATA.disabled = false
        }
        // lock/unlock checklist
      } else if (lockAction === 2) {
        if (status.done) {
          DATA.status[index].editable = false
        } else {
          DATA.status[index].editable = true
        }
      }
      // TODO external content
    }

    if (update && dataViewer.required_by) {
      removeIds = removeIds.concat(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await toggleCellVisibility(index, dataViewer.required_by)
      )
    }

    // Handle cell locking.
    if (update && dataViewer.locks) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      toggleCellLocking(index, dataViewer.locks, status.done)
    }

    // Handle multiple cells as one.
    if (dataViewer.done && dataViewer.exclusives) {
      for (let exclusive, i = 0; (exclusive = dataViewer.exclusives[i++]); ) {
        const x = exclusive.x
        const y = exclusive.y
        const exclEntry = getCellDataViewer(index, x, y)
        if (exclEntry) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          modifyCellValue(index, x, y, false, true)
          if (exclEntry.required_by) {
            removeIds = removeIds.concat(
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              await toggleCellVisibility(index, exclEntry.required_by)
            )
          }
        }
      }
    }

    // Handle color change for cell fields.
    if (update && dataViewer.color_change) {
      for (let cchange, i = 0; (cchange = dataViewer.color_change[i++]); ) {
        const cctype = ~~cchange.type
        const colors = [
          '',
          '#ff0000',
          '#00ff00',
          '#ffff00',
          '#0000ff',
          '#ff0000',
          '#00ff00',
          '#ffff00',
          '#0000ff'
        ]
        const color = colors[cctype]
        const ccDataViewer =
          getCellDataViewer(index, cchange.x, cchange.y) || {}
        if (cctype <= 4) {
          if (dataViewer.done) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ccDataViewer.outline_color = color || null
          } else {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ccDataViewer.outline_color = null
          }
        } else if (dataViewer.done) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ccDataViewer.temporary_background_color = color || null
        } else {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ccDataViewer.temporary_background_color = null
        }
      }
    }

    if (update) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const list = getChecklistDataEditor(index)
      if (!list.clone_of_checklist_id) {
        // TODO
        // let aindex = list.activate_checklist_tab_on_unlock
        const progress = DATA.status[index] || {}
        const requiredBy = progress.required_by || []
        const lists = DATA.collection.Checklists || []
        for (let _index, i = 0; (_index = requiredBy[i++]); ) {
          const _visible = (DATA.status[_index] || {}).visible
          if (!(await checkChecklistVisibility(lists[_index]))) {
            if (_visible) {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              removeIds = removeIds.concat(modifyCollectionView('hide', _index))
            }
          } else if (!_visible) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            modifyCollectionView('show', _index)
          }
        }
      }
    }

    if (removeIds.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      deleteChecklistValues(removeIds)
    }

    if (dataViewer.amounts_to) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateTotalAmount(index, dataViewer.amounts_to)
    }

    if (status.trigger && dataViewer.triggers) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      triggerEvents(index, dataViewer.triggers, ignoreAutoSaveSetting)
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    DATA.status[index].mandatory_done = await getMandatoryState(index)
    saveChecklistValues(
      index,
      dataEditor.x,
      dataEditor.y,
      dataViewer,
      ignoreAutoSaveSetting,
      saveCallback
    )

    if (typeof BUILDER_SETTINGS.ON_PROGRESS_CHANGE === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getProgress().then(progress => {
        BUILDER_SETTINGS.ON_PROGRESS_CHANGE(progress)
      })
    }

    return true
  }

  /**
   * Wertet den Zustand einer Zelle aus.
   * Eingeschränkte Kopie von evalValues.
   *
   * @param data - Daten für die Auswertung
   * @returns Zustand der Zelle
   */
  async function evalValuesByTrigger(data) {
    const dataEditor = data.dataEditor
    const dataViewer = data.dataViewer
    const index = data.index
    const valueEvents = data.valueEvents
    const ignoreAutoSaveSetting = data.ignoreAutoSaveSetting

    const status = {
      value: dataViewer[0],
      done: !!dataViewer.done
    }
    const x = dataEditor.x
    const y = dataEditor.y
    let removeIds = []

    if (valueEvents) {
      let _events = []
      const _ignoreEvents =
        BUILDER_SETTINGS.IGNORE_VALUE_EVENTS_ON_TRIGGER_VALUE_EVALUATION || []
      if (Array.isArray(_ignoreEvents) && _ignoreEvents.length) {
        for (let i = 0, _event; (_event = valueEvents[i++]); ) {
          if (_ignoreEvents.indexOf(_event.action) === -1) {
            _events.push(_event)
          }
        }
      } else {
        _events = valueEvents
      }
      const evaluation = await evalTerms({
        Events: _events,
        DataViewer: extend({}, dataViewer),
        DataEditor: dataEditor,
        Value: status.value,
        Muted: false,
        CloneIndex: 0,
        ChecklistIndex: index
      })
      if (evaluation.global_variable_modified) {
        if (typeof BUILDER_SETTINGS.ON_GLOBAL_VARIABLE_CHANGED === 'function') {
          BUILDER_SETTINGS.ON_GLOBAL_VARIABLE_CHANGED()
        }
      }
      // Set background-color if not undefined.
      if (evaluation.background_color) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dataViewer.background_color = evaluation.background_color || null
      } else {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dataViewer.background_color = dataEditor.cell_background_color || null
      }
      // Set outline-color if not undefined.
      if (evaluation.outline_color) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dataViewer.outline_color = evaluation.outline_color
      } else {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dataViewer.outline_color = null
      }
      if (dataViewer.locked !== evaluation.locked) {
        dataViewer.locked = evaluation.locked
      }
      // Überprüfung ob erledigt ein Boolean ist, ansonsten immer auf wahr setzen.
      if (!!evaluation.done === evaluation.done) {
        status.done = evaluation.done
      } else {
        status.done = true
      }
    } else {
      status.done = true
    }

    // Fortschritt aktualisieren und abhängig davon Checklisten ein- /ausblenden.
    const update = status.done !== dataViewer.done
    if (dataEditor.settings.count) {
      if (status.done && !dataViewer.done) {
        DATA.status[index].progress++
      } else if (!status.done && dataViewer.done) {
        DATA.status[index].progress--
      }
    }

    // Lock checklist/collection etc.
    if (update && dataEditor.type === 'countdown') {
      const lockAction = parseInt(dataEditor.settings.countdown_expired)
      // lock/unlock collection
      if (lockAction === 1) {
        if (status.done) {
          DATA.disabled = true
        } else {
          DATA.disabled = false
        }
        // lock/unlock checklist
      } else if (lockAction === 2) {
        if (status.done) {
          DATA.status[index].editable = false
        } else {
          DATA.status[index].editable = true
        }
      }
      // TODO external content
    }

    dataViewer.done = status.done
    dataViewer.index = (dataViewer.value || []).length

    if (update && dataViewer.required_by) {
      removeIds = removeIds.concat(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await toggleCellVisibility(index, dataViewer.required_by)
      )
    }

    // Handle cell locking.
    if (update && dataViewer.locks) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      toggleCellLocking(index, dataViewer.locks, status.done)
    }

    // Handle multiple cells as one.
    if (dataViewer.done && dataViewer.exclusives) {
      for (let exclusive, i = 0; (exclusive = dataViewer.exclusives[i++]); ) {
        const x = exclusive.x
        const y = exclusive.y
        const exclEntry = getCellDataViewer(index, x, y)
        if (exclEntry) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          modifyCellValue(index, x, y, false, true)
          if (exclEntry.required_by) {
            removeIds = removeIds.concat(
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              await toggleCellVisibility(index, exclEntry.required_by)
            )
          }
        }
      }
    }

    // Handle color change for cell fields.
    if (update && dataViewer.color_change) {
      for (let cchange, i = 0; (cchange = dataViewer.color_change[i++]); ) {
        const cctype = ~~cchange.type
        const colors = [
          '',
          '#ff0000',
          '#00ff00',
          '#ffff00',
          '#0000ff',
          '#ff0000',
          '#00ff00',
          '#ffff00',
          '#0000ff'
        ]
        const color = colors[cctype]
        const ccDataViewer =
          getCellDataViewer(index, cchange.x, cchange.y) || {}
        if (cctype <= 4) {
          if (dataViewer.done) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ccDataViewer.outline_color = color || null
          } else {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ccDataViewer.outline_color = null
          }
        } else if (dataViewer.done) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ccDataViewer.temporary_background_color = color || null
        } else {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ccDataViewer.temporary_background_color = null
        }
      }
    }

    if (update) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const list = getChecklistDataEditor(index)
      if (!list.clone_of_checklist_id) {
        // TODO
        // let aindex = list.activate_checklist_tab_on_unlock
        const progress = DATA.status[index] || {}
        const requiredBy = progress.required_by || []
        const lists = DATA.collection.Checklists || []
        for (let _index, i = 0; (_index = requiredBy[i++]); ) {
          const _visible = (DATA.status[_index] || {}).visible
          if (!(await checkChecklistVisibility(lists[_index]))) {
            if (_visible) {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              removeIds = removeIds.concat(modifyCollectionView('hide', _index))
            }
          } else if (!_visible) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            modifyCollectionView('show', _index)
          }
        }
      }
    }

    if (removeIds.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      deleteChecklistValues(removeIds)
    }

    if (update) {
      if (dataViewer.amounts_to) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateTotalAmount(index, dataViewer.amounts_to)
      }

      // eslint-disable-next-line @typescript-eslint/naming-convention
      DATA.status[index].mandatory_done = await getMandatoryState(index)
      saveChecklistValues(index, x, y, dataViewer, ignoreAutoSaveSetting)

      if (typeof BUILDER_SETTINGS.ON_PROGRESS_CHANGE === 'function') {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getProgress().then(progress => {
          BUILDER_SETTINGS.ON_PROGRESS_CHANGE(progress)
        })
      }
    }

    return status
  }

  /**
   * Holt den aktuellen Fortschritt für eine Kollektion.
   *
   * @returns Fortschritt der Kollektion.
   */
  async function getProgress() {
    const progress = {
      id: DATA.collection.id,
      done: 0,
      total: 0
    }
    const status = DATA.status
    const collection = DATA.collection
    const checklists = collection.Checklists || []

    if (
      collection.active ||
      ((MODE === MODE_VIEWER || MODE === MODE_PREVIEW) &&
        !BUILDER_SETTINGS.HIDE_INACTIVE_COLLECTION_IN_VIEWER) ||
      (MODE === MODE_PRINTER &&
        !BUILDER_SETTINGS.HIDE_INACTIVE_COLLECTION_IN_PRINTER)
    ) {
      for (let i = 0; i < status.length; i++) {
        const checklist = checklists[i] || {}
        if (checklist.active) {
          progress.total =
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            progress.total + (await countTotalProgress(checklist))
          progress.done = progress.done + status[i].progress
        }
      }
    }

    return progress
  }

  /**
   * Holt den aktuellen Fortschritt für eine Checkliste.
   *
   * @param checklist - Checkliste in der Kollektion.
   * @returns Fortschritt der Checkliste.
   */
  async function countTotalProgress(checklist) {
    let count = 0
    if (checklist) {
      const index = checklist.index
      const cells = checklist.ChecklistCells || [[]]
      for (let _column, i = 0; (_column = cells[i]); i++) {
        for (let _cell, j = 0; (_cell = _column[j]); j++) {
          if (
            _cell.settings.count &&
            !_cell.settings.inactive &&
            (_cell.settings.count_hidden ||
              (await checkCellVisibility(index, i, j)))
          ) {
            count++
          }
        }
      }
    }
    return count
  }

  /**
   * Löst Ereignisse von Zellen aus
   *
   * @param index - Index der Checkliste
   * @param triggers - Liste von Zellen,
   * in denen die Ereignisse ausgelöst werden.
   * @param ignoreAutoSaveSetting - Ignoriert die Einstellung
   * für automatische Speicherung
   */
  function triggerEvents(index, triggers, ignoreAutoSaveSetting) {
    for (let i = 0, alength = triggers.length; i < alength; i++) {
      const triggerEntry = triggers[i]
      const tx = triggerEntry.x
      const ty = triggerEntry.y
      const dataEditor = getCellDataEditor(index, tx, ty)
      const valueEvents = dataEditor.settings.value_events
      if (Array.isArray(valueEvents) && valueEvents.length) {
        const dataViewer = getCellDataViewer(index, tx, ty)
        evalValuesByTrigger({
          dataEditor: dataEditor,
          dataViewer: dataViewer,
          index: index,
          valueEvents: valueEvents,
          ignoreAutoSaveSetting: ignoreAutoSaveSetting
        })
      }
    }
  }

  /**
   * Löscht alle angegebene Zellwerte anhand ihrerer ID aus der Datenbank. Die
   * gelöschten Einträge werden zurückgegeben.
   *
   * @param ids - Liste von Ids der betroffenen Zellwerten
   * @param callback - Funktion die bei Erfolg ausgefürt wird
   * @returns Die gelöschten Einträge oder NULL bei einem Fehler
   */
  async function deleteChecklistValues(ids, callback) {
    if (MODE !== MODE_VIEWER || !Array.isArray(ids) || ids.length === 0) {
      return []
    }

    const result = await (
      await getService('checklists/cells/values', Connection.Offline)
    )
      .remove(null, { query: { id: { $in: ids } } })
      .then(
        result => {
          if (!Array.isArray(result)) {
            showMessage(
              'error',
              lang('message.content.delete_checklist_values_error')
            )
            return null
          }

          if (typeof callback === 'function') {
            callback()
          }

          return result
        },
        () => {
          showMessage(
            'error',
            lang('message.content.delete_checklist_values_error')
          )
          return null
        }
      )

    return result
  }

  /**
   * Führt Modulo auf Zahl oder Fließkommazahl aus.
   *
   * @param value - Zahl oder Fließkommazahl
   * @param step - Intervall
   * @returns Rest
   */
  function moduloFloatSafe(value, step) {
    const decimalsValue = (value.toString().split('.')[1] || '').length
    const decimalsStep = (step.toString().split('.')[1] || '').length
    const decimalsMax =
      decimalsValue > decimalsStep ? decimalsValue : decimalsStep
    const valueInt = parseInt(value.toFixed(decimalsMax).replace('.', ''))
    const stepInt = parseInt(step.toFixed(decimalsMax).replace('.', ''))
    return (valueInt % stepInt) / Math.pow(10, decimalsMax)
  }

  this.moduloFloatSafe = (value, step) => {
    return moduloFloatSafe(value, step)
  }

  /**
   * Holt die Daten einer Checklist anhand des Index.
   *
   * @param index - Index der Checkliste
   * @returns Daten der Checkliste
   */
  function getChecklistDataEditor(index) {
    if (!DATA.collection) {
      return {}
    }
    return DATA.collection.Checklists[index]
  }

  /**
   * Setzt checklist sichtbar oder unsichtbar,
   * gibt Liste mit IDs von werten die gelöscht werden können zurück.
   *
   * @param option - hide = ausblenden, show = einblenden
   * @param index - Index der Checkliste
   * @returns Daten der Checkliste
   */
  function modifyCollectionView(option, index) {
    const list = getChecklistDataEditor(index)
    const status = DATA.status[index]

    if (!list.active) {
      return
    }

    switch (option) {
      case 'show':
        status.visible = true
        break
      case 'hide': {
        status.visible = false
        let removeIds = []
        if (list.delete_values_on_hide) {
          // Add ChecklistValue ids from hidden checklist to hidden/remove list.
          const correlation = DATA.correlations[index]
          for (let x = 0, column; (column = correlation[x]); x++) {
            for (let y = 0, dataViewer; (dataViewer = column[y]); y++) {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              const removeId = deleteCellValue(index, x, y)
              if (removeId) {
                removeIds.push(removeId)
              }
              if (dataViewer.required_by) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                toggleCellVisibility(index, dataViewer.required_by)
              }
            }
          }
          status.progress = 0
        }

        // Hide all lists who depend on current one to be shown.
        const requiredBy = status.required_by || []
        for (let _index, i = 0; (_index = requiredBy[i++]); ) {
          const _visible = DATA.status[_index] || {}
          if (_visible) {
            removeIds = removeIds.concat(modifyCollectionView('hide', _index))
          }
        }

        // Hide all clones
        if (!list.clone_of_checklist_id) {
          if (list.clone_type === CLONE_TYPE_NEW_TAB) {
            for (
              let _index = index + 1, _list;
              (_list = getChecklistDataEditor(_index++));
              _index++
            ) {
              if (!_list.clone_of_checklist_id) {
                break
              }
              removeIds = removeIds.concat(modifyCollectionView('hide', _index))
            }
          }
        }
        return removeIds
      }
    }
  }

  /**
   * Modifiziert den Wert und Status einer Zelle
   *
   * @param index - Index der Checkliste
   * @param x - x-Position der Zelle
   * @param y - y-Position der Zelle
   * @param done - Neuer erledigt Status der Zelle
   * @param save - Gibt an, ob der Wert gespeichert wird
   * @returns False, wenn Aktion abgebrochen wird durch Ereignis bei Wert
   */
  async function modifyCellValue(index, x, y, done, save) {
    const dataViewer = getCellDataViewer(index, x, y) || {}
    const dataEditor = getCellDataEditor(index, x, y)
    const valueEvents = dataEditor.settings.value_events

    if (dataViewer.done === done) {
      return
    }

    // Fire value_events
    if (valueEvents && dataViewer.done) {
      const status = {
        value: getLocalization(dataEditor, 'preset_value'),
        done: done
      }
      dataViewer.done = false
      const evaluation = await evalTerms({
        Events: valueEvents,
        DataViewer: dataViewer,
        DataEditor: dataEditor,
        Value: status.value,
        Muted: false,
        CloneIndex: 0,
        ChecklistIndex: index
      })
      if (evaluation.abort) {
        return false
      }
      // Set background-color if not undefined.
      if (evaluation.background_color) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dataViewer.background_color = evaluation.background_color
      } else {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dataViewer.background_color = dataEditor.cell_background_color || null
      }
      done = status.done
    }

    dataViewer.value = []
    dataViewer.index = 0

    if (dataEditor.settings.count) {
      if (done && !dataViewer.done) {
        DATA.status[index].progress++
      } else if (!done && dataViewer.done) {
        if (DATA.status[index].progress > 0) {
          DATA.status[index].progress--
        }
      }
    }

    dataViewer.done = done
    switch (dataEditor.type) {
      case 'checkbox':
      case 'image':
        dataViewer.value.splice(1).splice(0, 1, /true|1/i.test(status.value))
        break
      default:
        dataViewer.value.splice(1).splice(0, 1, status.value)
        break
    }
    dataViewer.index = 1

    if (dataViewer.amounts_to) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateTotalAmount(index, dataViewer.amounts_to)
    }

    if (save) {
      saveChecklistValues(index, x, y, dataViewer)
    }
  }

  /**
   * Aktualisiert Wert in Zellen für Gesamtanzahl
   *
   * @param index - Index der Checkliste
   * @param amountsTo - Objekt das alle Zellen beinhaltet,
   * für die der Wert der Zelle zählt
   */
  function updateTotalAmount(index, amountsTo) {
    for (let i = 0, total; (total = amountsTo[i++]); ) {
      const tx = total.x
      const ty = total.y
      const dataEditor = getCellDataEditor(index, tx, ty)
      const dataViewer = getCellDataViewer(index, tx, ty)
      if (!dataViewer || !dataViewer.totalize) {
        continue
      }
      const totalize = dataViewer.totalize
      switch (dataEditor.type) {
        case 'total_amount_dates':
          {
            let value = {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              weeks_total: 0,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              days_total: 0,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              hours_total: 0,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              minutes_total: 0,
              weeks: 0,
              days: 0,
              hours: 0,
              minutes: 0,
              html: '0'
            }
            for (let j = 0, entry; (entry = totalize[j++]); ) {
              value = calculateTotalAmountDates(
                index,
                entry.x,
                entry.y,
                entry.type,
                value
              )
            }
            let pattern = (dataEditor.settings || {}).display_pattern || ''
            if (pattern) {
              const matches = pattern.match(/@[a-z_0-9]+@/gi) || []
              const variables = {
                '@value_weeks_total@': value.weeks_total,
                '@value_days_total@': value.days_total,
                '@value_hours_total@': value.hours_total,
                '@value_minutes_total@': value.minutes_total,
                '@value_weeks@': value.weeks,
                '@value_days@': value.days,
                '@value_hours@': value.hours,
                '@value_minutes@': value.minutes
              }
              for (let _match, i = 0; (_match = matches[i++]); ) {
                pattern = pattern.replace(
                  new RegExp(_match, 'gi'),
                  variables[_match]
                )
              }
              value.html = pattern
            } else {
              value.html = (value.minutes_total || value.days_total).toString()
            }

            evalValues({
              index: index,
              dataEditor: dataEditor,
              dataViewer: dataViewer,
              cloneIndex: 0,
              encode: false,
              saveCallback: undefined,
              ignoreAutoSaveSetting: false,
              value: value,
              previousValue: (dataViewer.value || [])[0]
            })
          }
          break
        default:
          {
            let value = 0
            for (let j = 0, entry; (entry = totalize[j++]); ) {
              value = calculateTotalAmount(
                index,
                entry.x,
                entry.y,
                entry.type,
                value
              )
            }
            value = value.toString()
            evalValues({
              index: index,
              dataEditor: dataEditor,
              dataViewer: dataViewer,
              cloneIndex: 0,
              encode: false,
              saveCallback: undefined,
              ignoreAutoSaveSetting: false,
              value: value,
              previousValue: (dataViewer.value || [])[0]
            })
          }
          break
      }
    }
  }

  /**
   * Setzt den gesperrt Zustand von Zellen
   *
   * @param index - Index der Checkliste
   * @param locks - Liste mit allen abhängigen Zellen
   * @param done - Erledigt Status der Zelle
   */
  function toggleCellLocking(index, locks, done) {
    for (let lock, i = 0; (lock = locks[i++]); ) {
      const lx = lock.x
      const ly = lock.y
      const type = lock.type
      const dataViewer = getCellDataViewer(index, lx, ly)
      const dataEditor = getCellDataEditor(index, lx, ly)
      if (
        dataEditor.settings.locked_content ||
        (type === 1 && done) ||
        (type === 2 && !done)
      ) {
        if (dataViewer.locked) {
          continue
        }
        dataViewer.locked = true
      } else {
        if (!dataViewer.locked) {
          continue
        }
        dataViewer.locked = false
      }
      // TODO extenal content
    }
  }

  /**
   * Setzt Sichtbarkeit von allen abhängigen Zellen
   *
   * @param index - Index der Checkliste
   * @param requiredBy - Liste mit allen abhängigen Zellen
   * @returns IDs der Zellen deren Wert Entfernt wird
   */
  async function toggleCellVisibility(index, requiredBy) {
    let removeIds = []
    for (let i = 0, req; (req = requiredBy[i++]); ) {
      const rx = req.x
      const ry = req.y
      const dataViewer = getCellDataViewer(index, rx, ry)
      const isVisible = dataViewer.visible
      const visible = await checkCellVisibility(index, rx, ry)
      if (visible !== isVisible) {
        if (!visible) {
          if (BUILDER_SETTINGS.DELETE_CELL_VALUE_ON_HIDE) {
            // Add ChecklistValue id to hidden/remove list.
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            const removeId = deleteCellValue(index, rx, ry)
            if (removeId) {
              removeIds[removeIds.length] = removeId
            }
          }
          // TODO check if any cell in row is visible?
        }
      }
      if (dataViewer) {
        const _requiredBy = dataViewer.required_by
        if (_requiredBy) {
          removeIds = removeIds.concat(
            await toggleCellVisibility(index, _requiredBy)
          )
        }
      }
    }
    return removeIds
  }

  /**
   * Setzt den Wert der Zelle zurück.
   *
   * @param index - {number} Index der Checkliste
   * @param x - {number} x-Position der Zelle
   * @param y - {number} y-Position der Zelle
   * @param setReset - {boolean} Setzt den `reset`-Flag und nicht `remove`-Flag
   * @returns - {Array} IDs der Zellen deren Wert entfernt wird
   */
  function deleteCellValue(index, x, y, setReset = false) {
    let id
    const entry = getCellDataViewer(index, x, y)
    let done = false
    if (MODE !== MODE_VIEWER && MODE !== MODE_PREVIEW) {
      return
    }
    const data = getCellDataEditor(index, x, y)
    if (entry) {
      id = entry.id
      done = entry.done
      entry.index = 0
      entry.value = []
      // eslint-disable-next-line @typescript-eslint/naming-convention
      entry.outline_color = null
      // eslint-disable-next-line @typescript-eslint/naming-convention
      entry.temporary_background_color = null
      // eslint-disable-next-line @typescript-eslint/naming-convention
      entry.info_visible = null
      // eslint-disable-next-line @typescript-eslint/naming-convention
      entry.comment_visible = null
      // eslint-disable-next-line @typescript-eslint/naming-convention
      entry.requires_action = null
      // eslint-disable-next-line @typescript-eslint/naming-convention
      entry.background_color = data.cell_background_color || null
      entry.actions = null
      entry.files = null
      entry.comment = null
      entry.done = null
      entry.visible = false
      entry.locked = false

      if (setReset !== true) {
        entry._remove = true
        entry._reset = false
      } else {
        entry._remove = false
        entry._reset = true
      }
    }
    if (done && data.settings.count) {
      if (DATA.status[index].progress > 0) {
        DATA.status[index].progress--
      }
    }
    return id
  }

  /**
   * Blendet jeweils letzten Klon einer Checkliste aus.
   *
   * @param checklist - Daten der Checkliste
   */
  function hideChecklistClone(checklist) {
    let index = checklist.index
    let lastVisibleIndex = index
    const checklistId = checklist.id
    const status = DATA.status || []
    const checklists = DATA.collection.Checklists || []
    if (
      checklist.clone_type === CLONE_TYPE_SAME_TAB ||
      checklist.clone_type === CLONE_TYPE_SAME_TAB_WITH_HEADER
    ) {
      for (let i = index + 1, ilength = status.length; i < ilength; i++) {
        const list = checklists[i]
        if (list && list.clone_of_checklist_id === checklistId) {
          if (status[i].visible) {
            lastVisibleIndex = i
          }
        } else {
          break
        }
      }

      if (lastVisibleIndex === index) {
        return
      }
      index = lastVisibleIndex
    }

    const removeIds = modifyCollectionView('hide', index)
    if (checklist.delete_values_on_hide && removeIds.length > 0) {
      deleteChecklistValues(removeIds)
    }
  }

  /**
   * Blendet jeweils den nächsten
   * ausgeblendeten Klon einer Checkliste ein.
   *
   * @param checklist - Daten der Checkliste
   */
  function showChecklistClone(checklist) {
    const id = checklist.clone_of_checklist_id || checklist.id
    const checklists = DATA.collection.Checklists || []
    const status = DATA.status || []
    let index = checklist.index
    let originIndex = 0
    let cloneCount = 0

    if (!id) {
      return
    }

    for (let tempList, i = 0; (tempList = checklists[i]); i++) {
      const cloneId = tempList.clone_of_checklist_id
      if (checklist.id === cloneId || checklist.index * -1 === cloneId) {
        originIndex = checklist.index
      }
      if (cloneId) {
        if (cloneId === id || cloneId === checklist.index * -1) {
          index = i
          checklist = tempList
          if (!status[i].visible) {
            break
          }
          cloneCount++
        }
      }
    }

    const maxClones = ~~(checklists[originIndex] || {}).clone_maximum
    if (maxClones && maxClones <= cloneCount) {
      return
    }

    if (status[index].visible) {
      /**
       * Im Offline-Modus werden nur bereits vorhandene
       * Klone angezeigt.
       */
      if (!BUILDER_SETTINGS.ONLINE) {
        return
      }
      if (MODE === MODE_VIEWER) {
        REQUEST.post('checklist/cloneChecklist', {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          checklist_id: checklist.id
        }).then(result => {
          try {
            if (typeof result.data.data === 'string') {
              result.data.data = JSON.parse(result.data.data)
            }
            if (!result.data.data) {
              throw new Error('Empty result for cloneChecklist().')
            }
            DATA.collection = result.data.data
            const listNew = checklists[index + 1]
            DATA.correlations.splice(index + 1, 0, [])
            preBuildCorrelationList(listNew)
            buildCorrelationList(listNew)
            preBuildListRequirements()
            modifyCollectionView('show', index + 1, true)
          } catch (ex) {
            showMessage('error', lang('message.content.clone_error'))
          }
        })
      } else if (MODE === MODE_PREVIEW) {
        if (BUILDER_SETTINGS.PREVIEW_CHECKLIST_CLONING) {
          const listNew = extend({}, checklist)
          for (let tempList, i = 0; (tempList = checklists[i++]); ) {
            tempList.index++
            if (
              typeof tempList.requirement === 'number' &&
              tempList.requirement > index + 1
            ) {
              tempList.requirement++
            }
            if (
              typeof tempList.activate_checklist_tab_on_unlock === 'number' &&
              tempList.activate_checklist_tab_on_unlock >= index + 1
            ) {
              tempList.activate_checklist_tab_on_unlock++
            }
            const returnPaths = tempList.return_paths
            if (Array.isArray(returnPaths)) {
              const newReturnPaths = []
              for (let _path, j = 0; (_path = returnPaths[j++]); ) {
                if (_path > index) {
                  newReturnPaths[newReturnPaths.length] = _path + 1
                } else {
                  newReturnPaths[newReturnPaths.length] = _path
                }
              }
              // eslint-disable-next-line @typescript-eslint/naming-convention
              tempList.return_paths = newReturnPaths
            }
            const matrix = tempList.requirement_matrix
            if (matrix) {
              const keys = Object.keys(matrix)
                .map(value => {
                  return ~~value
                })
                .sort((a, b) => {
                  return b - a
                })
              for (let key, j = 0; (key = keys[j++]); ) {
                if (key > index) {
                  matrix[key + 1] = matrix[key]
                  delete matrix[key]
                } else {
                  break
                }
              }
            }
            /**
             * Pass die Indizierung der Verknüpfungen der Checkliste an.
             *
             * @param index - Index der Checkliste
             * @param obj - Objekt mit Verknüpfungen
             */
            const reIdCorrelations = (index, obj) => {
              const keys = Object.keys(obj).sort((a, b) => {
                return b - a
              })
              for (let key, k = 0; (key = keys[k++]) !== undefined; ) {
                key = parseInt(key) || 0
                if (key < index) {
                  break
                }
                const _obj = obj[key]
                if (_obj) {
                  obj[key + 1] = _obj
                  delete obj[key]
                }
              }
            }
            const columns = tempList.ChecklistCells
            for (let x = 0, column; (column = columns[x++]); ) {
              for (let y = 0, cell; (cell = column[y++]); ) {
                let events = []
                if (
                  cell &&
                  cell.settings &&
                  (events = cell.settings.value_events)
                ) {
                  for (let z = 0, event; (event = events[z++]); ) {
                    if (event.action === 'remove_cell_values') {
                      const eventMatrix = event.matrix
                      if (eventMatrix) {
                        reIdCorrelations(index, eventMatrix)
                      }
                    }
                    if (typeof event.checklist_index === 'number') {
                      const tempObj = {}
                      tempObj[event.checklist_index] = true
                      reIdCorrelations(index, tempObj)
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      event.checklist_index =
                        parseInt(Object.keys(tempObj) || []) || 0
                    }
                  }
                }
              }
            }
          }
          // eslint-disable-next-line @typescript-eslint/naming-convention
          listNew.required_progress = null
          listNew.requirement = null
          // eslint-disable-next-line @typescript-eslint/naming-convention
          listNew.requirement_matrix = null
          // eslint-disable-next-line @typescript-eslint/naming-convention
          listNew.return_paths = null
          listNew.id = (index + 1) * -1
          listNew.index = index + 1
          // eslint-disable-next-line @typescript-eslint/naming-convention
          listNew.clone_of_checklist_id =
            listNew.clone_of_checklist_id ||
            checklist.id ||
            checklist.index * -1
          checklists.splice(index + 1, 0, listNew)
          DATA.correlations.splice(index + 1, 0, [])
          preBuildCorrelationList(listNew)
          buildCorrelationList(listNew)
          preBuildListRequirements()
          modifyCollectionView('show', index + 1, true)
        } else {
          showMessage(
            'information',
            lang('message.content.clone_preview_not_supported, true')
          )
        }
      }
    } else {
      modifyCollectionView('show', index)
    }
  }

  /**
   * Synchronisiert offline Daten.
   *
   * @param data - Daten für Synchronisierung.
   * ignoreGlobalVariables - Ignoriert globale Variablen.
   * collection - Daten der Kollektion im Offline Mode.
   * variables - Daten der Variablen im Offline Mode.
   * values - Werte der Zellen im Offline Mode.
   * variableValues - Werte der Variablen im Offline Mode.
   * @returns Gibt Promise zurück.
   */
  function synchronizeData(data) {
    const ignoreGlobalVariables = data.ignoreGlobalVariables
    const collection = data.collection
    const variableTypes = data.variables.types
    const variablesValues = data.variablesValues
    const values = data.values
    const promises = []
    const emailCells = []

    if (
      !BUILDER_SETTINGS.ONLINE ||
      BUILDER_SETTINGS.READ_ONLY ||
      !data.collection ||
      !data.collection.id
    ) {
      return
    }

    promises.push(
      REQUEST.post('checklist/saveVariableValues', {
        settings: BUILDER_SETTINGS.VARIABLE_SETTINGS,
        values: (() => {
          if (ignoreGlobalVariables) {
            const temp = {}
            for (const variable in variablesValues) {
              if (variablesValues.hasOwnProperty(variable)) {
                if (variableTypes[variable] !== 2) {
                  temp[variable] = variablesValues[variable]
                }
              }
            }
            return temp
          }
          return variablesValues
        })()
      }).then(response => {
        return response.data.data
      })
    )
    promises.push(
      REQUEST.post('checklist/saveChecklistCorrelations', {
        data: (() => {
          const correlation = []
          for (
            let c = 0, checklist;
            (checklist = collection.Checklists[c]);
            c++
          ) {
            const ck = (correlation[checklist.index] = [])
            for (
              let x = 0, column;
              (column = checklist.ChecklistCells[x]);
              x++
            ) {
              const col = (ck[x] = [])
              for (let y = 0, cell; (cell = column[y]); y++) {
                col[y] = values[cell.id] || {}
                if (
                  cell.type === 'text_email_formatted' &&
                  Array.isArray(col[y].value) &&
                  (col[y].value[0] || {}).unsend
                ) {
                  emailCells.push({
                    index: c,
                    cell: cell
                  })
                }
              }
            }
          }
          return correlation
        })(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        collection_id: collection.id,
        selector: BUILDER_SETTINGS.VALUE_SELECTOR,
        logging: ~~BUILDER_SETTINGS.CHECKLIST_VALUE_LOGGING
      }).then(response => {
        return response.data.data
      })
    )

    return Promise.all(promises).then(results => {
      const promisesEmail = []
      const correlation = results[1] || []
      for (let i = 0, entry; (entry = emailCells[i]); i++) {
        const dataEditor = entry.cell
        const cellSettings = dataEditor.settings
        const dataViewer = correlation[entry.index][dataEditor.x][dataEditor.y]
        const value = dataViewer.value[0] || {}
        promisesEmail.push(
          REQUEST.post('checklist/sendEmail', {
            variableData: variablesValues,
            variableSettings: BUILDER_SETTINGS.VARIABLE_SETTINGS,
            valueSelector: BUILDER_SETTINGS.VALUE_SELECTOR,
            customTokens: BUILDER_SETTINGS.CUSTOM_EMAIL_TOKENS,
            cellId: dataEditor.id,
            manual: value.manual || {},
            recipients: value.recipients || [],
            recipientsCcc: value.recipients_ccc || [],
            recipientsBcc: value.recipients_bcc || [],
            generatedDocuments: [],
            downloadDocuments: [],
            uploadDocuments: [],
            drawings: [],
            generatedDocumentsZipped:
              !!cellSettings.email_generated_document_attachment_zipped,
            downloadDocumentsZipped:
              !!cellSettings.email_download_document_attachment_zipped,
            uploadDocumentsZipped:
              !!cellSettings.email_upload_document_attachment_zipped,
            drawingsZipped: !!cellSettings.email_drawing_attachment_zipped,
            customSenderEmail: value.custom_sender_email,
            customSenderSurname: value.custom_sender_surname,
            customSenderLastname: value.custom_sender_lastname,
            customSenderCurrentUser: value.custom_sender_current_user,
            customSenderCurrentUserReplyTo:
              value.custom_sender_current_user_reply_to,
            acknowledgement: value.acknowledgement,
            collectionLinkId: value.collection_link_id
          }).then(response => {
            return response.data.data
          })
        )
      }
      return Promise.all(promisesEmail).then(() => {
        const emailCorrelations = []
        for (let c = 0, checklist; (checklist = correlation[c]); c++) {
          const ck = (emailCorrelations[c] = [])
          for (let x = 0, column; (column = checklist[x]); x++) {
            const col = (ck[x] = [])
            for (let y = 0, value; (value = column[y]); y++) {
              for (let v = 0, entry; (entry = emailCells[v]); v++) {
                if (entry.index === c) {
                  if (entry.cell.x === x && entry.cell.y === y) {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    value.send_date = Date.now()
                    value.success = !!/true/i.test(arguments[v])
                    delete value.unsend
                    col[y] = value
                    emailCells.splice(v, 1)
                  }
                }
              }
            }
          }
        }
        return REQUEST.post('checklist/saveChecklistCorrelations', {
          data: emailCorrelations,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_id: collection.id,
          selector: BUILDER_SETTINGS.VALUE_SELECTOR,
          logging: ~~BUILDER_SETTINGS.CHECKLIST_VALUE_LOGGING
        }).then(correlation => {
          return Array.isArray(correlation.data.data)
        })
      })
    })
  }

  /**
   * Überprüft ob ein Objekt leer ist.
   *
   * @param obj - Objekt das überprüft wird.
   * @returns Gibt zurück ob Objekt leer ist.
   */
  function isEmptyObject(obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }

  /**
   * Überprüft ob Overlay für den Zellentyp gültig ist.
   *
   * @param type - Typ der Zelle.
   * @param types - Liste der Typen auf die geprüft wird.
   * @returns Gültigkeit
   */
  function isValidOverlay(type, types) {
    if (Array.isArray(types)) {
      const allowedTypeTypes = {
        0: /^empty|label|text_display|text_display_formatted$/,
        4: /^empty|label|text_display|text_display_formatted$/
      }
      let allowed
      for (let _type, i = 0; (_type = types[i++]) !== undefined; ) {
        if (!(allowed = allowedTypeTypes[_type])) {
          continue
        }
        if (typeof allowed === 'object' && typeof allowed.test === 'function') {
          if (allowed.test(type)) {
            return true
          }
        }
      }
    }
    if (/^empty|create|label|text_display|text_display_formatted$/.test(type)) {
      return false
    }
    return true
  }

  /**
   * Fügt eine neue Checkliste an oder entfernt diese.
   *
   * @param cmd - Aktion die ausgeführt werden soll.
   * @param index - Index der Checkliste.
   * @param newIndex - Neuer Index der Checkliste.
   * @returns Ergebnis
   */
  function updateLists(cmd, index, newIndex) {
    MODE = MODE_EDITOR
    let result = {}
    const checklists = DATA.collection.Checklists || []
    const list = checklists[index]

    switch (cmd) {
      case 'add':
        if (!DATA.collection.Checklists) {
          DATA.collection.Checklists = []
        }

        const checklist = getBasicChecklist()
        checklist.index = index
        checklist.ChecklistCells = [[getBasicCell()]]
        DATA.collection.Checklists.splice(index, 0, checklist)
        result = checklist
        break
      case 'remove':
        // eslint-disable-next-line jsdoc/require-jsdoc
        const remove = () => {
          // Remove all clones before base can be deleted.
          if (!list.clone_of_checklist_id && list.clone_type) {
            for (let i = 0, checklist; (checklist = checklists[i++]); ) {
              if (checklist.clone_of_checklist_id === list.id) {
                showMessage(
                  'error',
                  lang('message.content.delete_checklist_clone_error'),
                  true
                )
                return
              }
            }
          }

          checklists.splice(index, 1)
          if (DATA.lockedElems[index]) {
            delete DATA.lockedElems[index]
          }

          for (let i = index, checklist; (checklist = checklists[i]); i++) {
            const oldIndex = checklist.index
            const requirement = checklist.requirement
            if (DATA.lockedElems[oldIndex]) {
              DATA.lockedElems[i] = DATA.lockedElems[oldIndex]
              delete DATA.lockedElems[oldIndex]
            }
            checklist.index = i
            checklist.requirement = requirement ? requirement - 1 : null
          }

          preBuildCorrelations()
          preBuildListRequirements()
        }

        if (DATA.collection.confirm_delete) {
          return {
            dialog: 'confirmation',
            arguments: [list, index],
            callback: remove,
            title: 'ChecklistBuilder.misc.warning',
            text: 'ChecklistBuilder.dialog.confirm_delete_checklist_text',
            type: 'warning'
          }
        }
        remove(index)
        break
      case 'sort':
        let oldLock = DATA.lockedElems[index]
        let tempLock

        // moved to the right
        if (newIndex > index) {
          for (let i = newIndex; i > index; i--) {
            checklists[i].index = i - 1
            tempLock = DATA.lockedElems[i]
            if (oldLock) {
              DATA.lockedElems[i] = oldLock
            } else {
              delete DATA.lockedElems[i]
            }
            oldLock = tempLock
          }
          // moved to the left
        } else if (newIndex < index) {
          oldLock = DATA.lockedElems[index]
          for (let i = newIndex; i < index; i++) {
            checklists[i].index = i + 1
            tempLock = DATA.lockedElems[i]
            if (oldLock) {
              DATA.lockedElems[i] = oldLock
            } else {
              delete DATA.lockedElems[i]
            }
            oldLock = tempLock
          }
        } else {
          return
        }

        if (oldLock) {
          DATA.lockedElems[index] = oldLock
        } else {
          delete DATA.lockedElems[index]
        }

        checklists.splice(index, 1)
        list.index = newIndex
        checklists.splice(newIndex, 0, list)
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateRequirementMatrix(index, newIndex)
        break
    }

    preBuildCorrelations()
    preBuildListRequirements()
    return result
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  function updateRequirementMatrix(index, newIndex) {
    // eslint-disable-next-line jsdoc/require-jsdoc
    const update = (matrix, list) => {
      // moved to the right
      if (newIndex > index) {
        const temp = matrix[index]
        delete matrix[index]
        for (let i = index; i <= newIndex; i++) {
          const tab = matrix[i]
          if (!tab || i === index) {
            continue
          }
          if (i - 1 < list.index) {
            matrix[i - 1] = tab
          }
          delete matrix[i]
        }
        if (temp && newIndex < list.index) {
          matrix[newIndex] = temp
        }
        if (list.requirement !== null) {
          if (newIndex > list.requirement) {
            list.requirement--
          }
        }
        // moved to the left
      } else if (newIndex < index) {
        const temp = matrix[index]
        delete matrix[index]
        for (let i = index; i >= newIndex; i--) {
          const tab = matrix[i]
          if (!tab || i === index) {
            continue
          }
          if (i + 1 < list.index) {
            matrix[i + 1] = tab
          }
          delete matrix[i]
        }
        if (temp) {
          matrix[newIndex] = temp
        }
        if (list.requirement !== null) {
          if (newIndex < list.requirement) {
            list.requirement++
          }
        }
      }
      return matrix
    }
    // eslint-disable-next-line jsdoc/require-jsdoc
    const updateLinks = list => {
      let _r = list.requirement
      let _a = list.activate_checklist_tab_on_unlock
      if (list.index <= _r) {
        _r = list.requirement = null
        // eslint-disable-next-line @typescript-eslint/naming-convention
        list.required_progress = null
      }
      if (list.index >= list.activate_checklist_tab_on_unlock) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _a = list.activate_checklist_tab_on_unlock = -1
      }
      // moved to the right
      if (newIndex > index) {
        if (_r !== null) {
          if (index === _r) {
            list.requirement = newIndex
          } else if (index <= _r && newIndex >= _r) {
            list.requirement--
          }
        }
        if (_a > -1) {
          if (index === _a) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            list.activate_checklist_tab_on_unlock = newIndex
          } else if (index <= _a && newIndex >= _a) {
            list.activate_checklist_tab_on_unlock--
          }
        }
        // moved to the left
      } else if (newIndex < index) {
        if (_r !== null) {
          if (index === _r) {
            list.requirement = newIndex
          } else if (index >= _r && newIndex <= _r) {
            list.requirement++
          }
        }
        if (_a > -1) {
          if (index === _a) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            list.activate_checklist_tab_on_unlock = newIndex
          } else if (index >= _a && newIndex <= _a) {
            list.activate_checklist_tab_on_unlock++
          }
        }
      }
    }

    // Update list requirements matrix
    const lists = DATA.collection.Checklists || []
    for (let _list, l = 0; (_list = lists[l]); l++) {
      updateLinks(_list)
      let returnPaths = _list.return_paths
      if (Array.isArray(returnPaths) && returnPaths.length) {
        returnPaths = Object.keys(
          update(
            returnPaths
              .map(item => {
                const o = {}
                o[item] = item
                return o
              })
              .reduce((x, y) => {
                return extend(true, x, y || {})
              }),
            _list
          )
        ).map(item => {
          return parseInt(item, 10)
        })
        if (returnPaths.length) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _list.return_paths = returnPaths
        } else {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _list.return_paths = null
        }
      }
      let matrix = _list.requirement_matrix
      if (!matrix) {
        continue
      }
      matrix = update(matrix, _list)
      if (isEmptyObject(matrix)) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _list.requirement_matrix = null
      }
    }

    // Update value event matrix and checklist indices
    for (const _guid in DATA.valueEventsQuickAccess) {
      if (DATA.valueEventsQuickAccess.hasOwnProperty(_guid)) {
        const _veqa = DATA.valueEventsQuickAccess[_guid]
        const _list = _veqa.list
        const _event = _veqa.event
        if (_event.action === 'remove_cell_values') {
          let matrix = _event.matrix
          if (matrix) {
            matrix = update(matrix, _list)
            if (isEmptyObject(matrix)) {
              _event.matrix = null
            } else {
              _event.matrix = matrix
            }
          }
        }
        if (typeof _event.checklist_index === 'number') {
          // moved to the right
          if (newIndex > index) {
            if (index === _event.checklist_index) {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              _event.checklist_index = newIndex
            } else if (newIndex > _event.checklist_index) {
              _event.checklist_index--
            }
            // moved to the left
          } else if (newIndex < index) {
            if (index === _event.checklist_index) {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              _event.checklist_index = newIndex
            } else if (
              newIndex < _event.checklist_index ||
              newIndex < _event.checklist_index
            ) {
              _event.checklist_index++
            }
          }
          if (_event.checklist_index > _list.index) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _event.checklist_index = undefined
          }
        }
      }
    }
  }

  /**
   * Speichert die Daten des Editors.
   *
   * @param callback - das nach dem Speichern ausgeführt wird.
   * @param showMessageFlag - Gibt an ob eine Nachricht angezeigt wird.
   */
  function saveEditorData(callback, showMessageFlag) {
    if (!DATA.collection || !DATA.collection.Checklists) {
      if (typeof callback === 'function') {
        callback()
      }
      return
    }
    // eslint-disable-next-line jsdoc/require-jsdoc
    const saveData = (type, data, lastChecklistIndex, lastCellIndex) => {
      if (!data) {
        showMessage('error', lang('message.content.save_collection_error'))
        if (typeof callback === 'function') {
          callback()
        }
        return
      }
      let postData, url, success
      switch (type) {
        case TYPE_COLLECTION:
          url = 'checklist/saveCollectionIncremental'
          postData = extend(true, {}, data)
          postData.Checklists = null
          success = result => {
            try {
              if (typeof result === 'string') {
                result = JSON.parse(result)
              }
              if (result && typeof result === 'object') {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                updateCollectionBuilderData(result)
                result.Checklists = DATA.collection.Checklists
                DATA.collection = extend(true, DATA.collection, result)
                for (let _item, co = 0; (_item = data.Checklists[co++]); ) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  _item.checklist_collection_id = result.id
                }
                saveData(
                  TYPE_CHECKLIST,
                  data.Checklists[~~lastChecklistIndex],
                  lastChecklistIndex
                )
              } else {
                showMessage(
                  'error',
                  lang('message.content.save_collection_error')
                )
                if (typeof callback === 'function') {
                  callback()
                }
              }
            } catch (ex) {
              showMessage(
                'error',
                lang('message.content.save_collection_fatal')
              )
              if (typeof callback === 'function') {
                callback()
              }
            }
          }
          break
        case TYPE_CHECKLIST:
          // Remove relation ID of cloned checklist if necessary
          if (!data.clone_type && data.clone_of_checklist_id) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            data.clone_of_checklist_id = undefined
          }
          url = 'checklist/saveChecklistIncremental'
          postData = extend(true, {}, data)
          postData.ChecklistCells = null
          lastChecklistIndex = postData.index
          success = result => {
            try {
              if (typeof result === 'string') {
                result = JSON.parse(result)
              }
              if (result && typeof result === 'object') {
                if (data.clone_type && !data.clone_of_checklist_id) {
                  for (
                    let _list, i = data.index;
                    (_list = DATA.collection.Checklists[++i]);

                  ) {
                    if (_list.clone_type && _list.clone_of_checklist_id) {
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      _list.clone_of_checklist_id = result.id
                    } else {
                      break
                    }
                  }
                }
                result.ChecklistCells =
                  DATA.collection.Checklists[result.index].ChecklistCells
                DATA.collection.Checklists[result.index] = extend(
                  true,
                  DATA.collection.Checklists[result.index],
                  result
                )
                const cells = []
                for (let _item, i = 0; (_item = data.ChecklistCells[i++]); ) {
                  for (let _cell, j = 0; (_cell = _item[j++]); ) {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    _cell.checklist_id = result.id
                    cells.push(_cell)
                  }
                }
                saveData(TYPE_CELL, cells, lastChecklistIndex)
              } else {
                showMessage(
                  'error',
                  lang('message.content.save_collection_error')
                )
                if (typeof callback === 'function') {
                  callback()
                }
              }
            } catch (ex) {
              showMessage(
                'error',
                lang('message.content.save_collection_fatal')
              )
              if (typeof callback === 'function') {
                callback()
              }
            }
          }
          break
        case TYPE_CELL:
          if (data.length < ~~lastCellIndex) {
            const nextChecklist =
              DATA.collection.Checklists[++lastChecklistIndex]
            if (nextChecklist) {
              saveData(
                TYPE_CHECKLIST,
                extend(true, {}, nextChecklist),
                lastChecklistIndex
              )
              return
            }
            // addEditorBackup(lang.misc.backup_on_save);
            COLLECTION_HASHCODE = getCollectionHashcode()
            showMessage(
              'success',
              lang('message.content.save_collection_success'),
              showMessageFlag
            )
            if (typeof callback === 'function') {
              callback(DATA.collection)
            }
            return
          }

          url = 'checklist/saveCellsIncremental'
          if (BUILDER_SETTINGS.INCREMENTAL_SAVE_MAX_CELLS) {
            postData = data.slice(
              ~~lastCellIndex,
              ~~lastCellIndex + BUILDER_SETTINGS.INCREMENTAL_SAVE_MAX_CELLS
            )
            lastCellIndex =
              ~~lastCellIndex + BUILDER_SETTINGS.INCREMENTAL_SAVE_MAX_CELLS
          } else {
            postData = data
            lastCellIndex = data.length
          }
          success = result => {
            try {
              if (typeof result === 'string') {
                result = JSON.parse(result)
              }
              if (result && typeof result === 'object') {
                if (Array.isArray(result)) {
                  for (let _entry, ni = 0; (_entry = result[ni++]); ) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    setCellDataEditor(lastChecklistIndex, _entry)
                  }
                }
                saveData(TYPE_CELL, data, lastChecklistIndex, lastCellIndex)
              } else {
                showMessage(
                  'error',
                  lang('message.content.save_collection_error')
                )
                if (typeof callback === 'function') {
                  callback()
                }
              }
            } catch (ex) {
              showMessage(
                'error',
                lang('message.content.save_collection_fatal')
              )
              if (typeof callback === 'function') {
                callback()
              }
            }
          }

          if (postData.length === 0) {
            const nextChecklist =
              DATA.collection.Checklists[++lastChecklistIndex]
            if (nextChecklist) {
              saveData(
                TYPE_CHECKLIST,
                extend(true, {}, nextChecklist),
                lastChecklistIndex
              )
              return
            }
            // addEditorBackup(lang.misc.backup_on_save);
            COLLECTION_HASHCODE = getCollectionHashcode()
            showMessage(
              'success',
              lang('message.content.save_collection_success'),
              showMessageFlag
            )
            if (typeof callback === 'function') {
              callback(DATA.collection)
            }
            return
          }
          break
        default:
          showMessage('error', lang('message.content.save_collection_error'))
          if (typeof callback === 'function') {
            callback()
          }
          return
      }

      if (!postData || !url || !success) {
        showMessage('error', lang('message.content.save_collection_error'))
        if (typeof callback === 'function') {
          callback()
        }
        return
      }

      REQUEST.post(url, postData).then(result => {
        success(result.data.data)
      })
    }

    const cleanupData = {}
    for (
      let _checklist, i = -1;
      (_checklist = DATA.collection.Checklists[++i]);

    ) {
      if (!_checklist.id) {
        continue
      }
      let count = -1
      cleanupData[_checklist.id] = []
      for (let _item, j = -1; (_item = _checklist.ChecklistCells[++j]); ) {
        for (let _cell, k = -1; (_cell = _item[++k]); ) {
          if (_cell.id) {
            cleanupData[_checklist.id][++count] = _cell.id
          }
        }
      }
    }

    let cleanupCount = 0
    const cleanupChecklists = Object.keys(cleanupData).map(x => parseInt(x))
    // eslint-disable-next-line jsdoc/require-jsdoc
    const cleanupCells = checklistID => {
      if (!checklistID) {
        saveData(TYPE_COLLECTION, DATA.collection)
        return
      }
      const _cleanup = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        checklist_id: checklistID,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        cell_ids: cleanupData[checklistID]
      }
      REQUEST.post('checklist/cleanupCells', _cleanup).then(result => {
        if (result.data.data) {
          cleanupCells(cleanupChecklists[cleanupCount++])
        } else {
          showMessage('error', lang('message.content.save_collection_error'))
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
    }

    if (!cleanupChecklists || !cleanupChecklists.length) {
      saveData(TYPE_COLLECTION, DATA.collection)
    } else {
      const _cleanup = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        collection_id: DATA.collection.id,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        checklist_ids: cleanupChecklists
      }
      REQUEST.post('checklist/cleanupChecklists', _cleanup).then(result => {
        if (result.data.data) {
          cleanupCells(cleanupChecklists[cleanupCount++])
        } else {
          showMessage('error', lang('message.content.save_collection_error'))
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
    }
  }

  /**
   * Fügt einen Eintrag für schnellen Zugriff auf Ereignisse bei Wert hinzu.
   *
   * @param updateGUID - GUID des Eintrags.
   * @param i - Index der Checkliste.
   * @param x - X-Position der Zelle.
   * @param y - Y-Position der Zelle.
   * @param e - Position des Events.
   */
  function addValueEventsQuickAccess(updateGUID, i, x, y, e) {
    const issetI = typeof i === 'number'
    const issetX = typeof x === 'number'
    const issetY = typeof y === 'number'
    const issetE = typeof e === 'number'
    if (!DATA.collection) {
      return
    }
    const _lists = DATA.collection.Checklists || []
    for (let _list, vi = i || 0; (_list = _lists[vi]); vi++) {
      const _cells = _list.ChecklistCells || []
      const isClone = !!_list.clone_of_checklist_id
      for (let _column, vx = x || 0; (_column = _cells[vx]); vx++) {
        for (let _cell, vy = y || 0; (_cell = _column[vy]); vy++) {
          let temp = _cell.settings
          if (!temp || !(temp = temp.value_events) || !temp.length) {
            continue
          }
          for (let _event, ve = e || 0; (_event = temp[ve]); ve++) {
            if (_event.action === 'remove_cell_values') {
              if (isClone) {
                _event.matrix = null
              }
              if (_event.GUID === undefined || updateGUID) {
                _event.GUID = TIME_MS_NOW()
              }
              DATA.valueEventsQuickAccess[_event.GUID] = {
                list: _list,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                data_editor: _cell,
                event: _event
              }
            }
            if (typeof _event.checklist_index === 'number') {
              if (_event.GUID === undefined || updateGUID) {
                _event.GUID = TIME_MS_NOW()
              }
              DATA.valueEventsQuickAccess[_event.GUID] = {
                list: _list,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                data_editor: _cell,
                event: _event
              }
            }
            if (issetE) {
              break
            }
          }
          if (issetY) {
            break
          } else {
            e = 0
          }
        }
        if (issetX) {
          break
        } else {
          y = 0
        }
      }
      if (issetI) {
        break
      } else {
        x = 0
      }
    }
  }

  /**
   * Setzt Daten von Zelle an richtiger Position in der Matrix.
   *
   * @param index - Index der Checkliste.
   * @param data - Daten der Zelle.
   */
  function setCellDataEditor(index, data) {
    if (!data || typeof data !== 'object') {
      return
    }
    if (!DATA.collection) {
      return
    }
    const lists = DATA.collection.Checklists
    if (!lists) {
      return
    }
    const list = lists[index]
    if (!list) {
      return
    }
    const cells = list.ChecklistCells
    if (!cells) {
      return
    }
    const rows = cells[data.x]
    if (!rows) {
      return
    }
    if (!rows[data.y]) {
      return
    }

    rows.splice(data.y, 1, data)
  }

  /**
   * Setzt Die Breite einer Spalte im Editor.
   *
   * @param checklist - Daten der Checkliste.
   * @param position - X-Position der Spalte.
   * @param width - Breite der Spalte.
   * @returns Spaltenbreite gesetzt
   */
  function setColumnWith(checklist, position, width) {
    if (width === '' || /^[0-9]+(px|pt|%|em)?$/i.test(width)) {
      let widths = checklist.column_widths
      if (!Array.isArray(widths)) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        widths = checklist.column_widths = []
      }
      widths[position] = width
      widths.splice(position, 1, width)
      return true
    }
    showMessage('error', lang('message.content.invalid_column_width'), true)
    return false
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  function getCorrelation(source, target, type) {
    if (isValidOverlay(target.type, [type])) {
      let matrix = source.correlation_matrix
      if (!matrix) {
        matrix = {}
      }
      if (!matrix[target.x]) {
        matrix[target.x] = {}
      }
      if (!matrix[target.x][target.y]) {
        matrix[target.x][target.y] = {}
      }
      // Benötigt, damit Vue die Änderungen mitbekommt
      // eslint-disable-next-line @typescript-eslint/naming-convention
      source.correlation_matrix = {}
      // eslint-disable-next-line @typescript-eslint/naming-convention
      source.correlation_matrix = matrix
      return matrix[target.x][target.y]
    }
    return {}
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  function getCorrelationChecklist(source, target, type, index) {
    if (isValidOverlay(target.type, [type])) {
      let matrix = source.requirement_matrix
      if (!matrix) {
        matrix = {}
      }
      if (!matrix[index]) {
        matrix[index] = {}
      }
      if (!matrix[index][target.x]) {
        matrix[index][target.x] = {}
      }
      if (!matrix[index][target.x][target.y]) {
        matrix[index][target.x][target.y] = {}
      }
      // Benötigt, damit Vue die Änderungen mitbekommt
      // eslint-disable-next-line @typescript-eslint/naming-convention
      source.requirement_matrix = {}
      // eslint-disable-next-line @typescript-eslint/naming-convention
      source.requirement_matrix = matrix
      return matrix[index][target.x][target.y]
    }
    return {}
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  function getCorrelationValueEvent(source, target, type, index) {
    if (isValidOverlay(target.type, [type])) {
      let matrix = source.matrix
      if (!matrix) {
        matrix = {}
      }
      if (!matrix[index]) {
        matrix[index] = {}
      }
      if (!matrix[index][target.x]) {
        matrix[index][target.x] = {}
      }
      if (!matrix[index][target.x][target.y]) {
        matrix[index][target.x][target.y] = {}
      }
      // Benötigt, damit Vue die Änderungen mitbekommt
      source.matrix = {}
      source.matrix = matrix
      return matrix[index][target.x][target.y]
    }
    return {}
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  function applyCorrelation(target, correlation, type) {
    let value
    switch (type) {
      case 0: // REQUIREMENT
        value = correlation.requirement = ~~!correlation.requirement
        if (value) {
          target.requirement_count++
        } else {
          target.requirement_count--
        }
        break
      case 1: // TOTAL AMOUNT & TOTAL AMOUNT DATES
        value = parseInt(correlation.total_amount) || 0
        switch (value) {
          case 0: // NONE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.total_amount = 1
            target.total_amount_count++
            break
          case 1: // ADD
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.total_amount = 2
            break
          case 2: // SUBTRACT
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.total_amount = 0
            target.total_amount_count--
            break
        }
        break
      case 2: // FIELD EXCLUSIVITY
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value = correlation.field_exclusivity = ~~!correlation.field_exclusivity
        if (value) {
          target.field_exclusivity_count++
        } else {
          target.field_exclusivity_count--
        }
        break
      case 3: // FIELD LOCK
        value = parseInt(correlation.field_lock) || 0
        switch (value) {
          case 0: // NONE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.field_lock = 1
            target.field_lock_count++
            break
          case 1: // LOCK ON DONE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.field_lock = 2
            break
          case 2: // LOCK ON UNDONE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.field_lock = 0
            target.field_lock_count--
            break
        }
        break
      case 4: // CHANGE COLOR
        value = parseInt(correlation.change_color) || 0
        switch (value) {
          case 0: // NONE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.change_color = 1
            target.change_color_count++
            break
          case 1: // RED OUTLINE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.change_color = 2
            break
          case 2: // GREEN OUTLINE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.change_color = 3
            break
          case 3: // YELLOW OUTLINE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.change_color = 4
            break
          case 4: // BLUE OUTLINE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.change_color = 5
            break
          case 5: // RED BACKGROUND
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.change_color = 6
            break
          case 6: // GREEN BACKGROUND
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.change_color = 7
            break
          case 7: // YELLOW BACKGROUND
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.change_color = 8
            break
          case 8: // BLUE BACKGROUND
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.change_color = 0
            target.change_color_count--
            break
        }
        break
      case 5: // CHECKLIST REQUIREMENT
        value = parseInt(correlation.checklist_requirement) || 0
        switch (value) {
          case 0: // NONE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.checklist_requirement = 1
            return 1
          case 1: // IF VISIBLE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.checklist_requirement = 2
            return 2
          case 2: // ALWAYS
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.checklist_requirement = 0
            return 0
        }
        break
      case 6: // REMOVE CELL VALUES
        value = parseInt(correlation.delete_value) || 0
        switch (value) {
          case 0: // NONE
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.delete_value = 1
            return 1
          case 1: // RESET CELL VALUE TO DEFAULT
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.delete_value = 2
            return 2
          case 2: // REMOVE VALUE FROM DB AND RESET CELL VALUE TO DEFAULT
            // eslint-disable-next-line @typescript-eslint/naming-convention
            correlation.delete_value = 0
            return 0
        }
        break
      case 7: // TRIGGER EVENT
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value = correlation.event_trigger = ~~!correlation.event_trigger
        if (value) {
          target.value_event_trigger_count++
        } else {
          target.value_event_trigger_count--
        }
        break
    }
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  function modifyOverlayCorrelations(event, data) {
    const x = data.x
    const y = data.y
    const cell = data.cell
    const checklist = data.checklist
    const index = checklist.index
    const altKey = event.altKey // Link column
    const ctrlKey = event.ctrlKey // Link row
    const correlationType = data.overlay.type

    if (correlationType === 6) {
      const sourceChecklist = data.overlay.source.checklist
      const sourceEvent = data.overlay.source.event
      const correlationSource = getCorrelationValueEvent(
        sourceEvent,
        cell,
        correlationType,
        index
      )
      const matrix = sourceEvent.matrix
      const result = applyCorrelation(
        sourceChecklist,
        correlationSource,
        correlationType
      )
      // wenn kein Eintrag entfernt werden muss
      if (result) {
        return
      }
      if (matrix) {
        const mi = matrix[index]
        if (mi) {
          const mx = mi[x]
          if (mx) {
            const my = mx[y]
            if (my) {
              if (!my.checklist_requirement) {
                delete mx[y]
              }
            }
            if (isEmptyObject(mx)) {
              delete mi[x]
            }
          }
          if (isEmptyObject(mi)) {
            delete matrix[index]
          }
        }
      }
    } else if (correlationType === 5) {
      const sourceChecklist = data.overlay.source.checklist
      const correlationSource = getCorrelationChecklist(
        sourceChecklist,
        cell,
        correlationType,
        index
      )
      const result = applyCorrelation(
        sourceChecklist,
        correlationSource,
        correlationType
      )
      // wenn kein Eintrag entfernt werden muss
      if (result) {
        return
      }
      const matrix = sourceChecklist.requirement_matrix
      if (matrix) {
        const mi = matrix[index]
        if (mi) {
          const mx = mi[x]
          if (mx) {
            const my = mx[y]
            if (my) {
              if (!my.checklist_requirement) {
                delete mx[y]
              }
            }
            if (isEmptyObject(mx)) {
              delete mi[x]
            }
          }
          if (isEmptyObject(mi)) {
            delete matrix[index]
          }
        }
        if (isEmptyObject(matrix)) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          sourceChecklist.requirement_matrix = null
        }
      }
    } else {
      const sourceCell = data.overlay.source.cell
      const xs = sourceCell.x
      const ys = sourceCell.y
      const correlationSource = getCorrelation(
        sourceCell,
        cell,
        correlationType,
        sourceCell.type
      )

      // Apply correlations to all cells in row.
      if (ctrlKey && y === ys) {
        const type = cell.type
        const cells = checklist.ChecklistCells
        for (let i = 0; cells[i]; i++) {
          const _cell = getCellDataEditor(index, i, y)
          if (_cell.type !== type) {
            continue
          }
          for (let j = 0; cells[i]; j++) {
            if (j !== i) {
              const _target = getCellDataEditor(index, j, y)
              if (_target.type !== type) {
                continue
              }
              const correlation = getCorrelation(
                _cell,
                _target,
                correlationType,
                _cell.type
              )
              if (correlation) {
                applyCorrelation(_cell, correlation, correlationType)
              }
            }
          }
        }
      }

      // Apply correlations to all cells in column.
      if (altKey && x === xs) {
        const type = cell.type
        const column = checklist.ChecklistCells[x] || []
        for (let _cell, i = 0; (_cell = column[i]); i++) {
          if (_cell.type !== type) {
            continue
          }
          for (let _target, j = 0; (_target = column[j]); j++) {
            if (j !== i) {
              if (_target.type !== type) {
                continue
              }
              const correlation = getCorrelation(
                _cell,
                _target,
                correlationType,
                _cell.type
              )
              if (correlation) {
                applyCorrelation(_cell, correlation, correlationType)
              }
            }
          }
        }
      }

      if (!altKey && !ctrlKey) {
        applyCorrelation(sourceCell, correlationSource, correlationType)
      }
    }
  }

  /**
   * Daten der Collection.
   *
   */
  function updateCollectionBuilderData() {
    /* TODO
    var update = false;
    var localization = getLocalization(result, 'label');
    if (!ChecklistBuilder.data.COLLECTIONS.DATA[result.id]) {
      update = true;
      ChecklistBuilder.data.COLLECTIONS.DATA[result.id] = localization;
      ChecklistBuilder.data.COLLECTIONS.HTML += '<option value="' + result.id + '">' + localization + '</option>';
    } else if (ChecklistBuilder.data.COLLECTIONS.DATA[result.id] !== localization) {
      update = true;
      var option_new = '<option value="' + result.id + '">' + localization + '</option>';
      var option_old = '<option value="' + result.id + '">' + ChecklistBuilder.data.COLLECTIONS.DATA[result.id] + '</option>';
      ChecklistBuilder.data.COLLECTIONS.DATA[result.id] = localization;
      ChecklistBuilder.data.COLLECTIONS.HTML = ChecklistBuilder.data.COLLECTIONS.HTML.replace(option_old, option_new);
    }
    if (update) {
      var $display_checklists = $('.dropdown-multiselect[data-setting-property="display_checklists"]');
      $display_checklists.html(ChecklistBuilder.data.COLLECTIONS.HTML);
      if (typeof BUILDER_SETTINGS.REFRESH_SELECTS === 'function') {
        BUILDER_SETTINGS.REFRESH_SELECTS($display_checklists, true);
      }
    } */
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  function initializeCells(initialize) {
    if (!Array.isArray(initialize) || !initialize.length) {
      return
    }
    for (let i = 0, entry; (entry = initialize[i++]); ) {
      const dataEditor = entry.dataEditor
      if (!dataEditor) {
        continue
      }
      const dataViewer = entry.dataViewer
      if (!dataViewer) {
        continue
      }
      const index = entry.index
      if (index === undefined || index === null || index < 0) {
        continue
      }
      let value = getLocalization(dataEditor, 'preset_value') || ''
      if (value && typeof value === 'string') {
        try {
          switch (dataEditor.type) {
            case 'radio':
              break
            default:
              value = JSON.parse(value) || ''
              break
          }
        } catch (ex) {}
      }
      evalValues({
        index: index,
        dataEditor: dataEditor,
        dataViewer: dataViewer,
        cloneIndex: 0,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: ''
      })
    }
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  function revaluateCells(checklist, valuesWithIDOnly, typeBlacklist) {
    if (!checklist) {
      return
    }

    const cells = checklist.ChecklistCells || []
    const values = DATA.correlations || []
    const index = checklist.index
    const _cells = values[index]
    const filter = Array.isArray(typeBlacklist)
    for (let x = 0, column; (column = cells[x]); x++) {
      const _column = _cells[x]
      for (let y = 0, cell; (cell = column[y]); y++) {
        const _cell = _column[y]
        if (valuesWithIDOnly) {
          if (_cell.id === undefined || _cell.id === null) {
            continue
          }
        }
        if (cell.settings.prevent_revaluation) {
          continue
        }
        if (filter) {
          if (typeBlacklist.indexOf(cell.type) !== -1) {
            continue
          }
        }
        let value = getCellValue(index, cell, 0)
        if (value && typeof value === 'string') {
          try {
            value = JSON.parse(value)
          } catch (ex) {}
        }
        let previousValue = getLocalization(cell, 'preset_value') || ''
        if (previousValue && typeof previousValue === 'string') {
          try {
            switch (cell.type) {
              case 'radio':
                break
              default:
                previousValue = JSON.parse(previousValue) || ''
                break
            }
          } catch (ex) {}
        }
        evalValues({
          index: index,
          dataEditor: cell,
          dataViewer: _cell,
          cloneIndex: 0,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: false,
          value: value,
          previousValue: previousValue
        })
      }
    }
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  function addVariableCellMapping(variable, index, dataEditor) {
    let mapping = VARIABLE_CELL_MAPPING[variable]
    if (!Array.isArray(mapping)) {
      mapping = VARIABLE_CELL_MAPPING[variable] = []
    }
    mapping.push({
      index: index,
      data: dataEditor
    })
  }

  /**
   * Gibt Einstellungen der Kollektion zurück.
   *
   * @returns Einstellungen der Kollektion
   */
  this.getSettings = () => {
    return BUILDER_SETTINGS
  }

  /**
   * Gibt Einstellunge der Kollektion zurück.
   *
   * @param property - Eigenschaft deren Wert abgefragt wird
   * @returns Wert der Einstellung
   */
  this.getSetting = property => {
    return BUILDER_SETTINGS[property]
  }

  this.lang = key => {
    return lang(key)
  }

  this.loadViewer = async (
    collectionId,
    moduleId,
    overrideValues = null,
    overrideVariables = null
  ) => {
    if (typeof collectionId !== 'number') {
      collectionId = Number.parseInt(collectionId, 10)
    }
    if ((collectionId <= 0 || Number.isNaN(collectionId)) && moduleId <= 0) {
      return
    }
    overrideVariables = overrideVariables || {}
    MODE = MODE_VIEWER
    const promises = []
    if (!BUILDER_SETTINGS.ONLINE && OFFLINE_DATA.collection) {
      promises.push(OFFLINE_DATA.collection)
    } else {
      promises.push(
        (await getService('checklists-collections/deep')).get(collectionId, {
          query: {
            $moduleID: moduleId
          }
        })
      )
    }

    if (!BUILDER_SETTINGS.ONLINE) {
      VARIABLE_DIGITS = extend(
        {},
        OFFLINE_DATA.variables.Digits || {},
        overrideVariables.Digits || {}
      )
      VARIABLE_DEFAULT_VALUES = extend(
        {},
        OFFLINE_DATA.variables.DefaultValues || {},
        overrideVariables.DefaultValues || {}
      )
      VARIABLE_TYPES = extend(
        {},
        OFFLINE_DATA.variables.Types || {},
        overrideVariables.Types || {}
      )
      promises.push(OFFLINE_DATA.values || {})
      promises.push(OFFLINE_DATA.variableValues || {})
      promises.push(OFFLINE_DATA.entries || {})
    } else {
      const [serviceVariables, serviceCellValues, serviceVariablesDeep] =
        await Promise.all([
          getService('checklists/variables', Connection.Offline),
          getService('checklists/cells/values', Connection.Offline),
          getService('checklists/variables/deep')
        ])

      if (overrideValues !== null && typeof overrideValues === 'object') {
        promises.push(overrideValues)
      } else {
        const queryFindValues = {
          selector: BUILDER_SETTINGS.VALUE_SELECTOR,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_id: collectionId || 0
        }
        promises.push(
          serviceCellValues.find({ query: queryFindValues }).then(
            async result => {
              let loadedCells = []

              if ('total' in result && typeof result.total === 'number') {
                const total = result.total
                const limit = result.limit

                if (total > limit) {
                  const loading = []

                  for (let offset = limit; offset < total; offset += limit) {
                    loading.push(
                      serviceCellValues
                        .find({
                          query: { ...queryFindValues, $offset: offset }
                        })
                        .catch(() => ({}))
                    )
                  }

                  loadedCells = await Promise.all(loading)
                }
              }

              let cellValues = Object.assign(result, ...loadedCells)
              delete cellValues.total
              delete cellValues.limit
              return cellValues
            },
            () => {}
          )
        )
      }

      await serviceVariables
        .find({ query: { selector: BUILDER_SETTINGS.VALUE_SELECTOR } })
        .then(
          x => (x.total > 0 ? x.data[0] : null),
          () => null
        )
        .then(variables => {
          if (variables !== null) {
            promises.push(variables)
          } else {
            const variableSettings = BUILDER_SETTINGS.VARIABLE_SETTINGS || {}
            const variableDate = new Date(variableSettings.date)

            promises.push(
              serviceVariablesDeep.find({
                query: {
                  $selectorGlobal: variableSettings.selector_global,
                  $selectorLocal: variableSettings.selector_local,
                  $month: variableDate.getMonth() + 1,
                  $year: variableDate.getFullYear(),
                  $collectionID: collectionId || 0,
                  $moduleID: moduleId || 0,
                  $analysisID: variableSettings.analysis_id || 0,
                  $locationID: variableSettings.location_id || 0,
                  $substanceID: variableSettings.substance_id || 0,
                  $emkgID: variableSettings.emkg_id || 0,
                  $employeeID: variableSettings.employee_id || 0
                }
              })
            )
          }
        })

      promises.push({})
    }

    /* promises.push(REQUEST.post('checklist/getEmailData', {
    }).then((response) => {
      return response.data.data
    })) */

    const success = await Promise.all(promises).then(data => {
      DATA.collection = data[0] || {}
      if (!DATA.collection.Checklists) {
        return
      }
      DATA.values = data[1] || {}
      const variables = data[2] || {}
      DATA.variables = VARIABLE_DATA = extend(
        {},
        VARIABLE_DATA || {},
        variables.Values || {},
        overrideVariables.Values || {}
      )
      if (BUILDER_SETTINGS.ONLINE) {
        VARIABLE_DIGITS = extend(
          VARIABLE_DIGITS || {},
          variables.Digits || {},
          overrideVariables.Digits || {}
        )
        VARIABLE_DEFAULT_VALUES = extend(
          {},
          VARIABLE_DEFAULT_VALUES || {},
          variables.DefaultValues || {},
          overrideVariables.DefaultValues || {}
        )
        VARIABLE_TYPES = extend(
          VARIABLE_TYPES || {},
          variables.Types || {},
          overrideVariables.Types || {}
        )
      }
      DATA.replaceEntries = data[3] || {}
      DATA.emailData = data[4] || {}
      preBuildCorrelations()
      preBuildListRequirements()
      if (typeof BUILDER_SETTINGS.ON_PROGRESS_CHANGE === 'function') {
        BUILDER_SETTINGS.ON_PROGRESS_CHANGE(getProgress())
      }
      if (typeof BUILDER_SETTINGS.ON_CHECKLIST_FULLY_LOADED === 'function') {
        BUILDER_SETTINGS.ON_CHECKLIST_FULLY_LOADED(true)
      }

      return true
    })

    return success
  }

  this.getCollectionData = () => {
    return DATA.collection || {}
  }

  this.getModuleData = () => {
    return DATA.modules || {}
  }

  this.getLocalization = (obj, property) => {
    return getLocalization(obj, property)
  }

  this.buildCorrelationList = checklist => {
    return buildCorrelationList(checklist)
  }

  this.preBuildCorrelations = () => {
    return preBuildCorrelations()
  }

  this.rebuildCorrelations = () => {
    DATA.correlations = []
    DATA.values = {}
    VARIABLE_CELL_MAPPING = {}
    VARIABLE_CHECKLIST_MAPPING = {}
    HIDDEN_SPAN_CELL_CACHE = {}
    JUMPTO_CELL_CACHE = []
    preBuildCorrelations()
    preBuildListRequirements()
    const checklists = DATA.collection.Checklists || []
    for (let checklist, i = 0; (checklist = checklists[i]); i++) {
      buildCorrelationList(checklist)
    }
  }

  this.checkChecklistVisibility = async (checklist, ignoreRights) => {
    const result = await checkChecklistVisibility(checklist, ignoreRights)
    return result
  }

  this.checkEditabilityRights = checklist => {
    return checkEditabilityRights(checklist)
  }

  this.isCloneWithChild = checklist => {
    if (checklist.clone_of_checklist_id) {
      return true
    }
    if (checklist.clone_type === CLONE_TYPE_SAME_TAB) {
      return true
    }
    if (checklist.clone_type === CLONE_TYPE_SAME_TAB_WITH_HEADER) {
      return true
    }
    return false
  }

  this.getCellDataViewer = (index, x, y) => {
    return getCellDataViewer(index, x, y)
  }

  this.getCellValue = (index, data, cloneIndex, defaultValue) => {
    return getCellValue(index, data, cloneIndex, defaultValue)
  }

  this.getReplaceEntry = entry => {
    return DATA.replaceEntries[entry] || ''
  }

  this.checkCellSpanVisibility = (index, x, y) => {
    return !((HIDDEN_SPAN_CELL_CACHE[index] || {})[x] || {})[y]
  }

  this.checkCellVisibility = async (index, x, y) => {
    if (await checkCellVisibility(index, x, y)) {
      const dataEditor = getCellDataEditor(index, x, y)
      if (
        !dataEditor.settings.countdown_hide ||
        dataEditor.settings.countdown_done
      ) {
        return true
      }
    }
    return false
  }

  this.getViewerCellButtonTypes = data => {
    const types = []

    for (let i = 0, button; (button = BUTTON_COLLECTION_VIEWER[i++]); ) {
      if (button.subtype === 'settings') {
        let property = button.property
        const value = data.settings[property]
        if (value || typeof value === 'number') {
          if (button.type) {
            if (types.indexOf(button.type) === -1) {
              types.push(button.type)
            }
          } else {
            property = property
              .split('_')
              .map(t => t.charAt(0).toUpperCase() + t.slice(1))
              .join('')
            types.push(`Settings${property}`)
          }
        }
      } else if (Array.isArray(button.celltypes)) {
        let property = button.property
        const value = data.settings[property]
        if (
          (Array.isArray(value) && value.length) ||
          (!Array.isArray(value) && (value || typeof value === 'number'))
        ) {
          const types = button.celltypes
          const type = data.type
          if (types.indexOf(type) !== -1) {
            if (button.type) {
              if (types.indexOf(button.type) === -1) {
                types.push(button.type)
              }
            } else {
              property = property
                .split('_')
                .map(t => t.charAt(0).toUpperCase() + t.slice(1))
                .join('')
              types.push(property)
            }
          }
        }
      } else {
        let property = button.property
        const value = data[button.property]
        if (value || typeof value === 'number') {
          if (button.type) {
            if (types.indexOf(button.type) === -1) {
              types.push(button.type)
            }
          } else {
            property = property
              .split('_')
              .map(t => t.charAt(0).toUpperCase() + t.slice(1))
              .join('')
            types.push(property)
          }
        }
      }
    }
    return types
  }

  this.getDisabledState = async (dataEditor, index) => {
    const result = await getDisabledState(dataEditor, index)
    return result
  }

  this.evalTerms = async data => {
    const result = await evalTerms(data)
    return result
  }

  this.replaceTokens = (tokens, text) => {
    return replaceTokens(tokens, text)
  }

  this.modifyReplaceEntries = (data, extend) => {
    if (!data || typeof data !== 'object') {
      return DATA.replaceEntries || {}
    }
    if (extend) {
      DATA.replaceEntries = extend({}, DATA.replaceEntries, data)
    } else {
      DATA.replaceEntries = data
    }
  }

  this.extend = () => {
    return extend
  }

  this.getDateObject = value => {
    return getDateObject(value)
  }

  this.getDateISO = (date, withTime = true) => {
    return getDateISO(date, withTime)
  }

  this.getDate = (value, locale) => {
    return getDate(value, locale)
  }

  this.getDateTime = (value, options) => {
    return getDateTime(value, options)
  }

  this.getTime = (value, options) => {
    return getTime(value, options)
  }

  this.getSelectPropertyToArray = (data, property) => {
    return getSelectPropertyToArray(data, property)
  }

  this.getSqlData = dataEditor => {
    return getSqlData(dataEditor || {})
  }
  this.applySQLData = (index, dataEditor, dataViewer) => {
    return applySQLData(index, dataEditor, dataViewer)
  }

  this.calculateTotalAmount = (index, matrix) => {
    let value = 0
    matrix = matrix || {}
    for (const columnKey in matrix) {
      if (!matrix.hasOwnProperty(columnKey)) {
        continue
      }
      const column = matrix[columnKey]
      if (!column) {
        continue
      }
      for (const cellKey in column) {
        if (!column.hasOwnProperty(cellKey)) {
          continue
        }
        const cell = column[cellKey]
        if (!cell) {
          continue
        }
        const operator = cell.total_amount
        if (operator) {
          value = calculateTotalAmount(
            index,
            columnKey,
            cellKey,
            operator,
            value
          )
        }
      }
    }
    return value
  }

  this.calculateTotalAmountDates = (index, matrix) => {
    let value = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      weeks_total: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      days_total: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      hours_total: 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      minutes_total: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      html: '0'
    }
    matrix = matrix || {}
    for (const columnKey in matrix) {
      if (!matrix.hasOwnProperty(columnKey)) {
        continue
      }
      const column = matrix[columnKey]
      if (!column) {
        continue
      }
      for (const cellKey in column) {
        if (!column.hasOwnProperty(cellKey)) {
          continue
        }
        const cell = column[cellKey]
        if (!cell) {
          continue
        }
        const operator = cell.total_amount
        if (operator) {
          value = calculateTotalAmountDates(
            index,
            columnKey,
            cellKey,
            operator,
            value
          )
        }
      }
    }
    return value
  }

  this.deleteValues = (checklistIDs, callback) => {
    const checklists = (DATA.collection || {}).Checklists || []
    const correlations = DATA.correlations || []
    const valueIDs = []
    for (let i = 0, checklist; (checklist = checklists[i++]); i++) {
      if (
        Array.isArray(checklistIDs) &&
        checklistIDs.indexOf(checklist.id) === -1
      ) {
        continue
      }
      const values = correlations[checklist.index]
      for (let x = 0, column; (column = values[x]); x++) {
        if (!Array.isArray(column)) {
          continue
        }
        for (let y = 0, value; (value = column[y]); y++) {
          if (!value || !value.id) {
            continue
          }
          valueIDs.push(value.id)
        }
      }
    }
    return deleteChecklistValues(valueIDs, callback)
  }

  this.showMessage = (type, message, ignoreMessageFilter) => {
    showMessage(type, message, ignoreMessageFilter)
  }

  this.saveChecklistValues = (
    index,
    x,
    y,
    entry,
    ignoreSettingAutoSave,
    callback
  ) => {
    saveChecklistValues(index, x, y, entry, ignoreSettingAutoSave, callback)
  }

  this.saveEditorData = (callback, message) => {
    saveEditorData(callback, message)
  }

  this.addVariableCellMapping = (variable, index, dataEditor) => {
    addVariableCellMapping(variable, index, dataEditor)
  }

  this.modifySettings = settings => {
    if (
      settings &&
      typeof settings === 'object' &&
      Object.keys(settings).length
    ) {
      applySettings(settings)
    }
  }

  this.hideChecklistClone = checklist => {
    hideChecklistClone(checklist)
  }

  this.showChecklistClone = checklist => {
    showChecklistClone(checklist)
  }

  this.setOfflineData = data => {
    OFFLINE_DATA = data
  }

  this.synchronizeData = data => {
    return synchronizeData(data)
  }

  // Return true if collection has unsaved changes in editor
  this.isCollectionChanged = () => {
    return !(COLLECTION_HASHCODE === getCollectionHashcode())
  }

  this.updateLists = (cmd, index, newIndex) => {
    return updateLists(cmd, index, newIndex)
  }

  this.setColumnWith = (checklist, position, width) => {
    return setColumnWith(checklist, position, width)
  }

  this.handleButtonAction = (type, checklist, index, position) => {
    const action = BUTTON_FUNCTIONS[type]
    if (typeof action === 'function') {
      return action(checklist, index, position)
    }
  }

  this.handleButtonCellAction = (type, checklist, index, data) => {
    const action = BUTTON_FUNCTIONS_CELL[type]
    if (typeof action === 'function') {
      return action(checklist, index, data)
    }
  }

  this.isValidOverlay = (type, types) => {
    return isValidOverlay(type, types)
  }

  this.modifyOverlayCorrelations = (event, data) => {
    return modifyOverlayCorrelations(event, data)
  }

  this.setDefaultCellSettings = cell => {
    return extend(
      true,
      cell || getBasicCell(),
      DEFAULT_CELL_TYPE_SETTINGS[cell.type] || {}
    )
  }

  this.getAllowedCellSettings = type => {
    return ALLOWED_CELL_SETTINGS[type] || []
  }

  this.getValueEventDefinitions = () => {
    return VALUE_EVENTS
  }

  this.setMode = mode => {
    MODE = mode
  }

  this.addValueEventsQuickAccess = (updateGUID, i, x, y, e) => {
    addValueEventsQuickAccess(updateGUID, i, x, y, e)
  }

  this.removeValueEventsQuickAccess = data => {
    delete DATA.valueEventsQuickAccess[data.GUID]
  }

  this.mandatoryFieldsDone = () => {
    const mandatory = DATA.mandatory || []
    for (let i = 0, checklist; (checklist = mandatory[i]); i++) {
      for (let j = 0, cell; (cell = checklist[j]); j++) {
        const dataViewer = getCellDataViewer(i, cell.x, cell.y) || {}
        if (!dataViewer.done) {
          if (!dataViewer.visible && cell.ignore_hidden) {
            return false
          }
          if (dataViewer.visible) {
            return false
          }
        }
      }
    }
    return true
  }

  this.getChecklistData = index => {
    return getChecklistDataEditor(index) || {}
  }

  this.checkChecklistVisibilityNoRights = async (index, cellReplacements) => {
    const list = extend(true, {}, getChecklistDataEditor(index) || {})
    if (!list.active || !checkVisibilityClone(list)) {
      return false
    }
    if (MODE === MODE_PRINTER) {
      if (DATA.collection.show_printer_hidden_checklists) {
        return true
      }
    } else if (list.show_in_printer_only) {
      return false
    }
    if (!(await checkVisibilityRequirements(list, cellReplacements))) {
      return false
    }
    return list
  }

  this.overrideCorrelations = (values, allowOverride) => {
    if (values !== null && typeof values === 'object') {
      const checklists = DATA.collection.Checklists || []

      if (typeof allowOverride !== 'function') {
        allowOverride = () => true
      }

      for (let i = 0, length = checklists.length; i < length; i++) {
        const checklist = checklists[i] || {}
        const cells = checklist.ChecklistCells || []

        for (let x = 0, xlength = cells.length; x < xlength; x++) {
          const column = cells[x]

          if (!Array.isArray(column)) {
            continue
          }

          for (let y = 0, ylength = column.length; y < ylength; y++) {
            const cell = column[y]

            if (!cell) {
              continue
            }

            const cellValue = values[cell.id]
            if (cellValue && allowOverride(cellValue)) {
              DATA.correlations[i][x][y] = cellValue
            }
          }
        }
      }
    }

    return DATA.correlations
  }

  this.revaluateCells = (
    checklistId,
    saveAllVariables = false,
    valuesWithIDOnly = false,
    typeBlacklist
  ) => {
    // eslint-disable-next-line jsdoc/require-jsdoc
    const revaluate = checklistId => {
      let checklists = DATA.collection.Checklists || []

      if (Number.isInteger(checklistId)) {
        checklists = (DATA.collection.Checklists || []).filter(item => {
          return item.id === checklistId
        })
      }
      for (let i = 0, checklist; (checklist = checklists[i++]); ) {
        revaluateCells(checklist, valuesWithIDOnly, typeBlacklist)
      }
    }

    if (saveAllVariables) {
      saveVariableData(() => {
        revaluate(checklistId, valuesWithIDOnly, typeBlacklist)
      })
    } else {
      revaluate(checklistId)
    }
  }

  this.hasGlobalVariable = filter => {
    if (VARIABLE_TYPES && typeof VARIABLE_TYPES === 'object') {
      for (const variable in VARIABLE_TYPES) {
        if (!Array.isArray(filter) || filter.indexOf(variable) !== -1) {
          if (VARIABLE_TYPES[variable] === 2) {
            return true
          }
        }
      }
    }
    return false
  }

  this.replaceMatches = (match, replaceString) => {
    if (!match || typeof replaceString !== 'string') {
      return false
    }
    const matches = match.matches
    if (!Array.isArray(matches) || !matches.length) {
      return false
    }
    const obj = match.obj
    const prop = match.prop
    let value = match.value
    for (let i = 0, _match; typeof (_match = matches[i++]) === 'string'; ) {
      value = value.replace(new RegExp(_match, 'gm'), replaceString)
    }
    obj[prop] = value
    return true
  }

  this.searchMatches = (searchString, filters, ignoreCase) => {
    const result = []
    if (!searchString || typeof searchString !== 'string') {
      return result
    }
    const settingsProperties = [
      'label',
      'inner_label_0',
      'inner_label_1',
      'inner_label_2',
      'radio_btn_label_1',
      'radio_btn_label_2',
      'email_btn_label',
      'combine_sep_operator',
      'custom_button_label',
      'on_click_callback_message_success',
      'on_click_callback_message_error',
      'on_click_callback_single_value',
      'on_click_callback_multi_value_0',
      'on_click_callback_multi_value_1',
      'on_click_callback_multi_value_2',
      'text_code_input_0_label',
      'text_code_input_1_label',
      'text_code_input_2_label',
      'text_code_input_3_label',
      'image_alternate_text'
    ]
    const settingsPropertArrays = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      value_events: 5,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dropdown_builder: 6,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dropdown_builder_2: 6,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dropdown_builder_3: 6,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      multiselect_radio_builder: 6
    }
    const flags = ignoreCase ? 'gmi' : 'gm'
    // eslint-disable-next-line jsdoc/require-jsdoc
    const getMatches = (value, search, flags) => {
      if (!value || typeof value !== 'string') {
        return []
      }
      const regex = new RegExp(search, flags)
      const seen = {}
      const matches = (value.match(regex) || []).filter(item => {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true)
      })
      return matches
    }
    // eslint-disable-next-line jsdoc/require-jsdoc
    const getLabel = (label, matches) => {
      if (!label) {
        return label
      }
      for (let i = 0, match; (match = matches[i++]); ) {
        label = label.replace(new RegExp(match, 'gm'), `<b>${match}</b>`)
      }
      return label
    }
    // eslint-disable-next-line jsdoc/require-jsdoc
    const getConverted = (label, type) => {
      switch (type) {
        case 'text_email_formatted':
          try {
            const _label = JSON.parse(label)
            const subject = lang('misc.email.subject')
            const message = lang('misc.email.message')
            return `
              <strong>${subject}:</strong><br>
              ${_label.subject || ''}<br>
              <strong>${message}:</strong><br>
              ${_label.message || ''}`
          } catch (ex) {
            return label
          }
        default:
          return label
      }
    }

    const hasFilter =
      !filters ||
      filters.label ||
      filters.dropdowns ||
      filters.events ||
      filters.value
    const collection = DATA.collection
    let matches = []
    if (!collection) {
      return result
    }

    if (!hasFilter || filters.label) {
      const value = collection.label
      matches = getMatches(value, searchString, flags)
      if (matches.length) {
        result.push({
          type: 1,
          obj: collection,
          prop: 'label',
          value: value,
          label: getLabel(value, matches),
          matches: matches
        })
      }
    }

    const checklists = collection.Checklists
    if (!checklists || !checklists.length) {
      return result
    }

    if (!hasFilter || filters.label) {
      for (let i = 0, checklist; (checklist = checklists[i++]); ) {
        const value = checklist.label
        matches = getMatches(value, searchString, flags)
        if (!matches.length) {
          continue
        }
        result.push({
          type: 2,
          obj: checklist,
          prop: 'label',
          value: value,
          label: getLabel(value, matches),
          matches: matches
        })
      }
    }

    for (let i = 0, checklist; (checklist = checklists[i++]); ) {
      const cells = checklist.ChecklistCells || []
      for (let x = 0, column; (column = cells[x++]); ) {
        for (let y = 0, cell; (cell = column[y++]); ) {
          if (!hasFilter || filters.value) {
            const value = cell.preset_value
            matches = getMatches(value, searchString, flags)
            if (matches.length) {
              result.push({
                type: 3,
                obj: cell,
                cell: cell,
                checklist: checklist,
                prop: 'preset_value',
                value: value,
                label: getLabel(getConverted(value, cell.type), matches),
                matches: matches
              })
            }
          }
          const settings = cell.settings || {}
          if (!hasFilter || filters.label) {
            for (let j = 0, setting; (setting = settingsProperties[j++]); ) {
              const svalue = settings[setting]
              matches = getMatches(svalue, searchString, flags)
              if (matches.length) {
                result.push({
                  type: 4,
                  obj: settings,
                  cell: cell,
                  checklist: checklist,
                  prop: setting,
                  value: svalue,
                  label: getLabel(svalue, matches),
                  matches: matches
                })
              }
            }
          }
          for (const sprop in settingsPropertArrays) {
            const type = settingsPropertArrays[sprop]
            const svalue = settings[sprop]
            if (!Array.isArray(svalue) || !svalue.length || !type) {
              continue
            }
            if (hasFilter) {
              if (type === 6 && !filters.dropdowns) {
                continue
              }
              if (type === 5 && !filters.events) {
                continue
              }
            }
            for (let j = 0, entry; (entry = svalue[j++]); ) {
              for (const prop in entry) {
                const evalue = entry[prop]
                if (typeof evalue !== 'string') {
                  continue
                }
                matches = getMatches(evalue, searchString, flags)
                if (matches.length) {
                  result.push({
                    type: type,
                    obj: entry,
                    cell: cell,
                    checklist: checklist,
                    prop: prop,
                    value: evalue,
                    label: getLabel(evalue, matches),
                    matches: matches
                  })
                }
              }
            }
          }
        }
      }
    }
    return result
  }

  this.importCollection = (
    data,
    callback,
    removeRelationIds,
    importVariables
  ) => {
    let response = {
      success: false
    }

    try {
      data = JSON.parse(data)
    } catch (ex) {
      if (typeof callback === 'function') {
        callback(response)
      }
    }

    if (!data) {
      if (typeof callback === 'function') {
        callback(response)
      }
    }

    let total = 0
    let valid = 0
    let imported = 0
    let collections = []
    const collectionsImports = []
    const mappingIds = []
    const mappingImportIds = {}
    let variables = []
    let newImportType = false

    // eslint-disable-next-line jsdoc/require-jsdoc
    const loopSubCollections = _subcollections => {
      if (!_subcollections || !_subcollections.COLLECTIONS) {
        return
      }
      if (_subcollections.SUB_COLLECTIONS) {
        variables = variables.concat(_subcollections.VARIABLES || [])
        loopSubCollections(_subcollections.SUB_COLLECTIONS)
        collections = collections.concat(_subcollections.COLLECTIONS || [])
      } else {
        variables = variables.concat(_subcollections.VARIABLES || [])
        collections = collections.concat(_subcollections.COLLECTIONS || [])
      }
    }

    // eslint-disable-next-line jsdoc/require-jsdoc
    const resetIds = (_collection, _removeRelationIds) => {
      if (!_collection) {
        return null
      }
      _collection.id = 0
      if (
        _collection.hasOwnProperty('Checklists') &&
        Array.isArray(_collection.Checklists)
      ) {
        const _checklists = _collection.Checklists
        for (let i = 0, _checklist; (_checklist = _checklists[i++]); ) {
          _checklist.id = 0
          if (
            _checklist.hasOwnProperty('ChecklistCells') &&
            Array.isArray(_checklist.ChecklistCells)
          ) {
            const _cells = _checklist.ChecklistCells
            for (let j = 0, _column; (_column = _cells[j]); j++) {
              for (let k = 0, _cell; (_cell = _column[k]); k++) {
                _cell.id = 0
                if (_removeRelationIds) {
                  _cell.files = null
                  _cell.actions = null
                  const _settings = _cell.settings
                  if (_settings) {
                    if (_settings.hasOwnProperty('emails')) {
                      delete _settings.emails
                    }
                    if (_settings.hasOwnProperty('display_checklists')) {
                      delete _settings.display_checklists
                    }
                    if (
                      _settings.hasOwnProperty('action_generation_tree_node')
                    ) {
                      delete _settings.action_generation_tree_node
                    }
                    if (_settings.hasOwnProperty('collection_links_select')) {
                      delete _settings.collection_links_select
                    }
                    if (_settings.hasOwnProperty('documents')) {
                      delete _settings.documents
                      if (_settings.hasOwnProperty('preset_value')) {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        _cell.preset_value = null
                      }
                      if (
                        _settings.hasOwnProperty('preset_value_localization')
                      ) {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        _cell.preset_value_localization = null
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      return _collection
    }

    // eslint-disable-next-line jsdoc/require-jsdoc
    const _importCollection = (_collection, isMainCollection) => {
      DATA.collection = _collection
      const mappingId = parseInt((mappingIds || [])[0]) || 0
      const _checklists = DATA.collection.Checklists || []
      let _llength = 0
      let _oldList = []
      let _settings = {}
      for (let _checklist, i = 0; (_checklist = _checklists[i++]); ) {
        const _cells = _checklist.ChecklistCells || []
        for (let _column, j = 0; (_column = _cells[j++]); ) {
          for (let _cell, k = 0; (_cell = _column[k++]); ) {
            if (!(_settings = _cell.settings)) {
              continue
            }
            if (mappingImportIds[_settings.collection_links_select]) {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              _settings.collection_links_select =
                mappingImportIds[_settings.collection_links_select] + ''
            }
            if (
              Array.isArray((_oldList = _settings.display_checklists)) &&
              (_llength = _oldList.length)
            ) {
              const _newList = []
              for (let l = 0; l < _llength; l++) {
                _newList.push(
                  (mappingImportIds[_oldList[l]] || _oldList[l]) + ''
                )
              }
              if (_newList.length) {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                _settings.display_checklists = _newList
              } else {
                delete _settings.display_checklists
              }
            } else {
              delete _settings.display_checklists
            }
          }
        }
      }
      if (isMainCollection) {
        saveEditorData(_collection => {
          if (_collection) {
            imported = imported + 1
          }
          if (typeof callback === 'function') {
            response = {
              success: true,
              total: total,
              valid: valid,
              imported: imported
            }
            if (importVariables && variables.length) {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              _importVariables()
            } else {
              callback(response)
            }
          }
        })
      } else {
        saveEditorData(_collection => {
          if (_collection) {
            imported = imported + 1
          }
          mappingImportIds[mappingId] = _collection.id
          mappingIds.splice(0, 1)
          collections.splice(0, 1)
          _importCollection(collections[0], collections.length === 1)
        })
      }
    }

    // eslint-disable-next-line jsdoc/require-jsdoc
    const _importVariables = () => {
      REQUEST.post('variable/saveVariables', {
        data: Object.values(
          variables.reduce((obj, item) => {
            obj[item.name] = item
            return obj
          }, {})
        )
      }).then(() => {
        callback(response)
      })
    }

    if (data.COLLECTION && data.VARIABLES) {
      newImportType = true
    }

    if (newImportType) {
      variables = variables.concat(data.VARIABLES || [])
      if (data.SUB_COLLECTIONS) {
        loopSubCollections(data.SUB_COLLECTIONS)
      }
      collections.push(data.COLLECTION)
    } else {
      collections.push(data)
    }

    for (let i = 0, _collection; (_collection = collections[i++]); ) {
      const oldId = _collection.id
      const collectionImport = resetIds(_collection, removeRelationIds)
      total = total + 1
      if (collectionImport) {
        mappingIds.push(oldId)
        collectionsImports.push(collectionImport)
        valid = valid + 1
      }
    }

    _importCollection(collectionsImports[0], collectionsImports.length === 1)
  }
}

/**
 * Standardeinstellungen mit den Standardwert der Checkliste
 */
ChecklistBuilder.defaults = getDefaultSettings()

export default ChecklistBuilder
export { CELLS_WITHOUT_VALUE }
