<template>
  <div
    class="cell-content-container-view"
    :data-position-x="x"
    :data-position-y="y"
    :style="visible"
  >
    <table class="cell-content-container-view-inner">
      <thead>
        <tr>
          <template v-if="hasStatusIcon">
            <th class="cell-status-header" />
          </template>
          <template v-if="isLeftButtonContainer">
            <th :style="buttonContainerStyle" />
          </template>
          <template v-if="label && labelPosition === 0">
            <th :style="`${labelWidth}`" />
          </template>
          <th
            v-for="i in clones"
            :key="`${cell.id}_header_clone_${i}`"
            class="element-content-header"
            :style="i === 1 ? contentWidth : false"
          />
          <template v-if="label && labelPosition === 1">
            <th :style="`${labelWidth}`" />
          </template>
          <template v-if="isRightButtonContainer">
            <th :style="buttonContainerStyle" />
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-if="label && labelPosition === 2">
          <tr>
            <td
              :colspan="colspan"
            >
              <div
                class="cell-label"
                :style="`${labelStyle}`"
                v-dompurify-html="label"
              />
            </td>
          </tr>
        </template>
        <tr>
          <template v-if="hasStatusIcon">
            <td class="cell-status">
              <span
                v-if="dataViewer.done"
                class="cell-done"
              >
                <v-icon>
                  mdi-check
                </v-icon>
              </span>
              <span
                v-else
                class="cell-undone"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </span>
            </td>
          </template>
          <template v-if="isLeftButtonContainer">
            <checklist-cell-button-container
              :x="x"
              :y="y"
              :cell="cell"
              :checklist="checklist"
              :count="buttonCount"
              :types="buttonTypes"
              :state="disabledState"
              :data-viewer="dataViewer"
              :data="data"
            />
          </template>
          <template v-if="label && labelPosition === 0">
            <td
              class="cell-label"
              :style="`${labelStyle}`"
              v-dompurify-html="label"
            />
          </template>
          <checklist-cell-content
            :x="x"
            :y="y"
            :cell="cell"
            :checklist="checklist"
            :state="disabledState"
            :clone="0"
            :data-viewer="dataViewer"
            :data="data"
          />
          <template
            v-if="isCloneableAndHasClones"
          >
            <template
              v-for="(clone, cloneIndex) in dataViewer.value"
            >
              <checklist-cell-content
                v-if="!isCloneMaximum(cloneIndex)"
                :key="`${cell.id}_clone_${cloneIndex}`"
                :x="x"
                :y="y"
                :cell="cell"
                :checklist="checklist"
                :state="disabledState"
                :clone="cloneIndex + 1"
                :data-viewer="dataViewer"
                :data="data"
              />
            </template>
          </template>
          <template v-if="label && labelPosition === 1">
            <td
              class="cell-label"
              :style="`${labelStyle}`"
              v-dompurify-html="label"
            />
          </template>
          <template v-if="isRightButtonContainer">
            <checklist-cell-button-container
              :x="x"
              :y="y"
              :cell="cell"
              :checklist="checklist"
              :count="buttonCount"
              :types="buttonTypes"
              :state="disabledState"
              :data-viewer="dataViewer"
              :data="data"
            />
          </template>
        </tr>
        <template v-if="label && labelPosition === 3">
          <tr>
            <td
              :colspan="colspan"
            >
              <div
                class="cell-label"
                :style="`${labelStyle}`"
                v-dompurify-html="label"
              />
            </td>
          </tr>
        </template>
        <template v-if="hasComment">
          <tr
            :style="commentStyle"
          >
            <td
              :colspan="colspan"
            >
              <checklist-cell-comment
                :x="x"
                :y="y"
                :cell="cell"
                :checklist="checklist"
                :state="disabledState"
                :data="data"
              />
            </td>
          </tr>
        </template>
        <template v-if="hasCommentSettings">
          <tr
            :style="commentSettingsStyle"
          >
            <td
              :colspan="colspan"
            >
              <checklist-cell-comment-settings
                :x="x"
                :y="y"
                :cell="cell"
                :checklist="checklist"
                :state="disabledState"
                :data-viewer="dataViewer"
                :data="data"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import ChecklistCellContent
  from '@/components/ChecklistBuilder/Viewer/Cell/Content.vue'
import ChecklistCellButtonContainer
  from '@/components/ChecklistBuilder/Viewer/Cell/Button/Container.vue'
import ChecklistCellComment
  from '@/components/ChecklistBuilder/Viewer/Cell/Comment.vue'
import ChecklistCellCommentSettings
  from '@/components/ChecklistBuilder/Viewer/Cell/CommentSettings.vue'

export default {
  name: 'ChecklistCellContainer',
  components: {
    ChecklistCellContent,
    ChecklistCellButtonContainer,
    ChecklistCellComment,
    ChecklistCellCommentSettings
  },
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'data'
  ],
  data() {
    return {
      dataViewer: {},
      visible: false
    }
  },
  asyncComputed: {
    disabledState: {
      get() {
        // TODO nach LHT state auf this.dataViewer.locked umstellen
        const locked = this.dataViewer.locked
        const builder = this.data.ChecklistBuilder
        return builder.getDisabledState(
          this.cell,
          this.checklist.index
        ).then((state) => {
          state = state || {
            classes: '',
            disabled: false
          }
          state.disabled = state.disabled || locked
          return state
        })
      },
      default: {
        classes: '',
        disabled: false
      }
    }
  },
  computed: {
    hasStatusIcon() {
      return this.cell.settings.status_icon
    },
    label() {
      return this.getLocalization(this.cell.settings, 'label')
    },
    labelPosition() {
      return parseInt(this.cell.settings.label_position) || 0
    },
    labelWidth() {
      const width = this.cell.settings.label_width || ''
      if (width) {
        return `width: ${width}; `
      }
      return ''
    },
    labelStyle() {
      const settings = this.cell.settings
      const alignH = settings.alignment_horizontal || ''
      const alignV = settings.alignment_vertical || ''
      let style = ''
      if (alignH) {
        style += `text-align: ${alignH}; `
      }
      if (alignV) {
        style += `vertical-align: ${alignV}; `
      }
      return style
    },
    buttonTypes() {
      const builder = this.data.ChecklistBuilder
      return builder.getViewerCellButtonTypes(this.cell)
    },
    buttonCount() {
      return (this.buttonTypes || []).length
    },
    buttonContainerAlignment() {
      return this.cell.settings.button_alignment || 'right'
    },
    buttonContainerStyle() {
      return `width: ${this.buttonCount * 30}px; `
    },
    isRightButtonContainer() {
      return this.buttonCount > 0 && this.buttonContainerAlignment === 'right'
    },
    isLeftButtonContainer() {
      return this.buttonCount > 0 && this.buttonContainerAlignment === 'left'
    },
    commentStyle() {
      if (!this.dataViewer.info_visible) {
        return 'display: none;'
      }
      return false
    },
    hasComment() {
      return this.buttonCount > 0 && this.cell.comment
    },
    commentSettingsStyle() {
      if (!this.dataViewer.comment_visible) {
        return 'display: none;'
      }
      return false
    },
    hasCommentSettings() {
      return this.buttonCount > 0 && this.cell.settings.comment
    },
    colspan() {
      let colspan = 1
      if (this.hasStatusIcon) {
        colspan++
      }
      if (this.buttonCount) {
        colspan++
      }
      if (this.isRightButtonContainer) {
        colspan++
      }
      if (this.isLeftButtonContainer) {
        colspan++
      }
      const value = this.dataViewer.value
      if (Array.isArray(value)) {
        const clones = value.length
        if (clones > 1) {
          colspan = colspan + clones - 1
        }
      }
      return colspan
    },
    clones() {
      const value = this.dataViewer.value
      if (!this.cell.settings.cloneable) {
        return 1
      }
      if (!Array.isArray(value)) {
        return 1
      }
      return value.length + 1
    },
    isCloneableAndHasClones() {
      const value = this.dataViewer.value
      if (!this.cell.settings.cloneable) {
        return false
      }
      if (!Array.isArray(value)) {
        return false
      }
      if (!value.length) {
        return false
      }
      return true
    },
    contentWidth() {
      const width = this.cell.settings.content_width || ''
      if (width) {
        return `width: ${width}; `
      }
      return ''
    }
  },
  watch: {
    'dataViewer.visible': {
      handler: function (newValue, oldValue) {
        this.visible = newValue ? false : 'display: none;'
      }
    }
  },
  created() {
    const builder = this.data.ChecklistBuilder
    const index = this.checklist.index
    this.dataViewer = builder.getCellDataViewer(index, this.x, this.y) || {}
  },
  methods: {
    isCloneMaximum(index) {
      const count = this.cell.settings.cloneable_count
      if (!count) {
        return false
      }
      return count === index
    },
    getLocalization(object, property) {
      return this.data.ChecklistBuilder.getLocalization(object, property)
    }
  }
}
</script>

<style>
  .checklist-viewer .checklist-content .cell-content-container-view {
    height: 100%;
  }

  .checklist-viewer .cell-content-container-view input[type="text"],
  .checklist-viewer .cell-content-container-view input[type="number"],
  .checklist-viewer .cell-content-container-view input[type="date"],
  .checklist-viewer .cell-content-container-view input[type="datetime"],
  .checklist-viewer .cell-content-container-view input[type="time"],
  .checklist-viewer .cell-content-container-view textarea,
  .checklist-viewer .cell-content-container-view select {
      width: 100%;
  }

  .checklist-viewer .cell-label {
    padding-left: 4px;
    padding-right: 4px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .checklist-viewer .cell-done {
    width: 21px;
    height: 21px;
    border: #0a0 solid 1px;
    border-radius: 3px;
    background: #0a0;
    color: #fff;
    opacity: 0.75;
    float: left;
    text-align: center;
    margin-left: 2px;
    font-size: 15px;
    line-height: 1.2;
  }

  .checklist-viewer .cell-undone {
    width: 21px;
    height: 21px;
    border: #f00 solid 1px;
    border-radius: 3px;
    background: #f00;
    color: #fff;
    opacity: 0.75;
    float: left;
    text-align: center;
    margin-left: 2px;
    font-size: 15px;
    line-height: 1.2;
  }

  .checklist-viewer .cell-content-container-view-inner > thead > tr .element-content-header {
    padding: 0px 2px;
  }

  .checklist-viewer .cell-status {
    width: 25px;
    padding: 2px;
  }

  .checklist-viewer .cell-status-header {
    width: 30px;
  }

  .checklist-viewer .cell-status i {
    font-size: 16px;
    color: #fff;
  }

  /* START: Fix for IE */
  .checklist-viewer .cell-content-container-view-inner {
    float: left;
  }
  /* END: Fix for IE */

</style>
