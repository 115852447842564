<template>
  <!-- eslint-disable -->
  <v-icon
    v-if="done"
    :class="`${classes} checklist-done`"
  >
    mdi-check
  </v-icon>
  <v-icon
    v-else-if="!done"
    :class="`${classes} checklist-undone`"
  >
    mdi-close
  </v-icon>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'ChecklistMandatoryIcon',
  props: [
    'checklist',
    'data'
  ],
  data() {
    return {
      status: {}
    }
  },
  computed: {
    classes() {
      return `mandatory-icon mandatory-icon-${this.checklist.index}`
    },
    done() {
      return this.status.mandatory_done
    }
  },
  created() {
    this.status = this.data.status[this.checklist.index]
  }
}
</script>

<style>
  .checklist-viewer .mandatory-icon.checklist-done {
    width: 28px;
    color: #0a0;
    opacity: 0.75;
    text-align: center;
    font-size: 28px;
  }

  .checklist-viewer .mandatory-icon.checklist-undone {
    width: 28px;
    color: #f00;
    opacity: 0.75;
    text-align: center;
  }
</style>
