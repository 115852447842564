<template>
  <v-container fluid>
    <v-row>
      <v-col sm="12">
        <v-skeleton-loader
          :loading='loading'
          type="table-heading, list-item, table-row-divider@8, list-item, table-row-divider@3"
          :types="{
            'table-heading': 'heading',
            'table-row': 'table-cell@4',
            'table-cell': 'text',
          }"
        >
          <div v-if="!isVisible">
            Keine Checkliste gefunden
          </div>
          <div v-else>
            <slot name="header" :checklist="data" />
            <div
              :key="`checklist-viewer-${collection.id}-${data.rebuild}`"
              class="checklist-viewer"
              data-guid="checklist_unique_id_1539244067656C"
            >
              <template v-if="!collection.hide_header">
                <h1 :class="collectionHeaderStyleClass">
                  {{ checklistBuilder.getLocalization(collection, 'label') }}
                </h1>
              </template>
              <template>
                <div class="checklist-workflow" />
              </template>
              <checklist-list
                v-if="collection.display_type === checklistBuilder.DISPLAY_TYPE_LIST"
                :data="data"
                :filter="filter"
              />
              <checklist-accordion
                v-if="collection.display_type === checklistBuilder.DISPLAY_TYPE_ACCORDION"
                :data="data"
                :filter="filter"
              />
              <checklist-tab
                v-if="collection.display_type === checklistBuilder.DISPLAY_TYPE_TABS"
                :data="data"
                :filter="filter"
              />
            </div>
            <checklist-log
              v-model="data.dialogs.log.active"
              :data="data"
              :log="data.dialogs.log.settings"
              @close="data.dialogs.log.active = false"
            />
            <slot name="footer" :checklist="data" />
          </div>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChecklistBuilder from '@/helpers/ChecklistBuilder.js'
import { ChecklistStatus } from '@/components/ChecklistBuilder/Misc/checklist-status.enum'
import ChecklistList from '@/components/ChecklistBuilder/Viewer/Checklist/List.vue'
import ChecklistTab from '@/components/ChecklistBuilder/Viewer/Checklist/Tab.vue'
import ChecklistAccordion from '@/components/ChecklistBuilder/Viewer/Checklist/Accordion.vue'
import ChecklistLog from '@/components/ChecklistBuilder/Log/ChecklistLog.vue'
import { getApp, getService } from '@/helpers/feathers'
import { Connection } from '@/offline/connection.enum'
import { Legacy, ALL } from '@nxt/permissions'
import { PermissionHelper } from '@/helpers/permission-helper'
import { RoleHelper } from '@/helpers/role-helper'
import store from '@/store'

export default {
  name: 'ChecklistViewer',
  components: {
    ChecklistList,
    ChecklistTab,
    ChecklistAccordion,
    ChecklistLog
  },
  props: [
    'settings',
    'settingsIgnoreDefault',
    'audit',
    'guid',
    'collectionId',
    'moduleId',
    'online',
    'message',
    'filter',
    'language',
    'relationData',
    'overrideValues',
    'variables'
  ],
  data() {
    return {
      lang: langkey => {
        if (!langkey) {
          return ''
        }
        const keys = (langkey || '').split('.')
        let langvalue = ((this.$i18n || {}).messages || {})[this.language]
        if (/^checklistbuilder$/i.test(keys[0])) {
          langvalue =
            ChecklistBuilder.defaults.language[this.language] ||
            ChecklistBuilder.defaults.language['en']
          keys.splice(0, 1)
        }
        if (langvalue === undefined) {
          return langkey
        }
        for (let i = 0, key; (key = keys[i]) !== undefined; i++) {
          const value = langvalue[key]
          if (value === undefined) {
            break
          }
          langvalue = value
        }
        return langvalue
      },
      loading: true,
      data: {
        rebuild: 0,
        relationData: {},
        language: 'de',
        user: {},
        collection: {},
        collectionBuild: {},
        ChecklistBuilder: {},
        disabled: false,
        correlations: [],
        message: null,
        dialogs: {
          log: {
            active: false,
            settings: {
              data: {},
              entries: []
            }
          }
        }
      }
    }
  },
  computed: {
    collectionHeaderStyleClass() {
      return 'checklist-collection-header-neo primary px-2 elevation-3 grey--text text--lighten-3'
    },
    /**
     * Gibt den ChecklistBuilder des Viewers zurück
     *
     * @returns - Instanz vom ChecklistBuilder
     */
    checklistBuilder() {
      return this.data.ChecklistBuilder
    },
    /**
     * Gibt die Struktur der Collection des Viewers zurück
     *
     * @returns Struktur der Collection
     */
    collection() {
      return this.data.collection
    },
    /**
     * Ob eine sichtbare Collection oder Checkliste im Viewer existiert
     *
     * @returns TRUE wenn sichtbar
     */
    isVisible() {
      const collection = this.collection
      const checklists = collection.Checklists
      if (!collection) {
        return false
      }
      if (!Array.isArray(checklists) || !checklists.length) {
        return false
      }
      const builder = this.data.ChecklistBuilder
      this.loading = false
      const hideInactive = builder.getSetting(
        'HIDE_INACTIVE_COLLECTION_IN_VIEWER'
      )
      if (!collection.active && hideInactive) {
        return false
      }
      return true
    },
    offlineEntries() {
      const entries = {}
      if (this.online) {
        return entries
      }
      if (this.audit) {
        this.getSubEntries(this.audit, 'tbl_au_audits', entries)
      }
      return entries
    },
    defaultSettings() {
      if (this.settingsIgnoreDefault) {
        return {}
      }
      const user = this.data.user || {}
      const audit = this.audit || {}
      return {
        ONLINE: this.online,
        WRAPPER_IS_DIALOG: false,
        TRANSLATION: this.lang,
        VALUE_SELECTOR: `audit_${audit.id}`,
        VARIABLE_SETTINGS: {
          date: new Date().toISOString(),
          /* eslint-disable prettier/prettier */
          'employee_id': user.id || 0,
          'selector_global': 'audit',
          'selector_local': `audit_${audit.id}`
          /* eslint-enable prettier/prettier */
        },
        MAPPING_ENTRIES: {
          ChecklistCollections: this.collectionId,
          /* eslint-disable prettier/prettier */
          'tbl_AU_Audits': audit.id || 0,
          'tbl_GA_Analysis': audit.analysisID || 0,
          'tbl_GA_HazardBricks': audit.hazardBrickID || 0
          /* eslint-enable prettier/prettier */
        },
        GET_ENTRIES: {
          ChecklistCollections: this.collectionId,
          /* eslint-disable prettier/prettier */
          'tbl_Mitarbeiter': user.id || 0,
          'tbl_HCM_Mitarbeiter': user.id || 0,
          'tbl_AU_Audits': audit.id || 0,
          'tbl_GA_Substance': 0,
          'tbl_HM_EMKG': 0,
          'tbl_DR_docs': 0,
          'tbl_Activities2Substances_Main': 0,
          'tbl_Locations2Substances': 0,
          'tbl_Activities2Substances': 0
          /* eslint-enable prettier/prettier */
        },
        SQL_TOKENS: {
          '[#CURRENT_USER_ID#]': user.id || 0,
          '[#EXECUTOR_ID#]': audit.executor || 0,
          '[#RESPONSIBLE_ID#]': audit.responsible || 0,
          '[#AFFECTED_EMPLOYEE_ID#]': audit.affected_employee || 0,
          '[#AUDIT_ID#]': audit.id || 0,
          '[#SUBSTANCE_ID#]': 0,
          '[#EMKG_ID#]': 0,
          '[#DIRECTIVE_ID#]': 0,
          '[#ANALYSIS_ID#]': audit.analysisID || 0,
          '[#HAZARD_BRICK_ID#]': audit.hazardBrickID || 0
        },
        ON_PROGRESS_CHANGE: progress => {
          this.$emit('progressChange', progress)
        },
        VALUE_EVENT_TEMPLATES: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          create_audit_checklist_status: {
            callback: async data => {
              if (!data.status.done) {
                return
              }

              const listStatus = this.data.status[data.index] || {}
              if (!listStatus.visible) {
                return
              }

              // benötigte Offline Services laden
              const servicePermission = await getService(
                'checklists/permissions',
                Connection.Offline
              )
              const serviceStatus = await getService(
                'checklists/status',
                Connection.Offline
              )

              // Vorhandene Einträge holen
              const existingPermissions = await servicePermission
                .get(audit.id)
                .catch(() => null)
              const existingStatus = await serviceStatus
                .get(audit.id)
                .catch(() => null)

              const builder = this.checklistBuilder
              const listRequiredBy = listStatus.required_by || []
              const unlockedLists = (existingPermissions || {}).listStatus || []
              const checklistStatus = { id: audit.id }
              const eventData = data.event_data || {}
              const visibleAndEditableOnly = eventData.visible_and_editable_only
              const _affectedEmployee = eventData.affected_employee > 0
              const _responsible = eventData.responsible > 0
              const _executor = eventData.executor > 0
              const _filterByLocations = !!eventData.filter_by_locations
              const _allLevels = !!eventData.match_all_levels
              const _skipLevels = parseInt(eventData.match_skip_levels) || 0
              const _extend = builder.extend()

              for (const key in existingStatus) {
                const checklistID = parseInt(key)
                if (checklistID > 0) {
                  checklistStatus[checklistID] = existingStatus[key]
                }
              }

              /**
               * Lokale Hilfsfunktion: Gibt Array mit einer Schnittmenge zweier
               * Arrays zurück
               *
               * @param array1 - Array was gefiltert werden soll
               * @param array2 - Array was als Filter verwendet werden soll
               * @returns Neues Array mit nur Einträgen die Überschneidungen
               */
              const arrayIntersections = (array1, array2) => {
                const _result = []
                if (
                  !Array.isArray(array1) ||
                  !array1.length ||
                  !Array.isArray(array2) ||
                  !array2.length
                ) {
                  return _result
                }

                for (let i = 0, ilength = array1.length; i < ilength; i++) {
                  const _value = array1[i]
                  if (
                    _value !== undefined &&
                    _value !== null &&
                    array2.indexOf(_value) > -1
                  ) {
                    _result.push(_value)
                  }
                }
                return _result
              }

              for (
                let _index, i = 0;
                (_index = listRequiredBy[i++]) !== undefined;

              ) {
                const replacements = {
                  [data.index]: {
                    [data.data_editor.x]: {
                      [data.data_editor.y]: {
                        done: data.status.done
                      }
                    }
                  }
                }
                let listVisible = null
                await builder
                  .checkChecklistVisibilityNoRights(_index, replacements)
                  .then(result => {
                    listVisible = result
                  })

                if (listVisible && checklistStatus[listVisible.id] === undefined) {
                  let visibilityRights
                  let editabilityRights
                  const extraInfo = listVisible.extra_info
                  let temp

                  if (extraInfo) {
                    if (
                      Array.isArray(listVisible.visibility_rights) &&
                      listVisible.visibility_rights.length &&
                      (temp = extraInfo.visible) &&
                      ((Array.isArray(temp.employees) &&
                        temp.employees.length) ||
                        (Array.isArray(temp.roles) && temp.roles.length))
                    ) {
                      visibilityRights = _extend(
                        true,
                        {},
                        listVisible.extra_info.visible || {}
                      )
                    }

                    if (
                      Array.isArray(listVisible.editability_rights) &&
                      listVisible.editability_rights.length &&
                      (temp = extraInfo.editable) &&
                      ((Array.isArray(temp.employees) &&
                        temp.employees.length) ||
                        (Array.isArray(temp.roles) && temp.roles.length))
                    ) {
                      editabilityRights = _extend(
                        true,
                        {},
                        listVisible.extra_info.editable || {}
                      )
                    }
                  }

                  if (visibleAndEditableOnly) {
                    editabilityRights = editabilityRights || {}
                    editabilityRights.roles = arrayIntersections(
                      visibilityRights.roles,
                      editabilityRights.roles
                    )
                    editabilityRights.employees = arrayIntersections(
                      visibilityRights.employees,
                      editabilityRights.employees
                    )

                    visibilityRights = visibilityRights || {}
                    visibilityRights.roles = editabilityRights.roles
                    visibilityRights.employees = editabilityRights.employees
                  }

                  unlockedLists.push({
                    id: listVisible.id,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    visibility_rights: visibilityRights,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    editability_rights: editabilityRights,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    affected_employee: _affectedEmployee,
                    responsible: _responsible,
                    executor: _executor,
                    // NOTIZ:  Muss für Online-Modus noch angepasst werden
                    // zu LocationID aus tbl_MappingIDs
                    locationID: parseInt(audit.checklistLocationID) || 0,
                    filterByLocations: _filterByLocations,
                    allLevels: _allLevels,
                    skipLevels: _skipLevels
                  })

                  checklistStatus[listVisible.id] =
                    ChecklistStatus.UndoneVisible
                }
              }
              if (unlockedLists.length) {
                // Vorhandene Einträge entfernen und mögliche Fehler ignorieren
                await servicePermission.remove(audit.id).catch(() => null)
                await serviceStatus.remove(audit.id).catch(() => null)

                // Neue Einträge erstellen und passende Meldung ausgeben
                let isSuccess = 0
                try {
                  await servicePermission.create({
                    id: audit.id,
                    auditID: audit.id,
                    listStatus: unlockedLists
                  })
                  await serviceStatus.create(checklistStatus)

                  isSuccess = 1
                } finally {
                  const key =
                    'checklist_editor.value_events.checklist_status_response.' +
                    isSuccess.toString()
                  const msg = this.lang(key)
                  if (msg !== key) {
                    this.checklistBuilder.showMessage('information', msg)
                  }
                }
              }
            }
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          set_audit_checklist_status: {
            callback: async data => {
              const eventData = data.event_data || {}
              const status = eventData.checklist_status
              const list = this.checklistBuilder.getChecklistData(
                eventData.checklist_index
              )

              if (list && list.id && status && ChecklistStatus[status]) {
                const service = await getService(
                  'checklists/status',
                  Connection.Offline
                )

                let isFailed = true
                try {
                  const auditStatus = await service.get(audit.id)

                  if (auditStatus[list.id] && auditStatus[list.id] !== status) {
                    const result = await service.patch(audit.id, {
                      [list.id]: status
                    })

                    if (result && result[list.id] === status) {
                      isFailed = false
                    }
                  }
                } finally {
                  if (isFailed) {
                    const key =
                      'checklist_editor.value_events.set_checklist_status_error'
                    const msg = this.lang(key)

                    if (msg !== key) {
                      this.checklistBuilder.showMessage('error', msg)
                    }
                  }
                }
              }
            }
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          delete_audit_checklist_status: {
            callback: async data => {
              const eventData = data.event_data || {}
              const list = this.checklistBuilder.getChecklistData(
                eventData.checklist_index
              )

              if (list && list.id) {
                const auditID = audit.id
                const servicePermissions = await getService(
                  'checklists/permissions',
                  Connection.Offline
                )
                const serviceStatus = await getService(
                  'checklists/status',
                  Connection.Offline
                )

                const permissions = await servicePermissions.get(auditID)
                const status = await serviceStatus.get(auditID)
                const cleanupListStatus = []

                for (
                  let entry, i = 0;
                  (entry = permissions.listStatus[i++]);

                ) {
                  if (entry.id !== list.id) {
                    cleanupListStatus.push(entry)
                  }
                }

                let isSuccess = false
                try {
                  if (cleanupListStatus.length) {
                    if (status[list.id]) {
                      delete status[list.id]
                    }

                    await Promise.all([
                      serviceStatus.update(auditID, status),
                      servicePermissions.patch(auditID, {
                        listStatus: cleanupListStatus
                      })
                    ])
                  } else {
                    await Promise.all([
                      serviceStatus.remove(auditID),
                      servicePermissions.remove(auditID)
                    ])
                  }

                  isSuccess = true
                } finally {
                  if (!isSuccess) {
                    const key =
                      'checklist_editor.value_events.delete_checklist_status_error'
                    const msg = this.lang(key)

                    if (msg !== key) {
                      this.checklistBuilder.showMessage('error', msg)
                    }
                  }
                }
              }
            }
          },
          close_dialog: {
            callback: async data => {
              // Momentan keine Aktion benötigt,
              // weil der ChecklistViewer nicht in einem Dialog geladen wird
              return
            }
          },
          redirect_to_audit_list: {
            callback: async data => {
              // Momentan nur Weiterleitung auf Liste der Offline-Audits,
              // da Online-Audits im Legacy-Eplas geöffnet werden
              this.$router.push({
                name: 'audit_list',
                params: {
                  content: 'offline'
                }
              })
            }
          }
        },
        CHECKLIST_VISIBILITY_RIGHTS: {
          employee: {
            label: this.lang('checklist_editor.visibility_rights.employee'),
            right: list => {
              const info = ((list || {}).extra_info || {}).visible || {}
              const employees = info.employees || []
              if (employees.indexOf(user.id) === -1) {
                return false
              }
              return true
            }
          },
          role: {
            label: this.lang('checklist_editor.visibility_rights.role'),
            right: list => {
              const info = ((list || {}).extra_info || {}).visible || {}
              const roles = info.roles || []
              if (roles.length) {
                for (let roleID, ri = 0; (roleID = roles[ri++]) !== undefined;) {
                  if (RoleHelper.hasRole({
                    id: roleID
                  })) {
                    return true
                  }
                }
                return false
              }
              return true
            }
          },
          'audit_responsible': {
            label: this.lang('checklist_editor.visibility_rights.audit_responsible'),
            right: () => {
              if (
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission66,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission67,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission68,
                  mask: ALL
                }) ||
                audit.responsible === user.id
              ) {
                return true
              }
              return false
            }
          },
          'audit_executor': {
            label: this.lang('checklist_editor.visibility_rights.audit_executor'),
            right: () => {
              if (
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission66,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission67,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission68,
                  mask: ALL
                }) ||
                audit.executor === user.id
              ) {
                return true
              }
              return false
            }
          },
          'audit_affected_employee': {
            label: this.lang('checklist_editor.visibility_rights.audit_affected_employee'),
            right: () => {
              if (
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission66,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission67,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission68,
                  mask: ALL
                }) ||
                audit.affected_employee === user.id
              ) {
                return true
              }
              return false
            }
          }
        },
        CHECKLIST_EDITABILITY_RIGHTS: {
          employee: {
            label: this.lang('checklist_editor.visibility_rights.employee'),
            right: (list) => {
              const info = ((list || {}).extra_info || {}).editable || {}
              const employees = info.employees || []
              if (employees.indexOf(user.id) === -1) {
                return false
              }
              return true
            }
          },
          role: {
            label: this.lang('checklist_editor.visibility_rights.role'),
            right: (list) => {
              const info = ((list || {}).extra_info || {}).editable || {}
              const roles = info.roles || []
              if (roles.length) {
                for (let roleID, ri = 0; (roleID = roles[ri++]) !== undefined;) {
                  if (RoleHelper.hasRole({
                    id: roleID
                  })) {
                    return true
                  }
                }
                return false
              }
              return true
            }
          },
          'audit_responsible_details': {
            label: this.lang('checklist_editor.visibility_rights.audit_responsible_details'),
            right: () => {
              if (audit.responsible === user.id) {
                return true
              } else {
                let participants = audit.participants || '[]'
                try {
                  participants = JSON.parse(participants)
                } catch (ex) {
                  participants = []
                }
                for (let i = 0, participant; (participant = participants[i]); i++) {
                  if (~~participant.id !== user.id) {
                    continue
                  }
                  if (~~participant.rights > 3) {
                    return true
                  }
                }
                return false
              }
            }
          },
          'audit_responsible': {
            label: this.lang('checklist_editor.visibility_rights.audit_responsible'),
            right: () => {
              if (
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission66,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission67,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission68,
                  mask: ALL
                }) ||
                audit.responsible === user.id
              ) {
                return true
              }
              return false
            }
          },
          'audit_executor': {
            label: this.lang('checklist_editor.visibility_rights.audit_executor'),
            right: () => {
              if (
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission66,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission67,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission68,
                  mask: ALL
                }) ||
                audit.executor === user.id
              ) {
                return true
              }
              return false
            }
          },
          'audit_affected_employee': {
            label: this.lang('checklist_editor.visibility_rights.audit_affected_employee'),
            right: () => {
              if (
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission66,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission67,
                  mask: ALL
                }) ||
                PermissionHelper.hasPermission({
                  permission: Legacy.Permission68,
                  mask: ALL
                }) ||
                audit.affected_employee === user.id
              ) {
                return true
              }
              return false
            }
          }
        },
        ON_VALUE_SAVED: entry => {},
        ON_CHECKLIST_FULLY_LOADED: status => {
          if (status) {
            this.$emit('loadingEnd')
          }
        }
      }
    }
  },
  watch: {
    guid: function() {
      this.loadData(this.collectionId || 0, this.moduleId || 0)
    },
    overrideValues() {
      this.loadData(this.collectionId || 0, this.moduleId || 0)
    },
    variables() {
      this.loadData(this.collectionId || 0, this.moduleId || 0)
    },
    language() {
      this.loadData(this.collectionId || 0, this.moduleId || 0)
    }
  },
  async mounted() {
    this.data.relationData = this.relationData || {}
    /* this.$axios.get('employee/getEmployeeRolesAndRights', {
      params: {}
    }).then((result) => {
      this.data.user = result.data.data || {}
      this.loadData(this.collectionId || 0, this.moduleId || 0)
    }) */
    this.data.user.id = await store.getters['user/id']
    this.loadData(this.collectionId || 0, this.moduleId || 0)
  },
  methods: {
    /**
     * Notiz: Kopie in ChecklistModel
     * sollte immer gleich sein.
     * @param {object} entry Wert einer Eigenschaft
     * @param {string} path Pfad der Eigenschaft
     * @param {object} result Objekt mit allen Werten der Einträge.
     * Eigenschaften sind immer in Kleinbuchstaben.
     * Werte sind immer Strings.
     * Beispiel: {tbl_au_audits.id: '42'}
     * @returns {undefined}
     */
    getSubEntries(entry, path, result) {
      if (entry && (typeof entry === 'object' || Array.isArray(entry))) {
        for (const subkey in entry) {
          if (entry.hasOwnProperty(subkey)) {
            const _data = entry[subkey]
            const _path = (path + '.' + subkey.toLowerCase())
            this.getSubEntries(_data, _path, result)
          }
        }
      } else if (entry === null || entry === undefined) {
        result[path] = ''
      } else {
        result[path] = entry.toString()
      }
    },
    /**
     * Audit in der Offline-Datenbank speichern. Die übergebenen Audit-Daten
     * werden immer als die Neusten betrachtet, entsprechend wird die Offline-
     * Variante mit diesen gepacht. Nicht vorhandene Einträge bekommen eine
     * entsprechende neue ID von der Offline-ID übergeben. Diese darf nicht
     * in die Online-Datenbank übernommen werden!
     */
    async updateOfflineAudit() {
      if (this.audit) {
        const audit = this.audit
        const service = await getService('audits/offline', Connection.Offline)
        if (audit.id > 0) {
          await service.get(audit.id).then(
            entry => {
              service.patch(entry.id, audit)
            },
            () => {
              service.create(audit)
            }
          )
        } else {
          audit.id = 0
          this.audit = await service.create(audit)
        }
      }
    },
    /**
     * Lädt und zeigt die angegebene Checklist-Collektion an.
     *
     * @param collectionId - ID der Collection
     * @param moduleId - ID des Checklisten-Modules
     **/
    loadData(collectionId, moduleId) {
      this.loading = true
      this.updateOfflineAudit().then(() => {
        this.$emit('loadingStart')
        const builder = new ChecklistBuilder(this.data, this.defaultSettings)
        if (this.settings) {
          builder.modifySettings(this.settings)
        }
        this.data.message = this.message || {}
        if (!this.online) {
          /* const offlineData = store.getters['checklist/values']
          const offlineValues = offlineData[this.guid || 248] || {}
          const cellValues = offlineValues.cells || {}
          const variableValues = offlineValues.variables || {}
          builder.setOfflineData({
            collection: (() => {
              if (Array.isArray(this.collections)) {
                for (let i = 0, collection; (collection = this.collections[i++]);) {
                  if (collectionId === collection.id) {
                    return collection
                  }
                }
              }
            })() || {},
            variables: this.variables || {},
            variableValues: variableValues,
            entries: this.offlineEntries,
            values: cellValues
          }) */
        }

        builder.loadViewer(
          collectionId,
          moduleId,
          this.overrideValues,
          this.variables
        ).catch(() => { this.loading = false })
        this.$emit('builder', builder)
      })
    },
    /*
    * @param {number} checklistId Wenn eine ID übergeben wird,
    * werden nur die Zellen für diese Checkliste neu ausgewertet.
    * Falls keine ID übergeben wird, werden alle Zellen aller Checklisten
    * der Collection neu ausgewertet.
    * @param {boolean} saveAllVariables Vor der Neuauswertung der Werte,
    * werden alle Variablen gespeichert.
    **/
    revaluateCells(checklistId, saveAllVariables) {
      const builder = this.data.ChecklistBuilder
      builder.revaluateCells(checklistId, saveAllVariables)
    }
  }
}
</script>

<style>
  .checklist-viewer {
    font-size: 14px;
  }

  .checklist-viewer, {
    color: #000 !important;
  }

  .checklist-viewer .disabled-content-custom,
  .checklist-viewer .disabled-content,
  .checklist-viewer .disabled-cell,
  .checklist-viewer .disabled-checklist,
  .checklist-viewer .disabled-collection:not(a):not(li) {
      opacity: 0.35;
  }

  .checklist-viewer input,
  .checklist-viewer select,
  .checklist-viewer textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    resize: vertical;
    color: #000;
  }

  .checklist-viewer textarea {
    resize: none;
    font-family: Trebuchet MS,Tahoma,Verdana,Arial,sans-serif;
    overflow-x: hidden;
  }

  .checklist-viewer .autosize-textarea {
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    vertical-align: middle;
    -webkit-transition: height 0.5s ease;
    -moz-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: height 0.5s ease;
  }

  .cell_type_checkbox .v-input .v-messages {
    display: none;
  }

  .cell_type_checkbox .v-input .v-input--selection-controls__input {
    margin-right: 0;
  }

  .checklist-viewer .element-content button {
    display: inline-block;
    min-height: 28px;
  }

  .checklist-viewer .element-content button.no-clip,
  .cell_type_location button.v-btn,
  .cell_type_department button.v-btn ,
  .cell_type_activity button.v-btn {
    height: initial !IMPORTANT;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .checklist-viewer .element-content button.no-clip > span,
  .cell_type_location button.v-btn > span,
  .cell_type_department button.v-btn > span,
  .cell_type_activity button.v-btn > span {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .cell_type_location button.v-btn,
  .cell_type_department button.v-btn,
  .cell_type_activity button.v-btn {
    padding-top: 9px !IMPORTANT;
    padding-bottom: 9px !IMPORTANT;
  }
</style>
