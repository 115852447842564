/**
 * Interface für die Standardeinstellungen der Zelltypen.
 * Die Stuktur ist Folgende: Zelltyp => Standardwerte Einstellungen
 */
export interface ListDefaultCellTypeSettting {
  [key: string]: ItemDefaultCellTypeSettting
}

/**
 * Interface für einen Zelltypen mit dessen Standardeinstellungen.
 * Es können hier noch Einstellungen fehlen! Fehlende Einträge also ergänzen!
 */
export interface ItemDefaultCellTypeSettting {
  /**
   * Die gesetzten/verwendeten Einstellungen der Zelle
   */
  settings?: object

  /**
   * Standardwert für die Zelle
   */
  preset_value?: unknown
}

/**
 * Gibt die Standardeinstellungen für jeden Zelltyp zurück.
 * Diese werden von uns festgesetzt und werden bei dem Erstellen der Checkliste
 * überschrieben.
 *
 * @returns alle Zelltypen mit ihren Standardeinstellungen
 */
export function getDefaultCellTypeSetttings(): ListDefaultCellTypeSettting {
  // CamelCase für den Inhalt dieser Funktion deaktiviert. Denn diese gibt nur
  // Werte für die Wegwerf-Checkliste zurück.
  // Anpassung dieser Werte an die neuen Standards würde nur unnötigen
  // Zeitaufwand bedeuten.
  return {
    create: {
      settings: {
        count: false
      }
    },
    empty: {
      settings: {
        count: false
      }
    },
    label: {
      settings: {
        count: false,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_short: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_long: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        textarea_fixed_rows_count: 1
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_formatted: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_display: {
      settings: {
        count: false
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_display_formatted: {
      settings: {
        count: false
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_replace: {
      settings: {
        count: false,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_replace_formatted: {
      settings: {
        count: false
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_email_formatted: {},
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_variables_formatted: {
      settings: {
        count: false
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_integer: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle',
        step: 1
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_float: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle',
        step: 0.0001
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    document_management: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    dropdown: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dropdown_sql: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    multiselect_sql: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    multiselect: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    multiselect_open: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    slider: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      preset_value: '50',
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle',
        min: 0,
        max: 100,
        step: 1,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        slider_min_hidden: false,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        slider_max_hidden: false,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        slider_value_hidden: false
      }
    },
    checkbox: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    date_time: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    date: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    time: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    countdown: {
      settings: {
        count: false,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        countdown_expired: '0'
      }
    },
    radio: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        radio_btn_label_1: '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        radio_btn_label_2: ''
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_amount: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_amount_dates: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    combine_selects: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        inner_label_0: '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        inner_label_1: '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        inner_label_2: ''
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    combine_selects_custom: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        inner_label_0: '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        inner_label_1: '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        inner_label_2: ''
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    custom_button: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    employee: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    time_recording: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      preset_value: JSON.stringify({
        started: false,
        stopped: false,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        start_timestamp: null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        start_date_time: null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        start_date: null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        start_time: null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        stop_timestamp: null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        stop_date_time: null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        stop_date: null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        stop_time: null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        result_timestamp: null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        result_time: '00:00:00',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        result_hours: 0,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        result_minutes: 0,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        result_seconds: 0
      }),
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_code: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        text_code_input_0: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        text_code_input_0_length: 4
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    external_content: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    image: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    drawing_board: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        drawing_width: 0,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        drawing_height: 0,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        drawing_board_canvas_width: 500,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        drawing_board_canvas_height: 300,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        drawing_board_stroke_color: '#000000',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        drawing_board_stroke_size: 3,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        drawing_board_stroke_type: 2
      }
    },
    tree: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    employee_search: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    task_tracking: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle'
      }
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    multiselect_radio: {
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_horizontal: 'left',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        alignment_vertical: 'middle',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        multiselect_radio_builder: [],
        // eslint-disable-next-line @typescript-eslint/naming-convention
        multiselect_radio_max_selected: 1
      }
    }
  }
}
