<template>
  <div
    data-toggable-view="show_info"
    class="element-content-extra"
    v-dompurify-html="comment"
  />
</template>

<script>
export default {
  name: 'ChecklistCellComment',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'data'
  ],
  computed: {
    comment() {
      return this.getLocalization(this.cell, 'comment') || ''
    }
  },
  methods: {
    getLocalization(object, property) {
      return this.data.ChecklistBuilder.getLocalization(object, property)
    }
  }
}
</script>
