<template>
  <!-- eslint-disable -->
  <component
    :is="loadComponent"
    :x="x"
    :y="y"
    :cell="cell"
    :checklist="checklist"
    :state="state"
    :buttontype="buttontype"
    :data-viewer="dataViewer"
    :data="data"
  />
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable vue/no-async-in-computed-properties */

import ChecklistCellButtonTypeDefault
  from '@/components/ChecklistBuilder/Viewer/Cell/Button/Type/Default.vue'

export default {
  name: 'ChecklistCellButtonContent',
  components: {
    ChecklistCellButtonTypeDefault
  },
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'buttontype',
    'dataViewer',
    'data'
  ],
  computed: {
    loadComponent() {
      const type = this.buttontype
      return () => import(`./Type/${type}.vue`).catch(() => ({
        render: h => h(ChecklistCellButtonTypeDefault, {
          props: {
            x: this.x,
            y: this.y,
            cell: this.cell,
            checklist: this.checklist,
            state: this.state,
            buttontype: this.buttontype
          }
        })
      }))
    }
  },
  methods: {
    getLocalization(object, property) {
      return this.data.ChecklistBuilder.getLocalization(object, property)
    }
  }
}
</script>
