/**
 * Mapping-Tabelle für die Standardeinstellungen
 */
export interface MappingTable {
  [key: string]: unknown
}

/**
 * Alle Einstellung von einem Item
 */
export interface DefaultSettings {
  /**
   * Standardeinstellungen für die Worfklows
   */
  workflow: MappingTable

  /**
   * Mapping: Local-Code zu Sprache
   */
  locale_to_language: MappingTable

  /**
   * Mapping: Sprache zu Local-Code
   */
  language_to_locale: MappingTable

  /**
   * Alle Sprachstrings der Checkliste - aus dem alten System!
   */
  language: object
}

/**
 * Einstellung für die Anzeige der Buttons in der Kollektion (?)
 *
 * @returns Liste mit den Einstellungen
 */
export function getDefaultSettings(): DefaultSettings {
  // CamelCase für den Inhalt dieser Funktion deaktiviert. Den diese gibt nur
  // Werte für die Wegwerf-Checkliste zurück.
  // Anpassung dieser Werte an diese an die neuen  Standards anzupassen, würde
  // nur unnötigen Zeitaufwand bedeuten.
  return {
    workflow: {
      containerHeight: undefined,
      containerWidth: undefined,
      autoAdjustContainerHeight: true,
      autoAdjustContainerWidth: false,
      rectangleSize: [100, 50],
      rectangleRadius: [10, 10],
      spacing: [20, 100],
      markerHidden: true,
      defaultScale: 100,
      groupSingleNodeTrees: true,
      hideNodeLabelOverflow: true,
      updateOnlySVG: false,
      nodeClick: undefined,
      rectangleStyle: {
        fill: '#45B6AF',
        stroke: '#000',
        'stroke-width': 2
      },
      textStyle: {
        'font-size': '12px',
        size: 12,
        fill: '#000'
      },
      scrollbarX: true,
      scrollbarY: false,
      markerStroke: { width: 1, color: '#000' },
      pathStroke: { width: 2, color: '#000' },
      returnPathStroke: { width: 2, color: '#FF0000' },
      returnMarkerStroke: { width: 1, color: '#FF0000' },
      elementCallback: (): void => {},
      data: []
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    locale_to_language: {
      'de-AT': 'de',
      'de-CH': 'de',
      'de-DE': 'de',
      'en-GB': 'en',
      'en-US': 'en',
      'cs-CZ': 'cs',
      'fr-FR': 'fr',
      'hu-HU': 'hu',
      'nl-NL': 'nl',
      'pl-PL': 'pl',
      'ro-RO': 'ro',
      'ru-RU': 'ru'
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    language_to_locale: {
      de: 'de-DE',
      en: 'en-US',
      cs: 'cs-CZ',
      fr: 'fr-FR',
      hu: 'hu-HU',
      nl: 'nl-NL',
      pl: 'pl-PL',
      ro: 'ro-RO',
      ru: 'ru-RU'
    },
    language: {
      en: {
        locales: {
          'de-AT': 'Austrian',
          'de-CH': 'Swiss',
          'de-DE': 'German',
          'en-GB': 'English',
          'en-US': 'English',
          'cs-CZ': 'Czech',
          'fr-FR': 'French',
          'hu-HU': 'Hungarian',
          'nl-NL': 'Dutch',
          'pl-PL': 'Polish',
          'ro-RO': 'Romanian',
          'ru-RU': 'Russian'
        },
        tables: {
          variables: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            loading_failed: 'Failed loading variable table.'
          }
        },
        templates: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_links: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_0: 'Sent',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_1: 'Opened',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_2: 'Edited',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_3: 'Declined',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_4: 'Done'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          display_type: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            linked_list: 'Linked List',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            linked_accordion: 'Linked Accordion',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            linked_tabs: 'Linked Tabs'
          },
          dropdown: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_0: 'Please select...',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_1: 'Please select.../Yes/No',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_2: 'Not applicable.../Yes/No',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_3: 'Unknown/Yes/No',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_4: 'No template',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_loading: 'Loading content...',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_no_data: 'No data...',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_0: 'Please select...',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_1: 'Not applicable...',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_2: 'Unknown',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_3: 'Yes',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_4: 'No',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_5: 'Top',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_6: 'Middle',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_7: 'Bottom',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_8: 'Left',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_9: 'Center',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_10: 'Right',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_11: 'None',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_12: 'Input',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_13: 'Multiselect',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_14: 'Both',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_15: 'Lock nothing',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_16: 'Lock collection',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_17: 'Lock checklist',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_18: 'Input (Select only)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_19: 'Standard Localization',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_20: 'Text (a-Z, 0-9)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_21: 'Number (0-9)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_22: 'Hexadecimal (0-F)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_23: 'Binary (0-1)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            print_format_0: 'Vertical format',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            print_format_1: 'Horizontal format',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_type_1: 'Local',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_type_2: 'Global',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_0: '-',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_1: 'Employee',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_2: 'Year',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_3: 'Year & Employee',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_4: 'Month & Year',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_5: 'Month & Year & Employee',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_6: 'Collection & Employee',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_value_changed_default: '-',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_type_0: ' - ',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_type_1: 'In same Tab',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_type_2: 'In new Tab',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_type_3: 'In same Tab with Header',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            optgroup_categories_assigned: 'Assigned to module',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            optgroup_categories_unassigned: 'Assigned to no module',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_display_type_0: 'Normal',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_display_type_1: 'Warning',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_display_type_2: 'Info',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_display_type_3: 'Danger',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_checklist_status_1: 'Undone',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_checklist_status_2: 'Visible',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_checklist_status_3: 'Undone & Visible',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_checklist_status_4: 'Done',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_checklist_status_6: 'Done & Visible',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_frame_0: 'None',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_frame_1: 'Full',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_frame_2: 'Bottom',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_type_1: 'Line',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_type_2: 'Circle',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_type_3: 'Square',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_type_4: 'Eraser',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_0: 'Default',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_1: 'Text',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_2: 'List (comma separated)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_3: 'List (new line)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_4: 'List (comma separated, new line)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_5: 'List (bullet points)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_6: 'Slider (hidden button)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ics_event: 'Event',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ics_invite: 'Invite'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          dropdown_groups: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_manually_added: 'Added Entries',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            default_dropdowns: 'Default Select Templates',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_dropdowns: 'Custom Select Templates',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            default_sql: 'Default SQl Templates',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sql: 'Custom SQL Templates'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          value_events: {
            completed: 'Completed',
            warning: 'Warning',
            abort: 'Abort',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            requires_action: 'Require Action',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_modify: 'Modify variable values',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            change_background_color: 'Change background color',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            change_outline_color: 'Change outline color',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            set_audit_interval: 'Set audit interval',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            set_audit_affected_employee: 'Set affected employee in audit',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_audit_checklist_status: 'Create audit checklist status',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            set_audit_checklist_status: 'Set audit checklist status',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_audit_checklist_status: 'Delete audit checklist status',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            lock_cell: 'Lock cell',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_cell_values: 'Reset/delete values in cells',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            trigger_cell_events: 'Trigger cell events',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hide_cell: 'Hide cell content',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            set_dbentry_value: 'Set value for database entry',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_dbentry_value: 'Get value of database entry',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            date_comparison: 'Date comparison',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_audit: 'Create audit',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_new_substance_version: 'Create new substance version',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_actions: 'Create actions',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_employee: 'Create employee',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            reload_sql: 'Reload SQL-Request',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            apply_sql_result_to_variable: 'Apply SQL-Result to Variable',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            lock_collection: 'Lock collection',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_appointment: 'Make appointment',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_appointment: 'Remove appointment',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            set_audit_locations: 'Set Audit locations',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            add_csv_entry: 'Add CSV entry',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_outlook_appointment_via_email:
              'Create Outlook appointment via E-Mail'
          },
          documents: {
            document: 'Document',
            relevant: 'Relevant?',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            file_name: 'File name',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_file: 'Generate/Download',
            received: 'Received',
            send: 'Generated',
            comment: 'Comment',
            status: 'Status'
          },
          variables: {
            name: 'Name',
            alias: 'Alias',
            type: 'Type',
            reference: 'Reference',
            limit: 'Limit',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            default_value: 'Default Value',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            fractional_digits: 'Fractional digits',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ignore_selectors: 'Ignore selectors',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            in_database: 'In database'
          },
          options: {
            label: 'Description:',
            category: 'Category:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_type: 'Display Type:',
            module: 'Module:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            confirm_delete: 'Confirm Delete:',
            active: 'Active:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            offline_available: 'Offline available:',
            requirement: 'Requirement:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            required_progress: 'Required Progress:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            requirement_matrix: 'Requirement Matrix:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return_paths: 'Workflow Return Paths:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            print_format: 'Format:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            print_new_page: 'Print on new page:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            extra_info: 'Additional Information:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            copy_cells: 'Copy cells into cache:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            paste_cells: 'Paste cells from cache:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            extra_info_rights: 'Right Assignation:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            visibility_rights: 'Required rights for display:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            editability_rights: 'Required rights for edit:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_type: 'Cloning:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_maximum: 'Maximum number of clones:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_create: 'Create clones:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            backup_add: 'Add Temporary Backup:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            backup_load: 'Load Temporary Backup:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hide_header: 'Hide header:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_viewer_workflow: 'Show workflow in Viewer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_printer_workflow: 'Show workflow in Printer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            print_format_workflow: 'Display format of workflow in Printer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_printer_hidden_checklists: 'Display hidden checklists:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_printer_hidden_cells: 'Display hidden cells:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_in_printer_only: 'Show in Printer only:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hideInPrinter: 'Hide in Printer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_in_workflow: 'Show in Workflow:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            unlock_group: 'Unlock if group is partially done:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            unlock_group_partial: 'Partial Group Unlock:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            workflow_node_type: 'Workflow node type:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_values_on_hide: 'Delete values on hiding Checklist:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            default_module_collection: 'Default checklist for module:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            localization: 'Localization:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            activate_checklist_tab_on_unlock:
              'Activate checklist tab on unlock:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hide_cell_borders: 'Hide cell borders:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            checklist_rows_colored: 'Alternating colors per checklist row:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ignore_visibility_rights: 'Ignore visibility rights:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ignore_editability_rights: 'Ignore editability rights:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ignore_workflow_rights: 'Ignore workflow rights:',
            offline: 'Offline',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            overlay_save: 'Show Overlay while saving Data:'
          },
          elements: {
            create: 'Create Element',
            empty: 'Empty',
            label: 'Label & Header',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_short: 'Text (Short)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_long: 'Text (Long)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_formatted: 'Text (Formatted)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_display: 'Text Display (HTML)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_display_formatted: 'Text Display (Formatted)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_replace: 'Text Replace',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_replace_formatted: 'Text Replace (Formatted)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_email_formatted: 'E-Mail (Formatted)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_variables_formatted: 'Variable Display (Formatted)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            number_integer: 'Number (Integer)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            number_float: 'Number (Float)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management: 'Document Management',
            dropdown: 'Dropdown',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            dropdown_sql: 'Dropdown (SQL)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            multiselect_sql: 'Multiselect (SQL)',
            multiselect: 'Multiselect',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            multiselect_open: 'Multiselect (Open)',
            slider: 'Slider',
            checkbox: 'Checkbox',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            date_time: 'Date & Time',
            date: 'Date',
            time: 'Time',
            radio: 'Radio Buttons',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            total_amount: 'Total Amount',
            countdown: 'Countdown',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            combine_selects: 'Combination Field (Select)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            combine_selects_custom: 'Combination Field (Enhanced)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button: 'Button',
            employee: 'Employee Picker',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            time_recording: 'Time Recording',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code: 'Text (Code)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            external_content: 'External Content',
            image: 'Image',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            total_amount_dates: 'Total Amount (Date)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board: 'Drawing Board',
            tree: 'Tree',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search: 'Employee Search',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking: 'Task Tracking'
          },
          settings: {
            inactive: 'Inactive:',
            cloneable: 'Cloneable:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cloneable_count: 'Maximum clones:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            font_size: 'Font Size:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            font_bold: 'Font Bold:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            font_italic: 'Font Italic:',
            label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_position: 'Label Position:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_width: 'Label Width:',
            count: 'Count for progress:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            count_hidden: 'Count Hidden Field:',
            files: 'Attach files:',
            actions: 'Attach actions:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            actions_type: 'Show type select:',
            comment: 'Attach comment:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            adopt_value: 'Adopt value:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_events: 'Value events:',
            min: 'Minimum:',
            max: 'Maximum:',
            step: 'Step:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            slider_min_hidden: 'Hide minimum:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            slider_max_hidden: 'Hide maximum:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            slider_value_hidden: 'Hide value:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            slider_value_custom_look: 'Highlight value:',
            sql: 'SQL:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_builder: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              template_management: 'Template Management',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              placeholder_label: 'Template Label',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              button_title_apply: 'Apply Template',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              button_title_delete: 'Delete Template',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              button_title_save: 'Save Template'
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_apply_once: 'Request and apply SQL data once:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_apply_always: 'Request and apply SQL data always:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            dropdown_builder: 'Select options:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            select_builder: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              template_apply: 'Apply template:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              template_save: 'Save template:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              template_delete: 'Delete template:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              option_add: 'Add option:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              option_edit: 'Edit option:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              option_delete: 'Delete option:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              option_color: 'Color option:'
            },
            colors: 'Color gradient:',
            color0: 'Color 1:',
            color1: 'Color 2:',
            color2: 'Color 3:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            radio_btn_label_1: 'Label Radiobutton 1:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            radio_btn_label_2: 'Label Radiobutton 2:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_builder: {
              action: 'Action:',
              term: 'Term:',
              msg: 'Message:',
              vars: 'Set variables:',
              color: 'Color:',
              interval: 'Interval:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              checklist_status: 'Checklist-Status:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              checklist_index: 'Checklist:',
              matrix: 'Choose cells:',
              remove: 'Remove:',
              value: 'Value:',
              column: 'Column:',
              table: 'Table:',
              encrypt: 'Encrypt:',
              decrypt: 'Decrypt:',
              type: 'Type:',
              comparator: 'Comparator:',
              date1: 'Date #1:',
              date2: 'Date #2:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              offset_days_date1: 'Offset in days for date #1:',
              date: 'Date:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              date_offset: 'Offset in days for date:',
              collection: 'Checklist:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              visible_and_editable_only: 'Only for editable and visible:',
              variable: 'Variable:',
              comment: 'Comment:',
              label: 'Label:',
              id: 'ID:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              id_optional: 'ID (optional):',
              sql: 'SQL:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              open_new_window: 'Open in new window:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              pin_length: 'PIN length:',
              container: 'Container:',
              location: 'Location:',
              employee: 'Employee:',
              theme: 'Theme:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              user_gui: 'User version:',
              appointment: 'Appointment:',
              locations: 'Locations:',
              whitelist: 'Whitelist:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              filter_by_locations: 'Filter by locations:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              match_all_levels: 'All levels:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              match_skip_levels: 'Skip levels:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              file_name: 'File name:',
              template: 'Template:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_subject: 'Subject:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_description: 'Description:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_start_date: 'Start (Date):',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_duration: 'Duration (Hours):',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_location: 'Location:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_receivers: 'Receivers:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_participants: 'Participants:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_organizer: 'Organizer:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_type: 'Type:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_text_1: 'Text:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_text_2: 'Text:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_text_3: 'Text:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_text_4: 'Text:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_text_5: 'Text:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              ics_sequence: 'Sequence:'
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_icon: 'Status icon:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            locked_content: 'Content lock:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            mandatory_field: 'Mandatory field:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            mandatory_field_hidden: 'Ignore hidden Mandatory Field:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            alignment_vertical: 'Vertical text alignment:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            alignment_horizontal: 'Horizontal text alignment:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            button_alignment: 'Button alignment:',
            tokens: 'Tokens:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            token_builder: {
              token: 'Token:',
              target: 'Target:'
            },
            emails: 'E-Mail Settings:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_by_user: 'E-Mail Input(User):',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_btn_label: 'Label E-Mail Button:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_text_editable: 'E-Mail editable in viewer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_inactive_users: 'Show inactive employees:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_acknowledgement_editable:
              'E-Mail acknowledgement setting in viewer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_acknowledgement_label_link:
              'Label for E-Mail acknowledgement hyperlink:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_generated_document_attachment:
              'Attachment of generated documents to E-Mail:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_generated_document_attachment_zipped:
              'Zip generated documents in attachment:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_download_document_attachment:
              'Attachment of downloadable documents to E-Mail:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_download_document_attachment_zipped:
              'Zip downloadable documents in attachment:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_upload_document_attachment:
              'Attachment of uploaded documents to E-Mail:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_upload_document_attachment_zipped:
              'Zip uploaded documents in attachment:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_drawing_attachment: 'Attachment of drawing to E-Mail:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_drawing_attachment_zipped: 'Zip drawing in attachment:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_audit_created_by_user_as_recipient:
              'Use creator of audit as E-Mail recipient:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_audit_affected_employee_supervisors_as_recipient:
              'Use supervisor(s) for affected employee of audit as E-Mail recipient:',
            documents: 'Manage documents:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            documents_hide_add: 'Dokument add hidden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_builder: {
              label: 'Label',
              select: 'Choose documents',
              relevance: 'Relevance',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              relevance_0: 'Documents are always required',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              relevance_1: 'Documents are required (changeable by user)',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              relevance_2: 'Documents are not required (changeable by user)',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              relevance_3: 'Relevance not set (changable by user)',
              settings: 'Extended settings',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              show_send: 'Show "Generated":',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              show_received: 'Show "Received":',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              show_comment: 'Show "Comment":',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              change_relevance: 'Relevance changeable:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              keep_generated_documents: 'Keep generated documents:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              reminder_send_days: 'Reminder "Generated" in days:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              reminder_received_days: 'Reminder "Received" in days:'
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_value_changed: 'Action after value changed:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_checklists_editable: 'Displayed checklists editable:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_checklists_label: 'Label for displayed checklists:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_checklists_width: 'Width for displayed checklists:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_checklists: 'Displayed checklists:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cell_background_color: 'Cell background color:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_hide: 'Hide countdown:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_seconds: 'Countdown Seconds:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_minutes: 'Countdown Minutes:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_hours: 'Countdown Hours:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_expired: 'After expiration:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_done: "Countdown 'Done':",
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_done_label: "Label for countdown 'Done':",
            // eslint-disable-next-line @typescript-eslint/naming-convention
            inner_label_0: 'Inner label #1:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            inner_label_1: 'Inner label #2:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            inner_label_2: 'Inner label #3:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            dropdown_builder_1: 'Select options #2:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            dropdown_builder_2: 'Select options #3:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            combine_sep_operator: 'Separator/Operator:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation: 'Escalation/Reminder:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_tree_node: 'Escalation/Reminder select Entry:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_tree_node_label: 'Escalation/Reminder Entry:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_display_type: 'Escalation/Reminder display type:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_title: 'Escalation/Reminder title:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_description: 'Escalation/Reminder description:',
            logging: 'Logging:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            logging_display: 'Display log:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            logging_display_never_locked: 'Ignore cell locking:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links: 'Send checklist:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_select: 'Select Checklist:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_select_label: 'Label for selection in viewer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_select_viewer: 'Select checklist in viewer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_delete_entry: 'Remove entry and values:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_show_table: 'Show checklist status in viewer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_open_entry: 'Open checklist from viewer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_label_link: 'Label for checklist hyperlink:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_label: 'Button label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_width: 'Button width:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_on_click: 'Execute pre-evaluation:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_action: 'Button action:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_collection: 'Collection:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_url: 'Button path/URL:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_params: 'Button parameters:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_email_attachments: 'E-Mail Attachments:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_email_addresses: 'E-Mail Receivers:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_email_message: 'E-Mail Message:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_medical_preparedness_data:
              'Medical Preparedness (Identifier):',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_substance_section: 'Hazardous Material Section:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_directive_section: 'Directive Section:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_directive_type: 'Directive Type:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_open_emkg_dialog_readonly: 'Read-Only:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_open_directive_window_readonly: 'Read-Only:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_open_substance_window_readonly: 'Read-Only:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_copy_emkg_locations_activities:
              'Use substance locations and properties for directive:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_uw_create: 'Create Instruction:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_uw_modify: 'Modify Instruction:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_uw_delete: 'Delete Instruction:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_uw_start_date: 'Date Instruction:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_uw_start_date_offset: 'Date Offset Instruction:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_copy_ae_loactions:
              'Use locations of affected employee for substance:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_copy_au_loactions:
              'Use audit locations for substance:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_set_audit_due_today: 'Use current date as default:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_set_audit_due_offset:
              'Offset for default date in days:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback: 'Cell as button:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_close: 'Close dialog:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_message_success: 'Message on success:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_message_error: 'Message on error:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_single: 'Single return value:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_single_value: 'Value:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_multi: 'Multiple return values:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_multi_value_0: 'Value #1:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_multi_value_1: 'Value #2:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_multi_value_2: 'Value #3:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hide_in_report: 'Hide in Report:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hide_cell_in_cloned_checklist: 'Hide in cloned Checklist:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_0: 'Input Code #1:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_1: 'Input Code #2:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_2: 'Input Code #3:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_3: 'Input Code #4:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_0_label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_1_label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_2_label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_3_label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_0_length: 'Length:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_1_length: 'Length:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_2_length: 'Length:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_3_length: 'Length:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_0_type: 'Type:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_1_type: 'Type:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_2_type: 'Type:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_3_type: 'Type:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_0_optional: 'Optional:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_1_optional: 'Optional:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_2_optional: 'Optional:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_3_optional: 'Optional:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_requirements_inverse: 'Invert Display Requirements:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            textarea_fixed_rows: 'Textarea fixed height:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            textarea_fixed_rows_count: 'Textarea rows:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            load_external_content: 'Load external content:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            load_external_content_substance_section: 'Substance Section:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            load_external_content_directive_section: 'Directive Section:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            date_start_today: 'Start day today:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            date_start_offset_days: 'Start day offset (days):',
            colspan: 'Column count for cell:',
            rowspan: 'Row count for cell:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_data: 'Image binding:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_width: 'Image width:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_height: 'Image height:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_alternate_text: 'Image alternate text:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_clickable: 'Image clickable:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_pattern: 'Display pattern:',
            color: 'Color:',
            type: 'Type:',
            size: 'Size:',
            scale: 'Scale:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_frame: 'Drawing Frame:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_width: 'Drawing Width:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_height: 'Drawing Height:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_timestamp: 'Drawing Timestamp:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_timestamp_date: 'Date:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_timestamp_time: 'Time:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_protected: 'Drawing protected:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_save_as_file: 'Drawing saved as file:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_canvas_width: 'Drawing Board Width:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_canvas_height: 'Drawing Board Height:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_color: 'Drawing Board Stroke Color:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_size: 'Drawing Board Stroke Size:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_type: 'Drawing Board Stroke Type:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_background_color: 'Drawing Board Background Color:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_auto_scale_canvas: 'Drawing Board Auto Scale Canvas:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_auto_scale_ribbons:
              'Drawing Board Auto Scale Ribbons:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_drawing_disabled: 'Drawing Board Disabled Drawing:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_advanced_down_scale:
              'Drawing Board Advanced Downscaling:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon: 'Drawing Board Ribbon:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_stroke_color: 'Stroke Color:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_stroke_size: 'Stroke Size:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_stroke_type: 'Stroke Type:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_restore: 'Redo & Undo:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_delete: 'Delete Content:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_upload_image: 'Upload Image:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_auto_scale_image: 'Auto Scale Image:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_save_as_document: 'Save as Document:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_open_document_in_tab: 'Open Document in Tab:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_crop: 'Crop:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_scale: 'Scale:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_rotate: 'Rotate:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_common: 'Display format in Printer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_multiselect: 'Display format in Printer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_slider: 'Display format in Printer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_comment: 'Display comment in Printer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions: 'Display actions in Printer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions_label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions_comment: 'Comment:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions_status: 'Status:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions_done: 'Done:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions_due: 'Due:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_files: 'Display files in Printer',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_files_label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_files_comment: 'Comment:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_files_due: 'Due:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_collection_links:
              'Display sent checklists in Printer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_unlock: 'Unlock Notification Tree:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_source: 'Tree:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_source_label: 'Selected Tree Entry:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_source_unlock: 'Tree:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_source_unlock_label: 'Selected Tree Entry:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            copyable_cell_value: 'Copy Cell Value:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_birthday: 'Filter for birthday:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_birthday_label: 'Label for birthday:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_company: 'Filter for company:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_company_label: 'Label for company:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_lastname: 'Filter for lastname:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_lastname_label: 'Label for lastname:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_surname: 'Filter for surname:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_surname_label: 'Label for surname:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking_collection: 'Task tracking collection:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking_checklist: 'Task tracking checklist:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking_priority: 'Task tracking priority:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking_type: 'Task tracking type:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking_check_visibility_right:
              'Task tracking check visibility right:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking_check_editability_right:
              'Task Tracking check editability right:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_picker_hide_staff_id:
              'Employee filter hide personnel number:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_picker_externals: 'Employee filter externals:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_picker_active_only: 'Employee filter active:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_picker_show_only_my_employees:
              'Employee filter own employees only:'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          email_tokens: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sender_surname: 'Sender: Surname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sender_lastname: 'Sender: Lastname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sender_name: 'Sender: Name',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sender_number: 'Sender: Employee Number',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sender_mail: 'Sender: E-Mail',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_surname: 'Recipient: Surname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_lastname: 'Recipient: Lastname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_name: 'Recipient: Name',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_number: 'Recipient: Employee Number',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_mail: 'Recipient: E-Mail',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            affected_employee_surname: 'Affected Employee: Surname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            affected_employee_lastname: 'Affected Employee: Lastname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            affected_employee_name: 'Affected Employee: Name',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            affected_employee_number: 'Affected Employee: Employee Number',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            affected_employee_mail: 'Affected Employee: E-Mail',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            referrer_surname: 'Referrer: Surname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            referrer_lastname: 'Referrer: Lastname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            referrer_name: 'Referrer: Name',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            referrer_number: 'Referrer: Employee Number',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            referrer_mail: 'Referrer: E-Mail',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            link_acknowledgement: 'Hyperlink: E-Mail Acknowledgement',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            link_collection_link: 'Hyperlink: Linked Collection'
          }
        },
        titles: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_element_picker: 'Show element picker',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          toggle_settings: 'Show/hide settings',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          remove_element: 'Remove element',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          upload_files: 'Attach files',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          toggle_comment: 'Show/hide comment',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          copy_cell: 'Copy cell into cache',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          paste_cell: 'Paste cell from cache',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          requirement_overlay: 'Overlay for visibility requirements',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          total_amount_overlay: 'Overlay for total amount calculation',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          field_exclusivity_overlay: 'Overlay for exclusive field values',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          field_lock_overlay: 'Overlay for cell locking',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          change_color_overlay: 'Overlay for temporary color change',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          value_event_trigger_overlay:
            'Overlay for triggering value events in cells',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_cell_dialog: 'Show cell as Dialog for edit',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          edit_settings: 'Edit checklist settings',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_add: 'Add column',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_clone: 'Clone column',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_copy: 'Copy column into cache',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_paste: 'Paste column from cache',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_delete: 'Remove column',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_left: 'Move column left',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_right: 'Move column right',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_width: 'Show/hide input for column width',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_add: 'Add row',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_clone: 'Clone row',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_copy: 'Copy row into cache',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_paste: 'Paste row from cache',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_delete: 'Remove row',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_up: 'Move row up',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_down: 'Move row down',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_split: 'Split after row (Printer)',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_info: 'Show information',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          toggle_content: 'Show/hide cell content',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          add_comment: 'Add comment',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_files: 'Show attached files',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_actions: 'Show attached actions',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          add_actions: 'Add actions',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          adopt_value: 'Set default value',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_hidden_documents: 'Show overview of hidden documents',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_clone: 'Add clone',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          hide_clone: 'Remove clone',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          reset_cell: 'Reset cell',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          reset_cell_value: 'Reset cell value',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          remove_values: 'Delete all related values for this Cell.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          display_checklists: 'Show attached checklists.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          countdown_done: "Abort countdown and mark it as 'done'.",
          // eslint-disable-next-line @typescript-eslint/naming-convention
          display_log: 'Display log',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          display_original_image: 'Display original Image',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          hide_cell_borders: 'Hide cell borders',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          checklist_rows_colored: 'Alternating colors per checklist row',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          drawing_board_display: 'Click here to open drawing board.',
          undo: 'Undo',
          redo: 'Redo',
          color: 'Color',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          delete_content: 'Delete content',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          upload_image: 'Upload Image',
          crop: 'Crop',
          upscale: 'Upscale',
          downscale: 'Downscale',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          rotate_left_90: 'Rotate to the left by 90°',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          rotate_right_90: 'Rotate to the right by 90°',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          unknown_type: 'Unknown Type'
        },
        placeholders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          element_comment: 'Add comment here.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          backup_label: 'Label',
          scaling: 'pixels or percentage'
        },
        dialog: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          checklist_properties: 'Edit additional properties',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          select_element_type: 'Select Element Type',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          cell_dialog_edit: 'Edit Cell',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_collection_text: 'Remove this collection?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_checklist_text: 'Remove this checklist?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_element_text: 'Remove this element?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_element_values:
            'Remove all related values of this cell? Values can not be restored.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_collection_values:
            'Remove all related values of this collection? Values can not be restored.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_row_text: 'Remove this row?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_column_text: 'Remove this column?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_requirements:
            'Progress requirements will be removed in order to use the requirement matrix!<br /><br />Proceed?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_requirement_matrix:
            'Requirement matrix will be removed in order to use the progress requirements!<br /><br />Proceed?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          variable_new: 'New variable',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          variable_edit: 'Edit variable',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          security_password: 'Password',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          security_password_text: 'Please enter the password:',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          action_new: 'New action'
        },
        message: {
          title: {
            error: 'Error',
            success: 'Success',
            warning: 'Warning',
            information: 'Note',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            mandatory_fields: 'Mandatory Fields'
          },
          content: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            invalid_column_width: 'Invalid column width.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_collection_success: 'Checklist(s) successfully deleted.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_collection_error:
              'Deleting checklist(s) failed.<br>' +
              'Remove existing dependencies first.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_collection_fatal: 'Fatal error while deleting checklist(s).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_collection_success: 'Checklist(s) successfully loaded.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_collection_error: 'Error loading checklist(s).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_collection_fatal: 'Fatal error while loading checklist(s).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_collection_success: 'Checklist(s) successfully saved.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_collection_error: 'Error saving checklist()s.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_collection_fatal: 'Fatal error while saving checklist(s).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            import_collection_success: 'Checkliste(n) erfolgreich importiert.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            read_only_collection_view:
              'Preview of checklist(s) in reading mode.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_checklist_values_success:
              'Values for checkliste(s) successfully loaded.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_checklist_values_error:
              'Error loading values for checklist(s).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_checklist_values_fatal:
              'Fatal error loading values for checklist(s).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_checklist_values_success:
              'Value for checklist successfully saved.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_checklist_values_error: 'Error saving value for checklist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_checklist_values_fatal:
              'Fatal error saving value for checklist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_checklist_values_success:
              'Value for checklist successfully deleted.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_checklist_values_error:
              'Error deleting value for checklist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_checklist_values_fatal:
              'Fatal error deleting value for checklist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_cell_values_success: 'Values for cell successfully deleted.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_cell_values_error: 'Error deleting values for cell.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_cell_values_fatal: 'Fatal error deleting values for cell.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            missing_selector_viewer:
              'Values could not be loaded because no selector was declared.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_not_declared: 'No declared checklist(s) to display.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            target_cell_locked:
              'Target cell can not be replaced, already in use.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            target_row_locked:
              'Row can not be deleted because a cell is already in use.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            target_column_locked:
              'Column can not be deleted because a cell is already in use.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            target_checklist_locked:
              'Checklist can not be deleted because a cell is already in use.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            checklist_unlocked_by_progress: 'New checklist unlocked.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_actions_cell:
              'Cell can not be removed, while actions are attached.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_files_cell:
              'Cell can not be removed, while files are attached.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_actions_row:
              'Row can not be removed, while actions are attached.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_files_row:
              'Row can not be removed, while files are attached.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_actions_column:
              'Column can not be removed, while actions are attached.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_files_column:
              'Column can not be removed, while files are attached.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_actions_checklist:
              'Checklist can not be removed, while actions are attached.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_files_checklist:
              'Checklist can not be removed, while files are attached.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_no_recipients: 'No recipient selected.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_send_error: 'Failure sending E-Mails.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_send_success: 'E-Mails successfully send.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_manual_entry_removed: 'Recipient successfully removed.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_manual_entry_exists:
              'An entry for this E-Mail address already exists.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_manual_entry_added: 'Recipient successfully added.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_manual_entry_invalid: 'Unable to add invalid entry.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_acknowledge_error: 'Error setting acknowledgement.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_acknowledge_success: 'Acknowledgement successfully set.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management_file_delete_error: 'Unable to delete document.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management_file_upload_error: 'Unable to upload document.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management_no_hidden_documents:
              'No documents available to add.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_disabled_cell_use:
              'Setting disabled because cell is already in use.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_save_error: 'Unable to save variable.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_delete_error: 'Unable to delete variable.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_transfer_error: 'Error transfering variable data.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_missing_data: 'Please fill all input fields.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_name_invalid:
              'Variable name has wrong structure. Example: @text_1@ (Numbers, Underscore, Lowercase)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_name_exists: 'Variable already exists.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_name_forbidden:
              'Variables which start with @value, @previous_value and @cell_ are not allowed.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            select_template_save_success: 'Saved template successfully.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            select_template_save_error: 'Error saving template.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            select_template_delete_success: 'Deleted template successfully.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            select_template_delete_error: 'Error deleting template.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_template_delete_success: 'Deleted template successfully.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_template_delete_error: 'Error deleting template.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_template_save_success: 'Saved template successfully.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_template_save_error: 'Error saving template.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_checklist_clone_error:
              'Can not be deleted, because of existing clones.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            password_invalid: 'Password invalid.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            password_valid: 'Password valid.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_no_node_selected: 'No node selected.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_variables_values_success:
              'Variable values successfully deleted.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_variables_values_error: 'Error deleting variable values.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_variable_values_success: 'Variable values successfully saved.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_variable_values_error: 'Error saving variable values.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_term_invalid: 'Term is invalid at: ',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_term_empty: 'No term specified.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_link_status_error: 'Error updating checklist status.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_link_status_success:
              'Checklist status successfully updated.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_link_delete_error: 'Unable to remove checklist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_link_delete_success: 'Checklist successfully removed.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_error: 'Cloning the checklist failed.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_preview_not_supported:
              'Cloning checklists is not supported in Preview-Mode.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management_download_no_file_selected:
              'No document selected.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management_download_error_preparing_data:
              'Error preparing data for document.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            not_available_in_preview: 'Functionality not available in preview.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            mandatory_fields_undone: 'Please fill all mandatory fields.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tab_removal_disabled_by_checklist_requirement_overlay:
              'Unable to remove tab while requirement overlay for checklists is active.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tab_removal_disabled_by_value_event_overlay:
              'Unable to remove tab while value event overlay is active.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tab_removal_last_checklist:
              'The collection has to contain at least one checklist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            log_loading_error: 'Error loading log.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            log_no_content: 'No entries found.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            log_entry_deleted_from_db: 'Entry was deleted in Database.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            checklist_active_deactivated_by_overlay:
              'Checklists can not be de/activated while a global overlay is still active.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_unsupported_type: 'Type is not supported.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cell_type_unknown: 'Unknown type: ',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            canvas_unsupported: 'Canvas element not supported.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_upload_unsupported_mime_type:
              'Image upload failed, unsupported format. Allowed MIME-Types: ',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_value:
              'Invalid value. Please enter a positive integer or percentage.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_canvas_no_context:
              'No context for downscaling canvas defined.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_source_width:
              'Invalid width of source. Should be bigger than 0 pixel.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_source_height:
              'Invalid height of source. Should be bigger than 0 pixel.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_target_width:
              'Invalid width of target. Should be bigger than 0 pixel.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_target_height:
              'Invalid height of target. Should be bigger than 0 pixel.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_scale:
              'Invalid scale. Should be bigger than 0 and lesser than 1',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_result: 'Employees found: #NUMBER#'
          }
        },
        workflow: {
          options: {
            containerHeight: 'Container Height:',
            containerWidth: 'Container Width:',
            autoAdjustContainerHeight: 'Auto-Adjust Container Height:',
            autoAdjustContainerWidth: 'Auto-Adjust Container Width:',
            rectangleWidth: 'Rectangle Width:',
            rectangleHeight: 'Rectangle Height:',
            rectangleRadius: 'Rectangle Radius:',
            spacingHorizontal: 'Rectangle Spacing Horizontal:',
            spacingVertical: 'Rectangle Spacing Vertical:',
            scrollbarX: 'Horizontal Scrollbar:',
            scrollbarY: 'Vertical Scrollbar:',
            markerHidden: 'Hide Marker:',
            groupSingleNodeTrees: 'Group Single Nodes:',
            rectangleColor: 'Default Rectangle Background Color:',
            returnPathStrokeVerticalOffset: 'Vertical Offset Return Path:',
            returnPathStrokeColor: 'Default Color Return Path:',
            userOptionPaths: 'Display Path Chooser Option:',
            userOptionVisible: 'Show/Hide Workflow Option:',
            userOptionScale: 'Zoom Option:',
            defaultScale: 'Default Zoom:',
            hideNodeLabelOverflow: 'Hide Node Label Overflow:',
            visibilityRightsEmployees: 'Visible for Employees:',
            visibilityRightsRoles: 'Visible for Roles:'
          }
        },
        misc: {
          path: 'Path',
          image: 'Image',
          checklist: 'Checklist',
          employee: 'Employee',
          status: 'Status',
          tasks: 'Tasks',
          index: 'Index',
          row: 'Row',
          column: 'Column',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          next_row: 'next Row',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          next_column: 'next Column',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          previous_row: 'previous Row',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          previous_column: 'previous Column',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          open_editor: 'Open editor',
          group: 'Group',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          new_checklist: 'New Checklist',
          label: 'Label',
          done: 'Done',
          due: 'Due',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_workflow: 'Workflow',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_workflow_options: 'Workflow Options',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_options: 'General Settings',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          checklist_options: 'Checklist Settings',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          printer_options: 'Printer Settings',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          workflow_node_types: {
            default: 'Default',
            circle: 'Circle',
            ellipse: 'Ellipse',
            square: 'Square',
            rhombus: 'Rhombus',
            cloud: 'Cloud'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          document_management: 'Document Management',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          variable_management: 'Variable Management',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          preview_settings: 'Temporary Preview Settings',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          checklist_quick_options: 'Options:',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          loading_content: 'Loading content...',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          loading_content_disabled: 'Loading content disabled in current mode.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          loading_content_unknown: 'Unable to load unknown content.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          loading_content_unprintable: 'Unprintable external content: ',
          created: 'Created',
          modified: 'Modified',
          deleted: 'Deleted',
          yes: 'Yes',
          no: 'No',
          new: 'New',
          warning: 'Warning',
          comment: 'Comment',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          cell_comment_printer: 'Comment: ',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          user_comment_printer: 'User Comment: ',
          date: 'Date',
          time: 'Time',
          datetime: 'Date, Time',
          backup: 'Backup',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          backup_on_save: 'Backup, Action: Save',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          backup_on_load: 'Backup, Action: Load',
          reminded: 'Reminded',
          escalated: 'Escalated',
          download: 'Download',
          generate: 'Generate',
          upload: 'Upload',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          document_overview: 'Document Overview',
          documents: 'Documents',
          document: 'Document',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          generated_documents: 'Generated documents',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          download_documents: 'Downloadable documents',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          upload_documents: 'Uploaded documents',
          actions: 'Actions',
          files: 'Files',
          drawings: 'Drawings',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          search_result: 'Search Result:',
          type: 'Type',
          acknowledgements: 'Acknowledgements',
          surname: 'Surname',
          lastname: 'Lastname',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          employee_number: 'Employee Number',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_links: 'Sent checklists',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          no_employee: 'no Employee',
          email: {
            subject: 'Subject',
            message: 'Message',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            btn_default: 'Send E-Mail',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            informed_at: 'Informed/Send at: ',
            recipient: 'To (Recipient):',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_ccc: 'CC:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_bcc: 'BCC:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            manual_select: 'Manual Entries:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            manual_name: 'Recipient name:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            manual_email: 'Recipient E-Mail address:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sender_email: 'Sender E-Mail address:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sender_lastname: 'Sender Lastname:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sender_surname: 'Sender Surname:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sender_current_user: 'Current User as Sender:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sender_current_user_reply_to: 'Current User as ReplyTo:',
            acknowledgement: 'acknowledgement:',
            pending: 'Pending'
          },
          buttons: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_escalations: 'Choose Escalations/Reminders',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            emails_settings: 'Choose E-Mail Settings',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            emails_send: 'Send E-Mails',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            emails_receivers: 'E-Mail Receivers',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            emails_message: 'E-Mail Message',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_binding: 'Bind Image',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            documents_add: 'Add Documents',
            apply: 'Apply',
            close: 'Close',
            save: 'Save',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            new_variable: 'New variable',
            browse: 'Browse',
            remove: 'Remove',
            upload: 'Upload',
            search: 'Search',
            ok: 'OK',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            add_action: 'Add action',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            import_all: 'Import all',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_cell_values_matrix: '',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            apply_drawing: 'Apply',
            print: 'Print',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_source: 'Choose Tree'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          variable_transfer: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            transfer_of_checklist_employee:
              'Transfer variable data from selected checklist to current one:'
          },
          dataTable: {
            oAria: {
              sSortAscending: ': activate to sort column ascending',
              sSortDescending: ': activate to sort column descending'
            },
            oPaginate: {
              sFirst: '&laquo;',
              sLast: '&raquo;',
              sNext: '&rsaquo;',
              sPrevious: '&lsaquo;'
            },
            sEmptyTable: 'No data available in table',
            sInfo: 'Showing _START_ to _END_ of _TOTAL_ entries',
            sInfoEmpty: 'Showing 0 to 0 of 0 entries',
            sInfoFiltered: '(filtered from _MAX_ total entries)',
            sInfoPostFix: '',
            sInfoThousands: ',',
            sLengthMenu: 'Show _MENU_ entries',
            sLoadingRecords: 'Loading...',
            sProcessing: 'Processing...',
            sSearch: 'Search:',
            sUrl: '',
            sZeroRecords: 'No matching records found'
          }
        }
      },
      de: {
        dialog: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          action_new: 'Neue Aufgabe',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          cell_dialog_edit: 'Zelle bearbeiten',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          checklist_properties: 'Erweiterte Einstellungen bearbeiten',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_checklist_text: 'Diese Checkliste entfernen?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_collection_text:
            'Diese Checklisten-Sammlung entfernen?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_collection_values:
            'Alle hinterlegten Werte dieser Checkliste entfernen? Werte können nicht wieder hergestellt werden!',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_column_text: 'Diese Spalte entfernen?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_element_text: 'Dieses Element entfernen?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_element_values:
            'Alle hinterlegten Werte dieser Zelle entfernen? Werte können nicht wieder hergestellt werden!',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_requirement_matrix:
            'Anzeige-Abhängigkeit Matrix wird entfernt um benötigten Fortschritt zu nutzen!<br /><br />Fortfahren?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_requirements:
            'Benötigter Fortschritt wird entfernt um die Anzeige-Abhängigkeit Matrix zu nutzen!<br /><br />Fortfahren?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          confirm_delete_row_text: 'Diese Zeile entfernen?',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          security_password: 'Passwort',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          security_password_text: 'Bitte geben Sie das Passwort ein:',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          select_element_type: 'Element-Typ auswählen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          variable_edit: 'Variable bearbeiten',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          variable_new: 'Neue Variable'
        },
        locales: {
          'cs-CZ': 'Tschechisch',
          'de-AT': 'Österreichisch',
          'de-CH': 'Schweizerisch',
          'de-DE': 'Deutsch',
          'en-GB': 'Englisch',
          'en-US': 'Englisch',
          'fr-FR': 'Französisch',
          'hu-HU': 'Ungarisch',
          'nl-NL': 'Niederländisch',
          'pl-PL': 'Polnisch',
          'ro-RO': 'Rumänisch',
          'ru-RU': 'Russisch'
        },
        message: {
          content: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_no_node_selected: 'Kein Knoten ausgewählt.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            canvas_unsupported: 'Canvas Element wird nicht unterstützt.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cell_type_unknown: 'Unbekannter Typ: ',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            checklist_active_deactivated_by_overlay:
              'Checklisten können nicht de/aktiviert werden während ein globales Overlay aktiv ist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            checklist_unlocked_by_progress: 'Neue Checkliste freigeschaltet.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_create_info:
              'Es können nur Klone für Checklisten erstellt werden, die klonbar sind. Die Checkliste, die geklont werden soll, muss in der Datenbank vorhanden sein.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_error: 'Klonen der Checkliste fehlgeschlagen.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_preview_not_supported:
              'Klonen der Checkliste wird in der Vorschau nicht unterstützt.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_link_delete_error:
              'Checkliste konnte nicht entfernt werden.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_link_delete_success:
              'Checkliste wurde erfolgreich entfernt.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_link_status_error:
              'Fehler beim aktualisieren des Checklisten-Status.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_link_status_success:
              'Checklisten-Status erfolgreich aktualisiert.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_not_declared:
              'Keine Checkliste(n) zum Anzeigen vorhanden.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_cell_values_error: 'Konnte keine Werte aus Zelle löschen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_cell_values_fatal:
              'Fataler Error beim Löschen von Werten aus Zelle',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_cell_values_success: 'Werte für Zelle erfolgreich gelöscht.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_checklist_cell_error:
              'Fehler beim Löschen von Wert für Zelle.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_checklist_cell_fatal:
              'Schwerwiegender Fehler beim Löschen der Werte für Zelle.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_checklist_cell_success:
              'Werte für Zelle erfolgreich gelöscht.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_checklist_clone_error:
              'Kann nicht gelöscht werden, da Klone vorhanden sind.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_checklist_values_error:
              'Fehler beim Löschen von Wert für Checkliste.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_checklist_values_fatal:
              'Schwerwiegender Fehler beim Löschen von Wert für Checkliste.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_checklist_values_success:
              'Wert für Checkliste erfolgreich gelöscht.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_collection_error:
              'Löschen von Checkliste(n) fehlgeschlagen.<br />Abhängigkeiten müssen zuerst entfernt werden.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_collection_fatal:
              'Schwerwiegender Fehler beim Löschen der Checkliste(n).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_collection_success: 'Checkliste(n) erfolgreich gelöscht.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_variables_values_error:
              'Fehler beim Löschen der Variablen-Werte.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_variables_values_success:
              'Variablen-Werte erfolgreich gelöscht.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management_download_error_preparing_data:
              'Fehler beim aufbereiten der Daten für das Dokument.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management_download_no_file_selected:
              'Kein Dokument ausgewählt.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management_file_delete_error:
              'Dokument konnte nicht gelöscht werden.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management_file_upload_error:
              'Dokument konnte nicht hochgeladen werden.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management_no_hidden_documents:
              'Keine Dokumente zum hinzufügen verfügbar.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_acknowledge_error:
              'Fehler beim Setzen der Empfangsbestätigung.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_acknowledge_success:
              'Empfangsbestätigung erfolgreich gesetzt.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_manual_entry_added:
              'Empfänger wurde erfolgreich zur Auswahl hinzugefügt.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_manual_entry_exists:
              'Ein Eintrag für diese E-Mail Adresse ist bereits vorhanden.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_manual_entry_invalid:
              'Eintrag kann nicht hinzugefügt werden, da üngültig.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_manual_entry_removed: 'Empfänger wurde erfolgreich entfernt.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_no_recipients: 'Keine Empfänger ausgewählt.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_send_error: 'Fehler beim versenden der E-Mails.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_send_success: 'E-Mails wurden erfolgreich versendet.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_result: 'Mitarbeiter gefunden: #NUMBER#',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_checklist_values_error:
              'Fehler beim Laden der Werte für Checkliste(n).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_checklist_values_fatal:
              'Schwerwiegender Fehler beim Laden der Werte für Checkliste(n).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_checklist_values_success:
              'Werte für Checkliste(n) erfolgreich geladen.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_collection_error: 'Fehler beim laden der Checkliste(n).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_collection_fatal:
              'Schwerwiegender Fehler beim Laden der Checkliste(n).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_collection_success: 'Checkliste(n) erfolgreich geladen.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_unsupported_type: 'Typ wird nicht unterstützt.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_upload_unsupported_mime_type:
              'Bildupload fehlgeschlagen, falsches Format. Erlaubte MIME-Typen: ',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            import_collection_success: 'Checkliste(n) erfolgreich importiert.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            invalid_column_width: 'Ungültige Spaltenbreite.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            log_entry_deleted_from_db:
              'Eintrag wurde in der Datenbank gelöscht.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            log_loading_error: 'Fehler beim Laden der Protokollierung.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            log_no_content: 'Keine Einträge gefunden.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            mandatory_fields_undone: 'Bitte füllen sie alle Pflichtfelder aus.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            missing_selector_viewer:
              'Werte können nicht geladen werden, da kein Selektor angegeben wurde.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            not_available_in_preview:
              'Funktionalität in der Vorschau nicht verfügbar.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_disabled_cell_use:
              'Einstellung deaktiviert, weil Zelle bereits in Nutzung ist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            password_invalid: 'Passwort ungültig.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            password_valid: 'Passwort gültig.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            read_only_collection_view:
              'Vorschau der Checkliste(n) im Lesemodus.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_actions_cell:
              'Zelle kann nicht gelöscht werden, wenn noch Maßnahmen zugewiesen sind.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_actions_checklist:
              'Checkliste kann nicht gelöscht werden, wenn noch Maßnahmen zugewiesen sind.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_actions_column:
              'Spalte kann nicht gelöscht werden, wenn noch Maßnahmen zugewiesen sind.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_actions_row:
              'Zeile kann nicht gelöscht werden, wenn noch Maßnahmen zugewiesen sind.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_files_cell:
              'Zelle kann nicht gelöscht werden, wenn noch Dokumente zugewiesen sind.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_files_checklist:
              'Checkliste kann nicht gelöscht werden, wenn noch Dokumente zugewiesen sind.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_files_column:
              'Spalte kann nicht gelöscht werden, wenn noch Dokumente zugewiesen sind.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_assigned_files_row:
              'Zeile kann nicht gelöscht werden, wenn noch Dokumente zugewiesen sind.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_checklist_values_error:
              'Fehler beim Speichern von Wert für Checkliste.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_checklist_values_fatal:
              'Schwerwiegender Fehler beim Speichern von Wert für Checkliste.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_checklist_values_success:
              'Wert für Checkliste erfolgreich gespeichert.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_collection_error: 'Fehler beim Speichern der Checkliste(n).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_collection_fatal:
              'Schwerwiegender Fehler beim Speichern der Checkliste(n).',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_collection_success: 'Checkliste(n) erfolgreich gespeichert.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_variable_values_error:
              'Fehler beim Speichern der Variablen-Werte.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            save_variable_values_success:
              'Variablen-Werte erfolgreich gespeichert.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_canvas_no_context:
              'Kein Kontext für das herunterskalieren definiert.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_scale:
              'ungültige Skalierung. Sollte größer als 0 und kleiner 1 sein.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_source_height:
              'Üngültige Höhe der Quelle. Sollte größer als 0 Pixel sein.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_source_width:
              'Üngültige Breite der Quelle. Sollte größer als 0 Pixel sein.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_target_height:
              'Üngültige Höhe des Ziels. Sollte größer als 0 Pixel sein.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_target_width:
              'Üngültige Breite des Ziels. Sollte größer als 0 Pixel sein.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            scaling_invalid_value:
              'ungültiger Wert. Bitte geben sie eine positive Ganzazahl oder Prozent an.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            select_template_delete_error: 'Fehler beim Löschen der Vorlage.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            select_template_delete_success: 'Vorlage erfolgreich gelöscht.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            select_template_save_error: 'Fehler beim Speichern der Vorlage.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            select_template_save_success: 'Vorlage erfolgreich gespeichert.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_template_delete_error: 'Fehler beim Löschen der Vorlage.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_template_delete_success: 'Vorlage erfolgreich gelöscht.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_template_save_error: 'Fehler beim Speichern der Vorlage.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_template_save_success: 'Vorlage erfolgreich gespeichert.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tab_removal_disabled_by_checklist_requirement_overlay:
              'Tab kann nicht entfernt werden, während das Overlay für die Anzeige-Abhängigkeit Matrix aktiv ist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tab_removal_disabled_by_value_event_overlay:
              'Tab kann nicht entfernt werden, während das Overlay für Ereignisse bei Wert aktiv ist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tab_removal_last_checklist:
              'Die Kollektion muss mindestens eine Checkliste beinhalten.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            target_cell_locked:
              'Ziel-Zelle kann nicht ersetzt werden, da bereits in Benutzung.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            target_checklist_locked:
              'Checkliste kann nicht gelöscht werden, da eine Zelle bereits in Benutzung ist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            target_column_locked:
              'Spalte kann nicht gelöscht werden, da eine Zelle bereits in Benutzung ist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            target_row_locked:
              'Zeile kann nicht gelöscht werden, da eine Zelle bereits in Benutzung ist.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_term_empty: 'Kein Term angegeben.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_term_invalid: 'Term ist ungültig bei: ',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_delete_error: 'Variable konnte nicht gelöscht werden.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_missing_data: 'Bitte alle Felder ausfüllen.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_name_exists: 'Variable bereits vorhanden.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_name_forbidden:
              'Variablen mit @value, @previous_value und @cell_ am Anfang sind nicht erlaubt.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_name_invalid:
              'Variablen Name hat falsche Struktur. Bsp: @text_1@ (Zahlen, Unterstrich, Kleinbuchstaben)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_save_error: 'Variable konnte nicht gespeichert werden.',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_transfer_error: 'Fehler beim Transferieren der Daten.'
          },
          title: {
            error: 'Fehler',
            information: 'Notiz',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            mandatory_fields: 'Pflichtfelder',
            success: 'Erfolg',
            warning: 'Warnung'
          }
        },
        misc: {
          acknowledgements: 'Empfangsbestätigungen',
          actions: 'Maßnahmen',
          backup: 'Backup',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          backup_on_load: 'Backup, Aktion: Laden',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          backup_on_save: 'Backup, Aktion: Speichern',
          buttons: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            add_action: 'Maßnahme hinzufügen',
            apply: 'Anwenden',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            apply_drawing: 'Verwenden',
            browse: 'Durchsuchen',
            close: 'Schließen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            documents_add: 'Dokumente hinzufügen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            emails_message: 'E-Mail Nachricht',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            emails_receivers: 'E-Mail Empfänger',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            emails_send: 'E-Mails versenden',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            emails_settings: 'E-Mail Einstellungen wählen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_binding: 'Bild einbinden',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            import_all: 'Alle importieren',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            new_variable: 'Neue Variable',
            ok: 'OK',
            print: 'Drucken',
            remove: 'Entfernen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_cell_values_matrix: '',
            save: 'Speichern',
            search: 'Suche',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_escalations: 'Eskalationen/Erinnerungen wählen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_source: 'Baum wählen',
            upload: 'Hochladen'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          cell_comment_printer: 'Kommentar:',
          checklist: 'Checkliste',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          checklist_options: 'Checklisten Einstellungen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          checklist_quick_options: 'Einstellungen:',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_links: 'Versandte Checklisten',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_options: 'Allgemeine Einstellungen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_workflow: 'Workflow',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_workflow_options: 'Workflow Einstellungen',
          column: 'Spalte',
          comment: 'Kommentar',
          created: 'Erstellt',
          dataTable: {
            oAria: {
              sSortAscending: ': Aktivieren um Spalte aufsteigend zu sortieren',
              sSortDescending: ': Aktivieren um Spalte absteigend zu sortieren'
            },
            oPaginate: {
              sFirst: '«',
              sLast: '»',
              sNext: '›',
              sPrevious: '‹'
            },
            sEmptyTable: 'Keine Daten in Tabelle vorhanden...',
            sInfo: 'Zeige _START_ bis _END_ von _TOTAL_ Einträgen',
            sInfoEmpty: 'Zeige 0 bis 0 von 0 Einträgen',
            sInfoFiltered: '(gefiltert aus insgesamt _MAX_ Einträgen )',
            sInfoPostFix: '',
            sInfoThousands: ',',
            sLengthMenu: 'Zeige _MENU_ Einträge',
            sLoadingRecords: 'Lade...',
            sProcessing: 'Aufbereitung...',
            sSearch: 'Suche:',
            sUrl: '',
            sZeroRecords: 'Keine übereinstimmenden Einträge gefunden'
          },
          date: 'Datum',
          datetime: 'Datum, Uhrzeit',
          deleted: 'Gelöscht',
          document: 'Dokument',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          document_management: 'Dokumente Verwaltung',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          document_overview: 'Dokumente Übersicht',
          documents: 'Dokumente',
          done: 'Erledigt',
          download: 'Download',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          download_documents: 'Herunterladbare Dokumente',
          drawings: 'Zeichnungen',
          due: 'Fälligkeit',
          email: {
            acknowledgement: 'Empfangsbestätigung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            btn_default: 'E-Mail versenden',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sender_current_user: 'Aktueller Benutzer als Absender:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sender_current_user_reply_to:
              'Aktueller Benutzer als Antwortadresse:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sender_email: 'Absender E-Mail Adresse:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sender_lastname: 'Absender Nachname:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sender_surname: 'Absender Vorname:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            informed_at: 'Informiert/Versendet am: ',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            manual_email: 'E-Mail Adresse des Empfängers:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            manual_name: 'Name des Empfängers:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            manual_select: 'Manuelle Einträge:',
            message: 'Nachricht',
            recipient: 'An (Empfänger):',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_bcc: 'BCC:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_ccc: 'CC:',
            subject: 'Betreff',
            pending: 'Ausstehend'
          },
          employee: 'Mitarbeiter',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          employee_number: 'MitarbeiterNummer',
          escalated: 'Eskaliert',
          files: 'Dateien',
          generate: 'Generierung',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          generated_documents: 'Generierte Dokumente',
          group: 'Gruppe',
          image: 'Bild',
          index: 'Index',
          label: 'Bezeichnung',
          lastname: 'Nachname',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          loading_content: 'Lade Inhalt...',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          loading_content_disabled:
            'Laden von Inhalt im aktuellen Modus deaktiviert.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          loading_content_unknown:
            'Unbekannter Inhalt kann nicht geladen werden.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          loading_content_unprintable: 'Nicht druckbarer externer Inhalt: ',
          modified: 'Geändert',
          new: 'Neu',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          new_checklist: 'Neue Checkliste',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          next_column: 'nächste Spalte',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          next_row: 'nächste Zeile',
          no: 'Nein',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          no_employee: 'kein Mitarbeiter',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          open_editor: 'Editor öffnen',
          path: 'Pfad',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          preview_settings: 'Temporäre Vorschau-Einstellungen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          previous_column: 'vorherige Spalte',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          previous_row: 'vorherige Zeile',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          printer_options: 'Druckeinstellungen',
          reminded: 'Erinnert',
          row: 'Zeile',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          search_result: 'Suchergebnis:',
          status: 'Status',
          surname: 'Vorname',
          time: 'Uhrzeit',
          type: 'Typ',
          upload: 'Upload',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          upload_documents: 'Hochgeladene Dokumente',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          user_comment_printer: 'Benutzerkommentar:',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          variable_management: 'Variablen Verwaltung',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          variable_transfer: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            transfer_of_checklist_employee:
              'Daten der Variable von der ausgewählten Checkliste auf die derzeitige transferieren:'
          },
          warning: 'Warnung',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          workflow_node_types: {
            circle: 'Kreis',
            cloud: 'Wolke',
            default: 'Standard',
            ellipse: 'Ellipse',
            rhombus: 'Raute',
            square: 'Quadrat'
          },
          yes: 'Ja'
        },
        placeholders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          backup_label: 'Bezeichnung',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          element_comment: 'Kommentar hier einfügen.',
          scaling: 'Pixel oder Prozentual'
        },
        tables: {
          variables: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            loading_failed: 'Fehler beim Laden der Variablen-Tabelle.'
          }
        },
        templates: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_links: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_0: 'Versandt',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_1: 'Geöffnet',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_2: 'In Bearbeitung',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_3: 'Abgelehnt',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_4: 'Erledigt'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          display_type: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            linked_accordion: 'Verknüpftes Akkordeon',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            linked_list: 'Verknüpfte Liste',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            linked_tabs: 'Verknüpfte Tabs'
          },
          documents: {
            comment: 'Bemerkung',
            document: 'Dokument',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            file_name: 'Dateiname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_file: 'Generieren/Herunterladen',
            received: 'Erhalten',
            relevant: 'Relevant?',
            send: 'Generiert',
            status: 'Status'
          },
          dropdown: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_display_type_0: 'Normal',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_display_type_1: 'Warnung',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_display_type_2: 'Info',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_display_type_3: 'Gefahr',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_type_0: ' - ',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_type_1: 'Im gleichen Tab',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_type_2: 'Im neuen Tab',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_type_3: 'Im gleichen Tab mit Kopfzeile',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            directive_sections: {
              'directive-edit-analysis': 'zugeordnete Gefährdungsbeurteilungen',
              'directive-edit-data': 'Inhalt',
              'directive-edit-details': 'Kopfdaten',
              'directive-laa-activities': 'Prozesse/Tätigkeiten',
              'directive-laa-locations': 'Orte',
              'ui-accordion-directive-edit-panel-5': 'Audits'
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_frame_0: 'Keiner',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_frame_1: 'Voll',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_frame_2: 'Unten',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_type_1: 'Linie',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_type_2: 'Kreis',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_type_3: 'Quadrat',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_type_4: 'Radierer',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            emkg_sections: {
              'subst_edit_p10_tabs-1': 'Stammdaten',
              'subst_edit_p10_tabs-3': 'Haut',
              'subst_edit_p10_tabs-4': 'Einatmen & Brandschutz',
              'subst_edit_p10_tabs-5': 'Komplexe Bewertung',
              'subst_edit_p10_tabs-6': 'abschl. Bewertung',
              'subst_edit_p10_tabs-7': 'Dokumente',
              'subst_edit_p10_tabs-8': 'Checkliste',
              'subst_edit_p10_tabs-9': 'Maßnahmen',
              'subst_edit_p10_tabs-laaActivities': 'Prozesse/Eigenschaften',
              'subst_edit_p10_tabs-laaLocations': 'Orte',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              subst_emkg_details_content: 'Schutzleitfäden',
              'substance-emkg-edit-log': 'Protokoll',
              'tabs-substitutiontest': 'Substitutionsprüfung'
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_0: 'Bitte wählen...',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_1: 'Bitte wählen.../Ja/Nein',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_2: 'Nicht zutreffend.../Ja/Nein',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_3: 'Unbekannt/Ja/Nein',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_4: 'Keine Vorlage',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_value_changed_default: '-',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            optgroup_categories_assigned: 'Modul zugehörig',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            optgroup_categories_unassigned: 'Keinem Modul zugehörig',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_0: 'Bitte wählen...',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_1: 'Nicht zutreffend...',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_10: 'Rechts',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_11: 'Keine',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_12: 'Eingabe',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_13: 'Mehrfachauswahl',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_14: 'Beide',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_15: 'Nichts sperren',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_16: 'Kollektion sperren',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_17: 'Checkliste sperren',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_18: 'Eingabe (Nur Auswahl)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_19: 'Standard Lokalisierung',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_2: 'Unbekannt',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_20: 'Text (a-Z, 0-9)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_21: 'Nummer (0-9)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_22: 'Hexadezimal (0-F)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_23: 'Binär (0-1)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_3: 'Ja',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_4: 'Nein',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_5: 'Oben',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_6: 'Mitte',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_7: 'Unten',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_8: 'Links',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_9: 'Mitte',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_loading: 'Inhalt wird geladen...',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            option_no_data: 'Keine Daten vorhanden...',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            print_format_0: 'Hochformat',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            print_format_1: 'Querformat',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_0: 'Standard',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_1: 'Text',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_2: 'Liste (kommasepariert)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_3: 'Liste (neue Zeile)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_4: 'Liste (kommasepariert, neue Zeile)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_5: 'Liste (Aufzählungspunkte)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_6: 'Schieberegler (ausgeblendeter Button)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            substance_sections: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_1: 'Abschnitt 1: Gefahrstoff',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_13: 'Abschnitt 13: Entsorgung',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_14: 'Abschnitt 14: Transport',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_15: 'Abschnitt 15: Rechtsvorschriften',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_16:
                'Verwendungsspezifische Angaben, Gefährdungsbeurteilung',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_17: 'Zugehörige Dokumente',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_18: 'Freigabe',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_19: 'Prozesse/Eigenschaften',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_1b: 'Abschnitt 1B: Gefahrstoff (weitere Daten)',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_2: 'Abschnitt 2: Einstufung nach GHS/GefStoffV',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_20: 'Checkliste',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_3: 'Abschnitt 3: Inhaltsstoffe',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_4: 'Abschnitt 4: Erste Hilfe',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_5: 'Abschnitt 5: Maßnahmen zur Brandbekämpfung',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_6:
                'Abschnitt 6: Maßnahmen bei unbeabsichtigter Freisetzung',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_7: 'Abschnitt 7: Handhabung und Lagerung',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_8: 'Abschnitt 8: Exposition',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_8b:
                'Abschnitt 8 PSA: Persönliche Schutzausrüstung',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              substance_panel_9: 'Abschnitt 9: Eigenschaften'
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_checklist_status_1: 'Unerledigt',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_checklist_status_2: 'Sichtbar',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_checklist_status_3: 'Unerledigt & Sichtbar',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_checklist_status_4: 'Erledigt',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_checklist_status_6: 'Erledigt & Sichtbar',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_0: '-',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_1: 'Mitarbeiter',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_2: 'Jahr',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_3: 'Jahr & Mitarbeiter',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_4: 'Monat & Jahr',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_5: 'Monat & Jahr & Mitarbeiter',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_6: 'Checkliste & Mitarbeiter',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_7: 'EMKG',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_8: 'Gefahrstoff',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_reference_9: 'Ort',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_type_1: 'Lokal',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_type_2: 'Global'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          dropdown_groups: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_dropdowns: 'Sonstige Vorlagen Aufklappmenü',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_sql: 'Sonstige Vorlagen SQL',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            default_dropdowns: 'Standard Vorlagen Aufklappmenü',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            default_sql: 'Standard Vorlagen SQL',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_manually_added: 'Hinzugefügte Einträge'
          },
          elements: {
            checkbox: 'Checkbox',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            combine_selects: 'Kombinationsfeld (Aufklappmenü)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            combine_selects_custom: 'Kombinationsfeld (Erweitert)',
            countdown: 'Zeitkontrolle',
            create: 'Element erstellen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button: 'Schaltfläche',
            date: 'Datum',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            date_time: 'Datum & Zeit',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_management: 'Dokumente Verwaltung',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board: 'Zeichenbrett',
            dropdown: 'Aufklappmenü',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            dropdown_sql: 'Aufklappmenü (SQL)',
            employee: 'Mitarbeiter-Auswahl',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search: 'Mitarbeitersuche',
            empty: 'Leer',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            external_content: 'Externer Inhalt',
            image: 'Bild',
            label: 'Label & Überschrift',
            multiselect: 'Mehrfach-Auswahl',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            multiselect_open: 'Mehrfach-Auswahl (offen)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            multiselect_sql: 'Mehrfach-Auswahl (SQL)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            number_float: 'Zahl (Float)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            number_integer: 'Zahl (Integer)',
            radio: 'Radio Buttons',
            slider: 'Schieberegler',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking: 'Aufgabenerfassung',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code: 'Text (Code)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_display: 'Text Anzeige (HTML)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_display_formatted: 'Text Anzeige (formatiert)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_email_formatted: 'E-Mail (formatiert)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_formatted: 'Text (formatiert)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_long: 'Text (lang)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_replace: 'Text ersetzen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_replace_formatted: 'Text ersetzen (formatiert)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_short: 'Text (kurz)',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_variables_formatted: 'Variablen Anzeigen (formatiert)',
            time: 'Zeit',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            time_recording: 'Zeiterfassung',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            total_amount: 'Gesamtbetrag',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            total_amount_dates: 'Gesamtbetrag (Datum)',
            tree: 'Baum'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          email_tokens: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            affected_employee_lastname: 'Betroffener Mitarbeiter: Nachname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            affected_employee_mail: 'Betroffener Mitarbeiter: E-Mail',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            affected_employee_name: 'Betroffener Mitarbeiter: Name',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            affected_employee_number:
              'Betroffener Mitarbeiter: Mitarbeiternummer',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            affected_employee_surname: 'Betroffener Mitarbeiter: Vorname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            link_acknowledgement: 'Hyperlink: E-Mail Bestätigung',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            link_collection_link: 'Hyperlink: Versandte Checkliste',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_lastname: 'Empfänger: Nachname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_mail: 'Empfänger: E-Mail',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_name: 'Empfänger: Name',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_number: 'Empfänger: Mitarbeiternummer',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            recipient_surname: 'Empfänger: Vorname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            referrer_lastname: 'Referent: Nachname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            referrer_mail: 'Referent: E-Mail',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            referrer_name: 'Referent: Name',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            referrer_number: 'Referent: Mitarbeiternummer',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            referrer_surname: 'Referent: Vorname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sender_lastname: 'Absender: Nachname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sender_mail: 'Absender: E-Mail',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sender_name: 'Absender: Name',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sender_number: 'Absender: Mitarbeiternummer',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sender_surname: 'Absender: Vorname'
          },
          options: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            activate_checklist_tab_on_unlock:
              'Aktive Checkliste bei Freischaltung:',
            active: 'Aktiv:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            backup_add: 'Temporäres Backup hinzufügen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            backup_load: 'Temporäres Backup laden:',
            category: 'Kategorie:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            checklist_rows_colored:
              'Abwechselnde Farben pro Checklisten-Zeile:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_create: 'Klone erstellen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_maximum: 'Maximale Anzahl an Klonen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            clone_type: 'Klonen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            confirm_delete: 'Löschen bestätigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            copy_cells: 'Zellen in Zwischenspeicher kopieren:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            default_module_collection: 'Standard Checkliste für Modul:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_values_on_hide:
              'Werte der Checkliste beim Ausblenden löschen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_type: 'Anzeige-Typ:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            editability_rights: 'Benötigte Rechte für die Bearbeitung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            extra_info: 'Zusatz Information:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            extra_info_rights: 'Rechte Zuordnung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hide_cell_borders: 'Umrandung von Zellen ausblenden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hide_header: 'Kopfzeile ausblenden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ignore_editability_rights: 'Bearbeitungsrechte ignorieren:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ignore_visibility_rights: 'Anzeigerechte ignorieren:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ignore_workflow_rights: 'Workflowrechte ignorieren:',
            label: 'Beschreibung:',
            localization: 'Lokalisierung:',
            module: 'Modul:',
            offline: 'Offline:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            offline_available: 'Offline verfügbar:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            paste_cells: 'Zellen aus Zwischenspeicher einfügen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            print_format: 'Format:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            print_format_workflow: 'Anzeigeformat des Workflows:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            print_new_page: 'Auf neue Seite drucken:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            required_progress: 'Benötigter Fortschritt:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            requirement: 'Anzeige-Abhängigkeit:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            requirement_matrix: 'Anzeige-Abhängigkeiten Matrix:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return_paths: 'Workflow Rückwege:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_in_printer_only: 'Nur im Druck anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_in_workflow: 'Im Workflow anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_printer_hidden_cells: 'Ausgeblendete Zellen anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_printer_hidden_checklists:
              'Ausgeblendete Checklisten anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_printer_workflow: 'Workflow anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            show_viewer_workflow: 'Workflow im Viewer Anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            unlock_group: 'Gruppe zur Freischaltung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            unlock_group_partial:
              'Freischaltung wenn Gruppen teilweise erledigt sind:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            visibility_rights: 'Benötigte Rechte für die Anzeige:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            workflow_node_type: 'Workflow Knotentyp:'
          },
          settings: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation: 'Eskalation/Erinnerung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_description:
              'Eskalation/Erinnerung Beschreibung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_display_type:
              'Eskalation/Erinnerung Anzeige Typ:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_title: 'Eskalation/Erinnerung Titel:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_tree_node:
              'Eskalation/Erinnerung Eintrag wählen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_generation_tree_node_label: 'Eskalation/Erinnerung Eintrag:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            actions: 'Maßnahmen hinzufügen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            adopt_value: 'Auf Standardwert setzen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            alignment_horizontal: 'Horizontale Textausrichtung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            alignment_vertical: 'Vertikale Textausrichtung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            button_alignment: 'Zentrierung von Schaltflächen: ',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cell_background_color: 'Hintergrundfarbe Zelle:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cloneable: 'Klonbar:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cloneable_count: 'Maximale Anzahl:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links: 'Checkliste versenden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_delete_entry: 'Eintrag und Werte entfernen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_label_link: 'Label für Checklisten-Hyperlink:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_open_entry: 'Checkliste aus Viewer öffnen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_select: 'Auswahl Checkliste:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_select_label: 'Label für Auswahl im Viewer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_select_viewer: 'Auswahl Checkliste im Viewer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            collection_links_show_table:
              'Checklisten-Status im Viewer anzeigen:',
            color: 'Farbe:',
            color0: 'Farbe 1:',
            color1: 'Farbe 2:',
            color2: 'Farbe 3:',
            colors: 'Farbverlauf:',
            colspan: 'Anzahl an Spalten für Zelle:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            combine_sep_operator: 'Separator/Operator:',
            comment: 'Kommentar hinzufügen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            copyable_cell_value: 'Wert der Zelle kopieren:',
            count: 'Zählt für Fortschritt:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            count_hidden: 'Ausgeblendetes Feld zählen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_done: `Zeitkontrolle auf 'Erledigt' setzen:`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_done_label: `Label für Zeitkontrolle 'Erledigt':`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_expired: 'Nach Ablauf:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_hide: 'Zeitkontrolle verstecken:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_hours: 'Zeitkontrolle Stunden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_minutes: 'Zeitkontrolle Minuten:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            countdown_seconds: 'Zeitkontrolle Sekunden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_action: 'Schaltfläche Aktion:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_collection: 'Checkliste:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_copy_ae_locations:
              'Orte des betroffenen Mitarbeiters für EMKG verwenden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_copy_au_locations:
              'Orte von Audit für EMKG verwenden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_copy_emkg_locations_activities:
              'Orte und Prozesse/Eigenschaften aus EMKG für Betriebsanweisung verwenden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_directive_section: 'Betriebsanweisung Abschnitt:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_directive_type: 'Typ der Betriebsanweisung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_email_addresses: 'E-Mail Empfänger:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_email_attachments: 'E-Mail Anhänge:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_email_message: 'E-Mail Nachricht:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_emkg_section: 'EMKG Abschnitt:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_label: 'Schaltfläche Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_medical_preparedness_data:
              'Vorsorgeuntersuchung (Bezeichnung):',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_on_click: 'Vorauswertung ausführen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_open_directive_window_readonly: 'Lesemodus:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_open_emkg_dialog_readonly: 'Lesemodus:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_open_emkg_substitution:
              'Substitutionsprüfung öffnen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_open_emkg_tab: 'Vorausgewählter Tab:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_open_substance_window_readonly: 'Lesemodus:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_open_substitution_dialog_readonly: 'Lesemodus:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_params: 'Schaltfläche Parameter:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_set_audit_due_offset:
              'Abweichung des voreingestellten Datums in Tagen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_set_audit_due_today:
              'Heutiges Datum als Voreinstellung benutzen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_substance_section: 'Gefahrstoff-Abschnitt:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_url: 'Schaltfläche Pfad/URL:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_uw_create: 'Unterweisung erstellen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_uw_delete: 'Unterweisung löschen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_uw_modify: 'Unterweisung bearbeiten:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_uw_start_date: 'Datum Unterweisung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_uw_start_date_offset:
              'Datum Unterweisung Abweichung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            custom_button_width: 'Schaltfläche Breite:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            date_start_offset_days: 'Abweichung vom Startdatum (Tage):',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            date_start_today: 'Startdatum heute:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_checklists: 'Auswahl Checklisten-Anzeige:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_checklists_editable: 'Checklisten-Anzeige editierbar:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_checklists_label: 'Label für Checklisten-Anzeige:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_checklists_width: 'Breite für Checklisten-Anzeige:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_pattern: 'Anzeigevorlage:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            display_requirements_inverse: 'Anzeigeabhängigkeiten umkehren:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            document_builder: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              change_relevance: 'Relevanz änderbar:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              keep_generated_documents: 'Generierte Dokumente behalten:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              label: 'Label',
              relevance: 'Relevanz',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              relevance_0: 'Dokumente werden immer benötigt',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              relevance_1:
                'Dokumente werden benötigt (änderbar durch Benutzer)',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              relevance_2:
                'Dokumente werden nicht benötigt (änderbar durch Benutzer)',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              relevance_3:
                'Relevanz nicht festgelegt (änderbar durch Benutzer)',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              reminder_received_days: "Erinnerung 'Erhalten' in Tagen:",
              // eslint-disable-next-line @typescript-eslint/naming-convention
              reminder_send_days: "Erinnerung 'Generiert' in Tagen:",
              select: 'Dokumente auswählen',
              settings: 'Erweiterte Einstellungen',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              show_comment: "Zeige 'Bemerkung':",
              // eslint-disable-next-line @typescript-eslint/naming-convention
              show_received: "Zeige 'Erhalten':",
              // eslint-disable-next-line @typescript-eslint/naming-convention
              show_send: "Zeige 'Generiert':"
            },
            documents: 'Dokumente verwalten:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            documents_hide_add: 'Dokument hinzufügen ausblenden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_advanced_down_scale:
              'Zeichenbrett verbessertes herunterskalieren:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_auto_scale_canvas:
              'Zeichenbrett automatisch skalieren:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_auto_scale_ribbons:
              'Zeichenbrett Ribbons automatisch skalieren:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_background_color: 'Zeichenbrett Hintergrundfarbe:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_canvas_height: 'Zeichenbrett Höhe:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_canvas_width: 'Zeichenbrett Breite:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_drawing_disabled:
              'Zeichenbrett zeichnen deaktivieren:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon: 'Zeichenbrett Ribbon:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_auto_scale_image:
              'Bild automatisch skalieren:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_crop: 'Zuschneiden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_delete: 'Inhalt löschen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_open_document_in_tab: 'Dokument öffnen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_restore: 'Rückgängig & Wiederherstellen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_rotate: 'Drehen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_save_as_document: 'Als Dokument speichern:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_scale: 'Skalieren:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_stroke_color: 'Farbe des Strichs:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_stroke_size: 'Größe des Strichs:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_stroke_type: 'Typ des Strichs:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_ribbon_upload_image: 'Bild hochladen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_color: 'Zeichenbrett Farbe des Strichs:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_size: 'Zeichenbrett Größe des Strichs:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_board_stroke_type: 'Zeichenbrett Typ des Strichs:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_frame: 'Zeichnung Rahmen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_height: 'Zeichnung Höhe:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_protected: 'Zeichnung geschützt:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_save_as_file: 'Zeichung gespeichert als Datei:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_timestamp: 'Zeichnung Zeitstempel:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_timestamp_date: 'Datum:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_timestamp_time: 'Zeit:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            drawing_width: 'Zeichnung Breite:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            dropdown_builder: 'Auswahl-Optionen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            dropdown_builder_1: 'Auswahl Optionen #2:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            dropdown_builder_2: 'Auswahl Optionen #3:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_acknowledgement_editable:
              'E-Mail Bestätigungs-Einstellung im Viewer:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_acknowledgement_label_link:
              'Label für E-Mail Bestätigungs-Hyperlink:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_audit_affected_employee_supervisors_as_recipient:
              'Vorgesetzte(n) des betroffenen Mitarbeiters eines Audits als E-Mail Empfänger:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_audit_created_by_user_as_recipient:
              'Ersteller des Audits als E-Mail Empfänger:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_btn_label: 'Label E-Mail Button:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_by_user: 'E-Mail Eingabe(Benutzer):',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_download_document_attachment:
              'Anhang von herunterladbaren Dokumenten an E-Mail:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_download_document_attachment_zipped:
              'Herunterladbare Dokumente in Anhang zippen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_drawing_attachment: 'Anhang von Zeichnungen an E-Mail:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_drawing_attachment_zipped: 'Zeichnungen in Anhang zippen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_generated_document_attachment:
              'Anhang von generierten Dokumenten an E-Mail:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_generated_document_attachment_zipped:
              'Generierte Dokumente im Anhang zippen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_inactive_users: 'Inaktive Mitarbeiter anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_text_editable: 'E-Mail im Viewer editierbar:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_upload_document_attachment:
              'Anhang von hochgeladenen Dokumenten an E-Mail:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            email_upload_document_attachment_zipped:
              'Hochgeladene Dokumente in Anhang zippen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            emails: 'E-Mail Einstellungen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_birthday: ' Filter für Geburtstag:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_birthday_label: 'Label für Geburtstag:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_company: 'Filter für Firma:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_company_label: 'Label für Firma:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_lastname: 'Filter für Nachname:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_lastname_label: 'Label für Nachname:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_surname: 'Filter für Vorname',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            employee_search_surname_label: 'Label für Vorname:',
            files: 'Dateien hinzufügen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            font_bold: 'Schrift fett:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            font_italic: 'Schrift kursiv:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            font_size: 'Schriftgröße:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hide_cell_in_cloned_checklist:
              'Zelle in geklonter Checkliste ausblenden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hide_in_report: 'In Report ausblenden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_alternate_text: 'Bild alternativer Text:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_clickable: 'Bild klickbar:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_data: 'Bild einbinden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_height: 'Bildhöhe:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            image_width: 'Bildbreite:',
            inactive: 'Inaktiv:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            inner_label_0: 'Inneres Label #1:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            inner_label_1: 'Inneres Label #2:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            inner_label_2: 'Inneres Label #3:',
            label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_position: 'Label Position:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            label_width: 'Label Breite:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            load_external_content: 'Lade externen Inhalt:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            load_external_content_directive_section:
              'Abschnitt aus Betriebsanweisung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            load_external_content_emkg_section: 'Abschnitt aus EMKG:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            load_external_content_substance_section: 'Gefahrstoff-Abschnitt:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            locked_content: 'Festgeschriebener Inhalt:',
            logging: 'Protokollierung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            logging_display: 'Protokollierung anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            logging_display_never_locked: 'Sperrung der Zelle ignorieren:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            mandatory_field: 'Pflichtfeld:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            mandatory_field_hidden: 'Augeblendetes Pflichtfeld ignorieren:',
            max: 'Maximum:',
            min: 'Minimum:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback: 'Zelle als Schaltfläche:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_close: 'Dialog schließen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_message_error: 'Nachricht bei Fehler:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_message_success: 'Nachricht bei Erfolg:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_multi: 'Mehrere Rückgabe-Werte:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_multi_value_0: 'Wert #1:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_multi_value_1: 'Wert #2:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_multi_value_2: 'Wert #3:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_single: 'Einzelner Rückgabe-Wert:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_click_callback_single_value: 'Wert:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            on_value_changed: 'Aktion nach Änderung des Wertes:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions: 'Maßnahmen im Druck anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions_comment: 'Kommentar:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions_done: 'Erledigt:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions_due: 'Fälligkeit:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions_label: 'Bezeichnung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_actions_status: 'Status:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_collection_links:
              'Versandte Checklisten im Druck anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_comment: 'Kommentar im Druck anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_files: 'Dateien im Druck anzeigen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_files_comment: 'Kommentar:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_files_due: 'Fälligkeit:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_files_label: 'Bezeichnung:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_common: 'Anzeigeformat im Druck:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_multiselect: 'Anzeigeformat im Druck:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            printing_display_type_slider: 'Anzeigeformat im Druck:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            radio_btn_label_1: 'Label Radiobutton 1:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            radio_btn_label_2: 'Label Radiobutton 2:',
            rowspan: 'Anzahl an Zeilen für Zelle:',
            scale: 'Skala:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            select_builder: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              option_add: 'Option hinzufügen:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              option_color: 'Option Farbe:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              option_delete: 'Option entfernen:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              option_edit: 'Option bearbeiten:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              template_apply: 'Vorlage übernehmen:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              template_delete: 'Vorlage löschen:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              template_save: 'Vorlage speichern:'
            },
            size: 'Größe:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            slider_max_hidden: 'Maximum ausblenden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            slider_min_hidden: 'Minimum ausblenden:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            slider_value_custom_look: 'Wert hervorheben:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            slider_value_hidden: 'Wert ausblenden:',
            sql: 'SQL:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_apply_once: 'SQL-Daten einmalig anfragen und zuweisen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sql_builder: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              button_title_apply: 'Vorlage anwenden',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              button_title_delete: 'Vorlage löschen',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              button_title_save: 'Vorlage speichern',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              placeholder_label: 'Vorlage-Bezeichnung',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              template_management: 'Vorlage-Verwaltung'
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            status_icon: 'Zustandssymbol:',
            step: 'Schritt:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking_check_editability_right:
              'Aufgabenerfassung Recht für Bearbeitung prüfen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking_check_visibility_right:
              'Aufgabenerfassung Recht für Sichbarkeit prüfen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking_checklist: 'Aufgabenerfassung Checkliste:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking_collection: 'Aufgabenerfassung Kollektion:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_tracking_priority: 'Aufgabenerfassung Priorität:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_0: 'Eingabe Code #1:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_0_label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_0_length: 'Länge:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_0_optional: 'Optional:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_0_type: 'Typ:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_1: 'Eingabe Code #2:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_1_label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_1_length: 'Länge:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_1_optional: 'Optional:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_1_type: 'Typ:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_2: 'Eingabe Code #3:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_2_label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_2_length: 'Länge:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_2_optional: 'Optional:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_2_type: 'Typ:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_3: 'Eingabe Code #4:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_3_label: 'Label:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_3_length: 'Länge:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_3_optional: 'Optional:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            text_code_input_3_type: 'Typ:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            textarea_fixed_rows: 'Textfeld feste Höhe:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            textarea_fixed_rows_count: 'Textfeld Zeilen:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            token_builder: {
              target: 'Ziel:',
              token: 'Token:'
            },
            tokens: 'Tokens:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_source: 'Baum:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_source_label: 'Baum Eintrag:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_source_unlock: 'Baum:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_source_unlock_label: 'Baum Eintrag:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tree_unlock: 'Baum für Benachrichtigung bei Freischaltung:',
            type: 'Typ:',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_event_builder: {
              action: 'Aktion:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              affected_employee: 'Betroffener Mitarbeiter:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              checklist_index: 'Checkliste:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              checklist_status: 'Checklisten-Status:',
              collection: 'Checkliste:',
              color: 'Farbe:',
              column: 'Spalte:',
              comment: 'Kommentar:',
              comparator: 'Vergleichsoperator:',
              content: 'Inhalt:',
              date: 'Datum:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              date_offset: 'Abweichung in Tagen für Datum:',
              date1: 'Datum #1:',
              date2: 'Datum #2:',
              decrypt: 'Entschlüsseln:',
              encrypt: 'Verschlüsseln:',
              executor: 'Ausführender:',
              id: 'ID:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              id_optional: 'ID (optional):',
              interval: 'Intervall:',
              label: 'Bezeichnung:',
              location: 'Ort:',
              matrix: 'Zellen auswählen:',
              msg: 'Nachricht:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              offset_days_date1: 'Abweichung in Tagen für Datum #1:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              open_new_window: 'In neuem Fenster öffnen:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              pin_length: 'PIN länge:',
              remove: 'Enfernen:',
              responsible: 'Verantwortlicher:',
              sql: 'SQL:',
              table: 'Tabelle:',
              term: 'Term:',
              type: 'Typ:',
              value: 'Wert:',
              variable: 'Variable:',
              vars: 'Variablen setzen:',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              visible_and_editable_only: 'Nur für sichtbare und editierbare:'
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            value_events: 'Ereignisse bei Wert:'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          value_events: {
            abort: 'Abbruch',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            action_creation_forced: 'Maßnahme erforderlich/eintragen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            add_csv_entry_instruction:
              'Eintrag in CSV hinzufügen für Fremdmitarbeiter inkl. Besuchermanagement',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            apply_sql_result_to_variable: 'SQL-Ergebnis an Variable zuweisen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            change_background_color: 'Hintergrundfarbe ändern',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            change_outline_color: 'Umrandungsfarbe ändern',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            change_photo_of_user_instruction: 'Foto ändern für Unterweisungen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            check_undone_instructions_by_visit_reason:
              'Überprüfen von unerledigten Unterweisungen nach Besuchsgrund',
            completed: 'Erledigt',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_accident_report_checklist_status:
              'Unfallbericht Checklisten-Status erstellen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_actions: 'Maßnahmen erstellen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_audit: 'Audit erstellen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_audit_checklist_status: 'Audit Checklisten-Status erstellen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_audit_continuation: 'Audit fortsetzen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_audit_visitor_container:
              'Eintrag für Fremdmitarbeiter inkl. Zutrittsmanagement erstellen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_employee: 'Mitarbeiter anlegen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_new_substance_version: 'Gefahrstoff neue Version erstellen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_substance_emkg_actions:
              'Aufgaben für alle Verantwortlichen von allen EMKGs des Gefahrstoffs erstelllen ',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            date_comparison: 'Datumsvergleich',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_accident_report_checklist_status:
              'Unfallbericht Checklisten-Status löschen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            delete_audit_checklist_status: 'Audit Checklisten-Status löschen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_dbentry_value: 'Wert von Datenbankeintrag holen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            get_pin_instruction:
              'PIN generieren für Fremdmitarbeiter inkl. Zutrittsmanagement',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hide_cell: 'Inhalt der Zelle ausblenden',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            lock_cell: 'Zelle sperren',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            lock_collection: 'Kollektion sperren',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            print_instruction_zertificate:
              'Drucken von Zertifikat für Unterweisungen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            reload_external_content: 'Externen Inhalt neu laden',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            reload_sql: 'SQL-Abfrage neu laden',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remove_cell_values: 'Werte aus Zellen zurücksetzen/löschen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            requires_action: 'Maßnahme benötigt',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            set_accident_report_checklist_status:
              'Unfallbericht Checklisten-Status setzen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            set_audit_affected_employee:
              'Betroffenen Mitarbeiter im Audit setzen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            set_audit_checklist_status: 'Audit Checklisten-Status setzen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            set_audit_interval: 'Audit Intervall setzen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            set_dbentry_value: 'Wert für Datenbankeintrag setzen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            set_location_variables: 'Ort für Variablen festlegen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            trigger_cell_events: 'Ereignisse von Zellen auslösen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            update_tree_mappings: 'Baum-Mapping aktualisieren',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            variable_modify: 'Variablen Werte ändern',
            warning: 'Warnung'
          },
          variables: {
            alias: 'Alias',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            default_value: 'Standardwert',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            fractional_digits: 'Nachkommastellen',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            in_database: 'In Datenbank',
            limit: 'Grenzwert',
            name: 'Name',
            reference: 'Bezug',
            type: 'Typ'
          }
        },
        titles: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          add_actions: 'Maßnahmen hinzufügen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          add_comment: 'Kommentar hinzufügen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          adopt_value: 'Auf Standardwert setzen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          change_color_overlay: 'Overlay für temporäre Farbänderung',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          checklist_rows_colored: 'Abwechselnde Farben pro Checklisten-Zeile',
          color: 'Farbe',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_add: 'Spalte hinzufügen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_clone: 'Spalte klonen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_copy: 'Spalte in Zwischenspeicher kopieren',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_delete: 'Spalte entfernen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_left: 'Spalte nach links',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_paste: 'Spalte aus Zwischenspeicher einfügen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_right: 'Spalte nach rechts',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          column_width: 'Eingabefeld für Spaltenbreite ein-/ausblenden',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          copy_cell: 'Zelle in Zwischenspeicher kopieren',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          countdown_done: `Zeitkontrolle abbrechen und auf 'Erledigt' setzen.`,
          crop: 'Zuschneiden',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          delete_content: 'Inhalt löschen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          display_checklists: 'Zeige zugehörige Checkliste.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          display_log: 'Protokollierung anzeigen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          display_original_image: 'Original Bild anzeigen',
          downscale: 'Herunterskalieren',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          drawing_board_display: 'Hier klicken um Zeichenbrett zu öffnen.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          edit_settings: 'Einstellungen der Checkliste bearbeiten',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          field_exclusivity_overlay: 'Overlay für Ergebnis-Exklusivität',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          field_lock_overlay: 'Overlay für Zellsperrung',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          hide_cell_borders: 'Umrandung von Zellen ausblenden',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          hide_clone: 'Klon entfernen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          paste_cell: 'Zelle aus Zwischenspeicher einfügen',
          redo: 'Wiederherstellen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          remove_element: 'Element entfernen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          remove_values: 'Alle hinterlegten Werte für diese Zelle löschen.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          required_action: 'Bitte legen Sie eine Maßnahme an.',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          requirement_overlay: 'Overlay für Anzeige-Abhängigkeiten',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          reset_cell: 'Zelle zurücksetzen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          reset_cell_value: 'Wert zurücksetzen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          rotate_left_90: 'Um 90° nach links drehen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          rotate_right_90: 'Um 90° nach rechts drehen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_add: 'Zeile hinzufügen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_clone: 'Zeile klonen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_copy: 'Zeile in Zwischenspeicher kopieren',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_delete: 'Zeile entfernen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_down: 'Zeile nach unten',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_paste: 'Zeile aus Zwischenspeicher einfügen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_split: 'Aufteilen nach Zeile (Druck)',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          row_up: 'Zeile nach oben',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_actions: 'Angehangte Maßnahmen anzeigen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_cell_dialog: 'Zeige Zelle als Dialog zum Editieren an',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_clone: 'Klon hinzufügen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_element_picker: 'Auswahl der Elemente anzeigen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_files: 'Dateianhange anzeigen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_hidden_documents: 'Übersicht ausgeblendeter Dokumente anzeigen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          show_info: 'Information anzeigen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          toggle_comment: 'Kommentarfeld ein-/ausblenden',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          toggle_content: 'Zelleninhalt ein-/ausblenden',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          toggle_settings: 'Einstellungen ein-/ausblenden',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          total_amount_overlay: 'Overlay für Berechnung des Gesamtbetrags',
          undo: 'Rückgängig',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          upload_files: 'Dateien anhängen',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          upload_image: 'Bild hochladen',
          upscale: 'Hochskalieren',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          value_event_trigger_overlay:
            'Overlay für das Auslösen von Ereignissen bei Wert in Zellen'
        },
        workflow: {
          options: {
            autoAdjustContainerHeight:
              'Höhe des Containers automatisch anpassen:',
            autoAdjustContainerWidth:
              'Breite des Containers automatisch anpassen:',
            containerHeight: 'Höhe des Containers:',
            containerWidth: 'Breite des Containers:',
            defaultScale: 'Standard Zoom:',
            groupSingleNodeTrees: 'Einzelne Knoten gruppieren:',
            hideNodeLabelOverflow: 'Überfließenden Text von Knoten ausblenden:',
            markerHidden: 'Richtungsanzeige ausblenden:',
            rectangleColor: 'Standard-Hintergrundfarbe der Rechtecke:',
            rectangleHeight: 'Höhe des Rechtecks:',
            rectangleRadius: 'Radius des Rechtecks:',
            rectangleWidth: 'Breite des Rechtecks:',
            returnPathStrokeColor: 'Standard-Farbe für Rückwege:',
            returnPathStrokeVerticalOffset: 'Vertikaler Versatz für Rückwege:',
            scrollbarX: 'Horizontale Scrollbar:',
            scrollbarY: 'Vertikale Scrollbar:',
            spacingHorizontal: 'Horizontaler Abstand der Rechtecke:',
            spacingVertical: 'Vertikaler Abstand der Rechtecke:',
            userOptionPaths: 'Option für die Auswahl der Wege anzeigen:',
            userOptionScale: 'Option für Zoom:',
            userOptionVisible: 'Option für ein-/ausblenden von Workflow:',
            visibilityRightsEmployees: 'Sichtbar für Mitarbeiter:',
            visibilityRightsRoles: 'Sichtbar für Rollen:'
          }
        }
      }
    }
  }
}
