/**
 * Auflistung aller unterstützen Typen des Value-Events `set_dbentry_value`
 * und `get_dbentry_value`. Nur die hier angegebenen Typen können in der
 * Whitelist [[ALLOW_TABLES]] verwendet werden und ermöglichen so das passende
 * Parsen der Variablen-Values.
 */
export enum Types {
  /**
   * Normaler Zeichenstring
   */
  String = 'string',

  /**
   * Integer
   */
  Int = 'int',

  /**
   * Float
   */
  Float = 'float',

  /**
   * Boolean (Variable:  '1', 'true')
   */
  Bool = 'bool',

  /**
   * Datum (Variable: `DD.MM.YYYY[ hh:mm]`)
   */
  Date = 'date'
}
