<template>
  <v-dialog
    v-model="modalOpen"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    width="100%"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="onClickClose"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ dialogTitle }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <table
          v-for="(entry, i) in log.entries"
          :key="i"
          class="checklist-log"
        >
          <tr class="checklist-log-header">
            <td>
              {{ lang('ChecklistBuilder.misc.datetime') + ': ' + (entry.time || ' - ') }}
            </td>
            <td>
              {{ lang('ChecklistBuilder.misc.employee') + ': ' + (entry.employeeName || ' - ') }}
            </td>
            <td>
              {{ lang('ChecklistBuilder.misc.status') + ': ' + (status[entry.state] || ' - ') }}
            </td>
          </tr>
          <tr
            class="checklist-log-body"
          >
            <td
              v-if="entry.state === 0"
              colspan="3"
            >
              {{ lang('ChecklistBuilder.message.content.log_entry_deleted_from_db') }}
            </td>
            <td
              v-else
              class="checklist-viewer"
              colspan="3"
            >
              <checklist-list
                :data="log.data[entry.id]"
              />
            </td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin.js'
import ChecklistList from '@/components/ChecklistBuilder/Viewer/Checklist/List.vue'

export default {
  name: 'ChecklistLog',
  components: {
    ChecklistList
  },
  mixins: [dialogMixin],
  props: {
    data: {
      type: Object,
      required: false,
      default: null
    },
    log: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      status: {
        0: this.lang('ChecklistBuilder.misc.deleted'),
        1: this.lang('ChecklistBuilder.misc.modified'),
        2: this.lang('ChecklistBuilder.misc.created')
      }
    }
  },
  computed: {
    dialogTitle() {
      return this.lang('ChecklistBuilder.templates.settings.logging').replace(/[:]+$/, '')
    }
  },
  methods: {
    onClickClose() {
      this.$emit('close')
    },
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    }
  }
}
</script>

<style>
  .checklist-log {
    width: 100%;
    border-spacing: 0px;
    text-align: left;
    table-layout: fixed;
  }

  .checklist-log .checklist-log-header {
    font-size: 14px;
    font-weight: bold;
  }

  .checklist-log .checklist-log-header > td {
    padding: 10px;
    background: #ddd;
  }

  .checklist-log .checklist-log-body > td {
    padding: 5px 0px;
  }
</style>
