<template>
  <div
    v-if="hasHeader"
    class="checklist-lists-header-neo primary--text subtitle-1 font-weight-bold"
  >
    {{ getLocalization(checklist, 'label') }}
    <checklist-mandatory-icon
      v-if="hasMandatoryFields"
      :checklist="checklist"
      :data="data"
    />
    <template v-if="hasCloneButtons">
      <checklist-clone-buttons
        :checklist="checklist"
        :data="data"
      />
    </template>
  </div>
</template>

<script>
import ChecklistCloneButtons
  from '@/components/ChecklistBuilder/Viewer/Checklist/CloneButtons.vue'
import ChecklistMandatoryIcon
  from '@/components/ChecklistBuilder/Viewer/Checklist/MandatoryIcon.vue'

export default {
  name: 'ChecklistListHeader',
  components: {
    ChecklistCloneButtons,
    ChecklistMandatoryIcon
  },
  props: [
    'checklist',
    'data'
  ],
  computed: {
    hasMandatoryFields() {
      return this.data.mandatory[this.checklist.index]
    },
    hasHeader() {
      if (this.checklist.hide_header) {
        return false
      }
      if (!this.checklist.clone_of_checklist_id) {
        return true
      }
      if (this.checklist.clone_type !== 1) {
        return true
      }
      return false
    },
    hasCloneButtons() {
      if (!this.checklist.clone_type) {
        return false
      }
      const builder = this.data.ChecklistBuilder
      if (this.checklist.clone_type === builder.CLONE_TYPE_SAME_TAB_WITH_HEADER) {
        return false
      }
      return true
    }
  },
  methods: {
    getLocalization(object, property) {
      return this.data.ChecklistBuilder.getLocalization(object, property)
    }
  }
}
</script>
