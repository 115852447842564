<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
  >
    <div>
      {{ unknownTypeLabel }}
    </div>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeDefault',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    unknownTypeLabel() {
      let label = this.lang('ChecklistBuilder.message.content.cell_type_unknown')
      const type = this.cell.type
        .split('_')
        .map(t => t.charAt(0).toUpperCase() + t.slice(1))
        .join('')
      const typeLabelSelector = `components.checklist.templates.elements.${type}`
      const typeLabel = this.lang(typeLabelSelector)
      if (typeLabelSelector === typeLabel) {
        label += type
      } else {
        label += typeLabel
      }
      return label
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    }
  }
}
</script>
