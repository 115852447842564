<template>
  <!-- eslint-disable -->
  <component
    :is="loadComponent"
    :x="x"
    :y="y"
    :cell="cell"
    :checklist="checklist"
    :state="state"
    :clone="clone"
    :data-viewer="dataViewer"
    :data="data"
  />
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable vue/no-async-in-computed-properties */
import ChecklistCellTypeDefault from '@/components/ChecklistBuilder/Viewer/Cell/Type/Default.vue'

export default {
  name: 'ChecklistCellContent',
  components: {
    ChecklistCellTypeDefault
  },
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    enabledType() {
      const settings = this.data.ChecklistBuilder.getSettings()
      const excludedTypes = settings.EXCLUDE_TYPES
      if (!Array.isArray(excludedTypes)) {
        return true
      }
      return excludedTypes.indexOf(this.cell.type) === -1
    },
    loadComponent() {
      const type = this.cell.type
        .split('_')
        .map(t => t.charAt(0).toUpperCase() + t.slice(1))
        .join('')
      if (this.enabledType) {
        return () => import(`./Type/${type}.vue`).catch(() => ({
          render: h => h(ChecklistCellTypeDefault, {
            props: {
              x: this.x,
              y: this.y,
              cell: this.cell,
              checklist: this.checklist,
              state: this.state,
              clone: this.clone,
              data: this.data,
              dataViewer: this.dataViewer
            }
          })
        }))
      }
      return () => import(`./Type/Empty.vue`).catch(() => ({
        render: h => h(ChecklistCellTypeDefault, {
          props: {
            x: this.x,
            y: this.y,
            cell: this.cell,
            checklist: this.checklist,
            state: this.state,
            clone: this.clone
          }
        })
      }))
    }
  }
}
</script>

<style>

  .checklist-viewer .v-text-field__details,
  .checklist-viewer .element-content .v-text-field__details {
    display: none;
  }

  .checklist-viewer .v-text-field,
  .checklist-viewer .element-content .v-text-field {
    margin-top: 0px;
    padding-top: 0px;
  }

  .checklist-viewer .v-input__slot,
  .checklist-viewer .element-content .v-input__slot {
    margin-bottom: 0px;
  }

  .checklist-viewer .element-content > input[type="text"],
  .checklist-viewer .element-content > input[type="number"],
  .checklist-viewer .element-content > select,
  .checklist-viewer .element-content > textarea,
  .checklist-viewer .element-content-extra > textarea {
    border: 1px solid #7a7a7a;
    padding-left: 2px;
  }

  .checklist-viewer .element-content
  input[type=number]::-webkit-inner-spin-button,
  .checklist-viewer .element-content
  input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
  }

  .checklist-viewer .element-content-extra {
    padding: 4px 4px 0px 4px;
  }

  .checklist-viewer .element-content {
    padding: 5px;
  }

  .checklist-viewer .element-content button {
    text-transform: none;
  }

  .checklist-viewer .element-content:not(.cell_type_external_content) {
    vertical-align: middle;
    text-align: center;
    word-break: break-word;
  }

  .checklist-viewer .element-content:not(.cell_type_external_content)
  > input[type="checkbox"] {
    margin: 0px;
    height: 19px;
    width: 100%;
    display: block;
  }

  .checklist-viewer .element-content:not(.cell_type_external_content)
  > input[type="checkbox"]:hover {
      cursor: pointer;
  }

  .checklist-viewer .element-content:not(.cell_type_external_content)
  > input[type="checkbox"].disabled-content-custom:hover,
  .checklist-viewer .element-content:not(.cell_type_external_content)
  > input[type="checkbox"].disabled-cell:hover,
  .checklist-viewer .element-content:not(.cell_type_external_content)
  > input[type="checkbox"].disabled-content:hover {
    cursor: not-allowed;
  }

  .checklist-viewer  .element-content:not(.cell_type_external_content)
  output {
    padding-top: 0px;
    font-weight: normal;
  }

  .checklist-viewer  .element-content:not(.cell_type_external_content)
  button output {
    font-weight: bold;
  }

  .checklist-viewer  .element-content:not(.cell_type_external_content)
  .form-group {
    margin-bottom: 0px;
  }

  .checklist-viewer .element-content:not(.cell_type_external_content)
  .acknowledgement-overview tbody td {
    text-align: left;
  }

  .checklist-viewer .element-content:not(.cell_type_external_content)
  .acknowledgement-overview tbody tr:nth-child(odd) {
    background-color: #eee;
  }

  .checklist-viewer .element-content:not(.cell_type_external_content)
  .collection-links-overview tbody td {
    text-align: left;
  }

  .checklist-viewer .element-content:not(.cell_type_external_content)
  .collection-links-overview tbody tr:nth-child(odd) {
    background-color: #eee;
  }

  .checklist-viewer .element-content .content_display {
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin: 0px;
    min-height: 20px;
  }

  .checklist-viewer .element-content .content_display > * {
    margin: 0px;
  }

  /* Style fix für Sichtbarkeit von Checkboxen */
  .checklist-viewer .element-content input[type="checkbox"].disabled-content-custom,
  .checklist-viewer .element-content input[type="checkbox"].disabled-content,
  .checklist-viewer .element-content input[type="checkbox"].disabled-cell,
  .checklist-viewer .element-content input[type="checkbox"].disabled-checklist,
  .checklist-viewer .element-content input[type="checkbox"].disabled-collection {
    opacity: 1;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .checklist-viewer .element-content input[type="checkbox"].disabled-content-custom,
    .checklist-viewer .element-content input[type="checkbox"].disabled-content,
    .checklist-viewer .element-content input[type="checkbox"].disabled-cell,
    .checklist-viewer .element-content input[type="checkbox"].disabled-checklist,
    .checklist-viewer .element-content input[type="checkbox"].disabled-collection {
      opacity: 0.35;
    }
  }

  @supports (-ms-ime-align:auto) {
    .checklist-viewer .element-content input[type="checkbox"].disabled-content-custom,
    .checklist-viewer .element-content input[type="checkbox"].disabled-content,
    .checklist-viewer .element-content input[type="checkbox"].disabled-cell,
    .checklist-viewer .element-content input[type="checkbox"].disabled-checklist,
    .checklist-viewer .element-content input[type="checkbox"].disabled-collection {
      opacity: 0.35;
    }
  }
</style>
