<template>
  <!-- eslint-disable -->
  <div
    v-if="isCloneSameTab"
    :id="`checklist-${collection.id}-${checklist.index}`"
    :key="`${collection.id}_${checklist.index}`"
    class="checklist-content"
    :data-index="checklist.index"
  >
    <checklist-table
      :checklist="checklist"
      :data="data"
    />
  </div>
  <div
    v-else-if="isCloneSameTabWithHeader"
    :id="`checklist-${collection.id}-${checklist.index}`"
    class="checklist-content"
    :data-index="checklist.index"
  >
    <checklist-list-header
      :checklist="checklist"
      :data="data"
    />
    <checklist-table
      :checklist="checklist"
      :data="data"
    />
  </div>
  <!-- eslint-enable -->
</template>

<script>
import ChecklistTable from '@/components/ChecklistBuilder/Viewer/Checklist/Table.vue'
import ChecklistListHeader from '@/components/ChecklistBuilder/Viewer/Checklist/ListHeader.vue'

export default {
  name: 'ChecklistInlineClone',
  components: {
    ChecklistTable,
    ChecklistListHeader
  },
  props: [
    'collection',
    'checklist',
    'data'
  ],
  computed: {
    isCloneSameTab() {
      const builder = this.data.ChecklistBuilder
      return this.checklist.clone_type === builder.CLONE_TYPE_SAME_TAB
    },
    isCloneSameTabWithHeader() {
      const builder = this.data.ChecklistBuilder
      return this.checklist.clone_type === builder.CLONE_TYPE_SAME_TAB_WITH_HEADER
    }
  }
}
</script>
