<template>
  <div
    class="clone-buttons"
  >
    <button
      :class="classes"
      type="button"
      data-button-action="show_clone"
      :disabled="disabled"
      data-index="' + index + '"
      :title="lang('ChecklistBuilder.titles.show_clone')"
      @mousedown="onMouseDown"
      @click="onShowCloneClick"
    >
      <v-icon>
        mdi-plus-box-multiple
      </v-icon>
    </button>
    <button
      v-if="isCloneWithChild(checklist)"
      :class="classes"
      type="button"
      data-button-action="hide_clone"
      :disabled="disabled"
      data-index="' + index + '"
      :title="lang('ChecklistBuilder.titles.hide_clone')"
      @mousedown="onMouseDown"
      @click="onHideCloneClick"
    >
      <v-icon>
        mdi-close
      </v-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ChecklistCloneButtons',
  props: [
    'checklist',
    'data'
  ],
  data() {
    return {
      status: {}
    }
  },
  computed: {
    classes() {
      let classes = 'disable-collection'
      if (this.disabled) {
        classes += ' disabled-collection'
      }
      return classes
    },
    disabled() {
      if (this.data.disabled) {
        return true
      }
      if (!this.status.editable) {
        return true
      }
      if (this.data.ChecklistBuilder.getSetting('READ_ONLY')) {
        return true
      }
      return false
    }
  },
  created() {
    this.status = this.data.status[this.checklist.index]
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    isCloneWithChild(checklist) {
      return this.data.ChecklistBuilder.isCloneWithChild(checklist)
    },
    onMouseDown(event) {
      event.preventDefault()
      event.stopImmediatePropagation()
      event.stopPropagation()
      return false
    },
    onHideCloneClick(event) {
      event.preventDefault()
      event.stopImmediatePropagation()
      event.stopPropagation()
      const builder = this.data.ChecklistBuilder
      builder.hideChecklistClone(this.checklist)
      return false
    },
    onShowCloneClick(event) {
      event.preventDefault()
      event.stopImmediatePropagation()
      event.stopPropagation()
      const builder = this.data.ChecklistBuilder
      builder.showChecklistClone(this.checklist)
      return false
    }
  }
}
</script>

<style>
  .checklist-viewer .clone-buttons {
    float: right;
    padding-left: 10px;
  }

  .checklist-viewer .clone-buttons button {
      font-size: 13px;
      margin-left: 2px;
      margin-bottom: 3px;
      vertical-align: middle;
      color: #000;
      padding: 0px 5px;
  }

  .theme--light .checklist-viewer .clone-buttons button i:hover {
    color: #777777;
  }

  .theme--light .checklist-viewer .clone-buttons button i {
    font-size: 16px;
    color: #000;
  }

  .theme--dark .checklist-viewer .clone-buttons button i {
    font-size: 16px;
  }
</style>
