/**
 * Interface für die erlaubten Zelleneinstellungen. Gibt das Rückgabe-Objekt an.
 * Die Stuktur ist folgende: Zelltyp => Erlaubte Einstellungen
 */
export interface ListAllowCellSettings {
  [key: string]: string[]
}

/**
 * Gibt die Unterschützen Einstellungen der einszelnen Zellen zurück, die
 * Standardmäßig verwendet werden können.
 *
 * @returns Unterschützte Zellen-Einstellungen, der einzelnen Zellen
 */
export function getAllowCellSettings(): ListAllowCellSettings {
  // CamelCase für den Inhalt dieser Funktion deaktiviert. Den diese gibt nur
  // Werte für die Wegwerf-Checkliste zurück.
  // Anpassung dieser Werte an diese an die neuen  Standards anzupassen, würde
  // nur unnötigen Zeitaufwand bedeuten.
  return {
    empty: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'actions',
      'cell_background_color',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    label: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'font_size',
      'font_bold',
      'font_italic',
      'cell_background_color',
      'hide_in_report',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'on_click_callback',
      'on_click_callback_close',
      'on_click_callback_message_success',
      'on_click_callback_message_error',
      'on_click_callback_single',
      'on_click_callback_single_value',
      'on_click_callback_multi',
      'on_click_callback_multi_value_0',
      'on_click_callback_multi_value_1',
      'on_click_callback_multi_value_2',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_short: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'sql',
      'sql_apply_once',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_long: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'textarea_fixed_rows',
      'textarea_fixed_rows_count',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'sql',
      'sql_apply_once',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_formatted: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_display: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'button_alignment',
      'on_click_callback',
      'on_click_callback_close',
      'on_click_callback_message_success',
      'on_click_callback_message_error',
      'on_click_callback_single',
      'on_click_callback_single_value',
      'on_click_callback_multi',
      'on_click_callback_multi_value_0',
      'on_click_callback_multi_value_1',
      'on_click_callback_multi_value_2',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_display_formatted: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'button_alignment',
      'on_click_callback',
      'on_click_callback_close',
      'on_click_callback_message_success',
      'on_click_callback_message_error',
      'on_click_callback_single',
      'on_click_callback_single_value',
      'on_click_callback_multi',
      'on_click_callback_multi_value_0',
      'on_click_callback_multi_value_1',
      'on_click_callback_multi_value_2',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_replace: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'comment',
      'hide_in_report',
      'printing_display_comment',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'tokens',
      'on_click_callback',
      'on_click_callback_close',
      'on_click_callback_message_success',
      'on_click_callback_message_error',
      'on_click_callback_single',
      'on_click_callback_single_value',
      'on_click_callback_multi',
      'on_click_callback_multi_value_0',
      'on_click_callback_multi_value_1',
      'on_click_callback_multi_value_2',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_replace_formatted: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'comment',
      'hide_in_report',
      'printing_display_comment',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'button_alignment',
      'tokens',
      'on_click_callback',
      'on_click_callback_close',
      'on_click_callback_message_success',
      'on_click_callback_message_error',
      'on_click_callback_single',
      'on_click_callback_single_value',
      'on_click_callback_multi',
      'on_click_callback_multi_value_0',
      'on_click_callback_multi_value_1',
      'on_click_callback_multi_value_2',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_email_formatted: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'label',
      'comment',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_collection_links',
      'cell_background_color',
      'mandatory_field',
      'mandatory_field_hidden',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'button_alignment',
      'collection_links',
      'collection_links_select',
      'collection_links_label_link',
      'collection_links_select_label',
      'collection_links_select_viewer',
      'collection_links_delete_entry',
      'collection_links_show_table',
      'collection_links_open_entry',
      'emails',
      'email_btn_label',
      'email_by_user',
      'email_inactive_users',
      'email_text_editable',
      'email_acknowledgement_editable',
      'email_acknowledgement_label_link',
      'email_generated_document_attachment',
      'email_generated_document_attachment_zipped',
      'email_download_document_attachment',
      'email_download_document_attachment_zipped',
      'email_upload_document_attachment',
      'email_upload_document_attachment_zipped',
      'email_drawing_attachment',
      'email_drawing_attachment_zipped',
      'email_audit_created_by_user_as_recipient',
      'email_audit_affected_employee_supervisors_as_recipient',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_variables_formatted: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'comment',
      'hide_in_report',
      'printing_display_comment',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_editable',
      'display_checklists_width',
      'button_alignment',
      'on_click_callback',
      'on_click_callback_close',
      'on_click_callback_message_success',
      'on_click_callback_message_error',
      'on_click_callback_single',
      'on_click_callback_single_value',
      'on_click_callback_multi',
      'on_click_callback_multi_value_0',
      'on_click_callback_multi_value_1',
      'on_click_callback_multi_value_2',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_integer: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'min',
      'max',
      'step',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'sql',
      'sql_apply_once',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_float: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'min',
      'max',
      'step',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'sql',
      'sql_apply_once',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    document_management: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'actions',
      'documents',
      'documents_hide_add',
      'cell_background_color',
      'adopt_value',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'button_alignment',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    dropdown: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'dropdown_builder',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dropdown_sql: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'sql',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'on_value_changed',
      'status_icon',
      'locked_content',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    multiselect_sql: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'sql',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_multiselect',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    multiselect: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_multiselect',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'dropdown_builder',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    multiselect_open: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_multiselect',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'dropdown_builder',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    slider: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'min',
      'max',
      'step',
      'slider_min_hidden',
      'slider_max_hidden',
      'slider_value_hidden',
      'slider_value_custom_look',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'sql',
      'sql_apply_once',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_slider',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'colors',
      'color0',
      'color1',
      'color2',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    checkbox: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'sql',
      'sql_apply_once',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'on_value_changed',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    date_time: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'sql',
      'sql_apply_once',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'date_start_today',
      'date_start_offset_days',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    date: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'sql',
      'sql_apply_once',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'date_start_today',
      'date_start_offset_days',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    time: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'sql',
      'sql_apply_once',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    countdown: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'font_size',
      'font_bold',
      'font_italic',
      'cell_background_color',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'countdown_hide',
      'countdown_seconds',
      'countdown_minutes',
      'countdown_hours',
      'countdown_expired',
      'countdown_done',
      'countdown_done_label',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    radio: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'sql',
      'sql_apply_once',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'radio_btn_label_1',
      'radio_btn_label_2',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_amount: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_comment',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_amount_dates: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_comment',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'display_pattern',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    combine_selects: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'label',
      'label_position',
      'label_width',
      'inner_label_0',
      'inner_label_1',
      'inner_label_2',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'dropdown_builder',
      'dropdown_builder_1',
      'combine_sep_operator',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    combine_selects_custom: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'label',
      'label_position',
      'label_width',
      'inner_label_0',
      'inner_label_1',
      'inner_label_2',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'dropdown_builder',
      'dropdown_builder_1',
      'dropdown_builder_2',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    custom_button: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'cell_background_color',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'custom_button_label',
      'custom_button_width',
      'custom_button_on_click',
      'custom_button_action',
      'custom_button_url',
      'custom_button_params',
      'custom_button_email_attachments',
      'custom_button_email_addresses',
      'custom_button_email_message',
      'custom_button_medical_preparedness_data',
      'custom_button_substance_section',
      'custom_button_directive_type',
      'custom_button_open_emkg_dialog_readonly',
      'custom_button_open_emkg_tab',
      'custom_button_open_directive_window_readonly',
      'custom_button_open_substance_window_readonly',
      'custom_button_open_substitution_dialog_readonly',
      'custom_button_copy_emkg_locations_activities',
      'custom_button_directive_section',
      'custom_button_emkg_section',
      'custom_button_uw_create',
      'custom_button_uw_modify',
      'custom_button_uw_delete',
      'custom_button_uw_start_date',
      'custom_button_uw_start_date_offset',
      'custom_button_copy_ae_locations',
      'custom_button_copy_au_locations',
      'custom_button_set_audit_due_offset',
      'custom_button_set_audit_due_today',
      'custom_button_collection',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    employee: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'sql',
      'sql_apply_once',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    time_recording: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'cell_background_color',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    text_code: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_type_common',
      'printing_display_comment',
      'label',
      'label_position',
      'label_width',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'on_value_changed',
      'cell_background_color',
      'mandatory_field',
      'mandatory_field_hidden',
      'text_code_input_0',
      'text_code_input_1',
      'text_code_input_2',
      'text_code_input_3',
      'text_code_input_0_label',
      'text_code_input_1_label',
      'text_code_input_2_label',
      'text_code_input_3_label',
      'text_code_input_0_length',
      'text_code_input_1_length',
      'text_code_input_2_length',
      'text_code_input_3_length',
      'text_code_input_0_type',
      'text_code_input_1_type',
      'text_code_input_2_type',
      'text_code_input_3_type',
      'text_code_input_0_optional',
      'text_code_input_1_optional',
      'text_code_input_2_optional',
      'text_code_input_3_optional',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    external_content: [
      'inactive',
      'colspan',
      'rowspan',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_comment',
      'label',
      'label_position',
      'label_width',
      'cell_background_color',
      'load_external_content',
      'load_external_content_substance_section',
      'load_external_content_directive_section',
      'load_external_content_emkg_section',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    image: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'on_value_changed',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'image_data',
      'image_link',
      'image_link_analysis',
      'image_width',
      'image_height',
      'image_alternate_text',
      'image_clickable',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    drawing_board: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'on_value_changed',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'drawing_frame',
      'drawing_width',
      'drawing_height',
      'drawing_timestamp',
      'drawing_timestamp_date',
      'drawing_timestamp_time',
      'drawing_protected',
      'drawing_save_as_file',
      'drawing_board_canvas_width',
      'drawing_board_canvas_height',
      'drawing_board_stroke_color',
      'drawing_board_stroke_size',
      'drawing_board_stroke_type',
      'drawing_board_background_color',
      'drawing_board_auto_scale_canvas',
      'drawing_board_auto_scale_ribbons',
      'drawing_board_drawing_disabled',
      'drawing_board_advanced_down_scale',
      'drawing_board_ribbon',
      'drawing_board_ribbon_stroke_color',
      'drawing_board_ribbon_stroke_size',
      'drawing_board_ribbon_stroke_type',
      'drawing_board_ribbon_restore',
      'drawing_board_ribbon_delete',
      'drawing_board_ribbon_upload_image',
      'drawing_board_ribbon_auto_scale_image',
      'drawing_board_ribbon_save_as_document',
      'drawing_board_ribbon_open_document_in_tab',
      'drawing_board_ribbon_crop',
      'drawing_board_ribbon_scale',
      'drawing_board_ribbon_rotate',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    tree: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'on_value_changed',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'tree_unlock',
      'tree_source_label',
      'tree_source',
      'tree_source_unlock_label',
      'tree_source_unlock',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    employee_search: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'on_value_changed',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'employee_search_birthday',
      'employee_search_birthday_label',
      'employee_search_company',
      'employee_search_company_label',
      'employee_search_lastname',
      'employee_search_lastname_label',
      'employee_search_surname',
      'employee_search_surname_label',
      'alignment_vertical',
      'alignment_horizontal',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    task_tracking: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'hide_in_report',
      'printing_display_comment',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'label',
      'label_position',
      'label_width',
      'files',
      'comment',
      'cell_background_color',
      'task_tracking_checklist',
      'task_tracking_collection',
      'task_tracking_priority',
      'task_tracking_check_visibility_right',
      'task_tracking_check_editability_right',
      'alignment_vertical',
      'alignment_horizontal',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    multiselect_radio: [
      'inactive',
      'colspan',
      'rowspan',
      'display_requirements_inverse',
      'hide_cell_in_cloned_checklist',
      'label',
      'label_position',
      'label_width',
      'count',
      'count_hidden',
      'logging',
      'logging_display',
      'logging_display_never_locked',
      'status_icon',
      'locked_content',
      'mandatory_field',
      'mandatory_field_hidden',
      'files',
      'actions',
      'action_generation',
      'action_generation_tree_node_label',
      'action_generation_tree_node',
      'action_generation_title',
      'action_generation_description',
      'comment',
      'hide_in_report',
      'printing_display_type_multiselect',
      'printing_display_comment',
      'printing_display_actions',
      'printing_display_actions_label',
      'printing_display_actions_comment',
      'printing_display_actions_status',
      'printing_display_actions_done',
      'printing_display_actions_due',
      'printing_display_files',
      'printing_display_files_label',
      'printing_display_files_comment',
      'printing_display_files_due',
      'adopt_value',
      'cloneable',
      'cloneable_count',
      'cell_background_color',
      'display_checklists',
      'display_checklists_label',
      'display_checklists_width',
      'display_checklists_editable',
      'alignment_vertical',
      'alignment_horizontal',
      'button_alignment',
      'multiselect_radio_builder',
      'multiselect_radio_max_selected',
      'copyable_cell_value',
      'prevent_revaluation',
      'initialize',
      'value_events',
      'tooltip_image_data',
      'tooltip_image_height',
      'tooltip_image_width'
    ]
  }
}
