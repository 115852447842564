/**
 * Dialog-Mixin für die Kompatiblität der Offline-Checkliste
 * **ACHTUNG: Darf nur für die Wegwerf-Offline-Checkliste verwendet werden.**
 *
 * @deprecated Wird später mit der Wegwerf-Offline-Checkliste entfernt
 */
export default {
  props: {
    value: Boolean
  },
  computed: {
    modalOpen: {
      /**
       * Ob der Dialog offen ist
       *
       * @returns boolean
       */
      get() {
        return this.value
      },
      /**
       * Ob der Dialog offen ist
       *
       * @param newValue - Ob Dialog geschlossen oder offen ist
       */
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    /**
     * Schließt den Dialog
     */
    close() {
      this.$emit('input', false)
    }
  }
}
