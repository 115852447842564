<template>
  <table class="checklist-table-content" border="0">
    <thead>
      <tr>
        <th
          v-for="x in columnLength"
          :key="x"
          :style="`${columnWidth(x - 1)}`"
        />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, y) in cellMatrix"
        :key="y"
        :class="`${rowColored}`"
        :style="`${rowVisibility[y] || ''}`"
      >
        <template
          v-for="(cell, x) in row"
        >
          <td
            v-if="checkCellSpanVisibility(checklist.index, x, y)"
            :key="`${cell.id}_${x}`"
            :class="`${cellBorders}`"
            :colspan="(cell.settings || {}).colspan || 1"
            :rowspan="(cell.settings || {}).rowspan || 1"
          >
            <checklist-cell-container
              :x="x"
              :y="y"
              :cell="cell"
              :checklist="checklist"
              :data="data"
            />
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ChecklistCellContainer from '@/components/ChecklistBuilder/Viewer/Cell/Container.vue'

export default {
  name: 'ChecklistTable',
  components: {
    ChecklistCellContainer
  },
  props: [
    'checklist',
    'data'
  ],
  data() {
    return {
      rowVisibility: []
    }
  },
  computed: {
    columnsHeader() {
      const collection = this.data.collection
      const checklist = collection.Checklists[this.checklist.index]
      return checklist.ChecklistCells || []
    },
    cellBorders() {
      if (this.checklist.hide_cell_borders) {
        return ''
      }
      return 'cell-borders-view'
    },
    rowColored() {
      if (this.checklist.checklist_rows_colored) {
        return 'checklist-row-colored'
      }
      return ''
    },
    cells() {
      return this.checklist.ChecklistCells || [[]]
    },
    columnLength() {
      return this.cells.length
    },
    cellMatrix() {
      const matrix = []
      for (let j = 0, column; (column = this.cells[j]); j++) {
        for (let k = 0, cell; (cell = column[k]); k++) {
          if (!matrix[k]) {
            matrix[k] = []
          }
          matrix[k][j] = cell
        }
      }
      return matrix
    }
  },
  async created() {
    for (let y = 0, row; (row = this.cellMatrix[y]); y++) {
      this.rowVisibility[y] = await this.getRowVisibility(y, row.length)
    }
  },
  methods: {
    checkCellSpanVisibility(index, x, y) {
      const builder = this.data.ChecklistBuilder
      return builder.checkCellSpanVisibility(index, x, y)
    },
    async getRowVisibility(y, length) {
      let visible = false
      const index = this.checklist.index
      const builder = this.data.ChecklistBuilder
      for (let x = 0; x < length; x++) {
        if (await builder.checkCellVisibility(index, x, y)) {
          visible = true
        }
      }
      if (visible) {
        return ''
      }
      return 'display: none; '
    },
    columnWidth(index) {
      const widths = this.checklist.column_widths
      if (!Array.isArray(widths)) {
        return ''
      }
      if (!widths.length) {
        return ''
      }
      let width = widths[index]
      if (
        width === undefined ||
        width === null ||
        width === ''
      ) {
        return ''
      }
      const builder = this.data.ChecklistBuilder
      if (!builder.getSetting('VARIABLE_CELL_WIDTH')) {
        return ''
      }
      if (/^[0-9]+$/.test(width)) {
        width = width + 'px'
      }
      return `width: ${width}; `
    }
  }
}
</script>
