import { Types } from './types.enum'
import { ConfigContainer } from './config-container.interface'

export const ALLOW_TABLE: ConfigContainer = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  tbl_au_audits: {
    offlinePath: 'audits/offline',
    type: {
      name: Types.String,
      interval: Types.Int,
      due: Types.Date,
      done: Types.Bool,
      responsible: Types.Int,
      executor: Types.Int,
      externals: Types.String,
      inactive: Types.Bool,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      affected_employee: Types.Int,
      category: Types.Int,
      mandatory: Types.Bool,
      namePrefix: Types.String,
      nameSuffix: Types.String,
      checklistLocationId: Types.Int
    },
    mapping: {
      id: 'id',
      name: 'label',
      interval: 'interval',
      due: 'due',
      done: 'done',
      responsible: 'responsibleID',
      executor: 'executorID',
      externals: 'externals',
      createdFrom: 'createdFrom',
      link: 'linkID',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      checklists_new: 'checklists',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      completed_on_date: 'completedAt',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      completed_by_user: 'completedBy',
      inactive: 'inactive',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      affected_employee: 'affectedEmployeeID',
      category: 'categoryID',
      mandatory: 'mandatory',
      anonymous: 'anonymous',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      clone_of: 'cloneOfID',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      created_by_user: 'createdBy',
      progressDone: 'progressDone',
      progressTotal: 'progressTotal',
      namePrefix: 'labelPrefix',
      nameSuffix: 'labelSuffix',
      checklistLocationId: 'checklistLocationID'
    }
  }
}
