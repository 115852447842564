import store from '@/store'
import { Role } from '../store/user/role.interface'

/**
 * Stellt statische Funktionen zum Überprüfen der Rollen des
 * angemeldeten Benutzers bereit.
 */
export class RoleHelper {
  /**
   * Prüft, ob der angemeldete Benutzer die erforderliche Rolle hat.
   *
   * @param role - Erforderliche Rolle.
   * @returns true, wenn der Benutzer die erforderliche Rolle hat,
   * ansonsten false.
   */
  public static hasRole(role: Role): boolean {
    return this.hasRoles([role])
  }

  /**
   * Prüft, ob der angemeldete Benutzer alle erforderlichen Rollen hat.
   *
   * @param roles - Alle erforderlichen Rollen.
   * @returns true, wenn der Benutzer alle erforderlichen Rollen hat,
   * ansonsten false.
   */
  public static hasRoles(roles: Role[]): boolean {
    const userRoles = store.getters['user/roles'] as Role[]

    if (!Array.isArray(userRoles)) {
      return false
    }

    for (const role of roles) {
      const result = userRoles.find(
        (userRole): boolean => userRole.id === role.id
      )

      if (!result) {
        return false
      }
    }

    return true
  }
}
