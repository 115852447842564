<template>
  <td
    class="cell-button-collection"
    :style="`${buttonContainerStyle}`"
  >
    <template
      v-for="(type, i) in types"
    >
      <checklist-cell-button-content
        :key="`${i}_${type}`"
        :x="x"
        :y="y"
        :cell="cell"
        :checklist="checklist"
        :state="state"
        :buttontype="type"
        :data-viewer="dataViewer"
        :data="data"
      />
    </template>
  </td>
</template>

<script>
import ChecklistCellButtonContent
  from '@/components/ChecklistBuilder/Viewer/Cell/Button/Content.vue'

export default {
  name: 'ChecklistCellButtonContainer',
  components: {
    ChecklistCellButtonContent
  },
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'count',
    'types',
    'state',
    'dataViewer',
    'data'
  ],
  computed: {
    buttonContainerStyle() {
      return `width: ${this.count * 30}px; `
    }
  },
  methods: {
    getLocalization(object, property) {
      return this.data.ChecklistBuilder.getLocalization(object, property)
    }
  }
}
</script>

<style>
  .checklist-viewer .cell-content-container-view-inner .cell-button-collection button {
    display: inline-block;
    margin: 1px;
    position: relative;
    height: 28px;
    width: 28px;
    padding: 0px;
    min-width: 28px;
  }

  .checklist-viewer .cell-button-collection {
    text-align: center;
  }

</style>
